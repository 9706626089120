import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import axios from 'axios';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getToken } from './utils/AuthService';

const defaultURL = process.env.REACT_APP_NODE_ENV === 'PRODUCTION'
  ? process.env.REACT_APP_PRODUCTION_URL
  : process.env.REACT_APP_NODE_ENV === 'STAGING'
    ? process.env.REACT_APP_SANDBOX_URL
    : process.env.REACT_APP_LOCAL_URL;

// axios.defaults.baseURL = defaultURL;
axios.defaults.baseURL = '/v1';
// axios.defaults.baseURL = 'https://19x4gdyeh9cg.ngrok.xiaomiqiu123.top/login/v1';
axios.interceptors.request.use((config) => {
  if (!['/auth/login'].includes(config.url)) {
    const token = getToken();
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access.token}`,
    };
  }
  
  return config;
}, undefined);
ReactDOM.render(
  <App />,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
