import React, { useState } from 'react';
import { Typography, Layout, Row, Col, Button, Space, Breadcrumb, Form, message, Tooltip } from 'antd';
import { DeleteOutlined, CheckOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

import GameItemForm from '../../components/gameStore/GameItemForm';

const { Title, Link } = Typography;
const { Content } = Layout;

export default function CreateGameItem() {
  const [form] = Form.useForm();
  const token = getToken();
  const [promoItems, setPromoItems] = useState([]);

  const handleSave = async () => {
    try {
      const formData = form.getFieldsValue();

      const dataPackage = {
        name: formData.gameItemName,
        nameCN: formData.gameItemNameCN,
        price: formData.gameItemPrice,
        largeIconUrl: formData.gameItemLargeImage || formData.bannerImage,
        smallIconUrl: formData.gameItemSmallImage || formData.bannerImage,
        currency: formData.gameItemCurrency,
        group: formData.gameItemGroup,
        type: formData.gameItemCategory,
        effect: formData.effect,
        description: formData.descriptions,
        promoItems,
      };
      dataPackage.status = 'unpublished';
      await axios.post('items', dataPackage, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      message.success('Game item data saved successfully.');

      setTimeout(() => {
        window.location.href = '/gamestore';
      }, 1000);
    } catch (error) {
      message.error(`Failed to save game item data. ${error}`);
    }
  };

  const handlePublish = async () => {
    try {
      const formData = form.getFieldsValue();

      const dataPackage = {
        name: formData.gameItemName,
        nameCN: formData.gameItemNameCN,
        price: formData.gameItemPrice,
        largeIconUrl: formData.gameItemLargeImage || formData.bannerImage,
        smallIconUrl: formData.gameItemSmallImage || formData.bannerImage,
        currency: formData.gameItemCurrency,
        group: formData.gameItemGroup,
        type: formData.gameItemCategory,
        effect: formData.effect,
        description: formData.descriptions,
        promoItems,
      };
      dataPackage.status = 'published';
      await axios.post('items', dataPackage, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      message.success('Game item data saved successfully.');

      setTimeout(() => {
        window.location.href = '/gamestore';
      }, 1000);
    } catch (error) {
      const errorMessage = error.response.data.message;
      let showError = '';
      if (errorMessage.includes('largeIconUrl') || errorMessage.includes('smallIconUrl')) {
        showError += 'Please upload images!';
      } else {
        showError += 'Please fill in the form!';
      }
      message.error(`Failed to save game item data. ${showError}`);
    }
  };

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/gamestore">
            <LeftOutlined />
            Game Store
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create Game Item</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Row>
          <Col flex={4}>
            <Title
              level={3}
              style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '20px' }}>
              New Game Item
            </Title>
          </Col>
          <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              <Button type="primary" disabled>
                <DeleteOutlined />
                Delete
              </Button>
              <Tooltip title="Save changes.">
                <Button onClick={handleSave}>
                  <CheckOutlined />
                  Save
                </Button>
              </Tooltip>
              <Tooltip title="Save changes and publish game item." placement="topRight">
                <Button type="primary" onClick={handlePublish}>
                  <CheckOutlined />
                  Publish
                </Button>
              </Tooltip>
            </Space>
          </Col>
        </Row>
        <GameItemForm form={form} data={null} setPromoItems={setPromoItems} />
      </Content>
    </>
  );
}
