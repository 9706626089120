import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { Area } from '@ant-design/plots';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

export default function SalesGraph({ year }) {
  const token = getToken();
  const [salesDetails, setSalesDetails] = useState([]);
  const [loading, setLoading] = useState(true);
  const [finalConfig, setFinalConfig] = useState(null);

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      await axios
        .get('dashboard/yearSalesDetails', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: { year },
        })
        .then((res) => {
          setSalesDetails(res.data);
        })
        .catch((error) => {
          message.error(`Failed to retrieve data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, []);

  const data = [
    {
      Date: 'Jan',
      Sales: 0,
    },
    {
      Date: 'Feb',
      Sales: 0,
    },
    {
      Date: 'Mar',
      Sales: 0,
    },
    {
      Date: 'Apr',
      Sales: 0,
    },
    {
      Date: 'May',
      Sales: 0,
    },
    {
      Date: 'Jun',
      Sales: 0,
    },
    {
      Date: 'Jul',
      Sales: 0,
    },
    {
      Date: 'Aug',
      Sales: 0,
    },
    {
      Date: 'Sep',
      Sales: 0,
    },
    {
      Date: 'Oct',
      Sales: 0,
    },
    {
      Date: 'Nov',
      Sales: 0,
    },
    {
      Date: 'Dec',
      Sales: 0,
    },
  ];

  const config = {
    data,
    xField: 'Date',
    yField: 'Sales',
    smooth: true,
    xAxis: {
      range: [0, 1],
    },
    yAxis: {
      label: {
        formatter: (value) => `RM ${value}`,
      },
    },
    tooltip: {
      formatter: (datum) => {
        return {
          name: 'Sales',
          value: `RM ${datum.Sales.toFixed(2)}`,
        };
      },
    },
    areaStyle: () => {
      return {
        fill: 'l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff',
      };
    },
  };

  // Mapping between month numbers and names
  const monthNameMap = {
    1: 'Jan',
    2: 'Feb',
    3: 'Mar',
    4: 'Apr',
    5: 'May',
    6: 'Jun',
    7: 'Jul',
    8: 'Aug',
    9: 'Sep',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };
  
  useEffect(() => {
    salesDetails.forEach((result) => {
      const { month } = result._id;
      const monthName = monthNameMap[month];
      const { amount } = result;

      // Find the corresponding object in data and update the Amount
      const dataObj = data.find((item) => item.Date === monthName);
      if (dataObj) {
        dataObj.Sales = amount;
      }
    });
    setFinalConfig({ ...config, data });
    setLoading(false);
  }, [salesDetails]);

  if (loading || !finalConfig) {
    return null;
  }

  return <Area {...finalConfig} style={{ height: '200px' }} />;
}
