import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Statistic,
  Card,
  Select,
  Layout,
  Space,
  Typography,
  Table,
  List,
  message,
  Breadcrumb,
  Spin,
} from 'antd';

import './Dashboard.css';
import axios from 'axios';
import moment from 'moment';
import Cookie from 'js-cookie';
import SalesGraph from '../../components/dashboard/SalesGraph';
import { getToken } from '../../utils/AuthService';

const { Content } = Layout;
const { Title, Link } = Typography;

export default function Dashboard(props) {
  const { userRole } = props;
  const token = getToken();

  const userCookieStr = Cookie.get('user');
  const userData = JSON.parse(userCookieStr);

  const [loading, setLoading] = useState(true);
  const [playerStatistic, setPlayerStatistic] = useState({
    totalPlayer: 0,
    totalNewPlayer: 0,
    totalActivePlayer: 0,
    totalInactivePlayer: 0,
  });
  const [eggStatistic, setEggStatistic] = useState({
    totalEgg: 0,
    totalHatched: 0,
    totalGoblinSteal: 0,
    totalUnhatched: 0,
    totalExpired: 0,
  });
  const [voucherStatistic, setVoucherStatistic] = useState({
    totalVouchers: 0,
    totalHWVouchers: 0,
    totalMerchantVouchers: 0,
    totalRedeemedHW: 0,
    totalRedeemedAmountHW: 0,
    totalUnredeemedHW: 0,
    totalRedeemedMerchant: 0,
    totalRedeemedAmountMerc: 0,
    totalUnredeemedMerchant: 0,
  });

  const [merchantVoucherStatistic, setMerchantVoucherStatistic] = useState({
    totalRedeemedVouchers: 0,
    totalRedeemedAmount: 0,
    totalUnredeemedVouchers: 0,
    totalActiveVouchers: 0,
    totalInactiveVouchers: 0,
  });

  const [merchantAnalysis, setMerchantAnalysis] = useState([]);
  const [recentActivity, setRecentActivity] = useState([]);

  const [filterBy, setFilterBy] = useState('');

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const [graphYear, setGraphYear] = useState(currentYear);
  const [yearTotalSales, setYearTotalSales] = useState(null);
  const [yearList, setYearList] = useState([]);

  const onSelectGraphYear = (value) => {
    setGraphYear(value);
  };

  function getSelectYearList(data) {
    const list = [{ value: currentYear, label: currentYear }];
    for (let i = 0; i < data.length; i++) {
      if (data[i]._id !== currentYear) {
        list.push({ value: data[i]._id, label: data[i]._id });
      }
    }

    return list;
  }

  useEffect(() => {
    if (userRole === 'admin') {
      let isMounted = true;
      setLoading(true);
      const getYearTotalSales = async () => {
        try {
          const response = await axios.get('dashboard/yearTotalSales', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
          });

          // if no data
          if (response.data.length === 0) {
            setYearList([{ _id: currentYear }]);
            setYearTotalSales(0);
            setLoading(false);
          }

          if (isMounted && response.data.length !== 0) {
            setYearList(response.data);
            const data = response.data.find((item) => item._id === graphYear);
            if (!data) {
              setYearTotalSales(0);
            } else {
              setYearTotalSales(data.amount);
            }
            setLoading(false);
          }
        } catch (error) {
          message.error(`Failed to retrieve data. ${error}`);
          setLoading(false);
        }
      };
      getYearTotalSales();

      return () => {
        isMounted = false;
      };
    }
    return undefined;
  }, [graphYear]);

  useEffect(() => {
    if (userRole === 'admin') {
      setLoading(true);
      const params = {};
      if (filterBy !== '') {
        params.filterBy = filterBy;
      }
      const getPlayerData = async () => {
        await axios
          .get('dashboard/playerStatistic', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
            params,
          })
          .then((res) => {
            setPlayerStatistic(res.data);
            setLoading(false);
          })
          .catch((error) => {
            message.error(`Failed to retrieve data. ${error}`);
            setLoading(false);
          });
      };
      getPlayerData();
    }
  }, [filterBy]);

  useEffect(() => {
    if (userRole === 'admin') {
      setLoading(true);
      const params = {};
      if (filterBy !== '') {
        params.filterBy = filterBy;
      }
      const getEggData = async () => {
        await axios
          .get('dashboard/eggStatistic', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
            params,
          })
          .then((res) => {
            setEggStatistic(res.data);
            setLoading(false);
          })
          .catch((error) => {
            message.error(`Failed to retrieve data. ${error}`);
            setLoading(false);
          });
      };
      getEggData();
    }
  }, [filterBy]);

  useEffect(() => {
    if (userRole === 'admin') {
      setLoading(true);
      const params = {};
      if (filterBy !== '') {
        params.filterBy = filterBy;
      }
      const getVoucherData = async () => {
        await axios
          .get('dashboard/voucherStatistic', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
            params,
          })
          .then((res) => {
            setVoucherStatistic(res.data);
            setLoading(false);
          })
          .catch((error) => {
            message.error(`Failed to retrieve data. ${error}`);
            setLoading(false);
          });
      };
      getVoucherData();
    }
  }, [filterBy]);

  useEffect(() => {
    if (userRole === 'merchant') {
      setLoading(true);
      const params = {};
      if (filterBy !== '') {
        params.filterBy = filterBy;
      }
      const getMerchantVoucherData = async () => {
        await axios
          .get('dashboard/voucherStatistic', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
            params,
          })
          .then((res) => {
            setMerchantVoucherStatistic(res.data);
            setLoading(false);
          })
          .catch((error) => {
            message.error(`Failed to retrieve data. ${error}`);
            setLoading(false);
          });
      };
      getMerchantVoucherData();
    }
  }, [filterBy]);

  useEffect(() => {
    if (userRole === 'merchant') {
      setLoading(true);
      const getMerchantAnalysis = async () => {
        await axios
          .get('dashboard/merchantAnalysis', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
          })
          .then((res) => {
            setMerchantAnalysis(res.data);
          })
          .catch((error) => {
            message.error(`Failed to retrieve merchant analysis data. ${error}`);
          });
      };

      const getRecentActivity = async () => {
        const merchantIdResponse = await axios
          .get(`merchants/getMerchantId/${userData.id}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
          })
          .catch((error) => {
            message.error(`Failed to retrieve merchant data. ${error}`);
          });

        await axios
          .get('actionlogs/getVoucherHistory', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
            params: {
              mode: 'recent',
              merchantId: merchantIdResponse.data,
            },
          })
          .then((res) => {
            setRecentActivity(res.data.results);
          })
          .catch((error) => {
            message.error(`Failed to retrieve recent activity data. ${error}`);
          });
      };

      getMerchantAnalysis();
      getRecentActivity();
      setLoading(false);
    }
    return undefined;
  }, []);

  const branchSalesColumns = [
    { title: 'Branch', dataIndex: 'branchName', key: 'branchName' },
    {
      title: 'Total Redemptions',
      dataIndex: 'totalRedeems',
      key: 'totalRedeems',
      width: '20%',
      align: 'right',
    },
  ];

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/">Dashboard</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
  const onSelectFilter = (value) => {
    setFilterBy(value);
  };
  if (userRole === 'admin') {
    return (
      <>
        <DisplayBreadCrumb />
        <Content className="layout-background">
          <Space direction="vertical" style={{ display: 'flex' }}>
            <Spin spinning={loading}>
              <Row justify="end">
                <Col>
                  <Select
                    defaultValue={null}
                    onChange={onSelectFilter}
                    style={{ width: 200 }}
                    options={[
                      {
                        value: null,
                        label: 'All-time',
                      },
                      {
                        value: 'Yearly',
                        label: 'Yearly',
                      },
                      {
                        value: 'Monthly',
                        label: 'Monthly',
                      },
                    ]}
                  />
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col xs={12} lg={6}>
                  <Card bordered={false}>
                    <Statistic
                      title="Total Player"
                      value={playerStatistic.totalPlayer}
                      valueStyle={{
                        color: '#1890FF',
                      }}
                    />
                  </Card>
                </Col>
                <Col xs={12} lg={6}>
                  <Card bordered={false}>
                    <Statistic
                      title="Total New Player"
                      value={playerStatistic.totalNewPlayer}
                      valueStyle={{
                        color: '#1890FF',
                      }}
                    />
                  </Card>
                </Col>
                <Col xs={12} lg={6}>
                  <Card bordered={false}>
                    <Statistic
                      title="Total Active Player"
                      value={playerStatistic.totalActivePlayer}
                      valueStyle={{
                        color: '#1890FF',
                      }}
                    />
                  </Card>
                </Col>
                <Col xs={12} lg={6}>
                  <Card bordered={false}>
                    <Statistic
                      title="Total Inactive Player"
                      value={playerStatistic.totalInactivePlayer}
                      valueStyle={{
                        color: '#F5222D',
                      }}
                    />
                  </Card>
                </Col>
                <Col xs={12} lg={8}>
                  <Card bordered={false}>
                    <Statistic title="Total Egg" value={eggStatistic.totalEgg} />
                  </Card>
                </Col>
                <Col xs={12} lg={8}>
                  <Card bordered={false}>
                    <Statistic title="Total Hatched Egg" value={eggStatistic.totalHatched} />
                  </Card>
                </Col>
                <Col xs={12} lg={8}>
                  <Card bordered={false}>
                    <Statistic title="Total Unhatched Egg" value={eggStatistic.totalUnhatched} />
                  </Card>
                </Col>
                <Col xs={12} lg={6}>
                  <Card bordered={false}>
                    <Statistic title="Total Eggs Stolen By Goblin" value={eggStatistic.totalGoblinSteal} />
                  </Card>
                </Col>
                <Col xs={12} lg={6}>
                  <Card bordered={false}>
                    <Statistic title="Total Expired Egg" value={eggStatistic.totalExpired} />
                  </Card>
                </Col>
                <Col xs={12} lg={6}>
                  <Card bordered={false}>
                    <Statistic title="Total HeeCoin Balance" value={eggStatistic.totalHeeCoinBalance} />
                  </Card>
                </Col>
                <Col xs={12} lg={6}>
                  <Card bordered={false}>
                    <Statistic title="Total Redeemed Reload Pin" value={`RM ${eggStatistic.totalReloadPinRedeemed}`} />
                  </Card>
                </Col>
              </Row>
              <div style={{ margin: '20px 0px' }}>
                <Card bordered={false} style={{ height: '350px' }}>
                  <Row>
                    <Col flex="auto">
                      <p style={{ textAlign: 'left', marginBottom: '0.5em' }}>Total Sales</p>
                      <Title level={3} style={{ textAlign: 'left', marginBottom: '1em' }}>
                        RM { yearTotalSales ? yearTotalSales.toFixed(2) : '0'}
                      </Title>
                    </Col>
                    <Col flex="150px">
                      Year: <Select
                        defaultValue={graphYear}
                        onChange={onSelectGraphYear}
                        style={{ width: 100 }}
                        options={getSelectYearList(yearList)}
                      />
                    </Col>
                  </Row>
                  <SalesGraph key={graphYear} year={graphYear} />
                </Card>
              </div>
              <Row>
                <Title level={4} style={{ textAlign: 'left' }}>
                  Vouchers
                </Title>
              </Row>
              <Row>
                <Row gutter={[16, 16]}>
                  <Col xs={24} lg={8}>
                    <Card bordered={false}>
                      <Statistic
                        title="Total Vouchers"
                        valueStyle={{
                          color: '#1890FF',
                        }}
                        value={voucherStatistic.totalVouchers}
                      />
                    </Card>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Card bordered={false}>
                      <Statistic
                        title="Total Hee Won's Voucher"
                        valueStyle={{
                          color: '#1890FF',
                        }}
                        value={voucherStatistic.totalHWVouchers}
                      />
                    </Card>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Card bordered={false}>
                      <Statistic
                        title="Total Merchant's Voucher"
                        valueStyle={{
                          color: '#1890FF',
                        }}
                        value={voucherStatistic.totalMerchantVouchers}
                      />
                    </Card>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Card bordered={false}>
                      <Statistic
                        title="Total Redeemed of Hee Won's Voucher"
                        value={voucherStatistic.totalRedeemedHW}
                      />
                    </Card>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Card bordered={false}>
                      <Statistic
                        title="Total Redeemed Amount of Hee Won's Voucher"
                        value={`RM ${voucherStatistic.totalRedeemedAmountHW}`}
                      />
                    </Card>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Card bordered={false}>
                      <Statistic
                        title="Total Unredeemed of Hee Won's Voucher"
                        value={voucherStatistic.totalUnredeemedHW}
                      />
                    </Card>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Card bordered={false}>
                      <Statistic
                        title="Total Redeemed of Merchant's Voucher"
                        value={voucherStatistic.totalRedeemedMerchant}
                      />
                    </Card>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Card bordered={false}>
                      <Statistic
                        title="Total Redeemed Amount of Merchant's Voucher"
                        value={`RM ${voucherStatistic.totalRedeemedAmountMerc}`}
                      />
                    </Card>
                  </Col>
                  <Col xs={12} lg={8}>
                    <Card bordered={false}>
                      <Statistic
                        title="Total Unredeemed of Merchant's Voucher"
                        value={voucherStatistic.totalUnredeemedMerchant}
                      />
                    </Card>
                  </Col>
                </Row>
              </Row>
            </Spin>
          </Space>
        </Content>
      </>
    );
  }

  if (userRole === 'merchant') {
    return (
      <>
        <DisplayBreadCrumb />
        <Content className="layout-background">
          <Space direction="vertical" style={{ display: 'flex' }}>
            <Row justify="end">
              <Col>
                <Select
                  defaultValue={null}
                  onChange={onSelectFilter}
                  style={{ width: 200 }}
                  options={[
                    {
                      value: null,
                      label: 'All-time',
                    },
                    {
                      value: 'Yearly',
                      label: 'Yearly',
                    },
                    {
                      value: 'Monthly',
                      label: 'Monthly',
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row gutter={[16, 16]}>
              <Col xs={12} lg={8}>
                <Card bordered={false}>
                  <Statistic
                    title="Total Redeemed Voucher"
                    value={merchantVoucherStatistic.totalRedeemedVouchers}
                    valueStyle={{
                      color: '#1890FF',
                    }}
                  />
                </Card>
              </Col>
              <Col xs={12} lg={8}>
                <Card bordered={false}>
                  <Statistic
                    title="Total Redeemed Voucher Amount"
                    value={`RM ${merchantVoucherStatistic.totalRedeemedAmount}`}
                    valueStyle={{
                      color: '#1890FF',
                    }}
                  />
                </Card>
              </Col>
              <Col xs={12} lg={8}>
                <Card bordered={false}>
                  <Statistic
                    title="Total Unredeemed Voucher"
                    value={merchantVoucherStatistic.totalUnredeemedVouchers}
                    valueStyle={{
                      color: '#1890FF',
                    }}
                  />
                </Card>
              </Col>
              <Col xs={12} lg={8}>
                <Card bordered={false}>
                  <Statistic
                    title="Total Active Voucher"
                    value={merchantVoucherStatistic.totalActiveVouchers}
                    valueStyle={{
                      color: '#1890FF',
                    }}
                  />
                </Card>
              </Col>
              <Col xs={12} lg={8}>
                <Card bordered={false}>
                  <Statistic
                    title="Total Inactive Voucher"
                    value={merchantVoucherStatistic.totalInactiveVouchers}
                    valueStyle={{
                      color: '#F5222D',
                    }}
                  />
                </Card>
              </Col>
            </Row>
            <div style={{ margin: '20px 0px' }}>
              <Card bordered={false}>
                <Title level={4} style={{ textAlign: 'left', marginBottom: '2em' }}>
                  Analysis
                </Title>
                <Table
                  dataSource={merchantAnalysis}
                  columns={branchSalesColumns}
                  bordered={false}
                  pagination={false}
                  scroll={{ x: '100%' }}
                />
              </Card>
            </div>
            <Card bordered={false}>
              <Title level={4} style={{ textAlign: 'left' }}>
                Recent Activity
              </Title>
              <List
                itemLayout="horizontal"
                dataSource={recentActivity}
                renderItem={(item) => (
                  <>
                    <List.Item>
                      <List.Item.Meta description={item.description} />
                      <div>{moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
                    </List.Item>
                  </>
                )}
              />
            </Card>
          </Space>
        </Content>
      </>
    );
  }
}
