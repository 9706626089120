import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form, Input, InputNumber, Popconfirm, Table, Typography, Button, message, Modal, Row, Col, Tooltip, Select,
} from 'antd';
import { PlusOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { PrizePool } from '../../../../components/componentV2/PrizePoolV2/PrizePool';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  component,
  record,
  index,
  children,
  ...restProps
}) => {
  const comMap = {
    RankInput: <InputNumber addonBefore={`${record?.startRank} ~`} min={record?.startRank || 1} />,
    
  };
  const inputNode = comMap[component] || <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export function GamePrize({ value, onChange }) {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);

  const [editingData, setEditingData] = useState(null);

  const [modalActive, setModalActive] = useState(false);

  const [editPrizeRowKey, setEditPrizeRowKey] = useState(null);

  const [prizePoolOption, setPrizPoolOption] = useState({
    mode: 'obtainAmount',
    poolTypes: ['Items', 'HeeCoin', 'CardpackVoucher'],
    totalProbability: 100,
    tierVouchersProbability: 0,
    itemPool: [],
    cardpackPool: [],
    heeCoinPool: [],
    voucherPool: [],
  });

  const resetPrizePool = () => {
    setPrizPoolOption({
      mode: 'obtainAmount',
      poolTypes: ['Items', 'HeeCoin', 'CardpackVoucher'],
      totalProbability: 100,
      tierVouchersProbability: 0,
      itemPool: [],
      cardpackPool: [],
      heeCoinPool: [],
      voucherPool: [],
    });
  };
  
  const showModal = () => {
    setModalActive(true);
  };

  const handlePrizePoolCancel = () => {
    setModalActive(false);
  };
  
  const emitNewData = (newData) => {
    onChange(newData.map((item) => {
      const deepData = JSON.parse(JSON.stringify(item));
      delete deepData.key;
      delete deepData.isNewAdd;
      return deepData;
    }));
  };

  const handlePrizePoolOk = () => {
    const newData = [...data];
    const index = newData.findIndex((item) => editPrizeRowKey === item.key);

    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        prizePool: prizePoolOption,
      });
      setData(newData);
      emitNewData(newData);
    } 
    setEditingData(null);
    setModalActive(false);
  };
  
  useEffect(() => {
    if (value) {
      setData(value.map((item, index) => {
        return {
          ...item,
          key: index.toString(),
        };
      }));
    }
  }, [value]);
  
  const isEditing = (record) => {
    return editingData && (record.key === editingData.key);
  };

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingData(record);
  };

  const cancel = (record) => {
    setEditingData(null);
    if (record.isNewAdd) {
      setData(data.filter((item) => item.key !== record.key));
    }
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();

      const newData = [...data];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        delete item.isNewAdd;
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setData(newData);
      } else {
        delete row.isNewAdd;
        newData.push(row);
        setData(newData);
      }

      setTimeout(() => {
        emitNewData(newData);
        setEditingData(null);
      });
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const deleteRow = async (key) => {
    const newData = data.filter((item) => item.key !== key);
    setData(newData);

    setTimeout(() => {
      emitNewData(newData);
    });
  };

  const toPrizePool = (record) => {
    const { prizePool } = record;
    showModal();
    setEditPrizeRowKey(record.key);
    resetPrizePool();
    if (prizePool) {
      setPrizPoolOption({
        ...prizePool,
      });
    }
  };
  const columns = [
    {
      title: 'Rank',
      dataIndex: 'endRank',

      width: 100,
      render: (_row, record) => {
        const { startRank, endRank } = record;

        if (startRank === endRank) {
          return <span>{ startRank}</span>;
        }

        if (!endRank) {
          return <span>{ startRank}+</span>;
        }

        return <span>{startRank}~{endRank }</span>;
      },
      component: 'RankInput',

      editable: true,
      
    },
    {
      title: 'prize',
      dataIndex: 'prizePool',
      width: 100,
      render: (_row, record) => {
        const { prizePool } = record;

        let count = 0;
        
        if (prizePool) {
          count = prizePool.itemPool.length + prizePool
            .heeCoinPool.length + prizePool
            .voucherPool.length;
        }
        return <Button onClick={() => toPrizePool(record)}>Setting ({count})</Button>;
      },
    },
 
    {
      title: 'operation',
      dataIndex: 'operation',
      width: 100,
      render: (_row, record, index) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={() => cancel(record)}>
              <span style={{ cursor: 'pointer' }}>Cancel</span>
            </Popconfirm>
          </span>
        ) : (
          <span>

            <Typography.Link disabled={editingData || index !== data.length - 1} onClick={() => edit(record)}>
              Edit
            </Typography.Link>

            <Typography.Link disabled={editingData || index !== data.length - 1}>

              <Popconfirm title="Sure to delete?" onConfirm={() => deleteRow(record.key)}>
                <span style={{ cursor: 'pointer', color: editingData || index !== data.length - 1 ? 'rgba(0, 0, 0, 0.25)' : 'red', marginLeft: '16px' }}>Delete</span>
              </Popconfirm>
            </Typography.Link>
          </span>

        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        component: col.component,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const addRow = () => {
    if (editingData) {
      message.warn('Only one piece of data can be edited simultaneously');
      return;
    }

    const lastRow = data[data.length - 1];

    const startRank = lastRow ? lastRow.endRank + 1 : 1;

    const pushData = {
      key: Math.random(),
      startRank,
      endRank: null,
      prizePool: null,
      isNewAdd: true,
    };

    const newData = [...data, pushData];
    setData(newData);
    edit(pushData);
  };
 
  return (
    <Form form={form} component={false}>

      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={data}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />

      <Modal
        title="Prize config"
        destroyOnClose
        width={900}
        open={modalActive}
        centered
        closable={false}
        footer={(
          <>
            <Button onClick={handlePrizePoolCancel}>Cancel</Button>
            <Button type="primary" onClick={handlePrizePoolOk}>
           
              Save
            </Button>
          </>
        )}>
        <div style={{ minHeight: '400px' }}>
          <PrizePool
            option={prizePoolOption}
            onOptionChange={setPrizPoolOption}
            itemSelectOptionUrl="/incubatorConfig/getCommonItemPrizeOptions"
        />

        </div>
      </Modal>

      <Button style={{ width: '100%', marginTop: '8px' }} onClick={addRow}>+ Add Prize</Button>
    </Form>
  );
}
