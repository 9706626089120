import React, { useState, useEffect } from 'react';
import {
  Typography,
  Layout,
  Row,
  Col,
  Button,
  Space,
  Breadcrumb,
  Form,
  Skeleton,
  message,
} from 'antd';
import { DeleteOutlined, CheckOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { getToken } from '../../../utils/AuthService';

import { IncubatorDetailForm } from './component/IncubatorDetailFormV2';

export function CreateIncubatorPage() {
  const token = getToken();
  const history = useHistory();

  const handleSubmit = async (formData) => {
    try {
      await axios.post('incubatorConfig/createIncubatorConfig', formData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      message.success('Config created successfully.');

      setTimeout(() => {
        history.push('/incubatorConfigListPage');
      }, 1000);
    } catch (error) {
      message.error(`Failed to create Config. ${error}`);
    }
  };

  return (
    <>
      <IncubatorDetailForm isCreate onSubmit={handleSubmit} loading={false} />
    </>
  );
}
