import React, { useState, useEffect } from 'react';
import {
  Typography,
  Layout,
  Row,
  Col,
  Button,
  Space,
  Form,
  Input,
  Tooltip,
  Breadcrumb,
  message,
} from 'antd';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
import Cookie from 'js-cookie';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

const { Title, Link } = Typography;
const { Content } = Layout;

export default function Settings() {
  const [form] = Form.useForm();
  const [editpw, setEditpw] = useState(false);
  const startEdit = () => setEditpw(true);
  const token = getToken();

  const resetInput = () => {
    form.resetFields(['newPass', 'confirmNewPass']);
  };
  const cancelEdit = () => {
    resetInput();
    setEditpw(false);
  };

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/settings">Settings</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  // fetch user data from cookies
  const userCookieStr = Cookie.get('user');
  const userData = JSON.parse(userCookieStr);

  useEffect(() => {
    form.setFieldsValue({
      userFullName: userData.name,
      userEmail: userData.email,
    });
  }, [userData]);

  const handleUpdate = async () => {
    const formData = form.getFieldsValue();
    const dataPackage = {
      name: formData.userFullName,
      email: formData.userEmail,
    };
    if (formData.newPass && formData.confirmNewPass) {
      if (formData.newPass === formData.confirmNewPass) {
        dataPackage.password = formData.newPass;
      } else {
        message.error('Passwords does not match.');
        return;
      }
    }

    try {
      await axios.patch(`users/${userData.id}`, dataPackage, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      });

      // updates name and email in cookies
      userData.name = dataPackage.name;
      userData.email = dataPackage.email;
      Cookie.set('user', JSON.stringify(userData));

      message.success('Updated user info successfully.');

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      let errorMessage;
      if (error.response.data.message.includes('"email" must be a valid email')) {
        errorMessage = 'Invalid email address.';
      }
      if (error.response.data.message.includes('password')) {
        if (errorMessage) {
          errorMessage += ' Password must be at least 8 characters and contain at least 1 number and letter.';
        } else {
          errorMessage = 'Password must be at least 8 characters and contain at least 1 number and letter.';
        }
      }
      message.error(`Failed to update account info. ${errorMessage}`);
    }
  };

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Row>
          <Col xs={24} sm={24} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }}>
            <Row>
              <Col flex={4}>
                <Title level={3} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  Account Settings
                </Title>
              </Col>
              <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Space>
                  <Button
                    onClick={resetInput}
                    disabled={!editpw}
                    type="primary"
                    style={{
                      backgroundColor: editpw ? 'grey' : null,
                      borderColor: editpw ? 'grey' : null,
                    }}>
                    Reset
                  </Button>
                  {editpw ? <Button onClick={cancelEdit}>Cancel</Button> : ''}
                  <Button type="primary" onClick={handleUpdate}>
                    <CheckOutlined />
                    Update Profile
                  </Button>
                </Space>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <div className="content-area" style={{ margin: '20px 0px' }}>
                  <Form form={form} layout="vertical">
                    <Form.Item name="userFullName" label="Name">
                      <Input placeholder="Name" />
                    </Form.Item>
                    <Form.Item name="userEmail" label="Email Address">
                      <Input placeholder="User Email" />
                    </Form.Item>
                    {editpw ? (
                      <>
                        <Form.Item name="newPass" label="New Password">
                          <Input.Password placeholder="Enter your new password" />
                        </Form.Item>
                        <Form.Item name="confirmNewPass" label="Confirm New Password">
                          <Input.Password placeholder="Confirm your new password" />
                        </Form.Item>
                      </>
                    ) : (
                      <Form.Item
                        name="userPassword"
                        initialValue="currentuserpassword"
                        label={(
                          <Row
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              flexDirection: 'row',
                            }}>
                            <Col>Password</Col>
                            <Col style={{ paddingLeft: '10px' }}>
                              <Tooltip title="Edit Password" placement="right">
                                <EditOutlined onClick={startEdit} />
                              </Tooltip>
                            </Col>
                          </Row>
                        )}>
                        <Input.Password disabled visibilityToggle={false} />
                      </Form.Item>
                    )}
                  </Form>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
    </>
  );
}
