import React, { useState, useEffect } from 'react';
import {
  Typography,
  Layout,
  Row,
  Col,
  Button,
  Space,
  Menu,
  Form,
  Input,
  Breadcrumb,
  message,
  Skeleton,
} from 'antd';
import { DeleteOutlined, CheckOutlined, PlusCircleOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

const { Title, Link } = Typography;
const { Content } = Layout;

// if getdata = true, add item for every data
// else default at 1
// add branch button add 1 item

const token = getToken();

const BranchMenu = ({ activeBranch, branchList, onBranchChange }) => {
  const handleMenuClick = ({ key }) => {
    onBranchChange(key);
  };

  return (
    <Menu
      defaultSelectedKeys={['1']}
      selectedKeys={[activeBranch]}
      onClick={handleMenuClick}
      style={{ textAlign: 'center' }}>
      {branchList.map((branch) => (
        <>
          <Menu.Item key={branch}>Branch {branch}</Menu.Item>
          <Menu.Divider />
        </>
      ))}
    </Menu>
  );
};

export default function BranchDetails() {
  const [form] = Form.useForm();
  const merchantID = window.location.href.split('/')[4];

  const [formValues, setFormValues] = useState({});
  const [loading, setLoading] = useState(true);

  const [branches, setBranches] = useState(['1']);
  const [activeBranch, setActiveBranch] = useState('1');

  useEffect(() => {
    if (formValues[activeBranch]) {
      form.setFieldsValue(formValues[activeBranch]);
    } else {
      form.resetFields();
    }

    console.log(`${JSON.stringify(formValues)}`);
  }, [activeBranch]);

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      await axios
        .get(`branch?merchantId=${merchantID}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            limit: 100,
            page: 1,
          },
        })
        .then((res) => {
          const { results } = res.data;
          if (results.length > 0) {
            const transformedObject = results.reduce((acc, curr, index) => {
              const key = (index + 1).toString();
              acc[key] = curr;
              return acc;
            }, {});

            setFormValues(transformedObject);

            const updatedArray = Array.from(
              { length: results.length }, 
              (_, index) => (index + 1).toString(),
            );

            setBranches(updatedArray);
          }
        })
        .catch((error) => {
          message.error(`Failed to retrieve merchant's branch data. ${error}`);
          setLoading(false);
        });
    };

    getData();
  }, []);

  useEffect(() => {
    form.setFieldsValue(formValues[activeBranch]);
    setLoading(false);
  }, [formValues, branches]);

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/merchants">
            <LeftOutlined />
            Merchants
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Branch Details</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const deleteBranch = () => {
    // remove saved form values
    const newFormValues = { ...formValues };
    delete newFormValues[activeBranch];
    setFormValues(newFormValues);

    const newBranches = branches.filter((branch) => branch !== activeBranch);
    setBranches([...newBranches]);
    let newActive;
    if (Number(activeBranch) - 1 !== 0) {
      newActive = String(Number(activeBranch) - 1);
    } else {
      newActive = String(Number(activeBranch) + 1);
    }
    setActiveBranch(newActive);
  };

  const handleSave = () => {
    const updatedFormValues = {
      ...formValues,
      [activeBranch]: {
        ...form.getFieldsValue(),
        id: formValues[activeBranch]?.id,
      },
    };

    const updatedData = Object.keys(updatedFormValues).reduce((acc, key) => {
      const obj = updatedFormValues[key];
      const { archive, QRbase64, createdAt, updatedAt, ...noArchive } = obj;
      const updatedobj = { ...noArchive, merchantId: merchantID };
      acc[key] = updatedobj;
      return acc;
    }, {});

    axios
      .post('branch', updatedData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      })
      .then(() => {
        message.success('Branch data updated.');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        message.error(`Failed to update merchant's branch data. ${error.response.data.message}`);
      });
  };

  const addBranch = () => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [activeBranch]: {
        ...form.getFieldsValue(),
        id: prevFormValues[activeBranch]?.id,
      },
    }));
    const newBranchNumber = String(branches.length + 1);
    setBranches([...branches, newBranchNumber]);
    setActiveBranch(newBranchNumber);
  };

  const handleBranchChange = (branch) => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [activeBranch]: {
        ...form.getFieldsValue(),
        id: prevFormValues[activeBranch]?.id,
      },
    }));
    setActiveBranch(branch);
  };

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background branch-details">
        <Row>
          <Col flex={4}>
            <Title level={3} style={{ display: 'flex', justifyContent: 'flex-start' }}>
              Add Branch
            </Title>
          </Col>
          <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              <Button
                danger
                onClick={deleteBranch}
                disabled={activeBranch !== String(branches.length) || activeBranch === '1'}>
                <DeleteOutlined />
                Delete
              </Button>
              <Button type="primary" onClick={handleSave}>
                <CheckOutlined />
                Save
              </Button>
            </Space>
          </Col>
        </Row>
        <Row gutter={16} style={{ height: '100%' }}>
          {loading === false ? (
            <>
              <Col xs={12} lg={4} style={{ height: '100%' }}>
                <div
                  className="content-area"
                  style={{ display: 'flex', padding: 0, height: '100%' }}>
                  <div style={{ flex: 1 }}>
                    <BranchMenu
                      activeBranch={activeBranch}
                      branchList={branches}
                      onBranchChange={handleBranchChange}
                    />
                    <div
                      style={{
                        alignSelf: 'flex-end',
                      }}>
                      <Button
                        type="default"
                        icon={<PlusCircleOutlined />}
                        style={{ width: '100%', color: '#1890FF', height: '40px' }}
                        onClick={addBranch}>
                        Add Branch
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={20}>
                <Form form={form} layout="vertical">
                  <Row>
                    <Col xs={24} lg={14}>
                      <div className="content-area">
                        <Form.Item
                          name="branchName"
                          label="Branch Name"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter branch name!',
                            },
                          ]}>
                          <Input placeholder="Enter Branch Name (e.g. Mid Valley Megamall)" />
                        </Form.Item>
                        <Form.Item
                          name="branchAddress"
                          label="Location / Address"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter branch address!',
                            },
                          ]}>
                          <Input placeholder="Enter Branch Address" />
                        </Form.Item>
                        <Form.Item
                          name="branchEmail"
                          label="E-mail Address"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter email address!',
                            },
                            {
                              type: 'email',
                              message: 'Input must be a valid email',
                            },
                          ]}>
                          <Input placeholder="Enter Branch Email Address" />
                        </Form.Item>
                        <Form.Item
                          name="branchContactNum"
                          label="Contact Number"
                          rules={[
                            {
                              required: true,
                              message: 'Please enter contact number!',
                            },
                            {
                              pattern: /^(\+60\d{9,10}|01\d{8,9})$/,
                              message:
                                "Phone number format must be '+60xxxxxxxxxx' or '01xxxxxxxxx'.",
                            },
                          ]}>
                          <Input placeholder="Enter Branch Contact Number" />
                        </Form.Item>
                        <Form.Item
                          name="password"
                          label="Password (Please enter for newly added branches or password change)">
                          <Input.Password placeholder="Enter Password" />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col xs={24} lg={10}>
                      <div className="content-area">
                        <Form.Item name="PIC" label="Person in Charge" rules={[{ required: true }]}>
                          <Input placeholder="Enter PIC's Name" />
                        </Form.Item>
                        <Form.Item
                          name="PICnumber"
                          label="Contact Number"
                          rules={[
                            { required: true,
                              message: 'Please enter PIC contact number!',
                            },
                            {
                              pattern: /^(\+60\d{9,10}|01\d{8,9})$/,
                              message:
                                "Phone number format must be '+60xxxxxxxxxx' or '01xxxxxxxxx'.",
                            },
                          ]}>
                          <Input placeholder="Enter PIC Contact Number" />
                        </Form.Item>
                        <Form.Item
                          name="PICemail"
                          label="E-mail Address"
                          rules={[
                            { 
                              required: true, 
                              message: 'Please enter PIC email address!',
                            },
                            {
                              type: 'email',
                              message: 'PIC email must be a valid email',
                            },
                          ]}>
                          <Input placeholder="Enter PIC's Email Address" />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </>
          ) : (
            <Skeleton />
          )}
        </Row>
      </Content>
    </>
  );
}
