import React, { useState, useEffect, useCallback } from 'react';
import { utils, writeFileXLSX } from 'xlsx';
import {
  Table,
  Layout,
  Button,
  Row,
  Col,
  Select,
  Space,
  DatePicker,
  Tag,
  Typography,
  Modal,
  Input,
  Breadcrumb,
  Skeleton,
  message,
  Form,
  Slider,
} from 'antd';
import {
  ExportOutlined,
  // ImportOutlined,
  EditOutlined,
  // CheckCircleOutlined,
  // ScanOutlined,
  // CloseCircleOutlined,
} from '@ant-design/icons';

import moment from 'moment';
// import Cookie from 'js-cookie';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';
import { getToken } from '../../utils/AuthService';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Text, Link } = Typography;
const { Option } = Select;

export default function VoucherList(props) {
  const { userRole } = props;

  const history = useHistory();

  const toEdit = (editData) => {
    history.push(`/vouchers/${editData.key}/edit`);
  };

  const toPlayer = (editData) => {
    history.push(`/voucherPlayerPage/${editData.key}`);
  };

  const adminVoucherCols = [
    {
      title: 'Merchant',
      dataIndex: 'merchantName',
      key: 'merchantName',
      width: 200,
    },
    {
      title: 'Voucher',
      key: 'voucher',
      width: 200,
      render: (record) => {
        let res;
  
        if (record.label) {
          res = <><Text>[{record.label}]</Text> {record.voucher}</>;
        } else {
          res = record.voucher;
        }
  
        return res;
      },
    },
    {
      title: 'Discount Amount',
      dataIndex: 'discountAmount',
      key: 'discountAmount',
      render: (data) => `RM ${data}`,
      width: 150,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: 100,
      align: 'center',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      width: 100,
      align: 'center',
    },
    {
      title: 'Distributed',
      dataIndex: 'distributedAmount',
      key: 'distributedAmount',
      width: 110,
      align: 'center',
    },
    {
      title: 'Redeemed',
      dataIndex: 'redeemedAmount',
      key: 'redeemedAmount',
      width: 110,
      align: 'center',
    },
    // {
    //   title: 'Voucher Code',
    //   dataIndex: 'voucherCode',
    //   key: 'voucherCode',
    // },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (data) => (data ? moment(data).format('YYYY-MM-DD') : '-'),
      width: 150,
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      render: (data) => (data ? moment(data).format('YYYY-MM-DD') : '-'),
      width: 150,
    },
    {
      title: 'Expired Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (data) => (data ? moment(data).format('YYYY-MM-DD') : '-'),
      width: 150,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        let res;
  
        switch (status) {
          case 'Active':
            res = <Tag color="blue">Active</Tag>;
            break;
          case 'Inactive':
            res = <Text type="secondary">Inactive</Text>;
            break;
          case 'expired':
            res = <Tag color="red">Expired</Tag>;
            break;
          default:
            res = '';
            break;
        }
  
        return res;
      },
      width: 100,
    },
    {
      title: 'Created Date',
      dataIndex: 'createDate',
      key: 'createDate',
      render: (data) => (data ? moment(data).format('YYYY-MM-DD HH:mm:ss') : '-'),
      width: 200,
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 120,
      render: (record) => (
        // <Button type="text" href={`/vouchers/${record.key}/edit`}>
        //   edit
        // </Button>
  
        <span>
  
          <Typography.Link onClick={() => toEdit(record)}>
            Edit
          </Typography.Link>
  
          <Typography.Link onClick={() => toPlayer(record)}>
  
            <span style={{ cursor: 'pointer', color: '#1890FF', marginLeft: '16px' }}>Player</span>
  
          </Typography.Link>
        </span>
        
      ),
    },
  ];

  // merchant redeem barcode modal trio
  // const [scanBarcodeActive, setScanBarcodeActive] = useState(false);
  // const [successModalActive, setSuccessModalActive] = useState(false);
  // const [failModalActive, setFailModalActive] = useState(false);

  // value in scan barcode modal inputfield
  // const [voucherCode, setVoucherCode] = useState('');
  // const updateVoucherCode = (e) => {
  //   setVoucherCode(e.target.value);
  // };

  // const showFailModal = () => {
  //   setFailModalActive(true);
  // };
  // const failSubmit = () => {
  //   setFailModalActive(false);
  //   // TODO: post processes here
  // };

  // const showSuccessModal = () => {
  //   setSuccessModalActive(true);
  // };
  // const successDone = () => {
  //   setSuccessModalActive(false);
  //   // currently showing next modal for demo/testing purposes
  //   showFailModal();
  // };

  // const showScanModal = () => {
  //   setScanBarcodeActive(true);
  // };
  // const submitScan = () => {
  //   setScanBarcodeActive(false);
  //   setVoucherCode('');
  //   // currently showing next modal for demo/testing purposes
  //   showSuccessModal();
  // };

  // export modal
  const [exportModalActive, setExportModalActive] = useState(false);
  const showExportModal = () => {
    setExportModalActive(true);
  };
  // const handleOk = () => {
  //   setExportModalActive(false);
  // };
  // closes all modals

  const token = getToken();
  const [loading, setLoading] = useState(true);

  const [voucherList, setVoucherList] = useState([]);

  const [voucherListData, setVoucherListData] = useState([]);

  const [adminFilterForm] = Form.useForm();
  const [merchantFilterForm] = Form.useForm();

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [filterData, setFilterData] = useState({});

  const [merchantList, setMerchantList] = useState([]);

  const [maxDiscount, setMaxDiscount] = useState();
  const [discountRange, setDiscountRange] = useState([]);

  const handleCancel = () => {
    setExportModalActive(false);
    // setScanBarcodeActive(false);
    // setSuccessModalActive(false);
    // setFailModalActive(false);
    // // resets the input field of scan barcode modal
    // setVoucherCode('');
  };

  // fetch user data from cookies
  // const userCookieStr = Cookie.get('user');
  // const userData = JSON.parse(userCookieStr);

  // const merchantUserID = userData.id;
  // const [merchantQRstr, setMerchantQRstr] = useState('');

  useEffect(() => {
    const getData = async () => {
      await axios
        .get('merchants', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setMerchantList(res.data.results);
        })
        .catch((error) => {
          message.error(`Failed to retrieve merchant data. ${error}`);
        });
    };

    // const getMerchantQR = async () => {
    //   await axios
    //     .get(`merchants/getQR/${merchantUserID}`, {
    //       headers: {
    //         'Content-Type': 'application/json',
    //         Authorization: `Bearer ${token.access.token}`,
    //       },
    //     })
    //     .then((res) => {
    //       setMerchantQRstr(res.data);
    //     })
    //     .catch((error) => {
    //       console.log(`No merchant data ${error}`);
    //     });
    // };

    if (userRole === 'admin') {
      getData();
    }
    // if (userRole === 'merchant') {
    //   getMerchantQR();
    // }
  }, []);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleOk = useCallback(() => {
    let selectedRows = [];

    if (rowSelection.selectedRowKeys.length === 0) {
      // Export all rows when no rows are selected
      if (userRole === 'admin') {
        selectedRows = voucherListData.map(({ key, balance, redeemedAmount, ...rest }) => rest);
      }
      if (userRole === 'merchant') {
        selectedRows = voucherListData.map(
          ({ key, merchantName, quantity, voucherCode, createDate, ...rest }) => rest,
        );
      }
    } else {
      // Export selected rows
      if (userRole === 'admin') {
        selectedRows = voucherListData
          .filter((row) => rowSelection.selectedRowKeys.includes(row.key))
          .map(({ key, balance, ...rest }) => rest);
      }
      if (userRole === 'merchant') {
        selectedRows = voucherListData
          .filter((row) => rowSelection.selectedRowKeys.includes(row.key))
          .map(
            (
              { 
                key,
                merchantName, 
                quantity, 
                voucherCode, 
                createDate, 
                distributedAmount, 
                ...rest
              },
            ) => rest,
          );
      }
    }

    // Rename column headers
    const renamedColumns = {
      merchantName: 'Merchant Name',
      voucher: 'Voucher Name',
      discountAmount: 'Discount Amount (RM)',
      quantity: 'Quantity',
      availableAmount: 'Available',
      // voucherCode: 'Voucher Code',
      balance: 'Balance',
      distributedAmount: 'Distributed',
      redeemedAmount: 'Redeemed',
      startDate: 'Start Date',
      endDate: 'End Date',
      expiryDate: 'Expiry Date',
      status: 'Status',
      createDate: 'Created At',
    };

    const transformedRows = selectedRows.map((row) => (
      Object.entries(row).reduce((acc, [key, value]) => {
        const renamedKey = renamedColumns[key] || key;
        if (key === 'createDate' || key === 'expiryDate') {
          const date = new Date(value);
          const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Asia/Kuala_Lumpur',
          };
          return { ...acc, [renamedKey]: date.toLocaleString('en-US', options) };
        }
        return { ...acc, [renamedKey]: value };
      }, {})));  
  
    const currentDate = new Date();
    const formattedDate = currentDate
      .toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(/[/:\s]/g, '_');

    const ws = utils.json_to_sheet(transformedRows);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    writeFileXLSX(wb, `Vouchers_${formattedDate}.xlsx`);

    setExportModalActive(false);
  }, [voucherListData, rowSelection.selectedRowKeys, userRole]);

  function getMerchantList(merchantData) {
    const list = [{ value: 'all', label: 'All' }];
    for (let i = 0; i < merchantData.length; i++) {
      list.push({ value: merchantData[i]._id, label: merchantData[i].name });
    }

    return list;
  }

  const merchantVoucherCols = [
    {
      title: 'Voucher',
      dataIndex: 'voucher',
      key: 'voucher',
      width: 200,
    },
    {
      title: 'Discount Amount',
      dataIndex: 'discountAmount',
      key: 'discountAmount',
      render: (data) => `RM ${data}`,
      align: 'right',
      width: 150,
    },
    {
      title: 'Available',
      dataIndex: 'availableAmount',
      key: 'availableAmount',
      align: 'right',
      width: 100,
    },
    {
      title: 'Redeemed',
      dataIndex: 'redeemedAmount',
      key: 'redeemedAmount',
      align: 'right',
      width: 110,
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
      align: 'right',
      width: 100,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      render: (data) => (data ? moment(data).format('YYYY-MM-DD') : '-'),
      width: 130,
    },
    {
      title: 'Expired Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (data) => (data ? moment(data).format('YYYY-MM-DD') : '-'),
      width: 130,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 100,
      render: (status) => {
        let res;

        switch (status) {
          case 'Active':
            res = <Tag color="blue">Active</Tag>;
            break;
          case 'Inactive':
            res = <Text type="secondary">Inactive</Text>;
            break;
          case 'expired':
            res = <Tag color="red">Expired</Tag>;
            break;
          default:
            res = '';
            break;
        }

        return res;
      },
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 90,
      render: (record) => (
        <Button type="text" href={`/vouchers/${record.key}/edit`}>
          <EditOutlined />
        </Button>
      ),
    },
  ];

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/vouchers">Vouchers</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const onFilterUpdate = () => {
    setIsFilterUpdated(true);
  };

  const handleRangeChange = () => {
    const range = adminFilterForm.getFieldValue('discountRange');
    setDiscountRange(range);
    onFilterUpdate();
  };

  const AdminFilterRow = () => (
    <Form form={adminFilterForm}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col lg={8} sm={12}>
          <Row>
            <p>Voucher</p>
          </Row>
          <Row>
            <Form.Item name="voucherName" style={{ width: '100%' }}>
              <Input placeholder="Voucher Name" />
            </Form.Item>
          </Row>
        </Col>
        <Col lg={8} sm={12}>
          <Row>
            <p>Label</p>
          </Row>
          <Row>
            <Form.Item name="label" style={{ width: '100%' }}>
              <Select
                defaultValue="all"
                style={{ width: '100%' }}
                onChange={onFilterUpdate}
              >
                <Option value="all">All</Option>
                <Option value="Budget-friendly">Budget-friendly</Option>
                <Option value="Standard">Standard</Option>
                <Option value="Mid-range">Mid-range</Option>
                <Option value="Premium">Premium</Option>
                <Option value="Luxury">Luxury</Option>
              </Select>
            </Form.Item>
          </Row>
        </Col>
        <Col lg={8} sm={12}>
          <Row>
            <p>Merchant</p>
          </Row>
          <Row>
            <Form.Item name="merchant" style={{ width: '100%' }}>
              <Select
                placeholder="Select Merchant" 
                options={getMerchantList(merchantList)} 
                onChange={onFilterUpdate}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Form.Item>
          </Row>
        </Col>
        <Col lg={8} sm={12}>
          <Row>
            <p>
              Discount Range 
              {discountRange && discountRange.length > 0 && (
                <Text>
                  <small style={{ marginLeft: '.5rem' }}>RM{discountRange[0]} ~ RM{discountRange[1]}</small>
                </Text>
              )}
            </p>
          </Row>
          <Row>
            <Form.Item name="discountRange" style={{ width: '100%' }}>
              <Slider 
                range
                step={10}
                min={0}
                max={Math.round(maxDiscount)} 
                onAfterChange={handleRangeChange}
                value={discountRange}
                tooltip={{
                  formatter: (value) => `RM ${value}`,
                }}
                defaultValue={[1, Math.round(maxDiscount)]} />
            </Form.Item>
          </Row>
        </Col>
        <Col lg={8} sm={12}>
          <Row>
            <p>Effective Date</p>
          </Row>
          <Row>
            <Form.Item name="voucherDate" style={{ width: '100%' }}>
              <RangePicker style={{ width: '100%' }} onChange={onFilterUpdate} />
            </Form.Item>
          </Row>
        </Col>
        <Col lg={8} sm={12}>
          <Row>
            <p>Status</p>
          </Row>
          <Row>
            <Form.Item name="status" style={{ width: '100%' }}>
              <Select
                defaultValue="all"
                style={{ width: '100%' }}
                onChange={onFilterUpdate}
                options={[
                  {
                    value: 'all',
                    label: 'All',
                  },
                  {
                    value: 'Active',
                    label: 'Active',
                  },
                  {
                    value: 'Inactive',
                    label: 'Inactive',
                  },
                  {
                    value: 'expired',
                    label: 'Expired',
                  },
                ]}
              />
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </Form>
  );

  const MerchantFilterRow = () => (
    <Form form={merchantFilterForm}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={12} lg={6}>
          <Row>
            <p>Voucher</p>
          </Row>
          <Row>
            <Form.Item name="voucherName" style={{ width: '100%' }}>
              <Input placeholder="Voucher Name" style={{ width: '100%' }} />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={6}>
          <Row>
            <p>Voucher Value</p>
          </Row>
          <Row>
            <Form.Item name="value" style={{ width: '100%' }}>
              <Select
                defaultValue="all"
                style={{ width: '100%' }}
                onChange={onFilterUpdate}
                options={[
                  {
                    value: 'all',
                    label: 'All',
                  },
                  {
                    value: '0to49',
                    label: 'RM 0 - RM 49',
                  },
                  {
                    value: '50to100',
                    label: 'RM 50 - RM 100',
                  },
                ]}
              />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={6}>
          <Row>
            <p>Expired Date</p>
          </Row>
          <Row>
            <Form.Item name="voucherDate" style={{ width: '100%' }}>
              <RangePicker style={{ width: '100%' }} onChange={onFilterUpdate} />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={6}>
          <Row>
            <p>Status</p>
          </Row>
          <Row>
            <Form.Item name="status" style={{ width: '100%' }}>
              <Select
                defaultValue="all"
                style={{ width: '100%' }}
                onChange={onFilterUpdate}
                options={[
                  {
                    value: 'all',
                    label: 'All',
                  },
                  {
                    value: 'Active',
                    label: 'Active',
                  },
                  {
                    value: 'Inactive',
                    label: 'Inactive',
                  },
                  {
                    value: 'expired',
                    label: 'Expired',
                  },
                ]}
              />
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </Form>
  );
  
  function findMaxValue(data) {
    let maxValue = -Infinity; // Initialize with a very small value

    data.forEach((item) => {
      if (parseFloat(item.discountAmount) > parseFloat(maxValue)) {
        maxValue = item.discountAmount;
      }
    });
    setDiscountRange([0, Math.round(maxValue)]);

    return maxValue;
  }

  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      const minus3days = moment(data[i].endDate).subtract(3, 'days').toDate();

      list.push({
        key: data[i].id,
        merchantName: data[i].merchant.name,
        voucher: data[i].name,
        label: data[i].label,
        discountAmount: data[i].value.toFixed(2),
        quantity: data[i].quantity,
        // voucherCode: data[i].voucherCode,
        availableAmount: data[i].quantity,
        balance: data[i].balance,
        redeemedAmount: data[i].redeemed,
        distributedAmount: data[i].distributed,
        startDate: data[i].startDate,
        endDate: minus3days,
        expiryDate: data[i].endDate,
        status: data[i].status,
        createDate: data[i].createdAt,
      });
    }
    setVoucherListData(list);
    if (!maxDiscount) setMaxDiscount(findMaxValue(list));
  };

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      const params = {
        ...filterData,
      };

      await axios
        .get('vouchers', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params,
        })
        .then((res) => {
          setVoucherList(res.data.results);
          setTotalData(res.data.totalResults);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve voucher data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, [filterData]);

  useEffect(() => {
    if (loading === false) {
      rerenderList(voucherList);
    }
  }, [loading, voucherList]);

  const adminFilter = async (action = null) => {
    await adminFilterForm
      .validateFields()
      .then((res) => {
        const { voucherName, label, merchant, voucherDate, status } = res;

        const items = {};

        items.name = voucherName;
        if (label !== 'all') {
          items.label = label;
        }
        if (merchant !== 'all') {
          items.merchantId = merchant;
        }
        if (status !== 'all') {
          items.status = status;
        }
        if (voucherDate) {
          items.filterStartDate = moment(voucherDate[0]).startOf('day').toDate();
          items.filterEndDate = moment(voucherDate[1]).endOf('day').toDate();
        }
        if (action && action !== 'clear') { //eslint-disable-line
          items.filterMinValue = discountRange[0] > 0 ? discountRange[0] : 1;
          items.filterMaxValue = discountRange[1];
        } else {
          items.filterMinValue = 0;
          items.filterMaxValue = Math.round(maxDiscount);
        }

        setFilterData(items);
        setIsFilterUpdated(true);
      })
      .catch(() => {});
  };

  const clearAdminFilter = async () => {
    adminFilterForm.resetFields();
    const defaultRange = [0, Math.round(maxDiscount)];
    setDiscountRange(defaultRange);
    await adminFilter('clear');
    setIsFilterUpdated(false);
  };

  const merchantFilter = () => {
    merchantFilterForm
      .validateFields()
      .then((res) => {
        const { voucherName, value, voucherDate, status } = res;

        const items = {};
        items.name = voucherName;
        if (value !== 'all') {
          items.filterValue = value;
        }
        if (status !== 'all') {
          items.status = status;
        }
        if (voucherDate) {
          items.filterStartDate = moment(voucherDate[0]).startOf('day').toDate();
          items.filterEndDate = moment(voucherDate[1]).endOf('day').toDate();
        }

        setFilterData(items);
        setIsFilterUpdated(true);
      })
      .catch(() => {});
  };

  const clearMerchantFilter = () => {
    merchantFilterForm.resetFields();
    merchantFilter();
    setTimeout(() => {
      setIsFilterUpdated(false);
    }, 0);
  };

  const onChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
  };

  if (userRole === 'merchant') {
    return (
      <>
        <DisplayBreadCrumb />
        <Content className="layout-background">
          {/* <Modal
            title={null}
            open={scanBarcodeActive}
            centered
            closable={false}
            onCancel={handleCancel}
            bodyStyle={{ padding: 0 }}
            footer={null}
            width={600}>
            <div
              className="content-area"
              style={{ textAlign: 'center', backgroundColor: 'inherit', padding: '40px 20px' }}>
              <Space direction="vertical" size="middle">
                <ScanOutlined style={{ fontSize: 50, fontWeight: 'bold' }} />
                <Title level={3}>Scan QR Code</Title>
                <Text type="secondary" style={{ fontSize: 15 }}>
                  User may scan this QR code.
                </Text>
                <img
                  src={merchantQRstr} 
                  alt="QR Code"
                />
                <Button block type="primary" onClick={submitScan}>
                  Submit
                </Button>
              </Space>
            </div>
          </Modal> */}
          {/* <Modal
            title={null}
            open={successModalActive}
            centered
            closable={false}
            onCancel={handleCancel}
            bodyStyle={{ padding: 0 }}
            footer={null}
            width={400}>
            <div
              className="content-area"
              style={{ textAlign: 'center', backgroundColor: 'inherit', padding: '40px 20px' }}>
              <Space direction="vertical" size="middle">
                <CheckCircleOutlined style={{ fontSize: 50, fontWeight: 'bold' }} />
                <Title level={3}>Redeemed Successfully!</Title>
                <Text type="secondary" style={{ fontSize: 15 }}>
                  Barcode scanned successfully.
                </Text>
                <Button block type="primary" onClick={successDone}>
                  Done
                </Button>
              </Space>
            </div>
          </Modal>
          <Modal
            title={null}
            open={failModalActive}
            centered
            closable={false}
            onCancel={handleCancel}
            bodyStyle={{ padding: 0 }}
            footer={null}
            width={400}>
            <div
              className="content-area"
              style={{ textAlign: 'center', backgroundColor: 'inherit', padding: '40px 20px' }}>
              <Space direction="vertical" size="middle">
                <CloseCircleOutlined style={{ fontSize: 50, fontWeight: 'bold' }} />
                <Title level={3}>Redeem Failed!</Title>
                <Text type="secondary" style={{ fontSize: 15 }}>
                  The voucher code is invalid.
                </Text>
                <Button block type="primary" onClick={failSubmit}>
                  Cancel
                </Button>
              </Space>
            </div>
          </Modal> */}
          <Space direction="vertical" style={{ display: 'flex' }}>
            <Row justify="end">
              <Col>
                <Space>
                  <Button onClick={showExportModal}>
                    <ExportOutlined rotate={-90} />
                    Export
                  </Button>
                  <Modal
                    title="Are you sure you want to export voucher data?"
                    open={exportModalActive}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    closable={false}
                    centered
                    bodyStyle={{ padding: 0 }}
                    footer={[
                      <>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button type="primary" onClick={handleOk}>
                          Export
                        </Button>
                      </>,
                    ]}
                  />
                </Space>
              </Col>
            </Row>
            <MerchantFilterRow />
            <Row justify="end">
              <Space>
                <Col>
                  <Button danger onClick={clearMerchantFilter} disabled={!isFilterUpdated}>
                    Reset Filters
                  </Button>
                </Col>
                <Col>
                  <Button onClick={merchantFilter}>Apply Filters</Button>
                </Col>
              </Space>
            </Row>
            <div className="table-content">
              <Table
                columns={merchantVoucherCols}
                dataSource={voucherListData}
                pagination={{
                  pageSize: limit,
                  pageSizeOptions: [20, 50, 100],
                  showSizeChanger: true,
                  total: totalData,
                  onChange,
                  current: page,
                }}
                scroll={{ x: '100%' }}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
              />
            </div>
          </Space>
        </Content>
      </>
    );
  }
  if (userRole === 'admin') {
    return (
      <>
        <DisplayBreadCrumb />
        <Content className="layout-background">
          <Space direction="vertical" style={{ display: 'flex' }}>
            <Row justify="end">
              <Col>
                <Space>
                  <Button onClick={showExportModal}>
                    <ExportOutlined rotate={-90} />
                    Export
                  </Button>
                  <Modal
                    title="Are you sure you want to export voucher data?"
                    open={exportModalActive}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    closable={false}
                    centered
                    bodyStyle={{ padding: 0 }}
                    footer={[
                      <>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button type="primary" onClick={handleOk}>
                          Export
                        </Button>
                      </>,
                    ]}
                  />
                  {/* <Button type="primary" href="/vouchers/create">
                    <PlusOutlined />
                    Create Voucher
                  </Button> */}
                </Space>
              </Col>
            </Row>
            <AdminFilterRow />
            <Row justify="end">
              <Space>
                <Col>
                  <Button danger onClick={clearAdminFilter} disabled={!isFilterUpdated}>
                    Reset Filters
                  </Button>
                </Col>
                <Col>
                  <Button onClick={adminFilter}>Apply Filters</Button>
                </Col>
              </Space>
            </Row>
            <div className="table-content">
              {loading === false ? (
                <Table
                  columns={adminVoucherCols}
                  dataSource={voucherListData}
                  pagination={{
                    pageSize: limit,
                    pageSizeOptions: [20, 50, 100],
                    showSizeChanger: true,
                    total: totalData,
                    onChange,
                    current: page,
                  }}
                  scroll={{ x: 1300 }}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                  }}
                />
              ) : (
                <Skeleton />
              )}
            </div>
          </Space>
        </Content>
      </>
    );
  }
}
