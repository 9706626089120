import React, { useState, useEffect } from 'react';
import {
  Typography,
  Layout,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Breadcrumb,
  Form,
  Skeleton,
  message,
} from 'antd';
import { DeleteOutlined, CheckOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { MiniGameConfigForm } from './component/MiniGameConfigForm';

export function MiniGameConfigPage(props) {
  const [loading, setLoading] = useState();
  const [curData, setCurData] = useState();

  async function initData() {
    try {
      setLoading(true);
      const res = await axios.get('/miniGame/getMiniGameConfig');

      setCurData(res.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  useEffect(() => {
    initData();
  }, []);

  const handleSubmit = async (formData) => {
    try {
      await axios.post(`/miniGame/upateMiniGameConfigById/${curData.id}`, formData);
      message.success('save successfully.');
      initData();
    } catch (error) {
      message.error(`Failed to save. ${error}`);
    }
  };

  return (
    <>
      <MiniGameConfigForm
        isCreate={false}
        onSubmit={handleSubmit}
        editData={curData}
        loading={loading} />
     
    </>
  );
}
