import React, { useEffect, useState } from 'react';
import { DatePicker, List, Row, Col, Select, Typography, message, Button, Form, Space, Skeleton } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { getToken } from '../../utils/AuthService';

const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function TopUpDetails({ playerId }) {
  const [loading, setLoading] = useState(true);
  const [topupList, setTopupList] = useState([]);
  const [topupData, setTopupData] = useState([]);

  const [filterForm] = Form.useForm();

  const token = getToken();

  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      list.push({
        key: data[i].id,
        title: `+ ${data[i].currency} ${data[i].amount.toFixed(2)} `,
        description: data[i].platform,
        date: data[i].createdAt,
      });
    }
    setTopupData(list);
  };

  const [limit, setLimit] = useState(7); 
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setLoading(true);

    const params = {
      playerId,
      category: 'topup',
      status: 'successful',
      ...filterData,
    };

    const getData = async () => {
      await axios
        .get('transactions', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params,
        })
        .then((res) => {
          setTopupList(res.data.results);
          setTotalData(res.data.totalResults);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve transactions data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, [filterData]);

  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const onFilterUpdate = () => {
    setIsFilterUpdated(true);
  };

  const onFilter = () => {
    filterForm
      .validateFields()
      .then((res) => {
        const { platform, date } = res;

        const items = {};

        if (platform !== 'all') {
          items.platform = platform;
        }
        if (date) {
          items.startDate = moment(date[0]).startOf('day').toDate();
          items.endDate = moment(date[1]).endOf('day').toDate();
        }

        setFilterData(items);
        setIsFilterUpdated(true);
      })
      .catch(() => {});
  };

  const clearFilter = () => {
    filterForm.resetFields();
    onFilter();
    setTimeout(() => {
      setIsFilterUpdated(false);
    }, 0);
  };

  useEffect(() => {
    if (loading === false) {
      rerenderList(topupList);
    }
  }, [loading, topupList]);

  const onChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
  };

  return (
    <>
      <Form form={filterForm}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col sm={24} lg={12}>
            <Row>
              <p>Date & Time</p>
            </Row>
            <Row>
              <Form.Item name="date" style={{ width: '100%' }}>
                <RangePicker style={{ width: '100%' }} onChange={onFilterUpdate} />
              </Form.Item>
            </Row>
          </Col>
          <Col sm={24} lg={12}>
            <Row>
              <p>Platform</p>
            </Row>
            <Row>
              <Form.Item name="platform" style={{ width: '100%' }}>
                <Select
                  defaultValue="all"
                  onChange={onFilterUpdate}
                  options={[
                    {
                      value: 'all',
                      label: 'All',
                    },
                    {
                      value: 'android',
                      label: 'Android',
                    },
                    {
                      value: 'ios',
                      label: 'iOS',
                    },
                    {
                      value: 'senangPay',
                      label: 'SenangPay',
                    },
                  ]}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
      <Row justify="end">
        <Space>
          <Col>
            <Button danger onClick={clearFilter} disabled={!isFilterUpdated}>Reset Filters</Button>
          </Col>
          <Col>
            <Button onClick={onFilter}>Apply Filters</Button>
          </Col>
        </Space>
      </Row>
      { loading === false ? (
        <List
          pagination={{ pageSize: limit,
            pageSizeOptions: [7, 15],
            showSizeChanger: true,
            total: totalData,
            onChange,
            current: page,
            position: 'bottom',
            align: 'end',
          }}
          itemLayout="horizontal"
          dataSource={topupData}
          renderItem={(item) => (
            <>
              <List.Item>
                <List.Item.Meta title={item.title} description={item.description} />
                <Text type="secondary">{moment(item.date).format('YYYY-MM-DD HH:mm:ss')}</Text>
              </List.Item>
            </>
          )}
          style={{ marginTop: '20px' }}
        />
      ) : (
        <Skeleton />
      )}
    </>
  );
}
