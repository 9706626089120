import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form, Input, InputNumber, Popconfirm, Table, Typography, Button, message, Image, Row, Col, Tooltip, Select,
} from 'antd';
import { PlusOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';

import axios from 'axios';

import { UploadOneIMG } from '../../../../components/componentV2/uploadOneIMG';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  component,

  record,
  index,
  children,
  ...restProps
}) => {
  const comMap = {
    InputNumber: <InputNumber min={0} />,
    Input: <Input />,
    Image: <UploadOneIMG />,
    SourceSelect: <Select
      mode="multiple"
      options={[
        {
          value: 'Spin',
          label: 'Spin',
        },
        {
          value: 'Egg',
          label: 'Egg',
        },
     
      ]}
  />,
  };
  const inputNode = comMap[component] || <Input />;

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: true,
              message: 'Please Input!',
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export function CardpackItem({ cardpackId }) {
  const [form] = Form.useForm();
  
  const [showData, setShowData] = useState([]);
  const [actionType, setActionType] = useState('add');
  const [edingData, setEdingData] = useState(null);

  async function getCardList() {
    const { data } = await axios.get(`/cardpack/getCardListByCardpackId/${cardpackId}`);
   
    setShowData(data.map((item, index) => {
      return {
        ...item,
        key: index.toString(),
      };
    }));
  }

  useEffect(() => { getCardList(); }, []);

  /**
   * 概率总和
   */
  const totalWeightage = useMemo(() => {
    let res = 0;
    showData.forEach((item) => {
      res += item.probability || 1;
    });
    return res;
  }, [showData]);
  
  const isEditing = (record) => {
    return edingData && record.key === edingData.key;
  };

  const setEdit = (record) => {
    form.setFieldsValue({ ...record });
    setEdingData(record);
  };

  const cancel = () => {
    setEdingData(null);
    form.resetFields();
    getCardList();
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();

      if (actionType === 'add') {
        await axios.post('cardpack/createCardpackItem', {
          cardpackId,
          ...row,
        });
      }

      if (actionType === 'edit') {
        await axios.post(`cardpack/updateCardpackItem/${edingData.id}`, row);
      }

      form.resetFields();
      setEdingData(null);
      getCardList();
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const deleteRow = async (record) => {
    await axios.delete(`cardpack/deleteCardpackItemById/${record.id}`);
    getCardList();
  };

  const columns = [
    {
      title: 'card Name',
      dataIndex: 'cardName',
      width: 100,
      component: 'Input',
      editable: true,
    },

    {
      title: 'card Name (CN)',
      dataIndex: 'cardNameCN',
      width: 100,
      component: 'Input',
      editable: true,
    },
    {
      title: 'star',
      dataIndex: 'star',
      component: 'InputNumber',
      width: 100,
      editable: true,
    },

    {
      title: 'Image Url',
      dataIndex: 'imageUrl',
      component: 'Image',

      render: (_row, record) => {
        return (
          <Image
            width={102}
            src={record.imageUrl}
          />
        );
      },
      width: 100,
      editable: true,
    },

    {
      title: 'Allow Source',
      dataIndex: 'allowSource',
      component: 'SourceSelect',
      width: 100,
      editable: true,
      render: (_row, record) => {
        return record.allowSource?.join(',') || '';
      },
    },

    {
      title: 'Limit Count',
      dataIndex: 'limitCount',
      component: 'InputNumber',
      width: 100,
      editable: true,
    },

    {
      title: 'Issue Count',
      dataIndex: 'issuedCount',

      width: 100,
      editable: false,
    },
    {

      title: (
        <Row>
          <Col>
            Probability (%)
          </Col>
          <Col style={{ padding: '0 5px' }}>
            <Tooltip title="Probability of player getting items out of 100%">
              <InfoCircleOutlined />
            </Tooltip>
          </Col>
          <Col>
            <span style={{
              color: totalWeightage === 100 ? 'green' : 'red',
              paddingLeft: '5px',
            }}>{totalWeightage}%
            </span>

          </Col>
        </Row>
      ),
      dataIndex: 'probability',
      component: 'InputNumber',
      width: 100,
      editable: true,
    },
    {
      title: 'operation',
      dataIndex: 'operation',
      width: 100,
      render: (_row, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link onClick={() => save(record.key)} style={{ marginRight: 8 }}>
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={() => cancel(record)}>
              <span style={{ cursor: 'pointer' }}>Cancel</span>
            </Popconfirm>
          </span>
        ) : (
          <span>

            <Typography.Link
              disabled={edingData}
              onClick={() => {
                setActionType('edit');
                setEdit(record);
              }}>
              Edit
            </Typography.Link>

            <Typography.Link disabled={edingData}>

              <Popconfirm title="Sure to delete?" onConfirm={() => deleteRow(record)}>
                <span style={{ cursor: 'pointer', color: edingData ? 'rgba(0, 0, 0, 0.25)' : 'red', marginLeft: '16px' }}>Delete</span>
              </Popconfirm>
            </Typography.Link>
          </span>

        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        component: col.component,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const addRow = () => {
    setActionType('add');

    if (edingData) {
      message.warn('Only one piece of data can be edited simultaneously');
      return;
    }
    const newData = [...showData];

    const pushData = {
      key: `add-${showData.length}`,
      cardName: '',
      cardNameCN: '',
      star: 1,
      probability: 1,
      imageUrl: '',
      allowSource: ['Spin'],
      issuedCount: 0,
      limitCount: 1,
      isNewAdd: true, // 新增行
    };

    newData.push(pushData);
    setShowData(newData);
    setEdit(pushData);
  };

  return (
    <Form form={form} component={false}>

      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={showData}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />

      <Button style={{ width: '100%', marginTop: '8px' }} onClick={addRow}>+ Add Card</Button>
    </Form>
  );
}
