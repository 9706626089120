import React, { useEffect, useState } from 'react';
import { Col, List, Row, Tabs, Typography, Tag, Button, Space } from 'antd';
import moment from 'moment';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { getToken } from '../utils/AuthService';

const { Title, Text } = Typography;

export default function NotificationsDrawerContent({ 
  role, 
  allNotif, 
  requestNotif, 
  closeNotifPanel, 
  onDataUpdate,
}) {
  const [allNotifData, setAllNotifData] = useState([]);
  const [requestNotifData, setRequestNotifData] = useState([]);

  const token = getToken();

  const rerenderAllList = (data) => {
    const list = [];
    if (data.length === 0) {
      list.push({
        key: 1,
        title: 'No unread notifications.',
      });
    } else {
      for (let i = 0; i < data.length; i++) {
        list.push({
          key: data[i]._id,
          title: data[i].title,
          description: moment(data[i].createdAt).format('DD-MM-YYYY'),
          requestId: data[i].editRequestId ? data[i].editRequestId : null,
        });
      }
    }
    setAllNotifData(list);
  };

  const rerenderReqList = (data) => {
    const list = [];
    if (data.length === 0) {
      list.push({
        key: 1,
        title: 'No unread notifications.',
      });
    } else {
      for (let i = 0; i < data.length; i++) {
        list.push({
          key: data[i]._id,
          title: data[i].title,
          description: moment(data[i].createdAt).format('DD-MM-YYYY'),
          requestId: data[i].editRequestId,
        });
      }
    }
    setRequestNotifData(list);
  };

  useEffect(() => {
    rerenderAllList(allNotif);
    rerenderReqList(requestNotif);
  }, [allNotif, requestNotif]);

  const readAllNotif = async () => {
    await axios.get('dashboardNotif/read-all', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    });

    onDataUpdate();
  };

  // const placeholderListContent = [
  //   {
  //     title: 'MerchantA wants something',
  //     description: '18/03/2022',
  //   },
  //   {
  //     title: '20 users registered',
  //     description: '18/03/2022',
  //   },
  // ];
  // const placeholderListContent2 = [
  //   {
  //     title: 'MerchantB wants something',
  //     description: '18/03/2022',
  //   },
  // ];

  return (
    <>
      <Row>
        <Col span={16} style={{ fontWeight: 500, fontSize: '24px' }}>
          <Title level={5}>Notification</Title>
        </Col>
        <Col span={8} style={{ fontWeight: 500, fontSize: '14px' }}>
          <Text underline className="hover-cursorChange" type="link" onClick={readAllNotif}>
            Mark all as read
          </Text>
        </Col>
      </Row>
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            label: (
              <span>
                All {allNotif.length > 0 ? <Tag color="#1890ff">{allNotif.length}</Tag> : ''}
              </span>
            ),
            key: '1',
            children: (
              <List
                dataSource={allNotifData}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta 
                      title={item.title} 
                      description={(
                        <>
                          <Space>
                            {item.requestId ? (
                              <Link to={`/editbranchrequest/${item.requestId}`}>
                                <Button onClick={closeNotifPanel}>View Request</Button>
                              </Link>
                            ) : (
                              ''
                            )}
                            {item.description}
                          </Space>
                        </>
                    )} />
                  </List.Item>
                )}
              />
            ),
          },
          role === 'admin' && {
            label: 'Requests',
            key: '2',
            children: (
              <List
                dataSource={requestNotifData}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={item.title}
                      description={(
                        <>
                          <Space>
                            {item.requestId ? (
                              <Link to={`/editbranchrequest/${item.requestId}`}>
                                <Button onClick={closeNotifPanel}>View Request</Button>
                              </Link>
                            ) : (
                              ''
                            )}
                            {item.description}
                          </Space>
                        </>
                    )}
                    />
                  </List.Item>
                )}
              />
            ),
          },
        ]}
      />
    </>
  );
}
