import React, { useState, useEffect } from 'react';
import {
  Table,
  Layout,
  Button,
  Row,
  Col,
  Space,
  message,
  Breadcrumb,
  Typography,
  Form,
  DatePicker,
  Input,
  Card,
  Statistic,
} from 'antd';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

const { Content } = Layout;
const { Link } = Typography;
const { RangePicker } = DatePicker;

export default function WelcomeGift() {
  const token = getToken();
  const history = useHistory();

  const [dataLoading, setDataLoading] = useState(true);
  const [welcomeGift, setWelcomeGiftList] = useState([]);
  const [welcomeGiftData, setWelcomeGiftData] = useState([]);
  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [totalGameCoin, setTotalGameCoin] = useState(0);
  const [totalHeeCoin, setTotalHeeCoin] = useState(0);
  const [filterData, setFilterData] = useState({});
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);

  const [filterForm] = Form.useForm();

  const tableColumns = [
    {
      title: 'Player Name',
      dataIndex: 'playerName',
      key: 'playerName',
      width: 150,
    },
    {
      title: 'Welcome Gifts Distributed Name',
      dataIndex: 'description',
      key: 'description',
      width: 170,
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (data) => (data ? moment(data).format('YYYY-MM-DD HH:mm:ss') : '-'),
      sorter: (a, b) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
      width: 150,
    },
  ];

  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      list.push({
        key: data[i]._id,
        playerId: data[i].player._id,
        playerName: data[i].player.name ? data[i].player.name : `Guest ${data[i].player.uid}`,
        description: data[i].description,
        createdDate: data[i].createdAt,
      });
    }
    setWelcomeGiftData(list);
  };

  useEffect(() => {
    setDataLoading(true);

    const params = { ...filterData };

    const getData = async () => {
      try {
        const res = await axios.get('actionlogs/welcomeGift', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params,
        });

        setWelcomeGiftList(res.data.results);
        setTotalData(res.data.totalResults);
        setTotalHeeCoin(res.data.totalHeeCoin);
        setTotalGameCoin(res.data.totalGameCoin);
      } catch (error) {
        message.error(`Failed to retrieve players data. ${error}`);
      } finally {
        setDataLoading(false);
      }      
    };
    getData();
  }, [filterData]);

  useEffect(() => {
    if (dataLoading === false) {
      rerenderList(welcomeGift);
    }
  }, [dataLoading, welcomeGift]);

  const onFilterUpdate = () => {
    setIsFilterUpdated(true);
  };

  const onFilter = () => {
    filterForm
      .validateFields()
      .then((res) => {
        const { playerName, date } = res;
        const items = {};
        
        if (playerName !== '') {
          items.playerName = playerName;
        }
        if (date) {
          items.startDate = moment(date[0]).startOf('day').toDate();
          items.endDate = moment(date[1]).endOf('day').toDate();
        }
        
        setFilterData(items);
        setIsFilterUpdated(true);
      })
      .catch(() => {});
  };

  const clearFilter = () => {
    filterForm.resetFields();
    onFilter();
    setTimeout(() => {
      setIsFilterUpdated(false);
    }, 0);
  };

  const StatisticRow = () => (
    <Row gutter={[16, 16]} style={{ padding: '20px 0' }}>
      <Col xs={12} lg={12}>
        <Card bordered={false}>
          <Statistic
            title="Total distributed game coins"
            value={totalGameCoin}
            valueStyle={{
              color: '#1890FF',
            }}
          />
        </Card>
      </Col>
      <Col xs={12} lg={12}>
        <Card bordered={false}>
          <Statistic
            title="Total distributed heecoins"
            value={totalHeeCoin}
            valueStyle={{
              color: '#1890FF',
            }}
          />
        </Card>
      </Col>
    </Row>
  );

  const FilterRow = () => (
    <Form form={filterForm}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={12} lg={12}>
          <Row>
            <p>Player Name</p>
          </Row>
          <Row>
            <Form.Item name="playerName" style={{ width: '100%' }}>
              <Input placeholder="Player Name" style={{ width: '100%' }} />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={12}>
          <Row>
            <p>Date</p>
          </Row>
          <Row>
            <Form.Item name="date" style={{ width: '100%' }}>
              <RangePicker style={{ width: '100%' }} onChange={onFilterUpdate} />
            </Form.Item>
          </Row>
        </Col>
      </Row>
      <Row justify="end">
        <Space>
          <Col>
            <Button danger onClick={clearFilter} disabled={!isFilterUpdated}>
              Reset Filters
            </Button>
          </Col>
          <Col>
            <Button onClick={onFilter}>Apply Filters</Button>
          </Col>
        </Space>
      </Row>
    </Form>
  );

  const onChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
  };

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/welcome-gift-setting">Heewon Welcome Gift Setting </Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <StatisticRow />
          <FilterRow />
          <Row>
            <div className="table-content" style={{ width: '100%' }}>
              <Table
                loading={dataLoading}
                columns={tableColumns}
                pagination={{ pageSize: limit,
                  pageSizeOptions: [20, 50, 100],
                  showSizeChanger: true,
                  total: totalData,
                  onChange,
                  current: page,
                }}
                scroll={{ x: 'max-content' }}
                onRow={(rowIndex) => ({
                  onClick: (event) => {
                    if (!event.target.closest('.ant-btn')) {
                      history.push(`player-management/${rowIndex.playerId}`);
                    }
                  }, // click row
                })}
                dataSource={welcomeGiftData}
                rowClassName="hover-cursorChange"
                style={{ tableLayout: 'fixed' }}
              />
            </div>
          </Row>
        </Space>
      </Content>
    </>
  );
}
