import React, { useState, useEffect, useCallback } from 'react';
import { utils, writeFileXLSX } from 'xlsx';
import {
  Table,
  Layout,
  Button,
  Row,
  Col,
  Select,
  Space,
  Modal,
  Input,
  Typography,
  Breadcrumb,
  message,
  Form,
  Skeleton,
} from 'antd';
import { ImportOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';

import moment from 'moment';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

const { Content } = Layout;
const { Link } = Typography;

const columns = [
  {
    title: 'Name',
    dataIndex: 'userName',
    key: 'userName',
    width: 300,
  },
  {
    title: 'Email',
    dataIndex: 'userEmail',
    key: 'userEmail',
    width: 300,
  },
  {
    title: 'Phone Number',
    dataIndex: 'userPhone',
    key: 'userPhone',
    width: 160,
  },
  {
    title: 'Role',
    dataIndex: 'userRole',
    key: 'userRole',
    width: 150,
    render: (data) => {
      let res;

      switch (data) {
        case 'admin':
          res = 'Superadmin';
          break;
        case 'merchantHQ':
          res = 'Merchant HQ';
          break;
        case 'merchantBranch':
          res = 'Merchant Branch';
          break;
        case 'operation':
          res = 'Operator';
          break;
        case 'finance':
          res = 'Finance';
          break;
        default:
          break;
      }
      return res;
    },
  },
  {
    title: 'Created By',
    dataIndex: 'userCreator',
    key: 'userCreator',
    width: 180,
  },
  {
    title: 'Created Date',
    dataIndex: 'userCreatedDate',
    key: 'userCreatedDate',
    width: 200,
    render: (data) => (data ? moment(data).format('YYYY-MM-DD') : '-'),
  },
  {
    title: 'Action',
    key: 'action',
    fixed: 'right',
    width: 90,
    render: (record) => (
      <Button type="text" href={`/user-management/${record.key}/edit`}>
        <EditOutlined />
      </Button>
    ),
  },
];

export default function UserManagement() {
  const [modalActive, setModalActive] = useState(false);
  const showModal = () => {
    setModalActive(true);
  };
  // const handleOk = () => {
  //   setModalActive(false);
  // };
  const handleCancel = () => {
    setModalActive(false);
  };

  const token = getToken();
  const [userList, setUserList] = useState([]);
  const [userListData, setUserListData] = useState([]);
  const [totalData, setTotalData] = useState();
  const [loading, setLoading] = useState(true);

  const [filterForm] = Form.useForm();

  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      list.push({
        key: data[i].id,
        userName: data[i].name,
        userEmail: data[i].email,
        userPhone: data[i].phone,
        userRole: data[i].role,
        userCreator: data[i].createdBy ? data[i].createdBy.name : '-',
        userCreatedDate: data[i].createdAt,
      });
    }
    setUserListData(list);
  };

  const [limit, setLimit] = useState(20); 
  const [page, setPage] = useState(1); 
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setLoading(true);

    const params = {
      limit,
      page,
      sortBy: 'createdAt:desc',
      ...filterData,
    };

    const getData = async () => {
      await axios
        .get('users', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params,
        })
        .then((res) => {
          setUserList(res.data.results);
          setTotalData({ 
            totalResults: res.data.totalResults,
            totalPages: res.data.totalPages,
          });
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve user data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, [limit, page, filterData]);

  useEffect(() => {
    if (loading === false) {
      rerenderList(userList);
    }
  }, [loading, userList]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleOk = useCallback(async () => {
    let selectedRows = [];
  
    if (rowSelection.selectedRowKeys.length === 0) {
      // Export all rows when no rows are selected
      const params = {
        limit,
        sortBy: 'createdAt:desc',
        ...filterData,
      };

      const getData = async (currentPage) => {
        const response = await axios
          .get('users', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
            params: { ...params, page: currentPage },
          });

        return response.data.results;
      };

      // generate promises to get all data
      const promises = Array.from({ length: totalData.totalPages }, (_, i) => getData(i + 1));

      // wait for promises to resolve
      const results = await Promise.all(promises);

      // array of arrays -> single array
      const flattenResults = results.flat();

      for (let i = 0; i < flattenResults.length; i++) {
        selectedRows.push({
          userName: flattenResults[i].name,
          userEmail: flattenResults[i].email,
          userPhone: flattenResults[i].phone,
          userRole: flattenResults[i].role,
          userCreator: flattenResults[i].createdBy ? flattenResults[i].createdBy.name : '-',
          userCreatedDate: flattenResults[i].createdAt,
        });
      }
    } else {
      // Export selected rows
      selectedRows = userListData
        .filter((row) => rowSelection.selectedRowKeys.includes(row.key))
        .map(({ key, ...rest }) => rest);
    }

    // Rename column headers
    const renamedColumns = {
      userName: 'User Name',
      userEmail: 'Email',
      userPhone: 'Phone',
      userRole: 'Role',
      userCreator: 'Created By',
      userCreatedDate: 'Created On',
    };

    const transformedRows = selectedRows.map((row) => (
      Object.entries(row).reduce((acc, [key, value]) => {
        const renamedKey = renamedColumns[key] || key;
        if (key === 'userCreatedDate') {
          const createdDate = new Date(value);
          const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Asia/Kuala_Lumpur',
          };
          return { ...acc, [renamedKey]: createdDate.toLocaleString('en-US', options) };
        }
        return { ...acc, [renamedKey]: value };
      }, {})));  
  
    const currentDate = new Date();
    const formattedDate = currentDate
      .toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(/[/:\s]/g, '_');
  
    const ws = utils.json_to_sheet(transformedRows);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    writeFileXLSX(wb, `Users_${formattedDate}.xlsx`);

    setModalActive(false);
  }, [userListData, rowSelection.selectedRowKeys]);

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/user-management">User Management</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const onFilterUpdate = () => {
    setIsFilterUpdated(true);
  };

  const onFilter = () => {
    filterForm
      .validateFields()
      .then((res) => {
        const { email, contact, role } = res;

        const items = {};

        items.email = email;
        items.phone = contact;
        if (role !== 'All') {
          items.role = role;
        }
        
        setFilterData(items);
        setIsFilterUpdated(true);
      })
      .catch(() => {});
  };

  const clearFilter = () => {
    filterForm.resetFields();
    onFilter();
    setTimeout(() => {
      setIsFilterUpdated(false);
    }, 0);
  };

  const FilterRow = () => (
    <Form form={filterForm}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={12} lg={8}>
          <Row>
            <p>Email</p>
          </Row>
          <Row>
            <Form.Item name="email" style={{ width: '100%' }}>
              <Input placeholder="Email" />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={8}>
          <Row>
            <p>Phone Number</p>
          </Row>
          <Row>
            <Form.Item name="contact" style={{ width: '100%' }}>
              <Input placeholder="Phone Number" />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={8}>
          <Row>
            <p>Role</p>
          </Row>
          <Row>
            <Form.Item name="role" style={{ width: '100%' }}>
              <Select placeholder="Select Role" defaultValue="All" onChange={onFilterUpdate}>
                <Select.Option value="All">All</Select.Option>
                <Select.Option value="admin">Superadmin</Select.Option>
                <Select.Option value="operator">Operator</Select.Option>
                <Select.Option value="finance">Finance</Select.Option>
                <Select.Option value="merchantHQ">Merchant HQ</Select.Option>
                <Select.Option value="merchantBranch">Merchant Branch</Select.Option>
              </Select>
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </Form>
  );

  const onChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
  };

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Row justify="end">
            <Col>
              <Space>
                <Button onClick={showModal}>
                  <ImportOutlined rotate={-90} />
                  Export
                </Button>
                <Modal
                  title="Are you sure you want to export user data?"
                  open={modalActive}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  centered
                  closable={false}
                  bodyStyle={{ padding: 0 }}
                  footer={[
                    <>
                      <Button onClick={handleCancel}>Cancel</Button>
                      <Button type="primary" onClick={handleOk}>
                        Export
                      </Button>
                    </>,
                  ]}
                />
                <Button type="primary" href="/user-management/create">
                  <PlusOutlined />
                  Add New User
                </Button>
              </Space>
            </Col>
          </Row>
          <FilterRow />
          <Row justify="end">
            <Space>
              <Col>
                <Button danger onClick={clearFilter} disabled={!isFilterUpdated}>
                  Reset Filters
                </Button>
              </Col>
              <Col>
                <Button onClick={onFilter}>Apply Filters</Button>
              </Col>
            </Space>
          </Row>
          <div className="table-content">
            {loading === false ? (
              <Table
                columns={columns}
                dataSource={userListData}
                pagination={{ pageSize: limit,
                  pageSizeOptions: [20, 50, 100],
                  showSizeChanger: true,
                  total: totalData.totalResults,
                  onChange,
                  current: page,
                }}
                scroll={{ x: 1300 }}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </Space>
      </Content>
    </>
  );
}
