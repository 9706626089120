import React, { useEffect } from 'react';
import { Form, Input, Row, Col, Tooltip } from 'antd';
import { EditOutlined, ReloadOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const { TextArea } = Input;

export default function MerchantForm({ form, mode, data }) {
  useEffect(() => {
    if (mode === 'Create') {
      form.setFieldsValue({
        merchantName: '',
        merchantEmail: '',
        merchantContactNum: '',
        merchantCategory: '',
      });
    } else if (mode === 'Edit') {
      form.setFieldsValue({
        merchantName: data.name,
        merchantEmail: data.email,
        merchantContactNum: data.contact,
        merchantCategory: data.category,
        remarks: data.remarks,
      });
    }
  }, [form, mode, data]);

  const generatePassword = () => {
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let randomPass = '';
    for (let i = 0; i < 10; i++) {
      randomPass += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    form.setFieldsValue({ password: randomPass });
  };

  return (
    <Form form={form} layout="vertical">
      <Form.Item
        name="merchantName"
        label="Merchant Name"
        rules={[
          {
            required: true,
            message: 'Merchant name cannot be empty!',
          },
        ]}>
        <Input placeholder="Enter Merchant's Name" />
      </Form.Item>
      <Form.Item
        name="merchantContactNum"
        label="Contact Number"
        rules={[
          {
            required: true,
            message: 'Contact number cannot be empty!',
          },
          {
            pattern: /^(\+60\d{9,10}|01\d{8,9})$/,
            message: "Phone number format must be '+60xxxxxxxxxx' or '01xxxxxxxxx'.",
          },
        ]}>
        <Input type="tel" placeholder="Enter Contact Number (e.g. +60123456789)" />
      </Form.Item>
      <Form.Item
        name="merchantEmail"
        label="E-mail Address"
        rules={[
          {
            required: true,
            message: 'E-mail address cannot be empty!',
          },
          {
            type: 'email',
            message: 'Input must be a valid email',
          },
        ]}>
        <Input placeholder="Enter E-mail Address" />
      </Form.Item>
      <Form.Item
        name="merchantCategory"
        label="Merchant Category"
        rules={[
          {
            required: true,
            message: 'Category cannot be empty!',
          },
        ]}>
        <Input placeholder="Enter Category" />
      </Form.Item>
      {mode === 'Create' ? (
        <div>
          <Form.Item
            name="password"
            rules={[{ required: true }]}
            label={(
              <Row
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                }}>
                <Col>Password</Col>
                <Col style={{ paddingLeft: '10px' }}>
                  <Tooltip title="Generate Random String Password">
                    <ReloadOutlined onClick={generatePassword} />
                  </Tooltip>
                </Col>
              </Row>
            )}>
            <Input.Password placeholder="Enter password" />
          </Form.Item>
        </div>
      ) : null}
      {/* add branch number field if editing merchant detail */}
      {mode === 'Edit' ? (
        <div>
          <Form.Item
            name="branchNumber"
            initialValue={data.branchCount}
            label={
              (
                <Row
                  style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
                  <Col>Total Branch</Col>
                  <Col style={{ paddingLeft: '20px' }}>
                    <Link to={`/merchants/${data._id}/branchdetails`}>
                      <Tooltip title="Edit Branch">
                        Edit Branch <EditOutlined />
                      </Tooltip>
                    </Link>
                  </Col>
                </Row>
              )
            }
          >
            <Input disabled />
          </Form.Item>
        </div>
      ) : null}
      <Form.Item name="remarks" label="Remarks">
        <TextArea placeholder="Write your description here..." />
      </Form.Item>
    </Form>
  );
}
