import React, { useState, useEffect, useCallback } from 'react';
import { utils, writeFileXLSX } from 'xlsx';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Layout,
  Button,
  Row,
  Col,
  Space,
  Typography,
  Modal,
  Breadcrumb,
  Skeleton,
  message,
  Form,
  Select,
  Statistic,
  DatePicker,
  Card,
  Input,
  Slider,
} from 'antd';
import { ExportOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import moment from 'moment';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

const { RangePicker } = DatePicker;
const { Content } = Layout;
const { Link, Text } = Typography;

const heeCoinCols = [
  {
    title: 'HeeCoin',
    key: 'name',
    dataIndex: 'name',
    width: 200,
  },
  {
    title: 'Amount per Distribution',
    dataIndex: 'amount',
    key: 'amount',
    width: 150,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    width: 100,
    align: 'center',
  },
  {
    title: 'Distributed',
    dataIndex: 'distributed',
    key: 'distributed',
    width: 110,
    align: 'center',
  },
  {
    title: 'Total Distributed Value',
    dataIndex: 'totalDist',
    key: 'totalDist',
    width: 150,
    align: 'center',
  },
  {
    title: 'Balance',
    dataIndex: 'balance',
    key: 'balance',
    width: 100,
    align: 'center',
  },
  // {
  //   title: 'Created Date',
  //   dataIndex: 'createDate',
  //   key: 'createDate',
  //   render: (data) => (data ? moment(data).format('YYYY-MM-DD HH:mm:ss') : '-'),
  //   width: 200,
  // },
  {
    title: 'Action',
    key: 'action',
    fixed: 'right',
    align: 'center',
    width: 90,
    render: (record) => (
      <Button type="text" href={`/heecoin-setting/${record.key}/edit`}>
        <EditOutlined />
      </Button>
    ),
  },
];

export default function HeeCoinList() {
  // export modal
  const [exportModalActive, setExportModalActive] = useState(false);
  const showExportModal = () => {
    setExportModalActive(true);
  };

  const token = getToken();
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const [heeCoinList, setHeeCoinList] = useState([]);

  const [heeCoinListData, setHeeCoinListData] = useState([]);
  const [amountList, setAmountList] = useState([]);

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [filterForm] = Form.useForm();
  const [filterData, setFilterData] = useState({});

  const [maxDiscount, setMaxDiscount] = useState();
  const [totalDistributed, setDiscountRange] = useState([]);
  const [playerList, setPlayerList] = useState([]);

  const handleCancel = () => {
    setExportModalActive(false);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleOk = useCallback(() => {
    let selectedRows = [];

    if (rowSelection.selectedRowKeys.length === 0) {
      // Export all rows when no rows are selected
      selectedRows = heeCoinListData.map(({ key, balance, redeemedAmount, ...rest }) => rest);
    } else {
      // Export selected rows
      selectedRows = heeCoinListData
        .filter((row) => rowSelection.selectedRowKeys.includes(row.key))
        .map(({ key, balance, ...rest }) => rest);
    }

    // Rename column headers
    const renamedColumns = {
      merchantName: 'Merchant Name',
      voucher: 'Voucher Name',
      discountAmount: 'Discount Amount (RM)',
      quantity: 'Quantity',
      availableAmount: 'Available',
      // voucherCode: 'Voucher Code',
      balance: 'Balance',
      distributedAmount: 'Distributed',
      redeemedAmount: 'Redeemed',
      startDate: 'Start Date',
      endDate: 'End Date',
      expiryDate: 'Expiry Date',
      status: 'Status',
      createDate: 'Created At',
    };

    const transformedRows = selectedRows.map((row) => (
      Object.entries(row).reduce((acc, [key, value]) => {
        const renamedKey = renamedColumns[key] || key;
        if (key === 'createDate' || key === 'expiryDate') {
          const date = new Date(value);
          const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Asia/Kuala_Lumpur',
          };
          return { ...acc, [renamedKey]: date.toLocaleString('en-US', options) };
        }
        return { ...acc, [renamedKey]: value };
      }, {})));  
  
    const currentDate = new Date();
    const formattedDate = currentDate
      .toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(/[/:\s]/g, '_');

    const ws = utils.json_to_sheet(transformedRows);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    writeFileXLSX(wb, `Vouchers_${formattedDate}.xlsx`);

    setExportModalActive(false);
  }, [heeCoinListData, rowSelection.selectedRowKeys]);

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/heecoin-setting">HeeCoin</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  function findMaxValue(data) {
    let maxValue = -Infinity; // Initialize with a very small value

    data.forEach((item) => {
      if (parseFloat(item.totalDist) > parseFloat(maxValue)) {
        maxValue = item.totalDist;
      }
    });
    setDiscountRange([0, Math.round(maxValue)]);

    return maxValue;
  }

  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      list.push({
        key: data[i]._id,
        name: data[i].name,
        amount: data[i].amount,
        quantity: data[i].quantity,
        distributed: data[i].distributed,
        totalDist: data[i].amount * data[i].distributed,
        balance: data[i].quantity - data[i].distributed,
      });
    }
    setHeeCoinListData(list);
    if (!maxDiscount) setMaxDiscount(findMaxValue(list));
    if (amountList.length === 0) {
      const amounts = list.map((heecoin) => heecoin.amount);
      setAmountList(amounts);
    }
  };

  useEffect(() => {
    setLoading(true);

    const params = {
      ...filterData,
    };

    const getData = async () => {
      await axios
        .get('heeCoin', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params,
        })
        .then((res) => {
          setHeeCoinList(res.data.results);
          setTotalData(res.data.totalResults);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve voucher data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, [filterData]);

  useEffect(() => {
    if (loading === false) {
      rerenderList(heeCoinList);
    }
  }, [loading, heeCoinList]);

  const onChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
  };

  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const onFilterUpdate = () => {
    setIsFilterUpdated(true);
  };

  const onFilter = (action = null) => {
    filterForm
      .validateFields()
      .then((res) => {
        const { amount, name } = res;

        const items = {};

        if (amount !== 'All') {
          items.amount = amount;
        }

        if (name !== '') {
          items.name = name;
        }

        if (action && action !== 'clear') {
          //eslint-disable-line
          items.filterMinValue = totalDistributed[0] > 0 ? totalDistributed[0] : 1;
          items.filterMaxValue = totalDistributed[1];
        }

        setFilterData(items);
        setIsFilterUpdated(true);
      })
      .catch(() => {});
  };

  const clearFilter = () => {
    filterForm.resetFields();
    const defaultRange = [0, Math.round(maxDiscount)];
    setDiscountRange(defaultRange);
    onFilter('clear');
    setTimeout(() => {
      setIsFilterUpdated(false);
    }, 0);
  };

  // heecoin statistic related block start
  const [statsLoading, setStatsLoading] = useState(true);
  const [totalHeeCoin, setTotalHeeCoin] = useState(0);
  const [statsFilterUpdated, setStatsFilterUpdated] = useState(false);
  const [statsFilterData, setStatsFilterData] = useState({});

  const [statsFilter] = Form.useForm();
  const onStatsDateUpdate = () => {
    setStatsFilterUpdated(true);
  };

  const filterStats = () => {
    statsFilter
      .validateFields()
      .then((res) => {
        const { date } = res;

        const items = {};

        if (date) {
          items.startDate = moment(date[0]).startOf('day').format('YYYY-MM-DD');
          items.endDate = moment(date[1]).endOf('day').format('YYYY-MM-DD');
        }

        setStatsFilterData(items);
        setStatsFilterUpdated(true);
      })
      .catch(() => {});
  };

  const clearStatsFilter = () => {
    statsFilter.resetFields();
    filterStats();
    setTimeout(() => {
      setStatsFilterUpdated(false);
    }, 0);
  };

  const getTotalHeeCoin = async (params) => {
    setStatsLoading(true);

    try {
      const res = await axios
        .get('/heecoinHistory/getTotalAbtainHeeCoinFromHistory', {
          params,
        });
      setStatsLoading(false);

      setTotalHeeCoin(res.data.total || 0);
    } catch (error) {
      setStatsLoading(false);
    }
  };
  
  useEffect(() => {
    getTotalHeeCoin(statsFilterData);
  }, [statsFilterData]);

  const redirectToHistory = () => {
    if (!statsLoading) {
      history.push('/heecoin-history');
    }
  };

  const StatisticRow = () => (
    <Form form={statsFilter}>
      <Row gutter={[16, 16]} style={{ padding: '20px 0' }}>
        <Col span={12} onClick={redirectToHistory} style={{ cursor: 'pointer' }}>
          <Card bordered={false}>
            <Statistic
              title="Total distributed HeeCoins (including tutorial reward)"
              value={totalHeeCoin}
              valueStyle={{
                color: '#1890FF',
              }}
            />
          </Card>
        </Col>
        <Col span={12}>
          <Space direction="vertical">
            <Row>
              <Form.Item name="date" style={{ width: '100%' }}>
                <RangePicker style={{ width: '100%' }} onChange={onStatsDateUpdate} />
              </Form.Item>
            </Row>
            <Row>
              <Space>
                <Button danger onClick={clearStatsFilter} disabled={!statsFilterUpdated}>
                  Reset
                </Button>
                <Button onClick={filterStats}>Apply</Button>
              </Space>
            </Row>
          </Space>
        </Col>
      </Row>
    </Form>
  );
  // heecoin statistic related block end

  const handleRangeChange = () => {
    const range = filterForm.getFieldValue('totalDistributed');
    setDiscountRange(range);
    onFilterUpdate();
  };

  const FilterRow = () => (
    <Form form={filterForm}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={12} lg={6}>
          <Row>
            <p>HeeCoin</p>
          </Row>
          <Row>
            <Form.Item name="name" style={{ width: '100%' }}>
              <Input placeholder="HeeCoin" allowClear />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={6}>
          <Row>
            <p>Amount per Distribution</p>
          </Row>
          <Row>
            <Form.Item name="amount" style={{ width: '100%' }}>
              <Select placeholder="Select Amount" defaultValue="All" onChange={onFilterUpdate}>
                <Select.Option value="All">All</Select.Option>
                {amountList.map((heeCoin) => (
                  <Select.Option key={heeCoin} value={heeCoin}>
                    {heeCoin}
                  </Select.Option>
                ))}
                {/* <Select.Option value="100">100</Select.Option>
                <Select.Option value="300">300</Select.Option>
                <Select.Option value="500">500</Select.Option>
                <Select.Option value="1000">1000</Select.Option> */}
              </Select>
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={6}>
          <Row>
            <p>
              Total Distributed Value
              {totalDistributed && totalDistributed.length > 0 && (
                <Text>
                  <small style={{ marginLeft: '.5rem' }}>
                    {totalDistributed[0]} ~ {totalDistributed[1]}
                  </small>
                </Text>
              )}
            </p>
          </Row>
          <Row>
            <Form.Item name="totalDistributed" style={{ width: '100%' }}>
              <Slider
                range
                step={10}
                min={0}
                max={Math.round(maxDiscount)}
                onAfterChange={handleRangeChange}
                value={totalDistributed}
                tooltip={{
                  formatter: (value) => value,
                }}
                defaultValue={[1, Math.round(maxDiscount)]}
              />
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </Form>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Row justify="end">
            <Col>
              <Space>
                <Button onClick={showExportModal}>
                  <ExportOutlined rotate={-90} />
                  Export
                </Button>
                <Modal
                  title="Are you sure you want to export voucher data?"
                  open={exportModalActive}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  closable={false}
                  centered
                  bodyStyle={{ padding: 0 }}
                  footer={[
                    <>
                      <Button onClick={handleCancel}>Cancel</Button>
                      <Button type="primary" onClick={handleOk}>
                        Export
                      </Button>
                    </>,
                  ]}
                />
                <Button type="primary" href="/heecoin-setting/create">
                  <PlusOutlined />
                  Create HeeCoin
                </Button>
              </Space>
            </Col>
          </Row>
          {statsLoading === false ? <StatisticRow /> : <Skeleton />}
          <FilterRow />
          <Row justify="end">
            <Space>
              <Col>
                <Button danger onClick={clearFilter} disabled={!isFilterUpdated}>
                  Reset Filters
                </Button>
              </Col>
              <Col>
                <Button onClick={onFilter}>Apply Filters</Button>
              </Col>
            </Space>
          </Row>
          <div className="table-content">
            {loading === false ? (
              <Table
                columns={heeCoinCols}
                dataSource={heeCoinListData}
                pagination={{
                  pageSize: limit,
                  pageSizeOptions: [20, 50, 100],
                  showSizeChanger: true,
                  total: totalData,
                  onChange,
                  current: page,
                }}
                scroll={{ x: 1300 }}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </Space>
      </Content>
    </>
  );
}
