import {
  Layout,
  Row,
  Col,
  Button,
  Space,
  Typography,
  Tabs,
  Input,
  Card,
  Divider,
  Tag,
  Statistic,
  Descriptions,
  Modal,
  message,
  Skeleton,
  Breadcrumb,
  Form,
  Progress,
  Image,
} from 'antd';
import {
  BlockOutlined,
  PhoneOutlined,
  ManOutlined,
  WomanOutlined,
  CalendarOutlined,
  DollarOutlined,
  LeftOutlined,
  HomeOutlined,
  CloseOutlined,
  CheckOutlined,
  EditOutlined,
  UserAddOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,

} from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

import TopUpDetails from '../../components/playerManagement/TopUpDetails';
import SpendingDetails from '../../components/playerManagement/SpendingDetails';
import PlayerActivity from '../../components/playerManagement/PlayerActivity';

const { Content } = Layout;
const { Title, Link, Text } = Typography;
const { TextArea } = Input;

const CardPackRecordList = ({ data = [], size = 80, strokeWidth = 10, strokeColor = '#52c41a' }) => {
  const Item = ({ imageUrl, name, totalCardType, collectCardType }) => {
    const percent = Math.floor((collectCardType * 100) / totalCardType);
    return (
      <div>
        <div style={{ position: 'relative', width: `${size}px`, height: `${size}px` }}>
          <Progress type="circle" percent={percent} width={size} strokeWidth={strokeWidth} strokeColor={strokeColor} />
          <div style={{ position: 'absolute', top: '50%', left: '50%', zIndex: 9, transform: 'translate(-50%,-50%)', borderRadius: '50%', overflow: 'hidden' }}
    >
            <Image
              preview={false}
              width={size - strokeWidth}
              src={imageUrl}
     />
          </div>

        </div>
        <div style={{ marginTop: '8px', fontSize: '12px', textAlign: 'center' }}>{name} <span style={{ color: '#1890ff' }}>{collectCardType}/{ totalCardType}</span> </div>

      </div>
      
    );
  };
  return (
    <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
      {
        data.map((item) => (
          <Item
            key={Math.random()}
            imageUrl={item.iconUrl}
            name={item.seriesName}
            totalCardType={item.totalCardType}
            collectCardType={item.collectCardType} />
        ))
      }

    </div>
  );
};

export default function PlayerDetail() {
  const playerID = window.location.href.split('/')[4];
  const [playerData, setPlayerData] = useState();
  const [form] = Form.useForm();

  const token = getToken();
  const [loading, setLoading] = useState(true);

  const [selectedTab, setSelectedTab] = useState('1');
  const tabItems = [
    { label: 'Top Up Details', key: '1' },
    { label: 'Spending Details', key: '2' },
    { label: 'Player Activity', key: '3' },
  ];

  const handleContentChange = (activeTab) => {
    setSelectedTab(activeTab);
  };

  const ContentsDiv = (currentTab) => {
    switch (currentTab) {
      case '1':
        return <TopUpDetails playerId={playerID} />;
      case '2':
        return <SpendingDetails playerId={playerID} />;
      case '3':
        return <PlayerActivity playerId={playerID} />;
      default:
        return '';
    }
  };

  const [suspendModalActive, setSuspendModalActive] = useState(false);
  const [deactivateModalActive, setDeactivateModalActive] = useState(false);
  const [unsuspendModalActive, setUnsuspendModalActive] = useState(false);
  const [activateModalActive, setActivateModalActive] = useState(false);

  const showSuspendModal = () => {
    setSuspendModalActive(true);
  };
  const showDeactivateModal = () => {
    setDeactivateModalActive(true);
  };
  const showUnsuspendModal = () => {
    setUnsuspendModalActive(true);
  };
  const showActivateModal = () => {
    setActivateModalActive(true);
  };

  const handleSuspend = async () => {
    try {
      await axios.patch(
        `players/${playerID}`,
        { status: 'Suspended' },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        },
      );
      message.success('Updated player status successfully.');

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      message.error(`Failed to update player status. ${error}`);
    }
    setSuspendModalActive(false);
  };

  const handleDeactivate = async () => {
    try {
      await axios.patch(
        `players/${playerID}`,
        { status: 'Deactivated' },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        },
      );
      message.success('Updated player status successfully.');

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      message.error(`Failed to update player status. ${error}`);
    }

    setDeactivateModalActive(false);
  };

  const handleReactivate = async () => {
    try {
      await axios.patch(
        `players/${playerID}`,
        { status: 'Active' },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        },
      );
      message.success('Updated player status successfully.');

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      message.error(`Failed to update player status. ${error}`);
    }

    setUnsuspendModalActive(false);
    setActivateModalActive(false);
  };

  const handleCancel = () => {
    setSuspendModalActive(false);
    setDeactivateModalActive(false);
    setActivateModalActive(false);
    setUnsuspendModalActive(false);
  };

  const updateRemarks = async () => {
    let newRemarks = form.getFieldsValue().remarks;
    if (!newRemarks) {
      newRemarks = ' ';
    }
    try {
      await axios.patch(
        `players/${playerID}`,
        { remarks: newRemarks },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        },
      );
      message.success('Player remarks updated.');
    } catch (error) {
      message.error(`Failed to update remarks. ${error}`);
    }
  };

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      await axios
        .get(`players/${playerID}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setPlayerData(res.data);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrive player data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, []);

  function playerStatus(status) {
    switch (status) {
      case 'Active':
        return <Tag color="blue">Active</Tag>;
      case 'Inactive':
        return <Tag>Inactive</Tag>;
      case 'Suspended':
        return <Tag color="warning">Suspend</Tag>;
      case 'Deactivated':
        return <Tag color="error">Deactivated</Tag>;
      default:
        break;
    }
    return '';
  }

  function playerGender(gender) {
    switch (gender) {
      case 'Male':
        return (
          <Descriptions.Item label={<ManOutlined style={{ alignSelf: 'center' }} />} span={1}>
            Male
          </Descriptions.Item>
        );
      case 'Female':
        return (
          <Descriptions.Item label={<WomanOutlined style={{ alignSelf: 'center' }} />} span={1}>
            Female
          </Descriptions.Item>
        );
      default:
        break;
    }
    return '';
  }

  function buttonDisplay(status) {
    switch (status) {
      case 'Active':
      case 'Inactive':
        return (
          <>
            <Row>
              <Space direction={{ xs: 'vertical', lg: 'horizontal' }}>
                <Col xs={24} lg={12}>
                  <Button danger onClick={showDeactivateModal}>
                    Deactivate Account
                  </Button>
                  <Modal
                    title="Are you sure you want to deactivate this account?"
                    centered
                    closable={false}
                    open={deactivateModalActive}
                    onOk={handleDeactivate}
                    onCancel={handleCancel}
                    bodyStyle={{ padding: 0 }}
                    footer={[
                      <>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button type="primary" danger onClick={handleDeactivate}>
                          Confirm
                        </Button>
                      </>,
                    ]}
                  />
                </Col>
                <Col xs={24} lg={12}>
                  <Button type="primary" danger onClick={showSuspendModal}>
                    Suspend Account
                  </Button>
                  <Modal
                    title="Are you sure you want to suspend this account?"
                    centered
                    closable={false}
                    open={suspendModalActive}
                    onOk={handleSuspend}
                    onCancel={handleCancel}
                    bodyStyle={{ padding: 0 }}
                    footer={[
                      <>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button type="primary" danger onClick={handleSuspend}>
                          Confirm
                        </Button>
                      </>,
                    ]}
                  />
                </Col>
              </Space>
            </Row>
          </>
        );
      case 'Suspended':
        return (
          <>
            <Button type="primary" onClick={showUnsuspendModal}>
              Unsuspend Account
            </Button>
            <Modal
              title="Are you sure you want to unsuspend this account?"
              centered
              closable={false}
              open={unsuspendModalActive}
              onOk={handleReactivate}
              onCancel={handleCancel}
              bodyStyle={{ padding: 0 }}
              footer={[
                <>
                  <Button onClick={handleCancel}>Cancel</Button>
                  <Button type="primary" onClick={handleReactivate}>
                    Confirm
                  </Button>
                </>,
              ]}
            />
          </>
        );
      case 'Deactivated':
        return (
          <>
            <Button type="primary" onClick={showActivateModal}>
              Activate Account
            </Button>
            <Modal
              title="Are you sure you want to reactivate this account?"
              centered
              closable={false}
              open={activateModalActive}
              onOk={handleReactivate}
              onCancel={handleCancel}
              bodyStyle={{ padding: 0 }}
              footer={[
                <>
                  <Button onClick={handleCancel}>Cancel</Button>
                  <Button type="primary" onClick={handleReactivate}>
                    Confirm
                  </Button>
                </>,
              ]}
            />
          </>
        );
      default:
        break;
    }
    return '';
  }

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/player-management">
            <LeftOutlined />
            Players
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Player Detail</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const PlayerDetailsBlock = () => {
    const [editState, setEditState] = useState(false);
    const [phone, setPhone] = useState(playerData.phone);
    const [address, setAddress] = useState(playerData.address);

    const handleEdit = () => {
      setEditState(true);
    };

    const handleSave = async () => {
      try {
        await axios.patch(
          `players/${playerID}`,
          {
            phone,
            address,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
          },
        );
        message.success('Player data updated.');
        setEditState(false);
      } catch (error) {
        message.error(`Failed to update player data. ${error.response.data.message}`);
      }
    };

    const handleInputChange = (e, itemName) => {
      const newValue = e.target.value;
      if (itemName === 'playerPhone') {
        setPhone(newValue);
      } else if (itemName === 'playerAddress') {
        setAddress(newValue);
      }
    };

    return (
      <Descriptions
        title="Player Details"
        layout="horizontal"
        colon={false}
        column={1}
        extra={
          editState ? (
            <>
              <Button
                type="text"
                onClick={() => {
                  setPhone(playerData.phone);
                  setAddress(playerData.address);
                  setEditState(false);
                }}>
                <CloseOutlined />
              </Button>
              <Button type="text" onClick={handleSave}>
                <CheckOutlined />
              </Button>
            </>
          ) : (
            <Button type="text" onClick={handleEdit}>
              <EditOutlined />
            </Button>
          )
        }>
        <Descriptions.Item label={<PhoneOutlined style={{ alignSelf: 'center' }} />} span={1}>
          {editState ? (
            <Input value={phone} onChange={(e) => handleInputChange(e, 'playerPhone')} />
          ) : (
            <span>{phone}</span>
          )}
        </Descriptions.Item>
        {playerGender(playerData.gender)}
        <Descriptions.Item label={<CalendarOutlined style={{ alignSelf: 'center' }} />} span={1}>
          {playerData.dob ? playerData.dob.split('T')[0] : 'No DOB Data'}
        </Descriptions.Item>
        <Descriptions.Item label={<HomeOutlined style={{ alignSelf: 'center' }} />} span={1}>
          {editState ? (
            <Input value={address} onChange={(e) => handleInputChange(e, 'playerAddress')} />
          ) : (
            <span>{address || 'No Address Data'}</span>
          )}
        </Descriptions.Item>
        <Descriptions.Item label={<DollarOutlined style={{ alignSelf: 'center' }} />} span={1}>
          {`RM ${playerData.monthTotalSpend.toFixed(2)} Spent This Month`}
        </Descriptions.Item>
        <Descriptions.Item label={<UserAddOutlined style={{ alignSelf: 'center' }} />} span={1}>
          {`Registered at ${playerData.createdAt.split('T')[0]}`}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background player-detail">
        {loading === false ? (
          <Row style={{ paddingTop: '20px' }}>
            <Col xs={24} lg={8}>
              <Row>
                <Col span={24}>
                  <Card>
                    <Space direction="vertical">
                      <div style={{ display: 'flex', gap: '.5rem' }}>
                        <Title level={3} style={{ marginTop: 'auto', marginBottom: 'auto', textAlign: 'left' }}>
                          {playerData.name ? playerData.name : `Guest ${playerData.uid}`}
                        </Title>
                        {playerData.isEKYCVerified ? (
                          <Text style={{ marginTop: 'auto', marginBottom: 'auto', flex: 'none' }}>
                            <CheckCircleOutlined /> EKYC Verified
                          </Text>
                        ) : (
                          <Text style={{ marginTop: 'auto', marginBottom: 'auto', flex: 'none', color: 'rgba(0, 0, 0, 0.45)' }}>
                            <MinusCircleOutlined /> EKYC Unverified
                          </Text>
                        )}
                      </div>
                      <p>User ID: {playerData.uid}</p>
                      
                      {playerStatus(playerData.status)}
                      <Row gutter={16}>
                        <Col xs={12} lg={12}>
                          <Statistic
                            title="Total Active Incubator"
                            value={playerData.incubatorCount}
                          />
                        </Col>
                        <Col xs={12} lg={12}>
                          <Statistic
                            title="Total Spent To Date"
                            prefix="RM"
                            value={playerData.totalSpend}
                            precision={2}
                          />
                        </Col>
                      </Row>
                      <Divider />
                      <PlayerDetailsBlock />
                      <Divider />

                      <Descriptions title="Game Detail" layout="horizontal" column={1}>
           
                        <Descriptions.Item label="Incubator Level" span={1}>
                          {playerData.incubatorLevel}
                        </Descriptions.Item>
                        <Descriptions.Item label="Incubator stars" span={1}>
                          {playerData.incubatorStars}
                        </Descriptions.Item>
                        <Descriptions.Item label="Game Point for this Week" span={1}>
                          {playerData.miniGameScore}
                        </Descriptions.Item>
                      </Descriptions>
                      <Divider />

                      <Descriptions title="Card Pack Detail" layout="horizontal" column={1}>

                        <Descriptions.Item>

                          <CardPackRecordList data={playerData.cardpackRecord} />
                        </Descriptions.Item>

                      </Descriptions>
                      <Divider />

                      <Descriptions title="Eggs Detail" layout="horizontal" column={1}>
                        <Descriptions.Item label="Total of Eggs" span={1}>
                          {playerData.eggCount}
                        </Descriptions.Item>
                        <Descriptions.Item label="Hatched Eggs" span={1}>
                          {playerData.hatchedCount}
                        </Descriptions.Item>
                        <Descriptions.Item label="Unatched Eggs" span={1}>
                          {playerData.unhatchedCount}
                        </Descriptions.Item>
                      </Descriptions>
                      <Divider />
                      <Descriptions title="HeeCoin" layout="horizontal" column={1}>
                        <Descriptions.Item label="HeeCoin Balance">
                          {playerData.heeCoin} HeeCoins
                        </Descriptions.Item>
                        <Descriptions.Item label="Total HeeCoin Since Register">
                          {playerData.heecoinTotal} HeeCoins
                        </Descriptions.Item>
                      </Descriptions>
                      <Divider />

                      <Descriptions title="In-Game Currency" layout="horizontal" column={1}>
                        <Descriptions.Item label="Game Coin Balance">
                          {playerData.gameCoin}
                        </Descriptions.Item>
                        <Descriptions.Item label="Diamond Balance">
                          {playerData.hopeCoin}
                        </Descriptions.Item>
                      </Descriptions>
                      {/* <Title level={4}>Top Spending Category</Title>
                      <Tag color="#1890FF" style={{ borderRadius: '40px' }}>
                        Equipment
                      </Tag> */}
                    </Space>
                  </Card>
                </Col>
                <Col span={24}>
                  <div className="content-area" style={{ margin: '20px 0px' }}>
                    <BlockOutlined /> Remarks
                    <Form form={form}>
                      <Form.Item name="remarks">
                        <TextArea
                          placeholder="Write your description here..."
                          defaultValue={playerData.remarks}
                        />
                      </Form.Item>
                    </Form>
                    <Button style={{ marginTop: '20px' }} onClick={updateRemarks}>
                      Save Remarks
                    </Button>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={16}>
              <div className="layout-background">
                <Row>
                  <Col flex={4}>
                    <Title level={3} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                      Game Details
                    </Title>
                  </Col>
                  <Col style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Space>{buttonDisplay(playerData.status)}</Space>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col xs={24} lg={24}>
                  <div className="content-area" style={{ marginTop: '0px' }}>
                    <Tabs
                      defaultActiveKey="1"
                      items={tabItems}
                      activeKey={selectedTab}
                      onChange={handleContentChange}
                    />
                    {ContentsDiv(selectedTab)}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Skeleton />
        )}
      </Content>
    </>
  );
}
