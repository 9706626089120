import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import moment from 'moment';

import {
  Modal,
  Form,
  Table,
  Col,
  Layout,
  Row,
  Space,
  Button,
  Typography,
  DatePicker,
  Breadcrumb,
  message,
  Input,
  Tooltip,
  Skeleton,
  Tag,
} from 'antd';
import { PlusOutlined, EditOutlined, LeftOutlined } from '@ant-design/icons';

import axios from 'axios';

const { Title, Link, Text } = Typography;

const { Content } = Layout;

const PlayerVoucherListTable = ({ data = [] }) => {
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    setTableData(data);
  }, [data]);
  const columns = [
    {
      title: 'Player Voucher ID',
      key: 'Voucher ID',
      dataIndex: '_id',
      width: 200,
   
    },

    {
      title: 'Voucher',
      key: 'voucher',
      width: 200,
      render: (_data, row) => {
        return row.voucher.name || '-';
      },
    },
  
    {
      title: 'Player UID',
      key: 'Player UID',
      width: 150,
      render: (_data, row) => {
        return row.player.uid || '-';
      },
    },

    {
      title: 'Pet Name',
      key: 'Player',
      width: 150,
      render: (_data, row) => {
        return row.pet.name || '-';
      },
    },
    {
      title: 'Obtaining Date',
      key: 'Obtaining Date',
      width: 150,
      render: (_data, row) => {
        return moment(row.createdAt).format('YYYY-MM-DD HH:mm:ss');
      },
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        let res;
  
        switch (status) {
          case 'active':
            res = <Tag color="blue">Active</Tag>;
            break;
          case 'inactive':
            res = <Text type="secondary">Inactive</Text>;
            break;
          case 'expired':
            res = <Tag color="red">Expired</Tag>;
            break;
          
          case 'used':
            res = <Tag color="#87d068">Used</Tag>;
            break;
          default:
            res = '';
            break;
        }
  
        return res;
      },
      width: 100,
    },
   
  ];

  return (
    <>
      <Title level={5} style={{ textAlign: 'left', marginBottom: '1em', marginLeft: '10px' }}>Amount: { tableData.length}</Title>
      <Table
        columns={columns}
        dataSource={tableData}
        pagination={false}
        rowKey="_id"
        scroll={{ x: '100%', y: 400 }}
   />
    </>
   
  );
};

export function VoucherPlayerPage(props) {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [filterForm] = Form.useForm();

  const [totalData, setTotalData] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [modalActive, setModalActive] = useState(false);

  const [curPlayerVoucherList, setCurPlayerVoucherList] = useState([]);

  const { voucherId } = props.match.params;

  const [voucherData, setVoucherData] = useState({});

  const getVoucherData = async () => {
    await axios
      .get(`vouchers/withDistributed/${voucherId}`, {
       
      })
      .then((res) => {
        setVoucherData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        message.error(`Failed to retrieve voucher data. ${error}`);
        setLoading(false);
      });
  };

  useEffect(() => {
    getVoucherData();
  }, []);

  const getTableData = async (pageNum, pageSize) => {
    try {
      setLoading(true);
      const res = await axios
        .get(`/playerVouchers/queryPlayerByVoucherId/${voucherId}`, {
          params: {
            ...filterForm.getFieldsValue(),

            limit: pageSize,
            page: pageNum,
          },
        });
    
      setTableData(res.data.results);
      setTotalData(res.data.totalResults);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    setPage(1);

    getTableData(1, limit);
  };

  const onTablePageChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
    getTableData(current, pageSize);
  };

  const clearFilter = () => {
    filterForm.resetFields();
  
    handleSearch();
  };

  useEffect(() => {
    handleSearch();
  }, []);

  const columns = [
  
    {
      title: 'Player UID',
      key: 'Player UID',
      width: 150,
      render: (data, row) => {
        return row.playerVoucher.player.uid || '-';
      },
    },

    {
      title: 'Pet Name',
      key: 'Player',
      width: 150,
      render: (_data, row) => {
        return row.playerVoucher.pet.name || '-';
      },
    },
    {
      title: 'Pet Type',
      key: 'Pet Type',
      width: 150,
      render: (_data, row) => {
        return row.playerVoucher.pet.type || '-';
      },
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      width: 150,

      render: (_data, row) => {
        return (
          <Typography.Link onClick={() => {
            setCurPlayerVoucherList(row.playerVoucherList);
            setModalActive(true);
          }}>
            {row.totalAmount}
          </Typography.Link>
        );
      },

    },
   
  ];

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/vouchers">
            <LeftOutlined />
            Vouchers
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item> Voucher Player</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background" style={{ padding: '0 20px' }}>

        <Space direction="vertical" style={{ display: 'flex' }}>
          <div style={{ display: 'flex' }}><Text style={{ marginRight: '10px' }}>[{voucherData.label}]</Text> {voucherData.name}</div> 

          <Form form={filterForm} layout="inline">
            <Form.Item name="playerUid" label="Player Uid">
              <Input placeholder="please input" />
            </Form.Item>

            <Form.Item>
              <Space>
                <Col>
                  <Button danger onClick={() => clearFilter()}>
                    Reset Filters
                  </Button>
                </Col>
                <Col>
                  <Button onClick={() => handleSearch()}>Apply Filters</Button>
                </Col>
              </Space>
            </Form.Item>
          </Form>

          <div className="table-content">
            {loading === false ? (
              <Table
                columns={columns}
                dataSource={tableData}
                pagination={{ pageSize: limit,
                  pageSizeOptions: [10, 20, 50, 100],
                  showSizeChanger: true,
                  total: totalData,
                  onChange: onTablePageChange,
                  current: page,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                rowKey="_id"
                scroll={{ x: '100%' }}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </Space>
      </Content>

      <Modal
        title="Player Vouchers"
        destroyOnClose
        width={1200}
        open={modalActive}
        centered
        closable
        onCancel={() => setModalActive(false)}
        footer={null}>
        <PlayerVoucherListTable
          data={curPlayerVoucherList}
         
          />
      </Modal>
    </>
  );
}
