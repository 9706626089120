import React, { useEffect, useState, useMemo } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  InputNumber,
  Table,
  Image,
  Tag,
  Tooltip,
  Tabs,
  Select,
  message,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

/**
 * Voucher奖池(Tier)
 */
export function TierVoucherPool({
  voucherOptions,
  value,
  onChange,
}) {
  const voucherColumns = [
    {
      title: '',
      dataIndex: 'voucherUrl',
      key: 'key',
      render: (data) => <Image width={50} src={data} />,
    },
    {
      title: 'Merchant',
      dataIndex: 'merchantId',
      key: 'merchantId',
      render: (data) => data.name,
    },
    {
      title: 'Voucher',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        let res;
      
        switch (status) {
          case 'Active':
            res = <Tag color="blue">Active</Tag>;
            break;
          case 'Inactive':
            res = <Tag>Inactive</Tag>;
            break;
          case 'expired':
            res = <Tag color="red">Expired</Tag>;
            break;
          default:
            res = '';
            break;
        }
      
        return res;
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Redeemed',
      dataIndex: 'redeemed',
      key: 'redeemed',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
    },
  ];

  const onRateChange = (v) => {
    onChange(v);
  };
  return (

    <div>

      <div style={{ display: 'flex', alignItems: 'center' }}>
       
        <span>Voucher Rate (%):</span>
        <InputNumber style={{ margin: '8px' }} defaultValue={value} min={0} onChange={onRateChange} placeholder="0" />
        <Tooltip title="The likelihood of a relevant tier player receiving a voucher assigned to the tier after egg incubation.">
          <InfoCircleOutlined />
        </Tooltip>
      </div>
      <Table
        columns={voucherColumns}
        dataSource={voucherOptions}
        rowKey="id"
        pagination={{ defaultPageSize: 10 }}
        scroll={{ x: '100%' }}
  />

    </div>
   
  );
}
