import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import DashboardPage from '../pages/dashboard/Dashboard';
import ConsultantManagementPage from '../pages/consultantManagement/ConsultantManagement';
import ConsultantDetailsPage from '../pages/consultantManagement/ConsultantDetails';
import AuditLog from '../pages/auditLog/AuditLog';
import PlayerManagement from '../pages/playerManagement/PlayerList';
import PlayerDetail from '../pages/playerManagement/PlayerDetail';
import MerchantManagement from '../pages/merchantManagement/MerchantList';
import VoucherManagement from '../pages/voucherManagement/VoucherList';
import VoucherRequest from '../pages/voucherManagement/VoucherRequest';
import CreateVoucherRequest from '../pages/voucherManagement/CreateVoucherRequest';
import VoucherHistory from '../pages/voucherManagement/VoucherHistory';
import TransactionsManagement from '../pages/transactions/TransactionList';
import CreateMerchant from '../pages/merchantManagement/CreateMerchant';
import EditMerchant from '../pages/merchantManagement/EditMerchant';
import BranchDetails from '../pages/merchantManagement/BranchDetails';
import EditBranchRequest from '../pages/merchantManagement/EditBranchRequest';
// import CreateVoucher from '../pages/voucherManagement/CreateVoucher';
import EditVoucher from '../pages/voucherManagement/EditVoucher';
import GoblinCfg from '../pages/goblinCfg/GoblinCfg';
import GameStoreList from '../pages/gameStore/GameStoreList';
import CreateGameItem from '../pages/gameStore/CreateGameItem';
import EditGameItem from '../pages/gameStore/EditGameItem';
import UserManagement from '../pages/userManagement/UserManagement';
import CreateUser from '../pages/userManagement/CreateUser';
import EditUser from '../pages/userManagement/EditUser';
// import AdManagement from '../pages/adManagement/AdManagement';
// import CreateAd from '../pages/adManagement/CreateAd';
// import EditAd from '../pages/adManagement/EditAd';
import Settings from '../pages/settings/Settings';
import MerchantBranchList from '../pages/merchantBranch/MerchantBranchList';
import MerchantBranchDetail from '../pages/merchantBranch/MerchantBranchDetail';
import MerchantEditBranch from '../pages/merchantBranch/MerchantEditBranch';
import TreasureHuntList from '../pages/treasureHunt/TreasureHuntList';
import EditTreasureHunt from '../pages/treasureHunt/EditTreasureHunt';
import Tier from '../pages/tier/Tier';
import CreateTier from '../pages/tier/CreateTier';
import EditTier from '../pages/tier/EditTier';
import ReloadPin from '../pages/reloadPin/ReloadPin';
import EditReloadPin from '../pages/reloadPin/EditReloadPin';
import WelcomeSettings from '../pages/welcomeSetting/welcomeSetting';
import HeeCoinList from '../pages/heeCoinSetting/HeeCoinList';
import { HeecoinHistoryPage } from '../pages/heeCoinSetting/HeecoinHistoryPage';

import CreateHeeCoin from '../pages/heeCoinSetting/CreateHeeCoin';
import EditHeeCoin from '../pages/heeCoinSetting/EditHeeCoin';
import NotFoundPage from './404';

import { IncubatorConfigListPage } from '../pages/modules/incubatorConfig/IncubatorConfigListPage';
import { CreateIncubatorPage } from '../pages/modules/incubatorConfig/CreateIncubatorPage';
import { EditIncubatorPage } from '../pages/modules/incubatorConfig/EditIncubatorPage';

import { VoucherPlayerPage } from '../pages/voucherManagement/VoucherPlayerPage';

import { CardpackConfigListPage } from '../pages/modules/cardpack/CardpackConfigListPage';
import { CreateCardpackPage } from '../pages/modules/cardpack/CreateCardpackPage';
import { EditCardpackPage } from '../pages/modules/cardpack/EditCardpackPage';
import { MiniGameConfigPage } from '../pages/modules/miniGame/miniGameConfigPage';
import { MiniGamePage } from '../pages/modules/miniGame/miniGamePage';

export default function RouteList(userRole) {
  const handleAdminRoutes = () => (
    <>
      <Route exact path="/" render={() => <DashboardPage userRole="admin" />} />
      <Route
        key="/consultant-management"
        exact
        path="/consultant-management"
        component={ConsultantManagementPage}
      />
      <Route
        key="/consultant-management/details"
        exact
        path="/consultant-management/details"
        component={ConsultantDetailsPage}
      />
      <Route key="/audit-log" exact path="/audit-log" component={AuditLog} />
      <Route
        key="/player-management"
        exact
        path="/player-management"
        component={PlayerManagement}
      />
      <Route
        key="/player-management/:id"
        exact
        path="/player-management/:id"
        component={PlayerDetail}
      />
      <Route
        key="/vouchers"
        exact
        path="/vouchers"
        render={() => <VoucherManagement userRole="admin" />}
      />
      <Route
        key="/voucher-request"
        exact
        path="/voucher-request"
        render={() => <VoucherRequest userRole="admin" />}
      />
      <Route
        key="/voucher-history"
        exact
        path="/voucher-history"
        render={() => <VoucherHistory userRole="admin" />}
      />
      <Route
        key="/vouchers/:id/edit"
        exact
        path="/vouchers/:id/edit"
        render={() => <EditVoucher userRole="admin" />}
        userRole="admin"
      />
      <Route key="/transaction" exact path="/transaction" component={TransactionsManagement} />
      <Route key="/merchants" exact path="/merchants" component={MerchantManagement} />
      <Route key="/merchants/create" exact path="/merchants/create" component={CreateMerchant} />
      <Route key="/merchants/:id/edit" exact path="/merchants/:id/edit" component={EditMerchant} />
      <Route
        key="/merchants/:id/branchdetails"
        exact
        path="/merchants/:id/branchdetails"
        component={BranchDetails}
      />
      <Route
        key="/editbranchrequest/:id"
        exact
        path="/editbranchrequest/:id"
        component={EditBranchRequest}
      />
      <Route key="/gamestore" exact path="/gamestore" component={GameStoreList} />
      <Route
        key="/gamestore/item/create"
        exact
        path="/gamestore/item/create"
        component={CreateGameItem}
      />
      <Route key="/gamestore/:id/edit" exact path="/gamestore/:id/edit" component={EditGameItem} />
      <Route key="/user-management" exact path="/user-management" component={UserManagement} />
      <Route
        key="/user-management/create"
        exact
        path="/user-management/create"
        component={CreateUser}
      />
      <Route
        key="/user-management/:id/edit"
        exact
        path="/user-management/:id/edit"
        component={EditUser}
      />
   
      <Route key="/treasurehunt" exact path="/treasurehunt" component={TreasureHuntList} />
      <Route
        key="/treasurehunt/:id/edit"
        exact
        path="/treasurehunt/:id/edit"
        component={EditTreasureHunt}
      />
      <Route key="/tier" exact path="/tier" component={Tier} />
      <Route key="/tier/create" exact path="/tier/create" component={CreateTier} />
      <Route key="/tier/:id/edit" exact path="/tier/:id/edit" component={EditTier} />
      <Route key="/settings" exact path="/settings" component={Settings} />
      <Route key="/goblinStats" exact path="/goblinStats" component={GoblinCfg} />
      <Route key="/reload-pin" exact path="/reload-pin" component={ReloadPin} />
      <Route
        key="/reload-pin/:id/edit"
        exact
        path="/reload-pin/:id/edit"
        component={EditReloadPin}
      />
      <Route key="/welcome-gift-setting" exact path="/welcome-gift-setting" component={WelcomeSettings} />
      <Route key="/heecoin-setting" exact path="/heecoin-setting" component={HeeCoinList} />
      <Route key="/heecoin-setting/create" exact path="/heecoin-setting/create" component={CreateHeeCoin} />
      <Route key="/heecoin-setting/:id/edit" exact path="/heecoin-setting/:id/edit" component={EditHeeCoin} />
      <Route key="/heecoin-history" exact path="/heecoin-history" component={HeecoinHistoryPage} />

      {/* 优惠券用户 */}
      <Route key="/voucherPlayerPage/:voucherId" exact path="/voucherPlayerPage/:voucherId" component={VoucherPlayerPage} />
      
      {/* 孵蛋器等级 */}
      <Route key="/incubatorConfigListPage" exact path="/incubatorConfigListPage" component={IncubatorConfigListPage} />
      <Route key="/createIncubatorPage" exact path="/createIncubatorPage" component={CreateIncubatorPage} />
      <Route key="/editIncubatorPage/:level" exact path="/editIncubatorPage/:level" component={EditIncubatorPage} />

      {/* 徽章卡包配置 */}
      <Route key="/cardpackConfigListPage" exact path="/cardpackConfigListPage" component={CardpackConfigListPage} />
      <Route key="/createCardpackPage" exact path="/createCardpackPage" component={CreateCardpackPage} />
      <Route key="/editCardpackPage/:id" exact path="/editCardpackPage/:id" component={EditCardpackPage} />

      {/* 小游戏配置 */}
      <Route key="/miniGameConfigPage" exact path="/miniGameConfigPage" component={MiniGamePage} />

    </>
  );

  const handleOpRoutes = () => (
    <>
      <Route exact path="/">
        <Redirect to="/player-management" />
      </Route>
      <Route
        key="/player-management"
        exact
        path="/player-management"
        component={PlayerManagement}
      />
      <Route
        key="/player-management/:id"
        exact
        path="/player-management/:id"
        component={PlayerDetail}
      />
      <Route
        key="/vouchers"
        exact
        path="/vouchers"
        render={() => <VoucherManagement userRole="admin" />}
      />
      <Route
        key="/vouchers/:id/edit"
        exact
        path="/vouchers/:id/edit"
        render={() => <EditVoucher userRole="merchant" />}
      />
      <Route
        key="/voucher-request"
        exact
        path="/voucher-request"
        render={() => <VoucherRequest userRole="merchant" />}
      />
      <Route key="/merchants" exact path="/merchants" component={MerchantManagement} />
      <Route key="/merchants/create" exact path="/merchants/create" component={CreateMerchant} />
      <Route key="/merchants/:id/edit" exact path="/merchants/:id/edit" component={EditMerchant} />
      <Route
        key="/merchants/:id/branchdetails"
        exact
        path="/merchants/:id/branchdetails"
        component={BranchDetails}
      />
      <Route
        key="/editbranchrequest/:id"
        exact
        path="/editbranchrequest/:id"
        component={EditBranchRequest}
      />
      <Route key="/gamestore" exact path="/gamestore" component={GameStoreList} />
      <Route
        key="/gamestore/item/create"
        exact
        path="/gamestore/item/create"
        component={CreateGameItem}
      />
      <Route key="/gamestore/:id/edit" exact path="/gamestore/:id/edit" component={EditGameItem} />
      <Route key="/user-management" exact path="/user-management" component={UserManagement} />
      <Route
        key="/user-management/create"
        exact
        path="/user-management/create"
        component={CreateUser}
      />
      <Route
        key="/user-management/:id/edit"
        exact
        path="/user-management/:id/edit"
        component={EditUser}
      />
      {/* <Route key="/ad-management" exact path="/ad-management" component={AdManagement} />
      <Route
        key="/ad-management/item/create"
        exact
        path="/ad-management/item/create"
        component={CreateAd}
      />
      <Route
        key="/ad-management/:id/edit"
        exact
        path="/ad-management/:id/edit"
        component={EditAd}
      /> */}
      <Route key="/treasurehunt" exact path="/treasurehunt" component={TreasureHuntList} />
      <Route key="/tier" exact path="/tier" component={Tier} />
      <Route key="/tier/create" exact path="/tier/create" component={CreateTier} />
      <Route key="/tier/:id/edit" exact path="/tier/:id/edit" component={EditTier} />
      <Route key="/settings" exact path="/settings" component={Settings} />
    </>
  );

  const handleFinanceRoutes = () => (
    <>
      <Route exact path="/" render={() => <DashboardPage userRole="admin" />} />
      <Route key="/audit-log" exact path="/audit-log" component={AuditLog} />
      <Route key="/transaction" exact path="/transaction" component={TransactionsManagement} />
      <Route key="/settings" exact path="/settings" component={Settings} />
    </>
  );

  const handleMerchantRoutes = () => (
    <>
      <Route exact path="/" render={() => <DashboardPage userRole="merchant" />} />
      <Route
        key="/vouchers"
        exact
        path="/vouchers"
        render={() => <VoucherManagement userRole="merchant" />}
      />
      <Route
        key="/vouchers/:id/edit"
        exact
        path="/vouchers/:id/edit"
        render={() => <EditVoucher userRole="merchant" />}
      />
      <Route
        key="/voucher-request"
        exact
        path="/voucher-request"
        render={() => <VoucherRequest userRole="merchant" />}
      />
      <Route
        key="/voucher-request/create"
        exact
        path="/voucher-request/create"
        render={() => <CreateVoucherRequest userRole="merchant" />}
      />
      <Route
        key="/voucher-history"
        exact
        path="/voucher-history"
        render={() => <VoucherHistory userRole="merchantHQ" />}
      />
      <Route key="/branches" exact path="/branches" component={MerchantBranchList} />
      <Route
        key="/branches/:branchId/branch-detail"
        exact
        path="/branches/:branchId/branch-detail"
        component={MerchantBranchDetail}
      />
      <Route
        key="/branches/:branchId/edit"
        exact
        path="/branches/:branchId/edit"
        component={MerchantEditBranch}
      />
      <Route key="/settings" exact path="/settings" component={Settings} />
    </>
  );
  const handleBranchRoutes = () => (
    <>
      <Route exact path="/">
        <Redirect to="/vouchers" />
      </Route>
      <Route
        key="/vouchers"
        exact
        path="/vouchers"
        render={() => <VoucherManagement userRole="merchant" />}
      />
      <Route
        key="/voucher-history"
        exact
        path="/voucher-history"
        render={() => <VoucherHistory userRole="merchantBranch" />}
      />
      <Route key="/settings" exact path="/settings" component={Settings} />
    </>
  );
  // TODO modify when api implement
  const handleRolesRoutes = (roles) => {
    switch (roles) {
      case 'merchantHQ':
        return handleMerchantRoutes();
      case 'merchantBranch':
        return handleBranchRoutes();
      case 'admin':
        return handleAdminRoutes();
      case 'operation':
        return handleOpRoutes();
      case 'finance':
        return handleFinanceRoutes();
      default:
        return NotFoundPage();
    }
  };

  return <Switch>{handleRolesRoutes(userRole)}</Switch>;
}
