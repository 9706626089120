import React, { useState, useEffect, useCallback } from 'react';
import { utils, writeFileXLSX } from 'xlsx';
import {
  Table,
  Layout,
  Button,
  Row,
  Col,
  Select,
  Space,
  DatePicker,
  Tag,
  Modal,
  Typography,
  Breadcrumb,
  message,
  Form,
  Input,
  Skeleton,
} from 'antd';
import { ImportOutlined } from '@ant-design/icons';

import moment from 'moment';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Link } = Typography;

const columns = [
  {
    title: 'Player Name',
    dataIndex: 'playerName',
    key: 'playerName',
    render: (text, record) => (
      <Link href={`/player-management/${record.playerId}`}>{text}</Link>
    ),
  },
  {
    title: 'Descriptions',
    dataIndex: 'description',
    key: 'description',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status) => {
      let res;

      switch (status) {
        case 'successful':
          res = <Tag color="success">Successful</Tag>;
          break;
        case 'failed':
          res = <Tag color="error">Failed</Tag>;
          break;
        default:
          res = '';
          break;
      }

      return res;
    },
  },
  {
    title: 'Top Up Amount',
    dataIndex: 'topupAmount',
    key: 'topupAmount',
  },
  {
    title: 'Type',
    dataIndex: 'paymentType',
    key: 'paymentType',
  },
  {
    title: 'Invoice Date',
    dataIndex: 'invoiceDate',
    key: 'invoiceDate',
    render: (data) => (data ? moment(data).format('YYYY-MM-DD HH:mm:ss') : '-'),
  },
];

export default function TransactionList() {
  const [modalActive, setModalActive] = useState(false);
  const showModal = () => {
    setModalActive(true);
  };
  // const handleOk = () => {
  //   setModalActive(false);
  // };
  const handleCancel = () => {
    setModalActive(false);
  };

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/transaction">Transactions</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const token = getToken();
  const [loading, setLoading] = useState(true);
  const [txnList, setTxnList] = useState([]);
  const [txnListData, setTxnListData] = useState([]);

  const [filterForm] = Form.useForm();

  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      list.push({
        key: data[i]._id,
        playerName: data[i].player.name ? data[i].player.name : data[i].player.uid,
        playerId: data[i].playerId,
        description: data[i].description,
        status: data[i].status,
        topupAmount: `${data[i].currency} ${data[i].amount.toFixed(2)} `,
        paymentType: data[i].type === 'Hope Coin' ? 'Diamond' : data[i].type,
        invoiceDate: data[i].createdAt,
      });
    }
    setTxnListData(list);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleOk = useCallback(() => {
    let selectedRows = [];

    if (rowSelection.selectedRowKeys.length === 0) {
      // Export all rows when no rows are selected
      selectedRows = txnListData.map(({ key, ...rest }) => rest);
    } else {
      // Export selected rows
      selectedRows = txnListData
        .filter((row) => rowSelection.selectedRowKeys.includes(row.key))
        .map(({ key, ...rest }) => rest);
    }

    // Rename column headers
    const renamedColumns = {
      playerName: 'Player Name',
      description: 'Description',
      status: 'Status',
      topupAmount: 'Topup Amount',
      paymentType: 'Payment Type',
      invoiceDate: 'Invoice Date',
    };

    const transformedRows = selectedRows.map((row) => (
      Object.entries(row).reduce((acc, [key, value]) => {
        const renamedKey = renamedColumns[key] || key;
        if (key === 'invoiceDate') {
          const date = new Date(value);
          const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Asia/Kuala_Lumpur',
          };
          return { ...acc, [renamedKey]: date.toLocaleString('en-US', options) };
        }
        return { ...acc, [renamedKey]: value };
      }, {})));  
  
    const currentDate = new Date();
    const formattedDate = currentDate
      .toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(/[/:\s]/g, '_');

    const ws = utils.json_to_sheet(transformedRows);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    writeFileXLSX(wb, `Transactions_${formattedDate}.xlsx`);

    setModalActive(false);
  }, [txnListData, rowSelection.selectedRowKeys]);

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setLoading(true);

    const params = {
      category: 'topup',
      ...filterData,
    };

    const getData = async () => {
      await axios
        .get('transactions', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params,
        })
        .then((res) => {
          setTxnList(res.data.results);
          setTotalData(res.data.totalResults);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve transactions data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, [filterData]);

  useEffect(() => {
    if (loading === false) {
      rerenderList(txnList);
    }
  }, [loading, txnList]);

  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const onFilterUpdate = () => {
    setIsFilterUpdated(true);
  };

  const onFilter = () => {
    filterForm
      .validateFields()
      .then((res) => {
        const { playerName, topupAmt, status, date } = res;

        const items = {};
        if (playerName !== '') {
          items.playerName = playerName;
        }
        if (topupAmt !== 'all') {
          items.topupAmt = topupAmt;
        }
        if (status !== 'all') {
          items.status = status;
        }
        if (date) {
          items.startDate = moment(date[0]).startOf('day').toDate();
          items.endDate = moment(date[1]).endOf('day').toDate();
        }

        setFilterData(items);
        setIsFilterUpdated(true);
      })
      .catch(() => {});
  };

  const clearFilter = () => {
    filterForm.resetFields();
    onFilter();
    setTimeout(() => {
      setIsFilterUpdated(false);
    }, 0);
  };

  const FilterRow = () => (
    <Form form={filterForm}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={12} lg={6}>
          <Row>
            <p>Player Name</p>
          </Row>
          <Row>
            <Form.Item name="playerName" style={{ width: '100%' }}>
              <Input placeholder="Player Name" style={{ width: '100%' }} />
            </Form.Item>
          </Row>
        </Col>
        {/* <Col sm={12} lg={8}>
          <Row>
            <p>Invoice No</p>
          </Row>
          <Row>
            <Form.Item name="invoice" style={{ width: '100%' }}>
              <Select
                defaultValue="all"
                style={{ width: '85%' }}
                options={[
                  {
                    value: 'all',
                    label: 'All',
                  },
                ]}
              />
            </Form.Item>
          </Row>
        </Col> */}
        <Col sm={12} lg={6}>
          <Row>
            <p>Top Up Amount</p>
          </Row>
          <Row>
            <Form.Item name="topupAmt" style={{ width: '100%' }}>
              <Select
                defaultValue="all"
                style={{ width: '100%' }}
                onChange={onFilterUpdate}
                options={[
                  {
                    value: 'all',
                    label: 'All',
                  },
                  {
                    value: '0to4.99',
                    label: 'RM 0 - RM 4.99',
                  },
                  {
                    value: '5to18.9',
                    label: 'RM 5 - RM 18.90',
                  },
                  {
                    value: '19to59.9',
                    label: 'RM 19 - RM 59.90',
                  },
                  {
                    value: '60to129.9',
                    label: 'RM 60 - RM 129.90',
                  },
                  {
                    value: '130to329.9',
                    label: 'RM 130 - RM 329.90',
                  },
                  {
                    value: '330to499.9',
                    label: 'RM 330 - RM 499.90',
                  },
                ]}
              />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={6}>
          <Row>
            <p>Status</p>
          </Row>
          <Row>
            <Form.Item name="status" style={{ width: '100%' }}>
              <Select
                defaultValue="all"
                style={{ width: '100%' }}
                onChange={onFilterUpdate}
                options={[
                  {
                    value: 'all',
                    label: 'All',
                  },
                  {
                    value: 'successful',
                    label: 'Successful',
                  },
                  {
                    value: 'failed',
                    label: 'Failed',
                  },
                ]}
              />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={6}>
          <Row>
            <p>Date</p>
          </Row>
          <Row>
            <Form.Item name="date" style={{ width: '100%' }}>
              <RangePicker style={{ width: '100%' }} onChange={onFilterUpdate} />
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </Form>
  );

  const onChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
  };

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Row justify="end">
            <Col>
              <Space>
                <Button onClick={showModal}>
                  <ImportOutlined rotate={-90} />
                  Export
                </Button>
                <Modal
                  title="Are you sure you want to export transaction management data?"
                  open={modalActive}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  centered
                  closable={false}
                  bodyStyle={{ padding: 0 }}
                  footer={[
                    <>
                      <Button onClick={handleCancel}>Cancel</Button>
                      <Button type="primary" onClick={handleOk}>
                        Export
                      </Button>
                    </>,
                  ]}
                />
              </Space>
            </Col>
          </Row>
          <FilterRow />
          <Row justify="end">
            <Space>
              <Col>
                <Button danger onClick={clearFilter} disabled={!isFilterUpdated}>
                  Reset Filters
                </Button>
              </Col>
              <Col>
                <Button onClick={onFilter}>Apply Filters</Button>
              </Col>
            </Space>
          </Row>
          <div className="table-content">
            {loading === false ? (
              <Table
                columns={columns}
                dataSource={txnListData}
                pagination={{
                  pageSize: limit,
                  pageSizeOptions: [20, 50, 100],
                  showSizeChanger: true,
                  total: totalData,
                  onChange,
                  current: page,
                }}
                scroll={{ x: '100%' }}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </Space>
      </Content>
    </>
  );
}
