import React, { useEffect, useState, useCallback } from 'react';
import { utils, writeFileXLSX } from 'xlsx';
import {
  Table,
  Col,
  Layout,
  Row,
  Space,
  Modal,
  Button,
  Typography,
  Breadcrumb,
  Skeleton,
  message,
} from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import { getToken } from '../../utils/AuthService';

const { Content } = Layout;
const { Link } = Typography;

const columns = [
  {
    title: 'User Name',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
  },
  {
    title: 'Date & Time',
    dataIndex: 'createdDate',
    key: 'createdDate',
    render: (data) => (data ? moment(data).format('YYYY-MM-DD HH:mm:ss') : '-'),
  },
];

export default function AuditLog() {
  const [modalActive, setModalActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [actionLogList, setActionLogList] = useState();
  const [actionLogListData, setActionLogListData] = useState();

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();

  const token = getToken();

  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      list.push({
        key: data[i]._id,
        username: data[i].user.name,
        role: data[i].user.role,
        action: data[i].description,
        createdDate: data[i].createdAt,
      });
    }
    setActionLogListData(list);
  };

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      await axios
        .get('actionlogs/getAuditLog', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setActionLogList(res.data.results);
          setTotalData(res.data.totalResults);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve audit logs data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, []);

  useEffect(() => {
    if (loading === false) {
      rerenderList(actionLogList);
    }
  }, [loading]);

  const showModal = () => {
    setModalActive(true);
  };

  // const handleOk = () => {
  //   setModalActive(false);
  // };

  const handleCancel = () => {
    setModalActive(false);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleOk = useCallback(() => {
    let selectedRows = [];
  
    if (rowSelection.selectedRowKeys.length === 0) {
      // Export all rows when no rows are selected
      selectedRows = actionLogListData.map(({ key, ...rest }) => rest);
    } else {
      // Export selected rows
      selectedRows = actionLogListData
        .filter((row) => rowSelection.selectedRowKeys.includes(row.key))
        .map(({ key, ...rest }) => rest);
    }

    // Rename column headers
    const renamedColumns = {
      username: 'User Name',
      role: 'Role',
      action: 'Action',
      createdDate: 'Date & Time',
    };

    const transformedRows = selectedRows.map((row) => (
      Object.entries(row).reduce((acc, [key, value]) => {
        const renamedKey = renamedColumns[key] || key;
        if (key === 'createdDate') {
          const createdDate = new Date(value);
          const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Asia/Kuala_Lumpur',
          };
          return { ...acc, [renamedKey]: createdDate.toLocaleString('en-US', options) };
        }
        return { ...acc, [renamedKey]: value };
      }, {})));  
  
    const currentDate = new Date();
    const formattedDate = currentDate
      .toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(/[/:\s]/g, '_');
  
    const ws = utils.json_to_sheet(transformedRows);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    writeFileXLSX(wb, `AuditLog_${formattedDate}.xlsx`);

    setModalActive(false);
  }, [actionLogListData, rowSelection.selectedRowKeys]);

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/audit-log">Audit Log</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const onChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
  };

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Row justify="end">
            <Col>
              <Space>
                <Button onClick={showModal}>
                  <ImportOutlined rotate={-90} />
                  Export
                </Button>
                <Modal
                  title="Are you sure you want to export audit log data?"
                  open={modalActive}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  centered
                  closable={false}
                  bodyStyle={{ padding: 0 }}
                  footer={[
                    <>
                      <Button onClick={handleCancel}>Cancel</Button>
                      <Button type="primary" onClick={handleOk}>
                        Export
                      </Button>
                    </>,
                  ]}
                />
              </Space>
            </Col>
          </Row>
          <div className="table-content">
            {loading === false ? (
              <Table
                columns={columns}
                dataSource={actionLogListData}
                pagination={{ pageSize: limit,
                  pageSizeOptions: [20, 50, 100],
                  showSizeChanger: true,
                  total: totalData,
                  onChange,
                  current: page,
                }}
                scroll={{ x: '100%' }}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </Space>
      </Content>
    </>
  );
}
