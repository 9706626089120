import React, { useEffect, useState, useMemo } from 'react';
import { Tabs, Image, Badge, Tag } from 'antd';
import moment from 'moment';

import { TierVoucherPool } from './TierVoucherPool';

import { PoolItem } from './PoolItem';

/**
 * 综合奖池（Item,HeeCoin, Voucher）
 * 
 * @returns
 * 
 * {
 *   heeCoinPool:[]//heeCoin奖池
 *   itemPrizePool:[]//道具奖池
 *   voucherItemPrizePoolRate:5  //voucher 抽中概率
 *   totalWeightage:100
 * }
 */
export function PrizePool({

  option = {
    mode: 'probability',
    poolTypes: ['Items', 'Cardpack'],
    totalProbability: 100,
    tierVouchersProbability: 0,
    itemPool: [],
    cardpackPool: [],
    heeCoinPool: [],
    voucherPool: [],
  },
  onOptionChange,

  headerTitle,
  tierVoucherOptions = [],
  itemSelectOptionUrl = '/incubatorConfig/getCommonItemPrizeOptions',
  heeCoinSelectOptionUrl = '/heeCoin/tier-heecoin-list',
  cardpackVoucherSelectOptionUrl = '/cardpack/getCardpackVoucherPrizeOptions',
  cardpackSelectOptionUrl = '/cardpack/getCardpackOptions',
}) {
  // heeCoin----------------------
  const [heeCoinPool, setHeeCoinPool] = useState(option.heeCoinPool || []);
  const heeCoinColumns = [
    {
      title: 'HeeCoin',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Amount per Distribution',
      dataIndex: 'amount',
      key: 'amount',
    },
  
  ];

  // item----------------------
  const [itemPrizePool, setItemPrizePool] = useState(option.itemPool || []);
  const itemColumns = [
    {
      title: '',
      dataIndex: 'largeIconUrl',
      key: 'key',
      width: '100px',
      render: (text) => (
        <Image
          width={40}
          src={text}
          style={{
            objectFit: 'contain',
          }} />
      ),
    },
    {
      title: 'Item',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'name (CN)',
      dataIndex: 'nameCN',
      key: 'nameCN',
    },

    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
  ];

  // tierVoucher----------------------------
  const [voucherItemPrizePoolRate, setVoucherItemPrizePoolRate] = useState(option.tierVouchersProbability || 0);

  // cardpackVoucher----------------------------
  const [cardpackVoucherPrizePool, setCardpackVoucherPrizePool] = useState(option.voucherPool || []);
  const cardpackVoucherColumns = [
    {
      title: '',
      dataIndex: 'voucherUrl',
      key: 'key',
      render: (data) => <Image width={50} src={data} />,
    },
    {
      title: 'Merchant',
      dataIndex: 'merchantId',
      key: 'merchantId',
      render: (data) => data.name,
    },
    {
      title: 'Voucher',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        let res;
      
        switch (status) {
          case 'Active':
            res = <Tag color="blue">Active</Tag>;
            break;
          case 'Inactive':
            res = <Tag>Inactive</Tag>;
            break;
          case 'expired':
            res = <Tag color="red">Expired</Tag>;
            break;
          default:
            res = '';
            break;
        }
      
        return res;
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
    },
    {
      title: 'Redeemed',
      dataIndex: 'redeemed',
      key: 'redeemed',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      key: 'balance',
    },
  ];

  // cardpack ----------------------------
  const [cardpackPrizePool, setCardpackPrizePool] = useState(option.cardpackPool || []);
  const cardpackColumns = [
    {
      title: 'icon',
      dataIndex: 'iconUrl',
      key: 'iconUrl',
      width: '100px',
      render: (text) => (
        <Image
          width={40}
          src={text}
          style={{
            objectFit: 'contain',
          }} />
      ),
    },
    {
      title: 'name',
      dataIndex: 'seriesName',
      key: 'seriesName',
    },
    {
      title: 'name (CN)',
      dataIndex: 'seriesNameCN',
      key: 'seriesNameCN',
    },
    {
      title: 'endDate',
      key: 'endDate',

      render: (record) => {
        return moment(record.endDate).format('YYYY-MM-DD');
      },
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (status) => {
        let res;
  
        switch (status) {
          case 'published':
            res = <Tag color="green">Published</Tag>;
            break;
          case 'unpublished':
            res = <Tag color="purple">Unpublished</Tag>;
            break;
          default:
            res = '';
            break;
        }
  
        return res;
      },
    },
   
  ];

  /**
   * 概率总和
   */
  const totalWeightage = useMemo(() => {
    let res = 0;
    heeCoinPool.forEach((item) => {
      res += item.probability || 1;
    });
      
    itemPrizePool.forEach((item) => {
      res += item.probability || 1;
    });

    cardpackVoucherPrizePool.forEach((item) => {
      res += item.probability || 1;
    });

    cardpackPrizePool.forEach((item) => {
      res += item.probability || 1;
    });

    res += voucherItemPrizePoolRate;

    return res;
  }, [heeCoinPool, itemPrizePool, voucherItemPrizePoolRate, cardpackVoucherPrizePool, cardpackPrizePool]);
    
  useEffect(() => {
    onOptionChange({
      ...option,
      totalProbability: totalWeightage,
      tierVouchersProbability: voucherItemPrizePoolRate,
      itemPool: itemPrizePool,
      cardpackPool: cardpackPrizePool,
      heeCoinPool,
      voucherPool: cardpackVoucherPrizePool,

    });
  }, [heeCoinPool, itemPrizePool, voucherItemPrizePoolRate, cardpackVoucherPrizePool, cardpackPrizePool]);
 
  const tabItems = option.poolTypes.map((key) => {
    const comMap = {
      TierVouchers: <TierVoucherPool value={voucherItemPrizePoolRate} onChange={setVoucherItemPrizePoolRate} voucherOptions={tierVoucherOptions} />,        
      Items: <PoolItem type="Items" mode={option.mode} columns={itemColumns} value={itemPrizePool} onChange={setItemPrizePool} selectOptionUrl={itemSelectOptionUrl} />,
      HeeCoin: <PoolItem type="HeeCoin" mode={option.mode} columns={heeCoinColumns} value={heeCoinPool} onChange={setHeeCoinPool} selectOptionUrl={heeCoinSelectOptionUrl} />,
      CardpackVoucher: <PoolItem type="CardpackVoucher" mode={option.mode} columns={cardpackVoucherColumns} value={cardpackVoucherPrizePool} onChange={setCardpackVoucherPrizePool} selectOptionUrl={cardpackVoucherSelectOptionUrl} />,
      Cardpack: <PoolItem type="Cardpack" mode={option.mode} columns={cardpackColumns} value={cardpackPrizePool} onChange={setCardpackPrizePool} selectOptionUrl={cardpackSelectOptionUrl} />,

    };

    const labelRender = () => {
      if (key === 'Items') {
        return <Badge count={itemPrizePool.length} size="small" offset={[10, 0]}> {key}</Badge>;
      }

      if (key === 'HeeCoin') {
        return <Badge count={heeCoinPool.length} size="small" offset={[10, 0]}> {key}</Badge>;
      }

      if (key === 'TierVouchers') {
        return 'Voucher';
      }

      if (key === 'CardpackVoucher') {
        return <Badge count={cardpackVoucherPrizePool.length} size="small" offset={[10, 0]}> Voucher</Badge>;
      }

      if (key === 'Cardpack') {
        return <Badge count={cardpackPrizePool.length} size="small" offset={[10, 0]}> Cardpack</Badge>;
      }

      return key;
    };
    return {
      key,
      label: labelRender(),
      children: comMap[key],
    };
  });
 
  return (
    <div className="content-area">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h3 style={{ marginBottom: '10px', color: 'rgba(0, 0, 0, 0.85)', textAlign: 'left' }}>{ headerTitle }</h3>
        {option.mode === 'probability' ? (
          <span>
            Probability: <span style={totalWeightage !== 100 ? { color: 'red' } : { color: 'green' }}>{totalWeightage}%</span>
          </span>
        ) : <></>}
       
      </div>

      <Tabs defaultActiveKey={option.poolTypes[0]} items={tabItems} />
    </div>
  );
}
