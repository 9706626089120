import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Select,
  Image as ImageCom,
  Upload,
  Typography,
  message,
  Tooltip,
  Row,
  Col,
  Table,
  Tabs,
} from 'antd';
import { InfoCircleOutlined, PictureFilled } from '@ant-design/icons';
import axios from 'axios';

const { Dragger } = Upload;
const { Paragraph, Text } = Typography;

export function UploadOneIMG({ value, onChange }) {
  const beforeUpload = (file, type) => {
    const sizeInMB = file.size / 1024 / 1024;
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return Promise.reject(new Error('Invalid file type.'));
    }
    
    if (sizeInMB > 2) {
      message.error(`${file.name} exceeds 2MB`);
      return Promise.reject(new Error('File size exceeds 2MB.'));
    }
    
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          resolve(file);
        };
       
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };
  const largeIconProps = {
    beforeUpload: (file) => beforeUpload(file, 'large'),
    maxCount: 1,
    action: 'items/upload-item-image',
    onChange(info) {
      const { status, name } = info.file;
      if (!status) {
        console.log('wrong type of file or dimension');
      }
    },
    customRequest: (options) => {
      const newName = `largeIcon_${options.file.name}`;
      const imageData = new FormData();
      imageData.append('itemImage', options.file, newName);
      axios
        .post('items/upload-item-image', imageData)
        .then((res) => {
          onChange(res.data.Location);
          message.success(`${options.file.name} file uploaded successfully.`);
        })
        .catch((err) => {
          message.error(`${options.file.name} file upload failed. ${err}`);
        });
    },
  };
    
  return (
    <div style={{ display: 'flex' }}>
      {value && (
        <div style={{ marginRight: '8px' }}>
          <ImageCom
            width={102}
            src={value}
            style={{ marginRight: '8px' }}
         />
        </div>
      
      )}
      <Upload
        showUploadList={false}
        listType="picture-card"
        maxCount="1"
        beforeUpload={largeIconProps.beforeUpload}
        customRequest={largeIconProps.customRequest}
      >
        上传
      </Upload>
     
    </div>
  );
}
