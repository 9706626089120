import React from 'react';
import { Typography, Layout, Row, Col, Button, Space, Breadcrumb, Form, message } from 'antd';
import { DeleteOutlined, CheckOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

import UserDetailForm from '../../components/userManagement/UserDetailForm';

const { Title, Link } = Typography;
const { Content } = Layout;

export default function CreateUser() {
  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/user-management">
            <LeftOutlined />
            User Management
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create User</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const [form] = Form.useForm();
  const token = getToken();

  const handleSubmit = async () => {
    try {
      const formData = form.getFieldsValue();

      const dataPackage = {
        name: formData.userFullName,
        email: formData.userEmail,
        phone: formData.userPhone,
        role: formData.userRole,
        remarks: formData.remarks,
        password: formData.password,
      };

      await axios.post('users', dataPackage, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      message.success('User created successfully.');

      setTimeout(() => {
        window.location.href = '/user-management';
      }, 1000);
    } catch (error) {
      let errorMessage;
      if (error.response.data.message.includes('"email" must be a valid email')) {
        errorMessage = 'Invalid email address.';
      }
      if (
        error.response.data.message.includes('Invalid phone number')
      ) {
        errorMessage = 'Invalid phone number.';
      }
      if (error.response.data.message.includes('password')) {
        if (errorMessage) {
          errorMessage += ' Password must be at least 8 characters and contain at least 1 number and letter.';
        } else {
          errorMessage = 'Password must be at least 8 characters and contain at least 1 number and letter.';
        }
      }

      if (errorMessage) {
        message.error(`Failed to create user. ${errorMessage}`);
      } else {
        message.error(`Failed to create user. ${error}`);
      }
    }
  };

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Row>
          <Col flex={4}>
            <Title
              level={3}
              style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '20px' }}>
              Create New User
            </Title>
          </Col>
          <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              <Button type="primary" disabled>
                <DeleteOutlined />
                Delete
              </Button>
              <Button type="primary" onClick={handleSubmit}>
                <CheckOutlined />
                Submit
              </Button>
            </Space>
          </Col>
        </Row>
        <UserDetailForm form={form} />
      </Content>
    </>
  );
}
