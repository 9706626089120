import React from 'react';
import { Typography, Layout, Row, Col, Button, Space, Form, message, Breadcrumb } from 'antd';
import { CheckOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { getToken } from '../../utils/AuthService';

import MerchantForm from '../../components/merchantManagement/MerchantForm';

const { Title, Link } = Typography;
const { Content } = Layout;

export default function CreateMerchant() {
  const [form] = Form.useForm();
  const token = getToken();
  const history = useHistory();

  const handleSave = async () => {
    try {
      const formData = form.getFieldsValue();

      const dataPackage = {
        name: formData.merchantName,
        email: formData.merchantEmail,
        contact: formData.merchantContactNum,
        category: formData.merchantCategory,
        password: formData.password,
        role: formData.role,
        remarks: formData.remarks,
      };

      const response = await axios.post('merchants', dataPackage, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      
      const merchantId = response.data.id;
      message.success('Merchant created successfully.');
      history.push(`/merchants/${merchantId}/branchdetails`);
    } catch (error) {
      let errorMessage;
      if (error.response.data.message.includes('"email" must be a valid email')) {
        errorMessage = 'Invalid email address.';
      }
      if (
        error.response.data.message.includes('Invalid phone number')
      ) {
        errorMessage = 'Invalid phone number.';
      }
      if (
        error.response.data.message.includes('password')
      ) {
        errorMessage += ' Password must be at least 8 characters and contain at least 1 number and letter.';
      }

      if (errorMessage) {
        message.error(`Failed to create merchant. ${errorMessage}`);
      } else {
        message.error(`Failed to create merchant. ${error.response.data.message}`);
      }
    }
  };

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/merchants">
            <LeftOutlined />
            Merchants
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create Merchant</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Row>
          <Col flex={4}>
            <Title
              level={3}
              style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '20px' }}>
              Create Merchant
            </Title>
          </Col>
          <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              {/* <Button disabled>
                <DeleteOutlined />
                Delete
              </Button> */}
              <Button type="primary" onClick={handleSave}>
                <CheckOutlined />
                Next
              </Button>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col xs={24} lg={15}>
            <div className="content-area">
              <MerchantForm
                form={form}
                mode="Create"
                data={{ name: null, contact: null, email: null, remarks: null, category: null }}
              />
            </div>
          </Col>
        </Row>
      </Content>
    </>
  );
}
