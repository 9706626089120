import React, { useEffect } from 'react';
import { Form, Input, Typography, InputNumber } from 'antd';
// import { PictureFilled } from '@ant-design/icons';
// import axios from 'axios';
// import moment from 'moment';
// import dayjs from 'dayjs';
// import { getToken } from '../../utils/AuthService';

const { TextArea } = Input;
// const { Dragger } = Upload;
// const { Paragraph, Text } = Typography;

export default function ReloadPinForm({ form, data }) {
  // const [uploadedFileNames, setUploadedFileNames] = useState('');
  // const [uploadedIconNames, setUploadedIconNames] = useState('');
  // const [imageUrl, setImageUrl] = useState();
  // const [iconUrl, setIconUrl] = useState();

  const platform = '7-Eleven';

  // const token = getToken();

  // const beforeUpload = (file, type) => {
  //   const sizeInMB = file.size / 1024 / 1024;
  //   const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

  //   if (!isJpgOrPng) {
  //     message.error('You can only upload JPG/PNG file!');
  //     return Promise.reject(new Error('Invalid file type.'));
  //   }

  //   if (sizeInMB > 2) {
  //     message.error(`${file.name} exceeds 2MB`);
  //     return Promise.reject(new Error('File size exceeds 2MB.'));
  //   }

  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       const img = new Image();
  //       img.onload = () => {
  //         if (type === 'image') {
  //           if (img.width === 1000 && img.height === 1350) {
  //             resolve(file);
  //           } else {
  //             message.error('Image must be 1000x1350');
  //             reject(new Error('Invalid image dimensions.'));
  //           }
  //         } else if (type === 'icon') {
  //           if (img.width === 256 && img.height === 256) {
  //             resolve(file);
  //           } else {
  //             message.error('Image must be 256x256');
  //             reject(new Error('Invalid image dimensions.'));
  //           }
  //         }
  //       };
  //       img.src = event.target.result;
  //     };
  //     reader.readAsDataURL(file);
  //   });
  // };

  // const props = {
  //   name: 'adItemImage',
  //   beforeUpload: (file) => beforeUpload(file, 'image'),
  //   action: 'ads/upload-ads-image',
  //   maxCount: 1,
  //   onChange(info) {
  //     const { status, name } = info.file;

  //     if (!status) {
  //       console.log('wrong type of file or dimension');
  //     } else {
  //       setUploadedFileNames(name);
  //     }
  //   },
  //   customRequest: (options) => {
  //     const imageData = new FormData();
  //     imageData.append('adsImage', options.file);
  //     const config = {
  //       headers: {
  //         'Content-type': 'multipart/form-data',
  //         Authorization: `Bearer ${token.access.token}`,
  //       },
  //     };
  //     axios
  //       .post(options.action, imageData, config)
  //       .then((res) => {
  //         setImageUrl(res.data.Location);
  //         form.setFieldsValue({ adItemImage: res.data.Location });
  //         setTimeout(() => {
  //           message.success(`${options.file.name} file uploaded successfully.`, 2);
  //         }, 2);
  //       })
  //       .catch((err) => {
  //         message.error(`${options.file.name} file upload failed. ${err}`);
  //       });
  //   },
  // };

  // const iconProps = {
  //   name: 'adIcon',
  //   beforeUpload: (file) => beforeUpload(file, 'icon'),
  //   action: 'ads/upload-ads-image',
  //   maxCount: 1,
  //   onChange(info) {
  //     const { status, name } = info.file;

  //     if (!status) {
  //       console.log('wrong type of file or dimension');
  //     } else {
  //       setUploadedIconNames(name);
  //     }
  //   },
  //   customRequest: (options) => {
  //     const imageData = new FormData();
  //     imageData.append('adsImage', options.file);
  //     const config = {
  //       headers: {
  //         'Content-type': 'multipart/form-data',
  //         Authorization: `Bearer ${token.access.token}`,
  //       },
  //     };
  //     axios
  //       .post(options.action, imageData, config)
  //       .then((res) => {
  //         setIconUrl(res.data.Location);
  //         form.setFieldsValue({ adIcon: res.data.Location });
  //         setTimeout(() => {
  //           message.success(`${options.file.name} file uploaded successfully.`, 2);
  //         }, 2);
  //       })
  //       .catch((err) => {
  //         message.error(`${options.file.name} file upload failed. ${err}`);
  //       });
  //   },
  // };

  useEffect(() => {
    if (data) {
      // setImageUrl(data.adsUrl);
      // setIconUrl(data.adsIconUrl);
      form.setFieldsValue({
        name: `${data.platform.name} RM${data.amount}`,
        value: data.amount,
        // code: data.code,
        descriptions: data.description,
      });
    }
  }, [data]);

  // get list of merchants for dropdown
  // const [merchantList, setMerchantList] = useState([]);

  // useEffect(() => {
  //   const getData = async () => {
  //     await axios
  //       .get('merchants', {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${token.access.token}`,
  //         },
  //       })
  //       .then((res) => {
  //         setMerchantList(res.data.results);
  //       })
  //       .catch((error) => {
  //         message.error(`Failed to retrieve merchant data. ${error}`);
  //       });
  //   };
  //   getData();
  // }, []);

  // const disabledDate = (current) => {
  //   return current && current < dayjs().endOf('day');
  // };

  // function getMerchantList(merchantData) {
  //   const list = [];
  //   for (let i = 0; i < merchantData.length; i++) {
  //     list.push({ value: merchantData[i]._id, label: merchantData[i].name });
  //   }

  //   return list;
  // }

  const onChange = () => {
    let amount = form.getFieldValue('value');
    if (amount < 1 || !amount) {
      amount = 1;
      form.setFieldsValue({ value: amount });
    }
    form.setFieldsValue({ name: `${platform} RM${amount}` });
  };

  return (
    <div className="content-area">
      <Form form={form} layout="vertical">
        {/* <Form.Item name="adItemImage">
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <Dragger {...props} accept=".jpg, .jpeg, .png" showUploadList={false}>
                <p className="ant-upload-drag-icon">
                  <PictureFilled />
                </p>
                <p className="ant-upload-text">Upload Image</p>
                <Paragraph className="ant-upload-hint">
                  <Text type="secondary">Upload ads image. Max 1 image.</Text>
                  <br />
                  <Text type="secondary">File Format</Text>
                  <Text> jpeg, png</Text>
                  <br />
                  <Text type="secondary">Required Size</Text>
                  <Text> 1000&times;1350</Text>
                  <br />
                  <Text type="secondary">Maximum file size: 2MB</Text>
                </Paragraph>
              </Dragger>
            </div>
            {imageUrl && (
              <div style={{ flex: 1, marginLeft: 10 }}> 
                <img src={imageUrl} alt="Uploaded ad image" style={{ width: 300, height: 'auto' }}/>
              </div>
            )}
          </div>
          <div>{uploadedFileNames ? `Uploaded File: ${uploadedFileNames}` : ''}</div>
        </Form.Item>
        <Form.Item name="adIcon">
          <div style={{ display: 'flex' }}>
            <div style={{ flex: 1 }}>
              <Dragger {...iconProps} accept=".jpg, .jpeg, .png" showUploadList={false}>
                <p className="ant-upload-drag-icon">
                  <PictureFilled />
                </p>
                <p className="ant-upload-text">Upload Image</p>
                <Paragraph className="ant-upload-hint">
                  <Text type="secondary">Upload ads icon. Max 1 image.</Text>
                  <br />
                  <Text type="secondary">File Format</Text>
                  <Text> jpeg, png</Text>
                  <br />
                  <Text type="secondary">Required Size</Text>
                  <Text> 256&times;256</Text>
                  <br />
                  <Text type="secondary">Maximum file size: 2MB</Text>
                </Paragraph>
              </Dragger>
            </div>
            {iconUrl && (
              <div style={{ flex: 1, marginLeft: 10 }}> 
                <img src={iconUrl} alt="Uploaded ad icon" style={{ width: 150, height: 'auto' }}/> 
              </div>
            )}
          </div>
          <div>{uploadedIconNames ? `Uploaded File: ${uploadedIconNames}` : ''}</div>
        </Form.Item> */}
        <Typography.Title level={3} style={{ textAlign: 'start' }}>
          Reload Pin Details
        </Typography.Title>
        <Form.Item name="name" label="Name">
          <Input placeholder="Enter a title" disabled />
        </Form.Item>
        <Form.Item
          name="code"
          label="Code"
          initialValue="somecode"
          // rules={[
          //   {
          //     required: true,
          //     type: 'string',
          //     message: 'Please enter code!',
          //   },
          // ]}
        >
          <Input.Password placeholder="Enter Code" disabled visibilityToggle={false} />
        </Form.Item>
        <Form.Item
          name="value"
          label="Redeem Amount"
          rules={[
            {
              required: true,
              type: 'number',
              message: 'Please enter redeem amount!',
            },
          ]}>
          <InputNumber
            addonBefore="RM"
            min={0}
            onChange={onChange}
            placeholder="Enter Redeem Amount"
          />
        </Form.Item>
        <Form.Item name="descriptions" label="Descriptions (Optional)">
          <TextArea placeholder="Enter descriptions" />
        </Form.Item>
      </Form>
    </div>
  );
}
