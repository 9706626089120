import React, { useState, useEffect } from 'react';
import {
  Typography,
  Layout,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Breadcrumb,
  Form,
  Skeleton,
  message,
} from 'antd';
import { DeleteOutlined, CheckOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

import UserDetailForm from '../../components/userManagement/UserDetailForm';

const { Title, Link } = Typography;
const { Content } = Layout;

export default function EditUser() {
  const [form] = Form.useForm();
  const token = getToken();
  const userID = window.location.href.split('/')[4];
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      await axios
        .get(`users/${userID}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setUserData(res.data);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve user data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, []);

  const handleSubmit = async () => {
    try {
      const formData = form.getFieldsValue();

      const dataPackage = {
        name: formData.userFullName,
        email: formData.userEmail,
        phone: formData.userPhone,
        role: formData.userRole,
        remarks: formData.remarks,
      };

      if (formData.password !== null) {
        dataPackage.password = formData.password;
      }

      await axios.patch(`users/${userID}`, dataPackage, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      message.success('User data saved successfully.');

      setTimeout(() => {
        window.location.href = '/user-management';
      }, 1000);
    } catch (error) {
      let errorMessage;
      if (error.response.data.message.includes('"email" must be a valid email')) {
        errorMessage = 'Invalid email address.';
      }
      if (
        error.response.data.message.includes('Invalid phone number')
      ) {
        errorMessage = 'Invalid phone number.';
      }
      if (error.response.data.message.includes('password')) {
        if (errorMessage) {
          errorMessage += ' Password must be at least 8 characters and contain at least 1 number and letter.';
        } else {
          errorMessage = 'Password must be at least 8 characters and contain at least 1 number and letter.';
        }
      }

      if (errorMessage) {
        message.error(`Failed to save user data. ${errorMessage}`);
      } else {
        message.error(`Failed to save user data. ${error}`);
      }
    }
  };

  const [modalActive, setModalActive] = useState(false);
  const showModal = () => {
    setModalActive(true);
  };

  const handleDelete = async () => {
    try {
      await axios.put(`users/${userID}/archive`, null, {
        headers: {
          accept: '*/*',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      message.success('User deleted successfully.');

      setTimeout(() => {
        window.location.href = '/user-management';
      }, 1000);
    } catch (error) {
      message.error(`Failed to delete user. ${error}`);
    }
  };

  const handleOk = () => {
    setModalActive(false);
    handleDelete();
  };
  const handleCancel = () => {
    setModalActive(false);
    handleDelete();
  };

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/user-management">
            <LeftOutlined />
            User Management
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit User</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Row>
          <Col flex={4}>
            <Title
              level={3}
              style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '20px' }}>
              Edit User
            </Title>
          </Col>
          <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              <Button danger onClick={showModal}>
                <DeleteOutlined />
                Delete
              </Button>
              <Modal
                title="Are you sure you want to delete?"
                open={modalActive}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                closable={false}
                footer={[
                  <>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button type="primary" danger onClick={handleOk}>
                      <DeleteOutlined />
                      Yes, Delete
                    </Button>
                  </>,
                ]}>
                <p>If you confirm this action, you are unable to undo.</p>
              </Modal>
              <Button type="primary" onClick={handleSubmit}>
                <CheckOutlined />
                Submit
              </Button>
            </Space>
          </Col>
        </Row>
        {loading === false ? <UserDetailForm form={form} data={userData} /> : <Skeleton />}
      </Content>
    </>
  );
}
