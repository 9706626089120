import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Col,
  Layout,
  Row,
  Space,
  Button,
  Typography,
  Breadcrumb,
  Tag,
  message,
  Tooltip,
  Skeleton,
} from 'antd';
import { PlusOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import axios from 'axios';

const { Content } = Layout;
const { Link } = Typography;

export function CardpackConfigListPage() {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const history = useHistory();
  const toCreate = () => {
    history.push('/createCardpackPage');
  };
  const toEdit = (editData) => {
    history.push(`/editCardpackPage/${editData.id}`);
  };
    
  const getTableData = async (pageNum, pageSize) => {
    try {
      setLoading(true);
      const res = await axios
        .get('cardpack/getCardpacks', {
          params: {
            limit: pageSize,
            page: pageNum,
          },
        });
    
      setTableData(res.data.results);
      setTotalData(res.data.totalResults);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    setPage(1);

    getTableData(1, limit);
  };

  const onTablePageChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
    getTableData(current, pageSize);
  };
    
  useEffect(() => { handleSearch(); }, []);

  const columns = [
    {
      title: 'Series Name',
      dataIndex: 'seriesName',
      key: 'seriesName',
      width: 100,
    },
  
    {
      title: 'End Date',
      key: 'endDate',
      width: 100,

      render: (record) => {
        return moment(record.endDate).format('YYYY-MM-DD');
      },
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: (status) => {
        let res;
  
        switch (status) {
          case 'published':
            res = <Tag color="green">Published</Tag>;
            break;
          case 'unpublished':
            res = <Tag color="purple">Unpublished</Tag>;
            break;
          default:
            res = '';
            break;
        }
  
        return res;
      },
    },
    
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 90,
      render: (record) => (
        <Button type="text" onClick={() => toEdit(record)}>
          <EditOutlined />
        </Button>
      ),
    },
  ];

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/cardpackConfigListPage">Cardpack Config</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Row justify="end">
            <Col>
              <Space>
                <Button type="primary" onClick={() => toCreate()}>
                  <PlusOutlined />
                  Add New Cardpack
                </Button>
              </Space>
            </Col>
          </Row>
          <div className="table-content">
            {loading === false ? (
              <Table
                columns={columns}
                dataSource={tableData}
                rowKey="id"
                pagination={{
                  pageSize: limit,
                  pageSizeOptions: [10, 20],
                  showSizeChanger: true,
                  total: totalData,
                  onChange: onTablePageChange,
                  current: page,
                }}
                scroll={{ x: '100%' }}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </Space>
      </Content>
    </>
  );
}
