import React, { useEffect, useState, useRef, useCallback } from 'react';
import {
  Form,
  Tooltip,
  Input,
  message,
  Row,
  Col,
  Modal,
  InputNumber,
  Breadcrumb,
  Skeleton,
  Button,
  Space,
  DatePicker,
  Typography,
  Layout,
} from 'antd';
import { InfoCircleOutlined, DeleteOutlined, CheckOutlined, LeftOutlined, CloseOutlined } from '@ant-design/icons';
import moment from 'moment';
import { CardpackItem } from './cardpackItemV2';
import { UploadOneIMG } from '../../../../components/componentV2/uploadOneIMG';

import { PrizePool } from '../../../../components/componentV2/PrizePoolV2/PrizePool';

const { Title, Link } = Typography;
const { Content } = Layout;

export function CardpackDetailForm({ isCreate, onSubmit, editData, loading, onDelete, onStatusUpdate }) {
  const [form] = Form.useForm();

  const [prizePoolConfig, setPrizePoolConfig] = useState({
    mode: 'obtainAmount',
    poolTypes: ['Items', 'HeeCoin', 'CardpackVoucher'],
    totalProbability: 100,
    tierVouchersProbability: 0,
    itemPool: [],
    cardpackPool: [],
    heeCoinPool: [],
    voucherPool: [],
  });

  function useDebounce(fn, delay) {
    const debouncedFn = useRef();
   
    useEffect(() => {
      debouncedFn.current = fn;
    }, [fn]);
   
    const debounced = useCallback((...args) => {
      const later = () => debouncedFn.current(...args);
      clearTimeout(debouncedFn.current.timeoutID);
      debouncedFn.current.timeoutID = setTimeout(later, delay);
    }, [delay]);
   
    return debounced;
  }

  const handleSubmit = async () => {
    await form.validateFields();
    const formData = form.getFieldsValue();
  
    const dataPackage = {

      ...formData,
      endDate: formData.endDate.format('YYYY-MM-DD 23:59:59'),

      prizePoolConfig,
    };

    onSubmit(dataPackage);
  };

  const debouncedSubmit = useDebounce(() => { handleSubmit(); }, 500);

  const handleStatusUpdate = async () => {
    await form.validateFields();
    const formData = form.getFieldsValue();
  
    const dataPackage = {

      ...formData,
      endDate: formData.endDate.format(),

      prizePoolConfig,
    };

    onStatusUpdate(dataPackage);
  };

  const initData = () => {
    if (!isCreate && editData) {
      form.setFieldsValue(editData);
      form.setFieldValue('endDate', moment(editData.endDate));

      if (editData.prizePoolConfig) {
        setPrizePoolConfig(editData.prizePoolConfig);
      }
    }
  };
  useEffect(() => { initData(); }, [editData]);

  const [modalActive, setModalActive] = useState(false);
  const showModal = () => {
    setModalActive(true);
  };

  const handleOk = () => {
    setModalActive(false);
    onDelete(editData);
  };
  const handleCancel = () => {
    setModalActive(false);
  };

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/cardpackConfigListPage">
            <LeftOutlined />
            Cardpack
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{isCreate ? 'Create Cardpack' : 'Edit Cardpack'}</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  return (

    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Row>
          <Col flex={4}>
            <Title
              level={3}
              style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '20px' }}>
              {isCreate ? 'Create New Cardpack' : 'Edit Cardpack'}
            </Title>
          </Col>
          <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>

              <Button danger onClick={showModal} disabled={isCreate}>
                <DeleteOutlined />
                Delete
              </Button>
              <Modal
                title="Are you sure you want to delete?"
                open={modalActive}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                closable={false}
                footer={(
                  <>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button type="primary" danger onClick={handleOk}>
                      <DeleteOutlined />
                      Yes, Delete
                    </Button>
                  </>
                )}>
                <p>If you confirm this action, you are unable to undo.</p>
              </Modal>

              {
                !isCreate && editData ? editData.status === 'published' ? (
                  <Tooltip title="Save changes and unpublish Card Pack.">
                    <Button onClick={handleStatusUpdate}>
                      <CloseOutlined />
                      Unpublish
                    </Button>
                  </Tooltip>
                ) : (
                  <Tooltip title="Save changes and publish Card Pack." placement="topRight">
                    <Button onClick={handleStatusUpdate}>
                      <CheckOutlined />
                      Publish
                    </Button>
                  </Tooltip>
                ) : <></>
              }
        
              <Button type="primary" onClick={debouncedSubmit}>
                <CheckOutlined />
                Submit
              </Button>
            </Space>
          </Col>
        </Row>
        {loading === false ? (
          <Row>
            <Col xs={24} lg={20}>
              <Form form={form} layout="vertical">
                <div className="content-area">

                  <Form.Item
                    name="iconUrl"
                    label="Icon"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter!',
                      },
                    ]}>
                    <UploadOneIMG />
                  </Form.Item>

                  <Form.Item
                    name="seriesName"
                    label="Series Name"
                    style={{ width: '200px' }}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter!',
                      },
                    ]}>
                    <Input placeholder="Series Name" />
                  </Form.Item>

                  <Form.Item
                    name="seriesNameCN"
                    label="Series Name (CN)"
                    style={{ width: '200px' }}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter!',
                      },
                    ]}>
                    <Input placeholder="series Name CN" />
                  </Form.Item>

                  <Form.Item
                    style={{ width: '200px' }}
                    name="endDate"
                    label="End Date"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter!',
                      },
                    ]}>
                    <DatePicker style={{ width: '100%' }} />
                  </Form.Item>

                </div>

                <PrizePool
                  headerTitle="Prize Pool"
                  itemSelectOptionUrl="/incubatorConfig/getCommonItemPrizeOptions"
                  option={prizePoolConfig}
                  onOptionChange={setPrizePoolConfig}
              
                /> 
                
                {editData ? (
                  <div className="content-area" style={{ overflow: 'hidden' }}>

                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <h3 style={{ marginBottom: '10px', color: 'rgba(0, 0, 0, 0.85)', textAlign: 'left' }}>Card List</h3>
                    </div>
                    <CardpackItem cardpackId={editData.id} />
                  </div>
                ) : null} 
                
              </Form>
            </Col>
          </Row>
        ) : (
          <Skeleton />
        )}
      </Content>
    </>
  );
}
