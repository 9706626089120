import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  DatePicker,
  Row,
  Col,
  InputNumber,
  // Switch,
  // Divider,
  Upload,
  Typography,
  Tooltip,
  message,
} from 'antd';
import { PictureFilled, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import dayjs from 'dayjs';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

const { TextArea } = Input;
const { Dragger } = Upload;
const { Paragraph, Text } = Typography;
const { Option } = Select;

export default function VoucherForm({ form, mode, data, userRole }) {
  const [uploadedFileNames, setUploadedFileNames] = useState('');
  const [imageUrl, setImageUrl] = useState();

  const token = getToken();
  // const [enableMonthlyDistribution, setEnableMonthlyDistribution] = useState(false);

  // const calculateMonths = (date = form.getFieldValue('voucherEffectiveDate')) => {
  //   const startDate = date[0];
  //   const endDate = date[1];
  //   const numberOfMonths = endDate.month() - startDate.month() + 1;
  //   return numberOfMonths;
  // };

  // const toggleMonthlyDistribution = (checked) => {
  //   if (checked) {
  //     form.setFieldsValue({
  //       monthlyDistributionQuantity: parseInt(
  //         form.getFieldValue('voucherQuantity') / calculateMonths(),
  //         10,
  //       ),
  //     });
  //   }
  //   setEnableMonthlyDistribution(checked);
  // };

  // const handleDateQuantityChange = () => {
  //   if (enableMonthlyDistribution) {
  //     form.setFieldsValue({
  //       monthlyDistributionQuantity: parseInt(
  //         form.getFieldValue('voucherQuantity') / calculateMonths(),
  //         10,
  //       ),
  //     });
  //   }
  // };

  const beforeUpload = (file) => {
    const sizeInMB = file.size / 1024 / 1024;
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return Promise.reject(new Error('Invalid file type.'));
    }

    if (sizeInMB > 2) {
      message.error(`${file.name} exceeds 2MB`);
      return Promise.reject(new Error('File size exceeds 2MB.'));
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          if (img.width === 140 && img.height === 140) {
            resolve(file);
          } else {
            message.error('Image must be 140x140');
            reject(new Error('Invalid image dimensions.'));
          }
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const uploadProps = {
    name: 'voucherImage',
    beforeUpload,
    action: 'vouchers/upload-voucher-image',
    maxCount: 1,
    onChange(info) {
      const { status, name } = info.file;

      if (!status) {
        console.log('wrong type of file or dimension');
      } else {
        setUploadedFileNames(name);
      }
    },
    customRequest: (options) => {
      const imageData = new FormData();
      imageData.append('voucherImage', options.file);
      const config = {
        headers: {
          'Content-type': 'multipart/form-data',
          Authorization: `Bearer ${token.access.token}`,
        },
      };
      axios
        .post(options.action, imageData, config)
        .then((res) => {
          setImageUrl(res.data.Location);
          form.setFieldsValue({ voucherImage: res.data.Location });
          setTimeout(() => {
            message.success(`${options.file.name} file uploaded successfully.`, 2);
          }, 2);
        })
        .catch((err) => {
          message.error(`${options.file.name} file upload failed. ${err}`);
        });
    },
  };

  useEffect(() => {
    if (mode === 'Edit') {
      setImageUrl(data.voucherUrl);
      form.setFieldsValue({
        voucherName: data.name,
        voucherValue: data.value,
        voucherMerchant: data.merchantId,
        voucherImage: data.voucherUrl,
        voucherEffectiveDate: [moment(data.startDate), moment(data.endDate)],
        remarks: data.description,
        voucherQuantity: data.quantity,
        tncContent: data.tncContent,
        monthlyDistribution: data.isMonthlyDistribution,
        monthlyDistributionQuantity: data.monthlyDistributionQuantity
          ? data.monthlyDistributionQuantity
          : null,
        voucherTier: data.tier,
        tncUrl: data.tncUrl || '',
        voucherLabel: data.label,
      });
    }
  }, [form, mode, data]);

  // get list of merchants for dropdown
  const [merchantList, setMerchantList] = useState([]);
  const [tierList, setTierList] = useState([]);

  useEffect(() => {
    const getData = async () => {
      await axios
        .get('merchants', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setMerchantList(res.data.results);
        })
        .catch((error) => {
          message.error(`Failed to retrieve merchant data. ${error}`);
        });
    };

    const getTierData = async () => {
      await axios
        .get('tiers', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            limit: 50,
            page: 1,
          },
        })
        .then((res) => {
          setTierList(res.data.results);
        })
        .catch((error) => {
          message.error(`Failed to retrieve transactions data. ${error}`);
        });
    };

    getData();
    getTierData();
  }, []);

  function getMerchantList(merchantData) {
    const list = [];
    for (let i = 0; i < merchantData.length; i++) {
      list.push({ value: merchantData[i]._id, label: merchantData[i].name });
    }

    return list;
  }

  function getTierList(tierData) {
    const list = [];
    for (let i = 0; i < tierData.length; i++) {
      list.push({ value: tierData[i]._id, label: tierData[i].name });
    }

    return list;
  }

  const disabledDate = (current) => {
    return current && current < dayjs().endOf('day');
  };

  return (
    <Form form={form} layout="vertical">
      <Row gutter={16}>
        <Col xs={24} lg={16}>
          <div className="content-area">
            <Form.Item
              name="voucherName"
              label="Voucher Name"
              rules={[
                {
                  required: true,
                  message: 'Voucher name cannot be empty!',
                },
              ]}>
              <Input placeholder="Enter Voucher Name" disabled={mode === 'Edit'} />
            </Form.Item>
            <Form.Item name="voucherImage" label="Voucher Image" rules={[{ required: true }]}>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <Dragger {...uploadProps} accept=".jpg, .jpeg, .png" showUploadList={false}>
                    <p className="ant-upload-drag-icon">
                      <PictureFilled />
                    </p>
                    <p className="ant-upload-text">Upload Image</p>
                    <Paragraph className="ant-upload-hint">
                      <Text type="secondary">Upload item image. Max 1 image.</Text>
                      <br />
                      <Text type="secondary">File Format</Text>
                      <Text> jpeg, png</Text>
                      <br />
                      <Text type="secondary">Required Size</Text>
                      <Text> 140&times;140 (1:1)</Text>
                      <br />
                      <Text type="secondary">Maximum file size: 2MB</Text>
                    </Paragraph>
                  </Dragger>
                </div>
                {imageUrl && (
                  <div style={{ flex: 1, marginLeft: 10 }}>
                    <img src={imageUrl} alt="Uploaded voucher image" /> {/*eslint-disable-line*/}
                  </div>
                )}
              </div>
              <div>{uploadedFileNames ? `Uploaded File: ${uploadedFileNames}` : ''}</div>
            </Form.Item>
            {userRole === 'admin' && (
              <Form.Item
                name="voucherMerchant"
                label="Merchant"
                rules={[
                  {
                    required: true,
                    message: 'Please select merchant!',
                  },
                ]}>
                <Select
                  placeholder="Select Merchant"
                  options={getMerchantList(merchantList)}
                  disabled={mode === 'Edit'}
                />
              </Form.Item>
            )}
            <Form.Item
              name="tncContent"
              label="T&C Content"
              // rules={[
              //   { required: true },
              //   {
              //     type: 'url',
              //     message: 'Please enter a valid URL!',
              //   },
              // ]}
              >
              <TextArea
                showCount
                style={{ height: 120 }}
                placeholder="Enter Voucher's T&C Content" 
              />
            </Form.Item>
            <Form.Item
              name="tncUrl"
              label="T&C Url"
              // rules={[
              //   { required: true },
              //   {
              //     type: 'url',
              //     message: 'Please enter a valid URL!',
              //   },
              // ]}
              >
              <Input placeholder="Enter Voucher's T&C Url" disabled={mode === 'Edit'} />
            </Form.Item>
            <Form.Item
              name="voucherValue"
              label="Voucher Value"
              rules={[
                {
                  required: true,
                  message: 'Voucher value cannot be empty!',
                },
              ]}>
              <Input placeholder="0.00" prefix="RM " disabled={mode === 'Edit'} />
            </Form.Item>
            <Row gutter={10}>
              <Col>
                <Form.Item
                  name="voucherEffectiveDate"
                  label={(
                    <Row
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'row',
                      }}>
                      <Col>Effective Date</Col>
                      <Col style={{ paddingLeft: '10px' }}>
                        <Tooltip title="Voucher distribution will end three (3) days before the end of selected effective date.">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Col>
                    </Row>
                  )}
                  rules={[
                    {
                      required: true,
                      type: 'array',
                      message: 'Please select time!',
                    },
                  ]}>
                  <DatePicker.RangePicker disabledDate={disabledDate} disabled={mode === 'Edit'} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item
                  name="voucherQuantity"
                  label="Voucher Quantity"
                  rules={[
                    {
                      required: true,
                      message: 'Voucher quantity cannot be empty!',
                    },
                  ]}>
                  <InputNumber style={{ width: '100%' }} min={0} placeholder="0" />
                </Form.Item>
              </Col>
              {userRole === 'admin' && (
                <Col style={{ flex: 1 }}>
                  <Form.Item
                    name="voucherLabel"
                    label="Voucher Label"
                    rules={[
                      {
                        required: true,
                        message: 'Voucher Label quantity cannot be empty!',
                      },
                    ]}
                  
                  >
                    <Select placeholder="Select Label">
                      <Option value="Budget-friendly">Budget-friendly</Option>
                      <Option value="Standard">Standard</Option>
                      <Option value="Mid-range">Mid-range</Option>
                      <Option value="Premium">Premium</Option>
                      <Option value="Luxury">Luxury</Option>
                    </Select>
                  </Form.Item>
                </Col>
              )}
            </Row>
            {userRole === 'admin' && (
              <Form.Item
                name="voucherTier"
                label={(
                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: 'row',
                    }}>
                    <Col>Voucher Tier</Col>
                  </Row>
                )}>
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Select Tier"
                  options={getTierList(tierList)}
                />
              </Form.Item>
            )}
            {userRole === 'admin' && (
              <Form.Item name="remarks" label="Remarks">
                <TextArea placeholder="Write your description here..." />
              </Form.Item>
            )}
          </div>
        </Col>
        {/* <Col xs={24} lg={8}>
          <div className="content-area">
            <Form.Item name="voucherQuantity" label="Voucher Quantity">
              <InputNumber placeholder="0" />
            </Form.Item>
            <Divider />
            <Form.Item name="monthlyDistribution" 
            label="Monthly Distribution" initialValue={false}>
              <Switch onChange={toggleMonthlyDistribution} />
            </Form.Item>
            {enableMonthlyDistribution && (
              <div>
                <Divider />
                <Form.Item
                  name="monthlyDistributionQuantity"
                  label="Monthly Distribution Voucher Quantity">
                  <InputNumber placeholder="0" readOnly />
                </Form.Item>
              </div>
            )}
          </div>
        </Col> */}
      </Row>
    </Form>
  );
}
