import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form,
  Table,
  Col,
  Layout,
  Row,
  Space,
  Button,
  Typography,
  DatePicker,
  Modal,
  message,
  Tag,
  Popconfirm,
  Skeleton,
} from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import axios from 'axios';
import moment from 'moment';
import { GamePrize } from './GamePrize';

const { Content } = Layout;
const { Link, Title } = Typography;

function RankPrizeConfigForm({ isCreate, onSubmit, editData, onCancel }) {
  const [form] = Form.useForm();
  
  const [prizeList, setPrizeList] = useState([]);
  
  const handleSubmit = async () => {
    await form.validateFields();
      
    if (!prizeList.length) {
      message.warning('At least one piece of prize!');
      return;
    }
    const formData = form.getFieldsValue();
  
    const dataPackage = {
      week: `${moment(formData.week).format('YYYY')}#${moment(formData.week).isoWeek()}`,
      weekDate: moment(formData.week).format('YYYY-MM-DD'),
      prizeConfig: prizeList,
  
    };
  
    onSubmit(dataPackage);
  };
  
  const initData = () => {
    if (!isCreate && editData) {
      form.setFieldsValue({
        week: moment(editData.weekDate),
      });
      setPrizeList(editData.prizeConfig);
    }
  };
  useEffect(() => { initData(); }, [editData]);
  
  return (
    <>
      <Form form={form} layout="vertical">
        <div className="content-area">
          <Row>   
            <Col xs={24} lg={6}>
              <Form.Item
                name="week"
                label="week"
                labelAlign="left"
                rules={[
                  {
                    required: true,
                    message: 'Please enter!',
                  },
                ]}
                style={{ width: '100%' }}>
                <DatePicker picker="week" disabled={!isCreate} />
              </Form.Item>
            </Col>
            
          </Row>
  
        </div>
  
        <div className="content-area">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h3 style={{ marginBottom: '10px', color: 'rgba(0, 0, 0, 0.85)', textAlign: 'left' }}>Prize List</h3>
          </div>
          <GamePrize value={prizeList} onChange={setPrizeList} />
        </div>

        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <Space>
            <Button onClick={() => onCancel()}>
              cancel
            </Button>
            <Button type="primary" onClick={() => handleSubmit()}>
              submit
            </Button>
          </Space>
        </div>
       
      </Form>
    </>
  );
}

export function RankPrizeList() {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [filterForm] = Form.useForm();

  const [modalActive, setModalActive] = useState(false);
    
  const [editingData, setEditingData] = useState(false);
    
  const [actionType, setActionType] = useState('add');

  const toCreate = () => {
    setActionType('add');
    setModalActive(true);
  };
  const toEdit = (editData) => {
    setEditingData(editData);
    setActionType('edit');
    setModalActive(true);
  };
    
  const getTableData = async (pageNum, pageSize) => {
    try {
      const { week } = filterForm.getFieldValue();
      setLoading(true);
      const res = await axios
        .get('/miniGame/queryMiniGamePrizeConfigs', {
       
          params: {

            week: week ? `${moment(week).year()}#${moment(week).week()}` : null,
            sortBy: 'weekDate:desc',
            limit: pageSize,
            page: pageNum,
          },
        });
    
      setTableData(res.data.results);
      setTotalData(res.data.totalResults);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    setPage(1);

    getTableData(1, limit);
  };

  const clearFilter = () => {
    filterForm.resetFields();
  
    handleSearch();
  };
    
  const onTablePageChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
    getTableData(current, pageSize);
  };
  
  useEffect(() => {
    handleSearch();
  }, []);

  const onSubmit = async (formData) => {
    try {
      if (actionType === 'add') {
        await axios.post('/miniGame/createMiniGamePrizeConfig', formData);
      }
                
      if (actionType === 'edit') {
        await axios.post(`/miniGame/updateMiniGamePrizeConfigById/${editingData.id}`, formData);
      }
      setModalActive(false);
      getTableData(page, limit);
      message.success('Create success !');
    } catch (error) {
      message.warning('The configuration for this week has been set up');
    }
  };

  const onDelete = async (row) => {
    await axios.delete(`/miniGame/deleteMiniGamePrizeConfigById/${row.id}`);
    getTableData(page, limit);
  };

  const columns = [

    {
      title: 'week',
      key: 'week',
      render: (record) => `${moment(record.weekDate).format('YYYY')}-${moment(record.weekDate).isoWeek()}th`,
    },
    
    {
      title: 'status',
      key: 'status',
      render: (record) => {
        const statusMap = {
          Future: 'blue',
          Current: 'green',
          Past: '#999',
        };
          
        let label = 'Current';
          
        const recordYear = moment(record.weekDate).year();
        const recordWeek = moment(record.weekDate).isoWeek();
        const todayYear = moment().year();
        const todayWeek = moment().isoWeek();

        if (recordYear > todayYear) {
          label = 'Future';
        }
        if (recordYear < todayYear) {
          label = 'Past';
        }
        if (recordYear === todayYear) {
          if (recordWeek > todayWeek) {
            label = 'Future';
          }
          if (recordWeek < todayWeek) {
            label = 'Past';
          }
        }
       
        return <Tag color={statusMap[label]}>{label }</Tag>;
      },
    },
    
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 150,
      render: (record) => (

        <span>

          <Typography.Link onClick={() => toEdit(record)}>
            Edit
          </Typography.Link>

          <Typography.Link>

            <Popconfirm title="Sure to delete?" onConfirm={() => onDelete(record)}>
              <span style={{ cursor: 'pointer', color: 'red', marginLeft: '16px' }}>Delete</span>
            </Popconfirm>
          </Typography.Link>
        </span>
        
      ),
    },
  ];

  return (
    <>
      <Content className="layout-background">
        <Space direction="vertical" style={{ display: 'flex' }}>
          
          <Form
            form={filterForm}
            layout="inline"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 18 }}>
            <div style={{ display: 'flex', width: '100%' }}>
              <Form.Item name="week">
                <DatePicker picker="week" style={{ width: '150px' }} />
              </Form.Item>

              <Form.Item>
                <Space>
                  <Button danger onClick={() => clearFilter()}>
                    Reset Filters
                  </Button>
                  <Button onClick={() => handleSearch()}>Apply Filters</Button>
                </Space>
                
              </Form.Item>

              <div style={{ marginLeft: 'auto' }}>
                <Form.Item>
                  <Space>
                    <Button type="primary" onClick={() => toCreate()}>
                      <PlusOutlined />
                      Add New Prize
                    </Button>
                  </Space>
                </Form.Item>
              </div>
              
            </div>
           
          </Form>
        
          <div className="table-content">
            {loading === false ? (
              <Table
                columns={columns}
                dataSource={tableData}
                rowKey="id"
                pagination={{
                  pageSize: limit,
                  pageSizeOptions: [10, 20],
                  showSizeChanger: true,
                  total: totalData,
                  onChange: onTablePageChange,
                  current: page,
                }}
                scroll={{ x: '100%' }}
              />
            ) : (
              <Skeleton />
            )}
          </div>

          <Modal
            title="Prize config"
            destroyOnClose
            width={1000}
            open={modalActive}
            centered
            closable
            onCancel={() => setModalActive(false)}
            footer={null}
                      
            >
            <div style={{ minHeight: '500px' }}>
              <RankPrizeConfigForm isCreate={actionType === 'add'} onCancel={() => setModalActive(false)} onSubmit={onSubmit} editData={editingData} />

            </div>
          </Modal>
        </Space>
      </Content>
    </>
  );
}
