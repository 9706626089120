import React, { useState, useEffect } from 'react';
import { Table, Layout, Space, Typography, Breadcrumb, Skeleton, message, Button } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

const { Content } = Layout;
const { Link } = Typography;

const treasureHuntCols = [
  {
    title: 'Name',
    dataIndex: 'tHuntName',
    key: 'tHuntName',
    width: 200,
  },
  {
    title: 'Required Satiety',
    dataIndex: 'food',
    key: 'food',
    width: 200,
  },
  {
    title: 'Required Health',
    dataIndex: 'health',
    key: 'health',
    width: 200,
  },
  {
    title: 'Duration',
    dataIndex: 'duration',
    key: 'duration',
    render: (data) => `${data}`,
  },
  {
    title: 'Game Coin Reward',
    dataIndex: 'gameCoinReward',
    key: 'gameCoinReward',
  },
  {
    title: 'Possible Item Reward',
    dataIndex: 'itemReward',
    key: 'itemReward',
  },
  {
    title: 'Action',
    key: 'action',
    fixed: 'right',
    width: 90,
    render: (record) => (
      <Button type="text" href={`/treasurehunt/${record.key}/edit`}>
        <EditOutlined />
      </Button>
    ),
  },
];

export default function TreasureHuntList() {
  const token = getToken();
  const [loading, setLoading] = useState(true);

  const [treasureHuntList, setTreasureHuntList] = useState([]);

  const [treasureHuntListData, setTreasureHuntListData] = useState([]);

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/treasurehunt">Treasure Hunt</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const convertSecondToString = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours} hrs ${minutes} mins ${remainingSeconds} sec`;
  };
  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      const duration = convertSecondToString(data[i].duration);
      const itemRewardNames = data[i].itemRewards.map((item) => item.name); 
      list.push({
        key: data[i].id,
        tHuntName: data[i].name,
        food: data[i].food,
        health: data[i].health,
        duration,
        gameCoinReward: data[i].gameCoinReward,
        itemReward: itemRewardNames.join(', '),
      });
    }
    setTreasureHuntListData(list);
  };

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      await axios
        .get('treasureHunts', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            limit,
            page,
          },
        })
        .then((res) => {
          setTreasureHuntList(res.data.results);
          setTotalData(res.data.totalResults);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve treasure hunt data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, [limit, page]);

  useEffect(() => {
    if (loading === false) {
      rerenderList(treasureHuntList);
    }
  }, [loading, treasureHuntList]);

  const onChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
  };

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <div className="table-content">
            {loading === false ? (
              <Table
                columns={treasureHuntCols}
                dataSource={treasureHuntListData}
                pagination={{ pageSize: limit,
                  pageSizeOptions: [20, 50],
                  showSizeChanger: true,
                  total: totalData,
                  onChange,
                  current: page,
                }}
                scroll={{ x: 1300 }}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </Space>
      </Content>
    </>
  );
}
