import React, { useState, useEffect } from 'react';
import {
  message,
} from 'antd';
import { DeleteOutlined, CheckOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import TierDetailForm from './component/TierDetailFormV2';

export default function CreateUser() {
  const history = useHistory();
  const handleSubmit = async (formData) => {
    try {
      await axios.post('tiers', formData);
      message.success('Tier created successfully.');

      setTimeout(() => {
        history.push('/tier');
      }, 1000);
    } catch (error) {
      message.error(`Failed to create tier. ${error}`);
    }
  };

  return (
    <>
      <TierDetailForm
        isCreate
        onSubmit={handleSubmit}
        loading={false}
      />
    </>
  );
}
