import React, { useState, useEffect, useCallback } from 'react';
import { utils, writeFileXLSX } from 'xlsx';
import {
  Table,
  Layout,
  Button,
  Row,
  Col,
  Select,
  Space,
  DatePicker,
  Tag,
  Modal,
  Statistic,
  Breadcrumb,
  Typography,
  message,
  Skeleton,
  Form,
  Input,
} from 'antd';
import { ImportOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';

import moment from 'moment';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Link } = Typography;

const columns = [
  {
    title: 'Item Name',
    dataIndex: 'itemName',
    key: 'itemName',
  },

  {
    title: 'Item Name (CN)',
    dataIndex: 'itemNameCN',
    key: 'itemName',
  },

  {
    title: 'Category',
    dataIndex: 'gameItemCategory',
    key: 'gameItemCategory',
  },
  {
    title: 'Sold',
    dataIndex: 'gameItemSoldAmount',
    key: 'gameItemSoldAmount',
  },
  {
    title: 'Total Sold Amount',
    dataIndex: 'totalSoldAmount',
    key: 'totalSoldAmount',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status) => {
      let res;

      switch (status) {
        case 'published':
          res = <Tag color="green">Published</Tag>;
          break;
        case 'unpublished':
          res = <Tag color="purple">Unpublished</Tag>;
          break;
        default:
          res = '';
          break;
      }

      return res;
    },
  },
  {
    title: 'Created Date',
    dataIndex: 'gameItemCreatedDate',
    key: 'gameItemCreatedDate',
    render: (data) => (data ? moment(data).format('YYYY-MM-DD HH:mm:ss') : '-'),
  },
  {
    title: 'Action',
    key: 'action',
    fixed: 'right',
    width: 90,
    render: (record) => (
      <Button type="text" href={`/gamestore/${record.key}/edit`}>
        <EditOutlined />
      </Button>
    ),
  },
];

export default function GameStoreList() {
  const token = getToken();
  const [loading, setLoading] = useState(true);
  const [statsLoading, setStatsLoading] = useState(true);

  const [gameItemList, setGameItemList] = useState([]);
  const [gameItemListData, setGameItemListData] = useState([]);

  const [gameStoreStats, setGameStoreStats] = useState();
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);

  const [filterForm] = Form.useForm();

  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      let soldAmountStr;
      if (data[i].currency === 'MYR') {
        soldAmountStr = `RM ${(data[i].price * data[i].sold).toFixed(2)}`;
      } else if (data[i].currency === 'Hope Coin') {
        soldAmountStr = `${(data[i].price * data[i].sold)} Diamond`;
      } else {
        soldAmountStr = `${(data[i].price * data[i].sold)} ${data[i].currency}`;
      }

      list.push({
        key: data[i].id,
        itemName: data[i].name,
        itemNameCN: data[i].nameCN,
        gameItemCategory: data[i].type === 'Hope Coin' ? 'Diamond' : data[i].type,
        gameItemSoldAmount: data[i].sold,
        totalSoldAmount: soldAmountStr,
        status: data[i].status,
        gameItemCreatedDate: data[i].createdAt,
      });
    }
    setGameItemListData(list);
  };

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [filterData, setFilterData] = useState({});

  const getTableData = async (pageNum, pageSize) => {
    const filterRes = await filterForm
      .validateFields();
    
    const { name, type, status, date } = filterRes;

    const params = {};

    if (name !== '') {
      params.name = name;
    }
    if (type !== 'All') {
      params.type = type;
    }
    if (status !== 'All') {
      params.status = status;        
    }
    if (date) {
      params.startDate = moment(date[0]).startOf('day').toDate();
      params.endDate = moment(date[1]).endOf('day').toDate();
    }

    setFilterData(params);
    setIsFilterUpdated(true);
     
    await axios
      .get('items', {
        params: {
          sortBy: 'createdAt:desc',
          limit: pageSize,
          page: pageNum,
          ...params,
        },
      })
      .then((res) => {
        setGameItemList(res.data.results);
        setTotalData({ 
          totalResults: res.data.totalResults,
          totalPages: res.data.totalPages,
        });
        setLoading(false);
      })
      .catch((error) => {
        message.error(`Failed to retrieve data. ${error}`);
        setLoading(false);
      });
  };

  const handleSearch = () => {
    setPage(1);
    getTableData(1, limit);
  };

  const getStats = async () => {
    await axios
      .get('items/game-store-statistic', {
      })
      .then((res) => {
        setGameStoreStats(res.data);
        setStatsLoading(false);
      })
      .catch((error) => {
        message.error(`Failed to retrieve game store stats data. ${error}`);
        setStatsLoading(false);
      });
  };

  const clearFilter = () => {
    filterForm.resetFields();
    setIsFilterUpdated(false);
    handleSearch();
  };

  const onTableChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
    getTableData(current, pageSize);
  };

  useEffect(() => {
    getStats();
    handleSearch();
  }, []);

  useEffect(() => {
    if (loading === false) {
      rerenderList(gameItemList);
    }
  }, [loading, gameItemList]);

  const [modalActive, setModalActive] = useState(false);
  const showModal = () => {
    setModalActive(true);
  };
  // const handleOk = () => {
  //   setModalActive(false);
  // };
  const handleCancel = () => {
    setModalActive(false);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleOk = useCallback(async () => {
    let selectedRows = [];
  
    if (rowSelection.selectedRowKeys.length === 0) {
      // Export all rows when no rows are selected
      const params = {
        limit,
        sortBy: 'createdAt:desc',
        ...filterData,
      };

      const getData = async (currentPage) => {
        const response = await axios
          .get('items', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
            params: { ...params, page: currentPage },
          });

        return response.data.results;
      };

      // generate promises to get all data
      const promises = Array.from({ length: totalData.totalPages }, (_, i) => getData(i + 1));

      // wait for promises to resolve
      const results = await Promise.all(promises);

      // array of arrays -> single array
      const flattenResults = results.flat();

      for (let i = 0; i < flattenResults.length; i++) {
        let soldAmountStr;
        if (flattenResults[i].currency === 'MYR') {
          soldAmountStr = `RM ${(flattenResults[i].price * flattenResults[i].sold).toFixed(2)}`;
        } else {
          soldAmountStr = `${(flattenResults[i].price * flattenResults[i].sold)} ${flattenResults[i].currency}`;
        }
        
        selectedRows.push({
          itemName: flattenResults[i].name,
          itemNameCN: flattenResults[i].nameCN,
          gameItemCategory: flattenResults[i].type,
          gameItemSoldAmount: flattenResults[i].sold,
          totalSoldAmount: soldAmountStr,
          status: flattenResults[i].status,
          gameItemCreatedDate: flattenResults[i].createdAt,
        });
      }
    } else {
      // Export selected rows
      selectedRows = gameItemListData
        .filter((row) => rowSelection.selectedRowKeys.includes(row.key))
        .map(({ key, ...rest }) => rest);
    }

    // Rename column headers
    const renamedColumns = {
      itemName: 'Item Name',
      gameItemCategory: 'Category',
      gameItemSoldAmount: 'Sold Amount',
      totalSoldAmount: 'Total Sold Amount',
      status: 'Status',
      gameItemCreatedDate: 'Created At',
    };

    const transformedRows = selectedRows.map((row) => (
      Object.entries(row).reduce((acc, [key, value]) => {
        const renamedKey = renamedColumns[key] || key;
        if (key === 'gameItemCreatedDate') {
          const date = new Date(value);
          const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Asia/Kuala_Lumpur',
          };
          return { ...acc, [renamedKey]: date.toLocaleString('en-US', options) };
        }
        return { ...acc, [renamedKey]: value };
      }, {})));  
  
    const currentDate = new Date();
    const formattedDate = currentDate
      .toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(/[/:\s]/g, '_');
  
    const ws = utils.json_to_sheet(transformedRows);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    writeFileXLSX(wb, `GameStore_${formattedDate}.xlsx`);

    setModalActive(false);
  }, [gameItemListData, rowSelection.selectedRowKeys]);

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/gamestore">Game Store</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const onFilterUpdate = () => {
    setIsFilterUpdated(true);
  };

  const FilterRow = () => (
    <Form form={filterForm}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={12} lg={6}>
          <Row>
            <p>Item Name</p>
          </Row>
          <Row>
            <Form.Item name="name" style={{ width: '100%' }}>
              <Input placeholder="Item Name" />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={6}>
          <Row>
            <p>Category</p>
          </Row>
          <Row>
            <Form.Item name="type" style={{ width: '100%' }}>
              <Select placeholder="Select Category" defaultValue="All" onChange={onFilterUpdate}>
                <Select.Option value="All">All</Select.Option>
                <Select.Option value="Hope Coin">Diamond</Select.Option>
                <Select.Option value="Game Coin">Game Coin</Select.Option>
                <Select.Option value="Spin">Spin</Select.Option>
                <Select.Option value="Food">Food</Select.Option>
                <Select.Option value="Health">Health</Select.Option>
                <Select.Option value="Colored Egg">Colored Egg</Select.Option>
                <Select.Option value="Game Ticket">Game Ticket</Select.Option>
                <Select.Option value="Equipment">Equipment</Select.Option>
                <Select.Option value="Promotion">Promotion</Select.Option>
                <Select.Option value="Special">Special</Select.Option>
              </Select>
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={6}>
          <Row>
            <p>Status</p>
          </Row>
          <Row>
            <Form.Item name="status" style={{ width: '100%' }}>
              <Select placeholder="Select Status" defaultValue="All" onChange={onFilterUpdate}>
                <Select.Option value="All">All</Select.Option>
                <Select.Option value="published">Published</Select.Option>
                <Select.Option value="unpublished">Unpublished</Select.Option>
              </Select>
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={6}>
          <Row>
            <p>Date</p>
          </Row>
          <Row>
            <Form.Item name="date" style={{ width: '100%' }}>
              <RangePicker style={{ width: '100%' }} onChange={onFilterUpdate} />
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </Form>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Row justify="end">
            <Col>
              <Space>
                <Button onClick={showModal}>
                  <ImportOutlined rotate={-90} />
                  Export
                </Button>
                <Modal
                  title="Are you sure you want to export game store data?"
                  open={modalActive}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  centered
                  closable={false}
                  bodyStyle={{ padding: 0 }}
                  footer={[
                    <>
                      <Button onClick={handleCancel}>Cancel</Button>
                      <Button type="primary" onClick={handleOk}>
                        Export
                      </Button>
                    </>,
                  ]}
                />
                <Button type="primary" href="/gamestore/item/create">
                  <PlusOutlined />
                  Create Game Item
                </Button>
              </Space>
            </Col>
          </Row>
          <div className="content-area" style={{ margin: '20px 0px', backgroundColor: 'white' }}>
            <Row>
              {statsLoading === false ? (
                <>
                  <Col xs={12} lg={8}>
                    <Statistic title="Total Food Item" value={gameStoreStats.foodCount} />
                  </Col>
                  <Col xs={12} lg={8}>
                    <Statistic title="Total Health Item" value={gameStoreStats.healthCount} />
                  </Col>
                  <Col xs={12} lg={8}>
                    <Statistic title="Total Equipment Item" value={gameStoreStats.equipmentCount} />
                  </Col>
                </>
              ) : (
                <Skeleton />
              )}
            </Row>
          </div>
          <FilterRow />
          <Row justify="end">
            <Space>
              <Col>
                <Button danger onClick={clearFilter} disabled={!isFilterUpdated}>
                  Reset Filters
                </Button>
              </Col>
              <Col>
                <Button onClick={handleSearch}>Apply Filters</Button>
              </Col>
            </Space>
          </Row>
          <div className="table-content">
            {loading === false ? (
              <Table
                columns={columns}
                dataSource={gameItemListData}
                pagination={{ pageSize: limit,
                  pageSizeOptions: [20, 50, 100],
                  showSizeChanger: true,
                  total: totalData.totalResults,
                  onChange: onTableChange,
                  current: page,
                }}
                scroll={{ x: 1300 }}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </Space>
      </Content>
    </>
  );
}
