import React, { useState, useEffect } from 'react';
import {
  Typography,
  Layout,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Breadcrumb,
  Form,
  message,
  Skeleton,
} from 'antd';
import { DeleteOutlined, CheckOutlined, LeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

import VoucherForm from '../../components/voucherManagement/VoucherForm';

const { Title, Link } = Typography;
const { Content } = Layout;

export default function EditVoucher(props) {
  const { userRole } = props;
  const [form] = Form.useForm();
  const token = getToken();
  const [loading, setLoading] = useState(true);

  const voucherID = window.location.href.split('/')[4];
  const [voucherData, setVoucherData] = useState({});

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      await axios
        .get(`vouchers/withDistributed/${voucherID}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setVoucherData(res.data);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve voucher data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, []);

  const handleSave = async () => {
    try {
      const formData = form.getFieldsValue();

      const dataPackage = {
        type: 'Merchant',
        name: formData.voucherName,
        voucherUrl: formData.voucherImage,
        merchantId: voucherData.merchantId,
        value: formData.voucherValue,
        startDate: moment(formData.voucherEffectiveDate[0]).format('YYYY-MM-DD'),
        endDate: moment(formData.voucherEffectiveDate[1]).format('YYYY-MM-DD'),
        description: formData.remarks,
        quantity: formData.voucherQuantity,
        balance: formData.voucherQuantity - voucherData.distributed,
        isMonthlyDistribution: formData.monthlyDistribution,
        monthlyDistributionQuantity: formData.monthlyDistributionQuantity,
        tier: formData.voucherTier,
        tncUrl: formData.tncUrl || '',
        tncContent: formData.tncContent || '',

        label: formData.voucherLabel,
      };

      if (voucherData.distributed > formData.voucherQuantity) {
        message.warning(
          `Voucher Quantity cannot be less than distributed vouchers! Distributed Vouchers: ${voucherData.distributed}`,
        );
        return;
      }

      await axios.patch(`vouchers/${voucherID}`, dataPackage, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      message.success('Voucher data saved successfully.');

      setTimeout(() => {
        window.location.href = '/vouchers';
      }, 1000);
    } catch (error) {
      message.error(`Failed to save voucher data. ${error}`);
    }
  };

  const handleDeactivate = async () => {
    try {
      const formData = form.getFieldsValue();
      if (voucherData.status === 'Active') {
        const dataPackage = {
          status: 'Inactive',
          merchantId: formData.voucherMerchant,
        };

        await axios.patch(`vouchers/${voucherID}`, dataPackage, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        });
        message.success('Voucher deactivated successfully.');
      } else {
        const dataPackage = {
          status: 'Active',
          merchantId: formData.voucherMerchant,
        };

        await axios.patch(`vouchers/${voucherID}`, dataPackage, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        });
        message.success('Voucher activated successfully.');
      }

      setTimeout(() => {
        window.location.href = '/vouchers';
      }, 1000);
    } catch (error) {
      message.error(`Failed to delete voucher. ${error}`);
    }
  };

  const [modalActive, setModalActive] = useState(false);
  const showModal = () => {
    setModalActive(true);
  };
  const handleOk = () => {
    setModalActive(false);
    handleDeactivate();
  };
  const handleCancel = () => {
    setModalActive(false);
  };

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/vouchers">
            <LeftOutlined />
            Vouchers
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Voucher</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Row>
          <Col flex={4}>
            <Title
              level={3}
              style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '20px' }}>
              Edit Voucher
            </Title>
          </Col>
          <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              <Button danger onClick={showModal} disabled={voucherData.status !== 'Active'}>
                {voucherData.status === 'expired'
                  ? 'Expired'
                  : voucherData.status !== 'Inactive'
                    ? 'Deactivate Voucher'
                    : 'Deactivated'}
              </Button>
              <Modal
                title="Are you sure you want to deactivate?"
                open={modalActive}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                closable={false}
                footer={[
                  <>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button type="primary" danger onClick={handleOk}>
                      <DeleteOutlined />
                      Yes, Deactivate
                    </Button>
                  </>,
                ]}>
                <p>If you confirm this action, you are unable to undo.</p>
              </Modal>
              <Button type="primary" onClick={handleSave}>
                <CheckOutlined />
                Save
              </Button>
            </Space>
          </Col>
        </Row>
        {loading === false ? (
          <VoucherForm form={form} mode="Edit" data={voucherData} userRole={userRole} />
        ) : (
          <Skeleton />
        )}
      </Content>
    </>
  );
}
