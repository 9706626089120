import React, { useState, useEffect } from 'react';

import {
  Row,
  Descriptions,
  Tag,
  Col,
  Modal,
  Form,
  Input,
  Select,
  DatePicker,
  message,
  Button,
  Collapse,
  Skeleton,
} from 'antd';
import {
  PlusCircleOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import 'antd/dist/antd.less';

import axios from 'axios';
import moment from 'moment';

import CountriesList from '../../assets/file/countriesList.json';
import '../../pages/consultantManagement/ConsultantDetails.css';
import { getToken } from '../../utils/AuthService';
import { capitalizedFirstAlphabet } from '../../utils/capitalizedFirstAlphabet';

const { Option } = Select;
const { RangePicker } = DatePicker;
const { Item } = Form;
const { Panel } = Collapse;

export default function DetailConsultantManagement(id) {
  const token = getToken();
  const [editQualificationForm] = Form.useForm();
  const [addNewQualificationForm] = Form.useForm();
  const [editFamilyPersonalForm] = Form.useForm();
  const [addNewFamilyPersonalForm] = Form.useForm();
  const [editEmergencyForm] = Form.useForm();
  const [addNewEmergencyForm] = Form.useForm();

  const [loadingPersonalInformation, setLoadingPersonalInformation] = useState(false);
  const [consultantData, setConsultantData] = useState([]);
  const [consultantName, setConsultantName] = useState('');
  const [consultantNationality, setConsultantNationality] = useState('');
  const [consultantGender, setConsultantGender] = useState('');
  const [consultantMaritalStatus, setConsultantMaritalStatus] = useState('');

  const [familyData, setFamilyData] = useState([]);
  const [openFamilyPersonalInfo, setOpenFamilyPersonalInfo] = useState(false);
  const [openEditFamilyPersonalInfo, setOpenEditFamilyPersonalInfo] = useState(false);
  const [confirmLoadingEditFamilyPersonal, setConfirmLoadingEditFamilyPersonal] = useState(false);
  const [currentFamilyMember, setCurrentFamilyMember] = useState([]);
  const [addNewFamilyData, setAddNewFamilyData] = useState(false);
  const [loadingAddNewFamilyPersonal, setLoadingAddNewFamilyPersonal] = useState(false);

  const [emergencyData, setEmergencyData] = useState([]);
  const [openEmergencyInfo, setOpenEmergencyInfo] = useState(false);
  const [openEditEmergencyInfo, setOpenEditEmergencyInfo] = useState(false);
  const [confirmLoadingEditEmergency, setConfirmLoadingEditEmergency] = useState(false);
  const [currentEmergencyData, setCurrentEmergencyData] = useState([]);
  const [addNewEmergencyData, setAddNewEmergencyData] = useState(false);
  const [loadingAddNewEmergency, setLoadingAddNewEmergency] = useState(false);
  const [selectedNewRelationship, setSelectedNewRelationship] = useState(null);

  const [qualificationData, setQualificationData] = useState([]);
  const [openQualificationInfo, setOpenQualificationInfo] = useState(false);
  const [openEditQualificationInfo, setOpenEditQualificationInfo] = useState(false);
  const [confirmLoadingEditQualification, setConfirmLoadingEditQualification] = useState(false);
  const [currentQualificationData, setCurrentQualificationData] = useState([]);
  const [addNewQualificationData, setAddNewQualificationData] = useState(false);
  const [loadingAddNewQualification, setLoadingAddNewQualification] = useState(false);
  const [totalFamilyMembers, setTotalFamilyMembers] = useState(0);
  const [totalEmergencyContacts, setTotalEmergencyContacts] = useState(0);
  const [totalQualifications, setTotalQualifications] = useState(0);

  const [successArchive, setSuccessArchive] = useState(false);
  const [loadingArchive, setLoadingArchive] = useState(false);

  useEffect(() => {
    const fetchPersonalInformationData = async () => {
      setLoadingPersonalInformation(true);
      await axios
        .get(`v1/consultants/${id}/getPersonalInformation`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((results) => {
          if (results.data.consultant) {
            setConsultantData(results.data.consultant);
            setConsultantName(results.data.consultant.name);
            setConsultantNationality(results.data.consultant.nationality);
            setConsultantGender(results.data.consultant.gender);
            setConsultantMaritalStatus(results.data.consultant.maritalStatus);
          }

          setFamilyData(results.data.family_details);
          setTotalFamilyMembers(results.data.family_details.length);

          setEmergencyData(results.data.emergency_contact);
          setTotalEmergencyContacts(results.data.emergency_contact.length);

          setQualificationData(results.data.qualification);
          setTotalQualifications(results.data.qualification.length);
          setLoadingPersonalInformation(false);
        })
        .catch((error) => {
          message.error(`Failed to retrive personal information data. ${error}`);
          setLoadingPersonalInformation(false);
        });
    };

    fetchPersonalInformationData();
  }, [successArchive]);

  function isToday(date) {
    const today = new Date();
    if (
      today.getFullYear() === date.getFullYear()
      && today.getMonth() === date.getMonth()
      && today.getDate() === date.getDate()
    ) {
      return true;
    }

    return false;
  }

  const validateMessages = {
    required: '${label} is required!',
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  //* family
  const handleViewFamilyInfo = (field) => {
    setCurrentFamilyMember({
      id: field.id,
      name: field.name,
      relationship: field.relationship,
      others: field.others === undefined ? null : field.others,
      occupation: field.occupation,
      dateOfBirth: field.dateOfBirth,
    });
    setOpenFamilyPersonalInfo(true);
  };
  const handleCloseFamilyInfo = () => {
    setOpenFamilyPersonalInfo(false);
  };

  const archiveFamilyData = async (familyId) => {
    setLoadingArchive(true);

    await axios
      .put(`v1/families/${familyId}/archive`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      })
      .then(() => {
        setSuccessArchive(true);
        setCurrentFamilyMember([]);
        setOpenFamilyPersonalInfo(false);
        setOpenEditFamilyPersonalInfo(false);
        message.success('Archive family data successfully!');
        setTimeout(window.location.reload(), 20000);
        setLoadingArchive(false);
      })
      .catch((error) => {
        setSuccessArchive(false);
        setOpenEditFamilyPersonalInfo(false);
        setLoadingArchive(false);
        message.error(`Failed to archive family. ${error}`);
      });
  };
  const onArchiveFamily = (familyId, name) => {
    Modal.confirm({
      title: `Confirm archive ${name}'s data?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirm',
      cancelText: 'Cancel',
      centered: true,
      onOk() {
        archiveFamilyData(familyId);
      },
      onCancel() {},
    });
  };
  const archiveFamilyIcon = (Id, name) => (
    <DeleteOutlined
      onClick={(event) => {
        onArchiveFamily(Id, name);
        event.stopPropagation();
      }}
    />
  );

  const handleEditFamilyInfo = () => {
    setOpenFamilyPersonalInfo(false);
    setOpenEditFamilyPersonalInfo(true);
  };
  const handleCancelEditFamilyInfo = () => {
    setOpenEditFamilyPersonalInfo(false);
  };
  const onFinishEditFamilyInfo = async (values) => {
    const { familyInfo } = values;

    const toUpdateFamilyData = [];
    familyData.map((val) => {
      toUpdateFamilyData.push({
        id: val.id,
      });
    });

    familyInfo.map((val, index) => {
      const isTodayDate = isToday(new Date(val.dob));
      if (isTodayDate === false) {
        toUpdateFamilyData[index].name = val.name;
        toUpdateFamilyData[index].relationship = val.relationship;
        toUpdateFamilyData[index].occupation = val.occupation;
        toUpdateFamilyData[index].others = val.others;
        toUpdateFamilyData[index].dob = val.dob;
      } else if (isTodayDate === true) {
        toUpdateFamilyData[index].name = val.name;
        toUpdateFamilyData[index].relationship = val.relationship;
        toUpdateFamilyData[index].occupation = val.occupation;
        toUpdateFamilyData[index].others = val.others;
        toUpdateFamilyData[index].dob = null;
      }
    });

    setOpenEditFamilyPersonalInfo(false);
    setConfirmLoadingEditFamilyPersonal(true);

    const jsonBody = {
      updatedData: toUpdateFamilyData,
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    };

    await axios
      .put(`v1/families/${currentFamilyMember.id}/update`, jsonBody, config)
      .then(() => {
        message.success('Updated family data successfully!');
        setTimeout(window.location.reload(), 20000);
      })
      .catch((error) => {
        message.error(`Failed to update family data. ${error}`);
      });

    editFamilyPersonalForm.resetFields();
    setConfirmLoadingEditFamilyPersonal(false);
  };

  const handleCancelAddNewFamilyInfo = () => {
    setAddNewFamilyData(false);
  };
  const onFinishAddNewFamilyInfo = async (values) => {
    const { name, relationship, occupation, dob } = values.newFamilyInfo;

    setAddNewFamilyData(false);
    setLoadingAddNewFamilyPersonal(true);

    const jsonBody = {
      consultantId: id,
      name,
      relationship,
      occupation,
      dateOfBirth: dob,
    };

    await axios
      .post('v1/families', jsonBody)
      .then(() => {
        message.success('Successfully create new family data!');
        setTimeout(window.location.reload(), 20000);
      })
      .catch((error) => {
        message.error(`Failed to create new family data. ${error}`);
      });

    addNewFamilyPersonalForm.resetFields();
    setLoadingAddNewFamilyPersonal(false);
  };

  //* emergency info
  const handleEditEmergencyInfo = () => {
    setOpenEmergencyInfo(false);
    setOpenEditEmergencyInfo(true);
  };
  const handleCancelEditEmergencyInfo = () => {
    setOpenEditEmergencyInfo(false);
  };
  const handleViewEmegerncyInfo = (field) => {
    setCurrentEmergencyData({
      id: field.id,
      name: field.name,
      relationship: field.relationship,
      phoneNumber: field.phoneNumber,
    });
    setOpenEmergencyInfo(true);
  };
  const handleCloseEmergencyInfo = () => {
    setCurrentEmergencyData([]);
    setOpenEmergencyInfo(false);
  };

  const archiveEmergencyContactData = async (emergencyContactId) => {
    setLoadingArchive(true);

    await axios
      .put(`v1/emergencyContacts/${emergencyContactId}/archive`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      })
      .then(() => {
        setSuccessArchive(true);
        setCurrentEmergencyData([]);
        setOpenEmergencyInfo(false);
        setOpenEditEmergencyInfo(false);
        message.success('Archive emergency contact data successfully!');
        setTimeout(window.location.reload(), 20000);
        setLoadingArchive(false);
      })
      .catch((error) => {
        setSuccessArchive(false);
        setLoadingArchive(false);
        message.error(`Failed to archive emergency contact. ${error}`);
      });
  };
  const onArchiveEmergencyContact = (emergencyContactId, name) => {
    Modal.confirm({
      title: `Confirm archive ${name}'s data?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirm',
      cancelText: 'Cancel',
      centered: true,
      onOk() {
        archiveEmergencyContactData(emergencyContactId);
      },
      onCancel() {},
    });
  };
  const archiveEmergencyContactIcon = (Id, name) => (
    <DeleteOutlined
      onClick={(event) => {
        onArchiveEmergencyContact(Id, name);
        event.stopPropagation();
      }}
    />
  );

  const onFinishEditEmergencyInfo = async (values) => {
    const { emergencyInfo } = values;

    const toUpdateEmergencyContactData = [];
    emergencyData.map((val) => {
      toUpdateEmergencyContactData.push({
        id: val.id,
      });
    });

    emergencyInfo.map((val, index) => {
      toUpdateEmergencyContactData[index].name = val.name;
      toUpdateEmergencyContactData[index].relationship = val.relationship;
      toUpdateEmergencyContactData[index].phoneNo = val.phoneNo;
    });

    setOpenEditEmergencyInfo(false);
    setConfirmLoadingEditEmergency(true);

    const jsonBody = {
      updatedData: toUpdateEmergencyContactData,
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    };

    await axios
      .put(`v1/emergencyContacts/${currentEmergencyData.id}/update`, jsonBody, config)
      .then(() => {
        message.success('Updated emergency contact data successfully!');
        setTimeout(window.location.reload(), 20000);
      })
      .catch((error) => {
        message.error(`Failed to update emergency data. ${error}`);
      });

    editEmergencyForm.resetFields();
    setConfirmLoadingEditEmergency(false);
  };
  const handleCancelAddNewEmergencyContact = () => {
    setAddNewEmergencyData(false);
  };
  const onFinishAddNewEmergencyContact = async (values) => {
    const { newEmergencyInfo } = values;

    setAddNewEmergencyData(false);
    setLoadingAddNewEmergency(true);

    const jsonBody = {
      consultantId: id,
      name: newEmergencyInfo.name,
      relationship: newEmergencyInfo.relationship,
      others: newEmergencyInfo.other,
      phoneNumber: newEmergencyInfo.phoneNo,
    };

    const config = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    };

    await axios
      .post('v1/emergencyContacts', jsonBody, config)
      .then(() => {
        message.success('Successfully create new emergency contact data!');
        setTimeout(window.location.reload(), 20000);
      })
      .catch((error) => {
        message.error(`Failed to create new emergency contact data. ${error}`);
      });

    addNewEmergencyForm.resetFields();
    setLoadingAddNewEmergency(false);
  };

  //* qualification
  const handleViewQualificationInfo = (field) => {
    setCurrentQualificationData({
      id: field.id,
      uniName: field.name,
      country: field.country,
      startDate: field.startDate,
      endDate: field.endDate,
      fieldOfStudy: field.fieldOfStudy,
      grade: field.grade,
    });
    setOpenQualificationInfo(true);
  };
  const handleEditQualificationInfo = () => {
    setOpenQualificationInfo(false);
    setOpenEditQualificationInfo(true);
  };
  const handleCancelEditQualificationInfo = () => {
    setOpenEditQualificationInfo(false);
  };
  const handleCloseQualificationInfo = () => {
    setCurrentQualificationData([]);
    setOpenQualificationInfo(false);
  };

  const archiveQualificationData = async (qualificationId) => {
    setLoadingArchive(true);

    await axios
      .put(`v1/qualifications/${qualificationId}/archive`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      })
      .then(() => {
        setSuccessArchive(true);
        setCurrentQualificationData([]);
        setOpenQualificationInfo(false);
        setOpenEditQualificationInfo(false);
        message.success('Archive qualification data successfully!');
        setTimeout(window.location.reload(), 20000);
        setLoadingArchive(false);
      })
      .catch((error) => {
        setSuccessArchive(false);
        setLoadingArchive(false);
        message.error(`Failed to archive qualification. ${error}`);
      });
  };
  const onArchiveQualification = (qualificationId) => {
    Modal.confirm({
      title: 'Confirm archive this data?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Confirm',
      cancelText: 'Cancel',
      centered: true,
      onOk() {
        archiveQualificationData(qualificationId);
      },
      onCancel() {},
    });
  };
  const archiveQualificationIcon = (Id) => (
    <DeleteOutlined
      onClick={(event) => {
        onArchiveQualification(Id);
        event.stopPropagation();
      }}
    />
  );

  const onFinishEditQualificationInfo = async (values) => {
    const { qualification } = values;

    setOpenEditQualificationInfo(false);
    setConfirmLoadingEditQualification(true);

    const toUpdateQualificationData = [];
    qualificationData.map((val) => {
      toUpdateQualificationData.push({
        id: val.id,
      });
    });

    qualification.map((val, index) => {
      toUpdateQualificationData[index].name = val.instituitionName;
      toUpdateQualificationData[index].country = val.country;
      toUpdateQualificationData[index].startDate = val.duration !== null ? val.duration[0] : '';
      toUpdateQualificationData[index].endDate = val.duration !== null ? val.duration[1] : '';
      toUpdateQualificationData[index].fieldOfStudy = val.studyField;
      toUpdateQualificationData[index].grade = val.grade;
    });

    const jsonBody = {
      updatedData: toUpdateQualificationData,
    };

    await axios
      .put(`v1/qualifications/${currentQualificationData.id}/update`, jsonBody)
      .then(() => {
        message.success('Updated qualification data successfully!');
        setTimeout(window.location.reload(), 20000);
      })
      .catch((error) => {
        message.error(`Failed to update qualification data. ${error}`);
      });

    editQualificationForm.resetFields();
    setConfirmLoadingEditQualification(false);
  };
  const handleCancelAddNewQualificationInfo = () => {
    setAddNewQualificationData(false);
  };
  const onFinishAddNewQualificationInfo = async (values) => {
    const { newQualification } = values;

    setAddNewQualificationData(false);
    setLoadingAddNewQualification(true);

    const jsonBody = {
      consultantId: id,
      name: newQualification.instituitionName,
      country: newQualification.country,
      startDate: newQualification.duration[0],
      endDate: newQualification.duration[1],
      fieldOfStudy: newQualification.studyField,
      grade: newQualification.grade,
    };

    await axios
      .post('v1/qualifications', jsonBody)
      .then(() => {
        message.success('Successfully create a new qualification data!');
        setTimeout(window.location.reload(), 20000);
      })
      .catch((error) => {
        message.error(`Failed to create a new qualification data. ${error}`);
      });

    addNewQualificationForm.resetFields();
    setLoadingAddNewQualification(false);
  };

  //* family component
  const EditFamilyPersonalInfoForm = () => (
    <Form
      form={editFamilyPersonalForm}
      layout="vertical"
      name="edit-family-personal-info"
      onFinish={onFinishEditFamilyInfo}
      validateMessages={validateMessages}>
      {familyData.map((field, index) => (
        <div key="1" style={{ padding: '10px 0' }}>
          <Collapse defaultActiveKey={['1']}>
            <Panel header={field.name} key="1" extra={archiveFamilyIcon(field.id, field.name)}>
              <Item label="Name" name={['familyInfo', index, 'name']} initialValue={field.name}>
                <Input />
              </Item>
              <Item
                label="Relationship"
                name={['familyInfo', index, 'relationship']}
                initialValue={field.relationship}>
                <Select
                  placeholder="Select relationship"
                  style={{
                    width: '100%',
                  }}>
                  <Option value="parents">Parents</Option>
                  <Option value="wife">Wife</Option>
                  <Option value="husband">Husband</Option>
                  <Option value="son">Son</Option>
                  <Option value="daughter">Daughter</Option>
                  <Option value="relatives">Relatives</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Item>
              <Item
                name={['familyInfo', index, 'others']}
                label="Others (Please specify if 'other' was chosen as value for relationship)"
                tooltip={{
                  title: "Required if 'other' was chosen as value for relationship",
                  icon: <InfoCircleOutlined />,
                }}
                initialValue={field.others}>
                <Input />
              </Item>
              <Item
                label="Occupation"
                name={['familyInfo', index, 'occupation']}
                initialValue={field.occupation}>
                <Input />
              </Item>
              <Item
                label="Date of Birth"
                name={['familyInfo', index, 'dob']}
                initialValue={field.dateOfBirth !== null ? moment(field.dateOfBirth) : moment()}>
                <DatePicker format="YYYY/MM/DD" style={{ width: '100%' }} />
              </Item>
            </Panel>
          </Collapse>
        </div>
      ))}
    </Form>
  );
  const EditFamily = () => (
    <Modal
      centered
      width={600}
      title="Edit Family Member"
      open={openEditFamilyPersonalInfo}
      onCancel={handleCancelEditFamilyInfo}
      confirmLoading={confirmLoadingEditFamilyPersonal}
      footer={[
        <>
          <Button key="back" onClick={handleCancelEditFamilyInfo}>
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            loading={confirmLoadingEditFamilyPersonal}
            onClick={editFamilyPersonalForm.submit}>
            Save Changes
          </Button>
        </>,
      ]}
      bodyStyle={{ overflowY: 'auto', height: '50vh' }}>
      <EditFamilyPersonalInfoForm />
    </Modal>
  );
  const ShowFamily = () => (
    <Modal
      centered
      title={currentFamilyMember.length !== 0 ? currentFamilyMember.name : ''}
      open={openFamilyPersonalInfo}
      onCancel={handleCloseFamilyInfo}
      footer={null}>
      {currentFamilyMember.relationship === null ? null : (
        <>
          <div className="personal-info-title">Relationship</div>
          <div>{currentFamilyMember.relationship}</div>
        </>
      )}

      {currentFamilyMember.others === null ? null : (
        <>
          <div className="personal-info-title">Others</div>
          <div>{currentFamilyMember.others}</div>
        </>
      )}

      {currentFamilyMember.occupation === null ? null : (
        <>
          <div className="personal-info-title">Occupation</div>
          <div>{currentFamilyMember.occupation}</div>
        </>
      )}

      {currentFamilyMember.dateOfBirth === null ? null : (
        <>
          <div className="personal-info-title">Date of Birth</div>
          <div>{moment(currentFamilyMember.dateOfBirth).format('DD MMMM YY')}</div>
        </>
      )}
    </Modal>
  );
  const AddNewFamilyInfoForm = () => (
    <Form
      form={addNewFamilyPersonalForm}
      layout="vertical"
      name="add-new-family-personal-info"
      onFinish={onFinishAddNewFamilyInfo}
      validateMessages={validateMessages}>
      <Item label="Name" name={['newFamilyInfo', 'name']} rules={[{ required: true }]}>
        <Input />
      </Item>
      <Item
        label="Relationship"
        name={['newFamilyInfo', 'relationship']}
        rules={[{ required: true }]}>
        <Select
          placeholder="Select relationship"
          style={{
            width: '100%',
          }}>
          <Option value="wife">Wife</Option>
          <Option value="husband">Husband</Option>
          <Option value="daughter">Daughter</Option>
          <Option value="son">Son</Option>
        </Select>
      </Item>
      <Item label="Occupation" name={['newFamilyInfo', 'occupation']}>
        <Input />
      </Item>
      <Item label="Date of Birth" name={['newFamilyInfo', 'dob']}>
        <DatePicker style={{ width: '100%' }} />
      </Item>
    </Form>
  );
  const AddNewFamily = () => (
    <Modal
      centered
      width={600}
      title="Add Family Member"
      open={addNewFamilyData}
      onCancel={handleCancelAddNewFamilyInfo}
      confirmLoading={loadingAddNewFamilyPersonal}
      footer={[
        <Button key="back" onClick={handleCancelAddNewFamilyInfo}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loadingAddNewFamilyPersonal}
          onClick={addNewFamilyPersonalForm.submit}>
          Add New
        </Button>,
      ]}
      bodyStyle={{ overflowY: 'auto', height: '50vh' }}>
      <AddNewFamilyInfoForm />
    </Modal>
  );

  //* emergency contact component
  const EditEmergencyForm = () => (
    <Form
      form={editEmergencyForm}
      layout="vertical"
      name="edit-emergency-info"
      onFinish={onFinishEditEmergencyInfo}
      validateMessages={validateMessages}>
      {emergencyData.map((field, index) => (
        <div key="1" style={{ padding: '10px 0' }}>
          <Collapse defaultActiveKey={['1']}>
            <Panel header={field.name} key="1" extra={archiveEmergencyContactIcon(field.id, field.name)}>
              <Item label="Name" name={['emergencyInfo', index, 'name']} initialValue={field.name}>
                <Input />
              </Item>
              <Item
                label="Relatioship"
                name={['emergencyInfo', index, 'relationship']}
                initialValue={field.relationship}>
                <Select
                  placeholder="Select relationship"
                  style={{
                    width: '100%',
                  }}>
                  <Option value="wife">Wife</Option>
                  <Option value="husband">Husband</Option>
                  <Option value="son">Son</Option>
                  <Option value="daughter">Daughter</Option>
                  <Option value="relatives">Relatives</Option>
                  <Option value="frineds">Friends</Option>
                  <Option value="other">Other</Option>
                </Select>
              </Item>
              <Item
                label="Others (Please specify if 'other' was chosen as value for relationship)"
                tooltip={{
                  title: "Required if 'other' was chosen as value for relationship",
                  icon: <InfoCircleOutlined />,
                }}
                initialValue={field.others}
                name={['emergencyInfo', index, 'other']}>
                <Input />
              </Item>
              <Item
                label="Phone Number"
                name={['emergencyInfo', index, 'phoneNo']}
                initialValue={field.phoneNumber}>
                <Input placeholder="+60123492837" />
              </Item>
            </Panel>
          </Collapse>
        </div>
      ))}
    </Form>
  );
  const EditEmergencyContactData = () => (
    <Modal
      centered
      width={600}
      title="Edit Emergency Contact"
      open={openEditEmergencyInfo}
      onCancel={handleCancelEditEmergencyInfo}
      confirmLoading={confirmLoadingEditEmergency}
      footer={[
        <Button key="back" onClick={handleCancelEditEmergencyInfo}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={confirmLoadingEditFamilyPersonal}
          onClick={editEmergencyForm.submit}>
          Save Changes
        </Button>,
      ]}
      bodyStyle={{ overflowY: 'auto', height: '60vh' }}>
      {loadingArchive === false ? <EditEmergencyForm /> : <Skeleton />}
    </Modal>
  );
  const ShowEmergencyContactData = () => (
    <Modal
      centered
      title={currentEmergencyData.length !== 0 ? currentEmergencyData.name : ''}
      open={openEmergencyInfo}
      onCancel={handleCloseEmergencyInfo}
      footer={null}>
      <div className="personal-info-title">Relationship</div>
      <div>
        {currentEmergencyData.relationship !== null ? currentEmergencyData.relationship : '-'}
      </div>

      <div className="personal-info-title">Phone No</div>
      <div>
        {currentEmergencyData.phoneNumber !== null ? currentEmergencyData.phoneNumber : '-'}
      </div>
    </Modal>
  );
  const AddNewEmergencyContactForm = () => (
    <Form
      form={addNewEmergencyForm}
      layout="vertical"
      name="add-new-emergency-info"
      onFinish={onFinishAddNewEmergencyContact}
      validateMessages={validateMessages}>
      <Item label="Name" name={['newEmergencyInfo', 'name']} rules={[{ required: true }]}>
        <Input />
      </Item>
      <Item
        label="Relatioship"
        name={['newEmergencyInfo', 'relationship']}
        rules={[{ required: true }]}>
        <Select
          placeholder="Select relationship"
          style={{
            width: '100%',
          }}
          onChange={(val) => {
            setSelectedNewRelationship(val);
          }}>
          <Option value="wife">Wife</Option>
          <Option value="husband">Husband</Option>
          <Option value="son">Son</Option>
          <Option value="daughter">Daughter</Option>
          <Option value="relatives">Relatives</Option>
          <Option value="frineds">Friends</Option>
          <Option value="other">Other</Option>
        </Select>
      </Item>
      <Item
        label="Others (Please specify if 'other' was chosen as value for relationship)"
        tooltip={{
          title: "Required if 'other' was chosen as value for relationship",
          icon: <InfoCircleOutlined />,
        }}
        name={['newEmergencyInfo', 'other']}>
        <Input disabled={selectedNewRelationship === null || selectedNewRelationship !== 'other'} />
      </Item>
      <Item label="Phone No" name={['newEmergencyInfo', 'phoneNo']} rules={[{ required: true }]}>
        <Input placeholder="+60123492837" />
      </Item>
    </Form>
  );
  const AddNewEmergencyContactData = () => (
    <Modal
      centered
      width={600}
      title="Add New Emergency Contact"
      open={addNewEmergencyData}
      onCancel={handleCancelAddNewEmergencyContact}
      confirmLoading={loadingAddNewEmergency}
      footer={[
        <Button key="cancel" onClick={handleCancelAddNewEmergencyContact}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loadingAddNewEmergency}
          onClick={addNewEmergencyForm.submit}>
          Add New
        </Button>,
      ]}
      bodyStyle={{ overflowY: 'auto', height: '50vh' }}>
      <AddNewEmergencyContactForm />
    </Modal>
  );

  //* qualification component
  const EditQualificationForm = () => (
    <Form
      form={editQualificationForm}
      layout="vertical"
      name="edit-emergency-info"
      onFinish={onFinishEditQualificationInfo}
      validateMessages={validateMessages}>
      {qualificationData.map((field, index) => (
        <div key="1" style={{ padding: '10px 0' }}>
          <Collapse defaultActiveKey={['1']}>
            <Panel header={field.name} key="1" extra={archiveQualificationIcon(field.id)}>
              <Item
                label="Name of Instituition, College & University"
                name={['qualification', index, 'instituitionName']}
                initialValue={field.name}>
                <Input />
              </Item>
              <Item
                label="Country"
                name={['qualification', index, 'country']}
                initialValue={field.country}>
                <Select
                  showSearch
                  allowClear
                  placeholder="Select country"
                  style={{
                    width: '100%',
                  }}>
                  {CountriesList.map((val) => (
                    <Option key={val} value={val}>
                      {val}
                    </Option>
                  ))}
                </Select>
              </Item>
              <Item
                label="Duration"
                name={['qualification', index, 'duration']}
                initialValue={
                  field.startDate === null && field.endDate === null
                    ? ['', '']
                    : [moment(field.startDate), moment(field.endDate)]
                }>
                <RangePicker format="YYYY/MM/DD" style={{ width: '100%' }} />
              </Item>
              <Item
                label="Field of Study"
                name={['qualification', index, 'studyField']}
                initialValue={field.fieldOfStudy}>
                <Input />
              </Item>
              <Item
                label="Grade"
                name={['qualification', index, 'grade']}
                initialValue={field.grade}>
                <Input placeholder="CGPA 3.2" />
              </Item>
            </Panel>
          </Collapse>
        </div>
      ))}
    </Form>
  );
  const EditQualification = () => (
    <Modal
      centered
      width={600}
      title="Edit Qualification"
      open={openEditQualificationInfo}
      onCancel={handleCancelEditQualificationInfo}
      confirmLoading={confirmLoadingEditQualification}
      footer={[
        <Button key="back" onClick={handleCancelEditQualificationInfo}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={confirmLoadingEditFamilyPersonal}
          onClick={editQualificationForm.submit}>
          Save Changes
        </Button>,
      ]}
      bodyStyle={{ overflowY: 'auto', height: '60vh' }}>
      <EditQualificationForm />
    </Modal>
  );
  const ShowQualifictaion = () => (
    <Modal
      centered
      title={currentQualificationData.length !== 0 ? currentQualificationData.uniName : '-'}
      open={openQualificationInfo}
      onCancel={handleCloseQualificationInfo}
      footer={null}>
      <div className="personal-info-title">Country</div>
      <div>
        {currentQualificationData.country !== null ? currentQualificationData.country : '-'}
      </div>

      <div className="personal-info-title">Duration</div>
      <div>
        {currentQualificationData.startDate !== null && currentQualificationData.endDate !== null
          ? `${moment(currentQualificationData.startDate).format('DD MMMM YY')} untill ${moment(
            currentQualificationData.endDate,
          ).format('DD MMMM YY')}`
          : '-'}
      </div>

      <div className="personal-info-title">Field of Study</div>
      <div>
        {currentQualificationData.fieldOfStudy !== null
          ? currentQualificationData.fieldOfStudy
          : '-'}
      </div>

      <div className="personal-info-title">Grade</div>
      <div>{currentQualificationData.grade !== null ? currentQualificationData.grade : '-'}</div>
    </Modal>
  );
  const AddNewQualificationForm = () => (
    <Form
      form={addNewQualificationForm}
      layout="vertical"
      name="edit-emergency-info"
      onFinish={onFinishAddNewQualificationInfo}
      validateMessages={validateMessages}>
      <Item
        label="Name of Instituition, College & University"
        name={['newQualification', 'instituitionName']}
        rules={[{ required: true }]}>
        <Input />
      </Item>
      <Item label="Country" name={['newQualification', 'country']} rules={[{ required: true }]}>
        <Select
          showSearch
          allowClear
          placeholder="Select country"
          style={{
            width: '100%',
          }}>
          {CountriesList.map((val) => (
            <Option key={val} value={val}>
              {val}
            </Option>
          ))}
        </Select>
      </Item>
      <Item label="Duration" name={['newQualification', 'duration']} rules={[{ required: true }]}>
        <RangePicker style={{ width: '100%' }} />
      </Item>
      <Item
        label="Field of Study"
        name={['newQualification', 'studyField']}
        rules={[{ required: true }]}>
        <Input />
      </Item>
      <Item label="Grade" name={['newQualification', 'grade']} rules={[{ required: true }]}>
        <Input placeholder="CGPA 3.2" />
      </Item>
    </Form>
  );
  const AddNewQualification = () => (
    <Modal
      centered
      width={600}
      title="Add New Qualification"
      open={addNewQualificationData}
      onCancel={handleCancelAddNewQualificationInfo}
      confirmLoading={loadingAddNewQualification}
      footer={[
        <Button key="back" onClick={handleCancelAddNewQualificationInfo}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loadingAddNewQualification}
          onClick={addNewQualificationForm.submit}>
          Add New
        </Button>,
      ]}
      bodyStyle={{ overflowY: 'auto', height: '60vh' }}>
      <AddNewQualificationForm />
    </Modal>
  );

  const PersonalInformation = () => (
    <>
      {loadingPersonalInformation === false ? (
        <Descriptions
          labelStyle={{
            maxWidth: '110px',
            fontWeight: '600',
            fontSize: '14px',
            lineHeight: '22px',
          }}
          contentStyle={{ fontWeight: '400' }}
          column={1}
          span={1}
          bordered>
          <Descriptions.Item label="Name">
            <Row className="personal-details-form-data">
              <div>{consultantName !== null ? capitalizedFirstAlphabet(consultantName) : '-'}</div>
            </Row>
          </Descriptions.Item>

          <Descriptions.Item label="IC / Passport">
            <Row className="personal-details-form-data">
              <div>{consultantData.nric !== null ? consultantData.nric : '-'}</div>
            </Row>
          </Descriptions.Item>

          <Descriptions.Item label="Nationality">
            <Row className="personal-details-form-data">
              <div>
                {consultantNationality !== null
                  ? capitalizedFirstAlphabet(consultantNationality)
                  : '-'}
              </div>
            </Row>
          </Descriptions.Item>

          <Descriptions.Item label="Gender">
            <Row className="personal-details-form-data">
              <div>
                {consultantGender != null ? capitalizedFirstAlphabet(consultantGender) : '-'}
              </div>
            </Row>
          </Descriptions.Item>

          <Descriptions.Item label="Email">
            <Row className="personal-details-form-data">
              <div>{consultantData.email !== null ? consultantData.email : '-'}</div>
            </Row>
          </Descriptions.Item>

          <Descriptions.Item label="Maritial Status">
            <Row style={{ justifyContent: 'space-between' }}>
              <div>
                {consultantMaritalStatus != null
                  ? capitalizedFirstAlphabet(consultantMaritalStatus)
                  : '-'}
              </div>
            </Row>
          </Descriptions.Item>

          <Descriptions.Item label="Address">
            <Row className="personal-details-form-data">
              {loadingPersonalInformation === false ? (
                <div>{consultantData.address != null ? consultantData.address : '-'}</div>
              ) : null}
            </Row>
          </Descriptions.Item>

          <Descriptions.Item label="Contact No (Phone)">
            <Row className="personal-details-form-data">
              <div>{consultantData.contactPhone != null ? consultantData.contactPhone : '-'}</div>
            </Row>
          </Descriptions.Item>

          <Descriptions.Item label="Contact No (Home)">
            <Row className="personal-details-form-data">
              <div>{consultantData.contactHome != null ? consultantData.contactHome : '-'}</div>
            </Row>
          </Descriptions.Item>

          <Descriptions.Item label="Family Details">
            <Row>
              <Col style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {familyData.length !== 0 ? (
                  <>
                    {familyData.map((field) => (
                      <Tag
                        key={field.id}
                        color="default"
                        onClick={() => handleViewFamilyInfo(field)}
                        style={{
                          cursor: 'pointer',
                        }}>
                        {field.name}
                      </Tag>
                    ))}
                  </>
                ) : null}
                {totalFamilyMembers < 3 ? (
                  <PlusCircleOutlined
                    onClick={() => {
                      setAddNewFamilyData(true);
                    }}
                  />
                ) : null}
              </Col>
              <Col>
                {familyData.length !== 0 ? (
                  <Button
                    key="submit"
                    type="text"
                    style={{ color: '#f7931d' }}
                    onClick={handleEditFamilyInfo}>
                    Edit
                  </Button>
                ) : null}
              </Col>
            </Row>
          </Descriptions.Item>

          <Descriptions.Item label="Emergency Contact">
            <Row>
              <Col style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {emergencyData.length !== 0 ? (
                  <>
                    {emergencyData.map((field) => (
                      <Tag
                        key={field.id}
                        color="default"
                        onClick={() => handleViewEmegerncyInfo(field)}
                        style={{
                          cursor: 'pointer',
                        }}>
                        {field.name}
                      </Tag>
                    ))}
                  </>
                ) : null}
                {totalEmergencyContacts < 3 ? (
                  <PlusCircleOutlined
                    onClick={() => {
                      setAddNewEmergencyData(true);
                    }}
                  />
                ) : null}
              </Col>
              <Col>
                {emergencyData.length !== 0 ? (
                  <Button
                    key="submit"
                    type="text"
                    style={{ color: '#f7931d' }}
                    onClick={handleEditEmergencyInfo}>
                    Edit
                  </Button>
                ) : null}
              </Col>
            </Row>
          </Descriptions.Item>

          <Descriptions.Item label="Qualifications">
            <Row>
              <Col style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                {qualificationData.length !== 0 ? (
                  <>
                    {qualificationData.map((field) => (
                      <Tag
                        key={field.id}
                        color="default"
                        onClick={() => handleViewQualificationInfo(field)}
                        style={{
                          cursor: 'pointer',
                        }}>
                        {field.name}
                      </Tag>
                    ))}
                  </>
                ) : null}
                {totalQualifications < 5 ? (
                  <Col>
                    <PlusCircleOutlined
                      onClick={() => {
                        setAddNewQualificationData(true);
                      }}
                    />
                  </Col>
                ) : null}
              </Col>
              <Col>
                {qualificationData.length !== 0 ? (
                  <Button
                    key="submit"
                    type="text"
                    style={{ color: '#f7931d' }}
                    onClick={handleEditQualificationInfo}>
                    Edit
                  </Button>
                ) : null}
              </Col>
            </Row>
          </Descriptions.Item>
        </Descriptions>
      ) : (
        <Skeleton />
      )}
    </>
  );

  return (
    <div className="table-content">
      <PersonalInformation />
      <ShowQualifictaion />
      <EditQualification />
      <ShowEmergencyContactData />
      <EditEmergencyContactData />
      <ShowFamily />
      <EditFamily />
      <AddNewFamily />
      <AddNewQualification />
      <AddNewEmergencyContactData />
    </div>
  );
}
