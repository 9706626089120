import { React, useState, useEffect } from 'react';
import { Typography, Layout, Row, Col, Button, Space, Breadcrumb, Form, message } from 'antd';
import { DeleteOutlined, CheckOutlined, LeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import axios from 'axios';
import { getToken, getUser } from '../../utils/AuthService';

import VoucherForm from '../../components/voucherManagement/VoucherForm';

const { Title, Link } = Typography;
const { Content } = Layout;

export default function CreateVoucherRequest() {
  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/voucher-request">
            <LeftOutlined />
            Voucher Requests
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create Voucher Request</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const [form] = Form.useForm();
  const token = getToken();
  const user = getUser();
  const [merchantId, setMerchantId] = useState();

  useEffect(() => {
    const getMerchantId = async () => {
      await axios
        .get(`merchants/getMerchantId/${user.id}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setMerchantId(res.data);
        })
        .catch((error) => {
          message.error(`Failed to retrieve merchant id. ${error}`);
        });
    };

    getMerchantId();
  }, []);

  const handleSave = async () => {
    try {
      const formData = form.getFieldsValue();
      console.log(user);

      if (!formData.tncContent && !formData.tncUrl) {
        message.warning('Please enter either T&C Content or T&C Url!');
        return;
      }

      // TODO: change status and balance
      const dataPackage = {
        type: 'Merchant',
        name: formData.voucherName,
        merchantId,
        voucherUrl: formData.voucherImage,
        value: formData.voucherValue,
        startDate: moment(formData.voucherEffectiveDate[0]).format('YYYY-MM-DD'),
        endDate: moment(formData.voucherEffectiveDate[1]).format('YYYY-MM-DD'),
        status: 'Pending',
        description: formData.remarks,
        quantity: formData.voucherQuantity,
        balance: formData.voucherQuantity,
        isMonthlyDistribution: formData.monthlyDistribution,
        monthlyDistributionQuantity: formData.monthlyDistributionQuantity,
        tier: formData.voucherTier,
        tncUrl: formData.tncUrl || '',
        tncContent: formData.tncContent || '',
      };

      await axios.post('voucherRequest', dataPackage, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      message.success('Voucher Request data saved successfully.');

      setTimeout(() => {
        window.location.href = '/voucher-request';
      }, 1000);
    } catch (error) {
      message.error(`Failed to save voucher data. ${error}`);
    }
  };

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Row>
          <Col flex={4}>
            <Title
              level={3}
              style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '20px' }}>
              Create Voucher Request
            </Title>
          </Col>
          <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              <Button type="primary" disabled>
                <DeleteOutlined />
                Deactivate Voucher
              </Button>
              <Button type="primary" onClick={handleSave}>
                <CheckOutlined />
                Save
              </Button>
            </Space>
          </Col>
        </Row>
        <VoucherForm form={form} mode="Create" data={null} />
      </Content>
    </>
  );
}
