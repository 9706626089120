import React, { useState, useEffect } from 'react';

import { Table, Popconfirm, Tag, message, Skeleton, Typography, Button } from 'antd';
import { ExclamationCircleFilled, CloseCircleFilled, CheckCircleFilled } from '@ant-design/icons';

import moment from 'moment';
import axios from 'axios';
import { saveAs } from 'file-saver';

import { getToken, getUser } from '../../utils/AuthService';

export default function RFPHistoryTable(consultantId) {
  const token = getToken();
  const user = getUser();
  const userRole = user.role;

  const [RFPData, setRFPData] = useState([]);
  const [loadingRFPData, setLoadingRFPData] = useState(false);
  const [archived, setArchived] = useState('');

  useEffect(() => {
    const fetchRFPData = async () => {
      setLoadingRFPData(true);
      await axios
        .get(`v1/consultants/${consultantId}/getConsultantRFPHistory`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            limit: 99999, // todo: modify further limit
            sortBy: 'updatedAt:desc',
          },
        })
        .then((results) => {
          const res = results.data;

          const list = [];
          res.map((e) => {
            if ((e.calloutId === undefined) === true) {
              list.push({
                id: e.id,
                position: null,
                runPaymentDate: e.runPaymentDate,
                startDate: null,
                endDate: null,
                paymentTotal: e.paymentTotal === undefined
                  ? null
                  : e.paymentTotal + e.claimingTotal,
                psl: null,
                status: e.status,
                contractNo: null,
                rfpURL: e.rfpUrl,
                remarks: e.remarks,
                createdAt: e.createdAt,
              });
            } else {
              list.push({
                id: e.id,
                position: e.calloutId.position[0].mandayRateSet,
                runPaymentDate: e.runPaymentDate,
                startDate: e.calloutId.effectiveDate,
                endDate: e.calloutId.completionDate,
                paymentTotal: e.paymentTotal === undefined
                  ? null
                  : e.paymentTotal + e.claimingTotal,
                psl: e.calloutId.pslId.psl,
                status: e.status,
                contractNo: e.calloutId.contractNumber,
                rfpURL: e.rfpUrl,
                remarks: e.remarks,
                createdAt: e.createdAt,
              });
            }
          });

          setRFPData(list);
        })
        .catch((error) => {
          message.error(`Failed to retrive RFP data. ${error}`);
        });
    };

    fetchRFPData();
    setLoadingRFPData(false);
  }, [archived]);

  const getDownloadRFPFile = async (fileURL) => axios.get(`v1/download?fileUrl=${fileURL}`, {
    responseType: 'blob',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token.access.token}`,
    },
  })
    .then((response) => response.data)
    .catch((e) => {
      message.error(`Failed to download RFP form. ${e}`);
    });

  const downloadForm = async (id, fileURL) => {
    getDownloadRFPFile(fileURL).then((blob) => {
      saveAs(blob, `${id}_RFP`);
    });
  };

  const archiveRFP = async (paymentId) => {
    await axios
      .put(`v1/payments/${paymentId}/archive`)
      .then((res) => {
        console.log(res);
        setArchived(res.statusText);
      })
      .catch((e) => {
        message.error(`Failed to archive RFP. ${e}`);
      });
  };

  const renderTagColorStatus = (text) => {
    switch (text) {
      case 'approved':
        return 'green';
      case 'rejected':
        return 'red';
      case 'pending':
        return 'orange';
      case 'incomplete':
        return 'blue';
      default:
        return '';
    }
  };

  const RFPColumns = [
    {
      title: 'Contract No',
      dataIndex: 'contractNo',
      key: 'RFPcontractNo',
      width: '12%',
      render: (val) => {
        if (val !== null) {
          return val;
        }
        return '-';
      },
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'RFPposition',
      render: (val) => {
        if (val) {
          return val.toUpperCase();
        }
        return '-';
      },
    },
    {
      title: 'PSL',
      dataIndex: 'psl',
      key: 'RFPpsl',
      render: (val) => {
        if (val) {
          return val.toUpperCase();
        }
        return '-';
      },
    },
    {
      title: 'RFP Total',
      dataIndex: 'paymentTotal',
      key: 'RFPpaymentTotal',
      width: '13%',
      align: 'right',
      render: (val) => {
        if (val !== null && (val !== undefined) === true) {
          return `RM ${val.toFixed(2)}`;
        }
        return 'RM 0.00';
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'RFPstatus',
      filters: [
        {
          text: 'Approved',
          value: 'approved',
        },
        {
          text: 'Rejected',
          value: 'rejected',
        },
        {
          text: 'Pending',
          value: 'pending',
        },
        {
          text: 'Incomplete',
          value: 'incomplete',
        },
      ],
      filterMode: 'tree',
      // sorter: (a, b) => a.status.localeCompare(b.status),
      onFilter: (value, record) => record.status === value,
      render: (status, data) => {
        switch (status) {
          case 'incomplete':
            return (
              <Popconfirm
                placement="top"
                title={<div>This RFP is incomplete. Do you wish to archive it?</div>}
                onCancel="Close"
                okText="Archive"
                onConfirm={() => archiveRFP(data.id)}
                icon={(
                  <ExclamationCircleFilled
                    style={{
                      color: '#1890FF',
                    }}
                  />
                )}>
                <Tag style={{ cursor: 'pointer' }} color={renderTagColorStatus(status)}>
                  Incomplete
                </Tag>
              </Popconfirm>
            );
          case 'approved':
            return (
              <Popconfirm
                placement="top"
                icon={<CheckCircleFilled style={{ color: '#52C41A' }} />}
                title={<div>This RFP was approved by admin on {moment(data.updatedAt).format('DD/MM/YYYY')}.</div>}
                showCancel={false}>
                <Tag style={{ cursor: 'pointer' }} color={renderTagColorStatus(status)}>
                  Approved
                </Tag>
              </Popconfirm>
            );
          case 'rejected':
            return (
              <Popconfirm
                placement="top"
                title={
                  <div>This RFP is rejected on {moment(data.updatedAt).format('DD/MM/YYYY')} due to <b>{data.remarks}</b>.</div>
                }
                icon={(
                  <CloseCircleFilled
                    style={{
                      color: '#FF4D4F',
                    }}
                  />
                )}
                okCancel="Close">
                <Tag style={{ cursor: 'pointer' }} color={renderTagColorStatus(status)}>
                  Rejected
                </Tag>
              </Popconfirm>
            );
          case 'pending':
            return (
              <Popconfirm
                placement="top"
                title={(
                  <div>
                    This RFP is waiting to be approved by admin.
                    <br />
                    Submitted at {moment(data.updatedAt).format('DD/MM/YYYY')}.
                  </div>
                )}
                icon={<ExclamationCircleFilled />}
                onCancel="Close">
                <Tag style={{ cursor: 'pointer' }} color={renderTagColorStatus(status)}>
                  Pending
                </Tag>
              </Popconfirm>
            );
          default:
            return '';
        }
      },
    },
    {
      title: 'Month',
      dataIndex: 'runPaymentDate',
      key: 'RFPmonth',
      render: (data) => (data ? moment(data).format('MMM') : '-'),
    },
    {
      title: 'RFP Date',
      dataIndex: 'runPaymentDate',
      key: 'RFPDate',
      align: 'right',
      width: '10%',
      sorter: (a, b) => moment(a.runPaymentDate).unix() - moment(b.runPaymentDate).unix(),
      render: (data) => (data ? moment(data).format('DD/MM/YYYY') : '-'),
    },
    // {
    //   title: 'Start Date',
    //   dataIndex: 'startDate',
    //   key: 'RFPstartDate',
    //   align: 'right',
    //   width: '12%',
    //   sorter: (a, b) => moment(a.startDate).unix() - moment(b.startDate).unix(),
    //   render: (val) => {
    //     if (val !== null) {
    //       return moment(val).format('DD/MM/YYYY');
    //     }
    //     return '-';
    //   },
    // },
    // {
    //   title: 'End Date',
    //   dataIndex: 'endDate',
    //   key: 'RFPendDate',
    //   align: 'right',
    //   width: '10%',
    //   sorter: (a, b) => moment(a.endDate).unix() - moment(b.endDate).unix(),
    //   render: (val) => {
    //     if (val !== null) {
    //       return moment(val).format('DD/MM/YYYY');
    //     }
    //     return '-';
    //   },
    // },
    {
      title: 'Creation Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'right',
      sorter: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
      render: (val) => {
        if (val !== null) {
          return moment(val).format('DD/MM/YYYY');
        }
        return '-';
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: '12%',
      render: (_, data) => {
        if (userRole === 'administrator') {
          if (data.status === 'incomplete') {
            return (
              <Button type="link">
                <Typography.Link style={{ color: '#f7931d' }} href={`/run-payment/edit/${data.id}`}>
                  Edit
                </Typography.Link>
              </Button>
            );
          }
          if (data.status === 'approved' && data.rfpURL) {
            return (
              <Button type="link">
                <Typography.Link
                  style={{ color: '#f7931d' }}
                  onClick={() => downloadForm(consultantId, data.rfpURL)}>
                  Download
                </Typography.Link>
              </Button>
            );
          }
          if (data.status === 'rejected') {
            return (
              <Button type="link">
                <Typography.Link style={{ color: '#f7931d' }} href={`/run-payment/edit/${data.id}`}>
                  Edit
                </Typography.Link>
              </Button>
            );
          }
        } else if (userRole === 'authorizer') {
          if (data.status === 'pending') {
            return (
              <Button type="link">
                <Typography.Link
                  style={{ color: '#f7931d' }}
                  href={`/run-payment/review/${data.id}`}>
                  Review
                </Typography.Link>
              </Button>
            );
          }
          if (data.status === 'approved' && data.rfpURL) {
            return (
              <Button type="link">
                <Typography.Link
                  style={{ color: '#f7931d' }}
                  onClick={() => downloadForm(consultantId, data.rfpURL)}>
                  Download
                </Typography.Link>
              </Button>
            );
          }
        }
        return null;
      },
    },
  ];

  return (
    <>
      {loadingRFPData ? (
        <Skeleton />
      ) : (
        <Table
          scroll={{ x: 'auto' }}
          columns={RFPColumns}
          dataSource={RFPData}
          pagination={{
            position: ['bottomRight'],
            total: RFPData.length,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            defaultCurrent: 1,
            showSizeChanger: true,
            defaultPageSize: 10,
            pageSizeOptions: ['10', '50', '100'],
          }}
        />
      )}
    </>
  );
}
