import React, { useState, useEffect } from 'react';
import {
  Typography,
  Layout,
  Row,
  Col,
  Button,
  Space,
  message,
  Skeleton,
  Form,
  Breadcrumb,
} from 'antd';
import { CheckOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

import MerchantForm from '../../components/merchantManagement/MerchantForm';

const { Title, Link } = Typography;
const { Content } = Layout;

export default function EditMerchant() {
  const [form] = Form.useForm();
  const token = getToken();
  const [loading, setLoading] = useState(true);

  const merchantID = window.location.href.split('/')[4];
  const [merchantData, setMerchantData] = useState({});

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      await axios
        .get(`merchants/${merchantID}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setMerchantData(res.data);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve merchant data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, []);

  const handleSave = async () => {
    try {
      const formData = form.getFieldsValue();

      const dataPackage = {
        name: formData.merchantName,
        email: formData.merchantEmail,
        contact: formData.merchantContactNum,
        category: formData.merchantCategory,
        remarks: formData.remarks,
      };

      await axios.patch(`merchants/${merchantID}`, dataPackage, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      message.success('Merchant data saved successfully.');

      setTimeout(() => {
        window.location.href = '/merchants';
      }, 1000);
    } catch (error) {
      let errorMessage;
      if (error.response.data.message.includes('"email" must be a valid email')) {
        errorMessage = 'Invalid email address.';
      }
      if (
        error.response.data.message.includes('Invalid phone number')
      ) {
        errorMessage = 'Invalid phone number.';
      }

      if (errorMessage) {
        message.error(`Failed to save merchant data. ${errorMessage}`);
      } else {
        message.error(`Failed to save merchant data. ${error}`);
      }
    }
  };

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/merchants">
            <LeftOutlined />
            Merchants
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Merchant</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  // const [modalActive, setModalActive] = useState(false);
  // const showModal = () => {
  //   setModalActive(true);
  // };

  // const handleDelete = async () => {
  //   try {
  //     await axios.put(`merchants/${merchantID}/archive`, null, {
  //       headers: {
  //         accept: '*/*',
  //         Authorization: `Bearer ${token.access.token}`,
  //       },
  //     });
  //     message.success('Merchant deleted successfully.');

  //     setTimeout(() => {
  //       window.location.href = '/merchants';
  //     }, 1000);
  //   } catch (error) {
  //     message.error(`Failed to delete merchant. ${error}`);
  //   }
  // };

  // const handleOk = () => {
  //   setModalActive(false);
  //   handleDelete();
  // };
  // const handleCancel = () => {
  //   setModalActive(false);
  // };

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Row>
          <Col flex={4}>
            <Title
              level={3}
              style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '20px' }}>
              Edit Merchant
            </Title>
          </Col>
          <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              {/* <Button onClick={showModal}>
                <DeleteOutlined />
                Delete
              </Button>
              <Modal
                title="Are you sure you want to delete?"
                open={modalActive}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                closable={false}
                footer={[
                  <>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button type="primary" danger onClick={handleOk}>
                      <DeleteOutlined />
                      Yes, Delete
                    </Button>
                  </>,
                ]}>
                <p>If you confirm this action, you are unable to undo.</p>
              </Modal> */}
              <Button type="primary" onClick={handleSave}>
                <CheckOutlined />
                Save
              </Button>
            </Space>
          </Col>
        </Row>
        {loading === false ? (
          <Row>
            <Col xs={24} lg={15}>
              <div className="content-area">
                <MerchantForm form={form} mode="Edit" data={merchantData} />
              </div>
            </Col>
          </Row>
        ) : (
          <Skeleton />
        )}
      </Content>
    </>
  );
}
