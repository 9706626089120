import React, { useState, useEffect } from 'react';
import {
  Typography,
  Layout,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Breadcrumb,
  Form,
  Skeleton,
  message,
  Tabs,
} from 'antd';
import { DeleteOutlined, CheckOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { MiniGameConfigPage } from './miniGameConfigPage';
import { MinGameRankPage } from './minGameRankPage';
import { MinGameHeecoinPage } from './minGameHeecoinPage';

export function MiniGamePage(props) {
  const tabItems = [
    {
      key: 0,
      label: 'Config',
      children: <MiniGameConfigPage />,
    },
      
    {
      key: 1,
      label: 'Rank History',
      children: <MinGameRankPage />,
    },
        
    {
      key: 2,
      label: 'Heecoin History',
      children: <MinGameHeecoinPage />,
    },
  ];
  return (
    <>
      <div style={{
        background: '#fff',
      }}>
        <Tabs
          defaultActiveKey="0"
          type="card"
          items={tabItems}
      />
      </div>  
     
    </>
  );
}
