import React, { useState, useEffect } from 'react';
import {
  Typography,
  Layout,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Breadcrumb,
  Form,
  Skeleton,
  message,
} from 'antd';
import { DeleteOutlined, CheckOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { getToken } from '../../utils/AuthService';

import TierDetailForm from './component/TierDetailFormV2';

const { Title, Link } = Typography;
const { Content } = Layout;

export default function EditTier() {
  const tierID = window.location.href.split('/')[4];

  const [curData, setCurData] = useState();

  const [loading, setLoading] = useState(true);

  const initData = async () => {
    setLoading(true);

    await axios
      .get(`tiers/${tierID}`)
      .then((res) => {
        setCurData(res.data);

        setLoading(false);
      })
      .catch((error) => {
        message.error(`Failed to retrieve tier data. ${error}`);
        setLoading(false);
      });
  };
  useEffect(() => {
    initData();
  }, []);

  const history = useHistory();

  const handleSubmit = async (formData) => {
    console.log(tierID);
    try {
      await axios.patch(`tiers/${tierID}`, formData);
      message.success('Tier data saved successfully.');

      setTimeout(() => {
        history.push('/tier');
      }, 1000);
    } catch (error) {
      message.error(`Failed to save tier data. ${error}`);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.put(`tiers/${tierID}/archive`, null);
      message.success('Tier deleted successfully.');

      setTimeout(() => {
        window.location.href = '/tier';
      }, 1000);
    } catch (error) {
      message.error(`Failed to delete tier. ${error}`);
    }
  };

  return (
    <>
      <TierDetailForm
        isCreate={false}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        editData={curData}
        loading={loading} />

    </>
  );
}
