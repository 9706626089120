import React, { useState, useEffect, useCallback } from 'react';
import { utils, writeFileXLSX } from 'xlsx';
import {
  Table,
  Layout,
  Button,
  Row,
  Col,
  Space,
  Modal,
  Input,
  Breadcrumb,
  Typography,
  Form,
  message,
  Skeleton,
} from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import axios from 'axios';
import Cookie from 'js-cookie';
import { getToken } from '../../utils/AuthService';

const { Content } = Layout;
const { Link } = Typography;

export default function MerchantBranchList() {
  const [qrString, setQrString] = useState('');
  const [QRModalActive, setQRModalActive] = useState(false);

  const openImage = (image) => {
    setQrString(image);
  };

  useEffect(() => {
    if (qrString) {
      setQRModalActive(true);
    } else {
      setQRModalActive(false);
    }
  }, [qrString]);

  const columns = [
    {
      title: 'Branch',
      dataIndex: 'branchName',
      key: 'branchName',
    },
    {
      title: 'Contact Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
    },
    {
      title: 'Email Address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Person In Charge',
      dataIndex: 'PIC',
      key: 'PIC',
    },
    {
      title: 'QR code',
      dataIndex: 'QRbase64',
      key: 'QRcode',
      render: (record) => (
        <Button type="primary" onClick={() => openImage(record)}>
          View QR code
        </Button>
      ),
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (data) => (data ? moment(data).format('YYYY-MM-DD HH:mm:ss') : '-'),
      sorter: (a, b) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
    },
  ];

  const token = getToken();
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  const [branchList, setBranchList] = useState([]);
  const [branchListData, setBranchListData] = useState([]);

  const userCookieStr = Cookie.get('user');
  const userData = JSON.parse(userCookieStr);
  const merchantUserID = userData.id;

  const [exportModalActive, setExportModalActive] = useState(false);

  const [filterForm] = Form.useForm();

  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      list.push({
        key: data[i].id,
        branchName: data[i].branchName,
        email: data[i].branchEmail,
        phoneNumber: data[i].branchContactNum,
        PIC: data[i].PIC,
        QRbase64: data[i].QRbase64,
        createdDate: data[i].createdAt,
      });
    }
    setBranchListData(list);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleOk = useCallback(() => {
    let selectedRows = [];
  
    if (rowSelection.selectedRowKeys.length === 0) {
      // Export all rows when no rows are selected
      selectedRows = branchListData.map(({ key, QRbase64, ...rest }) => rest);
    } else {
      // Export selected rows
      selectedRows = branchListData
        .filter((row) => rowSelection.selectedRowKeys.includes(row.key))
        .map(({ key, QRbase64, ...rest }) => rest);
    }

    // Rename column headers
    const renamedColumns = {
      branchName: 'Branch Name',
      email: 'Branch Email',
      phoneNumber: 'Branch Contact Number',
      PIC: 'Person in Charge',
      createdDate: 'Created At',
    };

    const transformedRows = selectedRows.map((row) => (
      Object.entries(row).reduce((acc, [key, value]) => {
        const renamedKey = renamedColumns[key] || key;
        if (key === 'createdDate') {
          const date = new Date(value);
          const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Asia/Kuala_Lumpur',
          };
          return { ...acc, [renamedKey]: date.toLocaleString('en-US', options) };
        }
        return { ...acc, [renamedKey]: value };
      }, {})));  
  
    const currentDate = new Date();
    const formattedDate = currentDate
      .toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(/[/:\s]/g, '_');
  
    const ws = utils.json_to_sheet(transformedRows);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    writeFileXLSX(wb, `Branch_${formattedDate}.xlsx`);

    setExportModalActive(false);
  }, [branchListData, rowSelection.selectedRowKeys]);

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      try {
        const merchantIdResponse = await axios
          .get(`merchants/getMerchantId/${merchantUserID}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
          })
          .catch((error) => {
            message.error(`Failed to retrieve merchant data. ${error}`);
          });

        const merchantId = merchantIdResponse.data;
        const params = {
          merchantId,
          limit,
          page,
          ...filterData,
        };

        await axios
          .get('branch', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
            params,
          })
          .then((res) => {
            setBranchList(res.data.results);
            setTotalData(res.data.totalResults);
            setLoading(false);
          })
          .catch((error) => {
            message.error(`Failed to retrieve branch data. ${error}`);
            setLoading(false);
          });
      } catch (error) {
        message.error('Failed to retrieve merchant data.');
      }
    };

    getData();
  }, [limit, page, filterData]);

  useEffect(() => {
    if (loading === false) {
      rerenderList(branchList);
    }
  }, [loading, branchList]);

  const showModal = () => {
    setExportModalActive(true);
  };
  // const handleOk = () => {
  //   setExportModalActive(false);
  // };
  const handleCancel = () => {
    setExportModalActive(false);
    setQRModalActive(false);
    setQrString('');
  };

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/branches">Branches</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const [isFilterUpdated, setIsFilterUpdated] = useState(false);

  const onFilter = () => {
    filterForm
      .validateFields()
      .then((res) => {
        const { branchName, contact, PIC } = res;

        const items = {};
        if (branchName !== '') {
          items.branchName = branchName;
        }
        if (contact !== '') {
          items.branchContactNum = contact;
        }
        if (PIC !== '') {
          items.PIC = PIC;
        }

        setFilterData(items);
        setIsFilterUpdated(true);
      })
      .catch(() => {});
  };

  const clearFilter = () => {
    filterForm.resetFields();
    onFilter();
    setTimeout(() => {
      setIsFilterUpdated(false);
    }, 0);
  };

  const FilterRow = () => (
    <Form form={filterForm}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={12} lg={8}>
          <Row>
            <p>Branch</p>
          </Row>
          <Row>
            <Form.Item name="branchName" style={{ width: '100%' }}>
              <Input placeholder="Branch" />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={8}>
          <Row>
            <p>Contact Number</p>
          </Row>
          <Row>
            <Form.Item name="contact" style={{ width: '100%' }}>
              <Input placeholder="Contact Number" />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={8}>
          <Row>
            <p>Person In Charge</p>
          </Row>
          <Row>
            <Form.Item name="PIC" style={{ width: '100%' }}>
              <Input placeholder="Person in charge" />
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </Form>
  );

  const onChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
  };

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Row justify="end">
            <Col>
              <Button onClick={showModal}>
                <ImportOutlined rotate={-90} />
                Export
              </Button>
              <Modal
                title="Are you sure you want to export branch data?"
                open={exportModalActive}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                closable={false}
                bodyStyle={{ padding: 0 }}
                footer={[
                  <>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button type="primary" onClick={handleOk}>
                      Export
                    </Button>
                  </>,
                ]}
              />
              <Modal
                title={null}
                open={QRModalActive}
                centered
                closable={false}
                onCancel={handleCancel}
                maskClosable
                bodyStyle={{ padding: 0 }}
                footer={null}
                width={600}>
                <div
                  className="content-area"
                  style={{ textAlign: 'center', backgroundColor: 'inherit', padding: '40px 20px' }}>
                  <img src={qrString} alt="Branch QR code" />
                </div>
              </Modal>
            </Col>
          </Row>
          <FilterRow />
          <Row justify="end">
            <Space>
              <Col>
                <Button danger onClick={clearFilter} disabled={!isFilterUpdated}>
                  Reset Filters
                </Button>
              </Col>
              <Col>
                <Button onClick={onFilter}>Apply Filters</Button>
              </Col>
            </Space>
          </Row>
          <div className="table-content" style={{ width: '100%' }}>
            {loading === false ? (
              <Table
                columns={columns}
                pagination={{ pageSize: limit,
                  pageSizeOptions: [20, 50],
                  showSizeChanger: true,
                  total: totalData,
                  onChange,
                  current: page,
                }}
                scroll={{ x: '100%' }}
                onRow={(rowIndex) => ({
                  onClick: (event) => {
                    if (!event.target.closest('.ant-btn')) {
                      history.push(`branches/${rowIndex.key}/branch-detail`);
                    }
                  }, // click row
                })}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                dataSource={branchListData}
                rowClassName="hover-cursorChange"
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </Space>
      </Content>
    </>
  );
}
