import React, { useState, useEffect } from 'react';
import {
  Table,
  Layout,
  Button,
  Row,
  Col,
  Space,
  Tag,
  Modal,
  Statistic,
  Breadcrumb,
  Typography,
  message,
  Skeleton,
  InputNumber,
  Form,
  DatePicker,
} from 'antd';

import moment from 'moment';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Link, Text } = Typography;

const columns = [
  {
    title: 'Stealing Target',
    dataIndex: 'unluck',
    key: 'unluck',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status) => {
      let res;

      switch (status) {
        case 'Success':
          res = <Tag color="green">Success</Tag>;
          break;
        case 'Failed':
          res = <Tag color="red">Failed</Tag>;
          break;
        case 'Failed: No Egg':
          res = <Tag color="purple">Failed: No Egg</Tag>;
          break;
        default:
          res = '';
          break;
      }

      return res;
    },
  },
  {
    title: 'Date & Time',
    dataIndex: 'createdDate',
    key: 'createdDate',
    render: (data) => (data ? moment(data).format('YYYY-MM-DD HH:mm:ss') : '-'),
  },
];

export default function GoblinCfg() {
  const token = getToken();
  const [loading, setLoading] = useState(true);
  const [statsLoading, setStatsLoading] = useState(true);
  const [cfgLoading, setCfgLoading] = useState(true);

  const [goblinLogList, setGoblinLogList] = useState([]);
  const [goblinLogListData, setGoblinLogListData] = useState([]);

  const [goblinStats, setGoblinStats] = useState();
  const [goblinConfig, setGoblinConfig] = useState();

  const [inputValue, setInputValue] = useState();

  const [filterForm] = Form.useForm();

  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      list.push({
        key: data[i].id,
        unluck: data[i].stolenFrom.name
          ? data[i].stolenFrom.name
          : `Player ${data[i].stolenFrom.uid}`,
        status: data[i].status,
        createdDate: data[i].createdAt,
      });
    }
    setGoblinLogListData(list);
  };

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setLoading(true);

    const params = {
      sortBy: 'createdAt:desc',
      limit,
      page,
      ...filterData,
    };

    const getData = async () => {
      await axios
        .get('actionlogs/getGoblinLog', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params,
        })
        .then((res) => {
          setGoblinLogList(res.data.results);
          setTotalData(res.data.totalResults);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, [limit, page, filterData]);

  useEffect(() => {
    const getStats = async () => {
      await axios
        .get('actionlogs/getGoblinStats', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setGoblinStats(res.data);
          setStatsLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve goblin stats data. ${error}`);
          setStatsLoading(false);
        });
    };
    getStats();
  }, []);

  useEffect(() => {
    const getGoblinConfig = async () => {
      await axios
        .get('configs/goblinConfig', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setGoblinConfig(res.data);
          setInputValue(res.data.value * 100);
          setCfgLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve goblin config data. ${error}`);
          setCfgLoading(false);
        });
    };
    getGoblinConfig();
  }, []);

  useEffect(() => {
    if (loading === false) {
      rerenderList(goblinLogList);
    }
  }, [loading, goblinLogList]);

  const [modalActive, setModalActive] = useState(false);
  const showModal = () => {
    setModalActive(true);
  };

  const handleCancel = () => {
    setModalActive(false);
  };

  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  // const rowSelection = {
  //   selectedRowKeys,
  //   onChange: (selectedKeys) => {
  //     setSelectedRowKeys(selectedKeys);
  //   },
  // };

  const handleOk = async () => {
    try {
      const percentage = inputValue / 100;
      await axios.patch(
        'configs/goblinConfig',
        { value: percentage },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        },
      );
      message.success('Goblin steal percentage saved successfully.');
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } catch (error) {
      message.error(`Failed to save goblin data. ${error}`);
    }
  };

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/goblinStats">Goblin Statistics</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const onFilterUpdate = () => {
    setIsFilterUpdated(true);
  };

  const onFilter = () => {
    filterForm
      .validateFields()
      .then((res) => {
        const { date } = res;

        const items = {};
        
        if (date) {
          items.startDate = moment(date[0]).startOf('day').toDate();
          items.endDate = moment(date[1]).endOf('day').toDate();
        }

        setFilterData(items);
        setIsFilterUpdated(true);
      })
      .catch(() => {});
  };

  const clearFilter = () => {
    filterForm.resetFields();
    onFilter();
    setTimeout(() => {
      setIsFilterUpdated(false);
    }, 0);
  };

  const FilterRow = () => (
    <Form form={filterForm}>
      <Row align="bottom" style={{ width: '100%' }}>
        <Col style={{ width: '100%' }}>
          <Row>
            <p>Date</p>
          </Row>
          <Row gutter={{ xs: 8, sm: 8, md: 8, lg: 8 }} style={{ width: '100%' }}>
            <Col>
              <Form.Item name="date" style={{ width: '100%' }}>
                <RangePicker style={{ width: '100%' }} onChange={onFilterUpdate} />
              </Form.Item>
            </Col>
            <Col>
              <Button danger onClick={clearFilter} disabled={!isFilterUpdated}>
                Reset Filters
              </Button>
            </Col>
            <Col>
              <Button onClick={onFilter}>Apply Filters</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );

  const onChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
  };

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <div className="content-area" style={{ margin: '20px 0px', backgroundColor: 'white' }}>
            <Row>
              {statsLoading === false ? (
                <>
                  <Col xs={12} lg={8}>
                    <Statistic title="Total Steal Attempt" value={goblinStats.stealAttempt} />
                  </Col>
                  <Col xs={12} lg={8}>
                    <Statistic title="Total Success Steal" value={goblinStats.stealSuccess} />
                  </Col>
                  <Col xs={12} lg={8}>
                    <Statistic title="Total Failed Steal" value={goblinStats.stealFail} />
                  </Col>
                </>
              ) : (
                <Skeleton />
              )}
            </Row>
          </div>
          <FilterRow />
          <Row justify="end">
            <Space>
              {cfgLoading === false ? (
                <>
                  <Col>
                    <Text style={{ color: 'black' }}>Currently Stealing From <strong>{goblinConfig.value * 100} %</strong> of Active Players</Text>
                  </Col>
                  <Col>
                    <Button onClick={showModal}>Change</Button>
                  </Col>
                  <Modal
                    title="Change percentage"
                    open={modalActive}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    centered
                    closable={false}
                  >
                    <div>
                      Goblin will steal from{' '}
                      <InputNumber 
                        defaultValue={inputValue} 
                        min={0} 
                        max={100} 
                        style={{ width: 70 }}
                        onChange={(value) => setInputValue(value)}
                      /> % of
                      active players every 2 hours.
                    </div>
                    <div>
                      *Settings will be applied on the next steal attempt.
                    </div>
                  </Modal>
                </>
              ) : (
                <Skeleton />
              )}
            </Space>
          </Row>
          <div className="table-content">
            {loading === false ? (
              <Table
                columns={columns}
                dataSource={goblinLogListData}
                pagination={{
                  pageSize: limit,
                  pageSizeOptions: [20, 50, 100],
                  showSizeChanger: true,
                  total: totalData,
                  onChange,
                  current: page,
                }}
                scroll={{ x: 1300 }}
                // rowSelection={{
                //   type: 'checkbox',
                //   ...rowSelection,
                // }}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </Space>
      </Content>
    </>
  );
}
