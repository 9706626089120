import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form,
  Table,
  Col,
  Layout,
  Row,
  Space,
  Button,
  Typography,
  Breadcrumb,
  Input,
  Select,
  Statistic,
  Skeleton,
  DatePicker,
} from 'antd';
import { PlusOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import axios from 'axios';

const { Content } = Layout;
const { Title, Link } = Typography;
const { RangePicker } = DatePicker;

// 奖励来源
const HEECOIN_HISTORY_SOURCE = {
  MiniGameScoreConversion: 'Game Score Conversion',
  EggReward: 'Egg Reward', // 蛋孵化奖励
  CardpackReward: 'Cardpack Reward', // 卡包集齐奖励
  MiniGameReward: 'Game Reward', // 小游戏排行奖励
  TutorialReward: 'Tutorial Reward', // 新手教程奖励
};

export function HeecoinHistoryPage() {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const [totalData, setTotalData] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [filterForm] = Form.useForm();
  const [totalHeeCoin, setHeeCoin] = useState(0);
 
  const getTotalHeeCoin = async (params) => {
    const res = await axios
      .get('/heecoinHistory/getTotalAbtainHeeCoinFromHistory', {
        params,
      });
    setHeeCoin(res.data.total || 0);
  };
  
  const getTableData = async (pageNum, pageSize) => {
    const { date = [], playerUid, source } = filterForm.getFieldsValue();
    const params = {
      playerUid,
      startDate: date[0] ? date[0].format('YYYY-MM-DD') : null,
      endDate: date[1] ? date[1].format('YYYY-MM-DD') : null,
      source,
 
    };
    
    try {
      setLoading(true);
      const res = await axios
        .get('/heecoinHistory/queryHeeCoinHistory', {
          params: {
            ...params,
            limit: pageSize,
            page: pageNum,
          },
        });
    
      setTableData(res.data.results);
      setTotalData(res.data.totalResults);

      getTotalHeeCoin(params);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    setPage(1);

    getTableData(1, limit);
  };

  const onTablePageChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);

    getTableData(current, pageSize);
  };
  
  useEffect(() => { handleSearch(); }, []);

  const columns = [

    {
      title: 'Pet Name',
      key: 'Pet Name',
      width: 150,
      render: (_data, row) => {
        return row.pet?.name || '-';
      },
    },
    {
      title: 'Pet Type',
      key: 'Pet Type',
      width: 150,
      render: (_data, row) => {
        return row.pet?.type || '-';
      },
    },
    {
      title: 'Player UID',
      key: 'playerId',
      width: 150,
      render: (data, row) => {
        return row.player?.uid || '-';
      },
    },
    
    {
      title: 'HeeCoin',
      dataIndex: 'heeCoin',
      key: 'heeCoin',
      width: 150,
    },

    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      width: 200,
      render: (_data, row) => {
        return HEECOIN_HISTORY_SOURCE[row.source];
      },
    },
    
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (data) => {
        return moment(data).format('YYYY-MM-DD HH:mm:ss');
      },
    },
 
  ];
  const clearFilter = () => {
    filterForm.resetFields();
    handleSearch();
  };

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/heecoin-history">HeeCoin History</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
  return (
    <>
      <DisplayBreadCrumb />

      <Content className="layout-background">
    
        <Form form={filterForm} layout="inline" style={{ marginTop: '10px' }}>
          <Form.Item name="playerUid" label="Player Uid">
            <Input placeholder="input" style={{ width: '250px' }} />
          </Form.Item>

          <Form.Item name="source" label="Source">
            <Select placeholder="select" style={{ width: '250px' }} allowClear>
              {Object.keys(HEECOIN_HISTORY_SOURCE).map((item) => (
                <Select.Option key={item} value={item}>
                  {HEECOIN_HISTORY_SOURCE[item]}
                </Select.Option>
              ))}
       
            </Select>
          </Form.Item>

          <Form.Item name="date" label="Date">
            <RangePicker style={{ width: '100%' }} />
          </Form.Item>

          <Form.Item>
            <Space>
              <Col>
                <Button danger onClick={() => clearFilter()}>
                  Reset Filters
                </Button>
              </Col>
              <Col>
                <Button onClick={() => handleSearch()}>Apply Filters</Button>
              </Col>
            </Space>
          </Form.Item>
        </Form>
        
        <div style={{ fontSize: '16px', marginTop: '10px', textAlign: 'right' }}>
          Total: <span style={{ color: '#1890FF' }}> {totalHeeCoin}</span>
        </div>
        <Space direction="vertical" style={{ display: 'flex' }}>
    
          <div className="table-content">
            {loading === false ? (
              <Table
                columns={columns}
                dataSource={tableData}
                rowKey="_id"
                scroll={{ x: '100%' }}
                pagination={{ pageSize: limit,
                  pageSizeOptions: [10, 20, 50],
                  showSizeChanger: true,
                  total: totalData,
                  onChange: onTablePageChange,
                  current: page,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </Space>
      </Content>
    </>
  );
}
