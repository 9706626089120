import React, { useEffect, useState } from 'react';
import { Form, Input, Select, Row, Col, Tooltip } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

const { TextArea } = Input;
const { Option } = Select;

export default function UserDetailForm({ form, data }) {
  const [isMerchant, setIsMerchant] = useState(false);

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        userFullName: data.name,
        userEmail: data.email,
        userPhone: data.phone,
        userRole: data.role,
        remarks: data.remarks,
      });
      if (data.role === 'merchantHQ' || data.role === 'merchantBranch') {
        setIsMerchant(true);
      }
    }
  }, [form, data]);

  const generatePassword = () => {
    const letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const numbers = '0123456789';
    const chars = letters + numbers;

    // at least 1 num + char
    const randomLetter = letters.charAt(Math.floor(Math.random() * letters.length));
    const randomNumber = numbers.charAt(Math.floor(Math.random() * numbers.length));

    let randomPass = randomLetter + randomNumber;
    for (let i = 0; i < 8; i++) {
      randomPass += chars.charAt(Math.floor(Math.random() * chars.length));
    }

    // shuffle string
    randomPass = randomPass.split('').sort(() => Math.random() - 0.5).join('');

    form.setFieldsValue({ password: randomPass });
  };

  return (
    <Row gutter={16}>
      <Col xs={24} lg={16}>
        <div className="content-area">
          <Form form={form} layout="vertical">
            <Form.Item
              name="userFullName"
              label="Full Name"
              rules={[
                {
                  required: true,
                  message: 'Please enter user\'s name!',
                },
              ]}>
              <Input placeholder="Name" />
            </Form.Item>
            <Form.Item
              name="userEmail"
              label="Email"
              rules={[
                {
                  required: true,
                  message: 'Please enter user email!',
                },
                {
                  type: 'email',
                  message: 'Input must be a valid email',
                },
              ]}>
              <Input placeholder="User Email" />
            </Form.Item>
            <Form.Item
              name="userPhone"
              label="Phone Number"
              rules={[
                {
                  required: true,
                  message: 'Please enter phone number!',
                },
                {
                  pattern: /^(\+60\d{9,10}|01\d{8,9})$/,
                  message: "Phone number format must be '+60xxxxxxxxxx' or '01xxxxxxxxx'.",
                },
              ]}>
              <Input placeholder="Phone Number" type="tel" />
            </Form.Item>
            <Form.Item
              name="userRole"
              label="Role"
              rules={[
                {
                  required: true,
                  message: 'Please select role!',
                },
              ]}>
              <Select placeholder="Select Role" disabled={isMerchant}>
                <Option value="admin">Admin</Option>
                <Option value="operation">Operator</Option>
                <Option value="finance">Finance</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="password"
              rules={data ? null : [{ required: true }]}
              label={(
                <Row
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                  }}>
                  <Col>{data ? 'Password (enter only if change required)' : 'Password'}</Col>
                  <Col style={{ paddingLeft: '10px' }}>
                    <Tooltip title="Generate Random String Password">
                      <ReloadOutlined onClick={generatePassword} />
                    </Tooltip>
                  </Col>
                </Row>
              )}>
              <Input.Password placeholder="Enter password" />
            </Form.Item>
            <Form.Item name="remarks" label="Remarks">
              <TextArea placeholder="Write your description here..." />
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
}
