import React, { useState, useEffect } from 'react';
import {
  Typography,
  Layout,
  Row,
  Col,
  Button,
  Space,
  message,
  Skeleton,
  Form,
  Breadcrumb,
  Input,
  InputNumber,
  Select,
} from 'antd';
import { CheckOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

const { Title, Link } = Typography;
const { Content } = Layout;

export default function EditTreasureHunt() {
  const [form] = Form.useForm();
  const token = getToken();
  const [loading, setLoading] = useState(true);

  const treasureHuntId = window.location.href.split('/')[4];
  const [itemList, setItemList] = useState([]);

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      await axios
        .get(`treasureHunts/${treasureHuntId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          form.setFieldsValue({
            tHuntName: res.data.name,
            food: res.data.food,
            health: res.data.health,
            duration: res.data.duration,
            gameCoinReward: res.data.gameCoinReward,
            itemReward: res.data.itemRewards,
          });
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve treasure hunt data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const foodRes = await axios.get('items?type=Food', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        });

        const healthRes = await axios.get('items?type=Health', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        });

        const equipRes = await axios.get('items?type=Equipment', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        });

        const foodList = foodRes.data.results;
        const healthList = healthRes.data.results;
        const equipList = equipRes.data.results;
        const combine = [...foodList, ...healthList, ...equipList];

        setItemList(combine);
      } catch (error) {
        message.error(`Failed to retrieve item data. ${error}`);
      }
    };
    getData();
  }, []);

  function getItemList(items) {
    const list = [];
    for (let i = 0; i < items.length; i++) {
      list.push({ value: items[i].id, label: `${items[i].type} - ${items[i].name}` });
    }

    return list;
  }

  const handleSave = async () => {
    try {
      const formData = form.getFieldsValue();

      const dataPackage = {
        name: formData.tHuntName,
        food: formData.food,
        health: formData.health,
        duration: formData.duration,
        gameCoinReward: formData.gameCoinReward,
        itemRewards: formData.itemReward,
      };

      if (formData.itemReward.length === 0) {
        message.error('Item rewards cannnot be empty!');
        return;
      }

      await axios.patch(`treasureHunts/${treasureHuntId}`, dataPackage, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      message.success('Treasure hunt data saved successfully.');

      setTimeout(() => {
        window.location.href = '/treasurehunt';
      }, 1500);
    } catch (error) {
      message.error(`Failed to save treasure hunt data. ${error}`);
    }
  };

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/treasurehunt">
            <LeftOutlined />
            Treasure Hunt
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Treasure Hunt</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Row>
          <Col flex={4}>
            <Title
              level={3}
              style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '20px' }}>
              Edit Treasure Hunt
            </Title>
          </Col>
          <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              <Button type="primary" onClick={handleSave}>
                <CheckOutlined />
                Save
              </Button>
            </Space>
          </Col>
        </Row>
        {loading === false ? (
          <Row>
            <Col xs={24} lg={15}>
              <div className="content-area">
                <Form form={form} layout="vertical">
                  <Form.Item
                    name="tHuntName"
                    label="Treasure Hunt Location Name"
                    rules={[
                      {
                        required: true,
                        message: 'Treasure hunt location name cannot be empty!',
                      },
                    ]}>
                    <Input placeholder="Enter Treasure Hunt Name" disabled style={{ width: '40%' }} />
                  </Form.Item>
                  <Form.Item
                    name="food"
                    label="Satiety Cost"
                    rules={[
                      {
                        required: true,
                        message: 'Food cost cannot be empty!',
                      },
                    ]}>
                    <InputNumber min={0} placeholder="Enter Satiety Cost" disabled style={{ width: '20%' }} />
                  </Form.Item>
                  <Form.Item
                    name="health"
                    label="Health Cost"
                    rules={[
                      {
                        required: true,
                        message: 'Health cost cannot be empty!',
                      },
                    ]}>
                    <InputNumber min={0} placeholder="Enter Health Cost" disabled style={{ width: '20%' }} />
                  </Form.Item>
                  <Form.Item
                    name="duration"
                    label="Duration (in seconds)"
                    rules={[
                      {
                        required: true,
                        message: 'Duration cannot be empty!',
                      },
                    ]}>
                    <InputNumber min={0} placeholder="Enter Duration" disabled style={{ width: '20%' }} />
                  </Form.Item>
                  <Form.Item
                    name="gameCoinReward"
                    label="Game coin reward amount"
                    rules={[
                      {
                        required: true,
                        message: 'Game coin reward cannot be empty!',
                      },
                    ]}>
                    <InputNumber min={0} placeholder="Enter Game Coin Reward" disabled style={{ width: '20%' }} />
                  </Form.Item>
                  <Form.Item
                    name="itemReward"
                    label="Item Reward Pool"
                    rules={[
                      {
                        required: true,
                        message: 'Please select item reward!',
                      },
                    ]}>
                    <Select 
                      mode="multiple"
                      allowClear
                      placeholder="Select Item Reward" 
                      options={getItemList(itemList)} 
                      style={{ width: '70%' }}
                   />
                  </Form.Item>
                </Form>
              </div>
            </Col>
          </Row>
        ) : (
          <Skeleton />
        )}
      </Content>
    </>
  );
}
