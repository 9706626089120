import React, { useState, useEffect } from 'react';
import {
  Typography,
  Layout,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Breadcrumb,
  Tooltip,
  Form,
  Skeleton,
  message,
} from 'antd';
import { DeleteOutlined, CheckOutlined, CloseOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

import GameItemForm from '../../components/gameStore/GameItemForm';

const { Title, Link } = Typography;
const { Content } = Layout;

export default function EditGameItem() {
  const [form] = Form.useForm();
  const token = getToken();
  const [loading, setLoading] = useState(true);
  const gameItemID = window.location.href.split('/')[4];
  const [gameItemData, setGameItemData] = useState({});
  const [promoItems, setPromoItems] = useState([]);

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      await axios
        .get(`items/${gameItemID}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setGameItemData(res.data);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve game item data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, []);

  const handleStatusUpdate = async () => {
    try {
      const formData = form.getFieldsValue();

      const dataPackage = {
        name: formData.gameItemName,
        nameCN: formData.gameItemNameCN,
        price: formData.gameItemPrice,
        largeIconUrl: formData.gameItemLargeImage || formData.bannerImage,
        smallIconUrl: formData.gameItemSmallImage || formData.bannerImage,
        currency: formData.gameItemCurrency,
        group: formData.gameItemGroup,
        type: formData.gameItemCategory,
        effect: formData.effect,
        description: formData.descriptions,
        promoItems,
      };
      dataPackage.status = gameItemData.status === 'published' ? 'unpublished' : 'published';
      await axios.patch(`items/${gameItemID}`, dataPackage, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      message.success('Game item data and status updated successfully.');

      setTimeout(() => {
        window.location.href = '/gamestore';
      }, 1000);
    } catch (error) {
      message.error(`Failed to save game item data. ${error}`);
    }
  };

  const handleSave = async () => {
    try {
      const formData = form.getFieldsValue();

      const dataPackage = {
        name: formData.gameItemName,
        nameCN: formData.gameItemNameCN,
        price: formData.gameItemPrice,
        largeIconUrl: formData.gameItemLargeImage,
        smallIconUrl: formData.gameItemSmallImage,
        currency: formData.gameItemCurrency,
        group: formData.gameItemGroup,
        type: formData.gameItemCategory,
        effect: formData.effect,
        description: formData.descriptions,
        promoItems,
      };
      if (formData.gameItemCategory === 'Promotion') {
        dataPackage.largeIconUrl = formData.bannerImage;
        dataPackage.smallIconUrl = formData.bannerImage;
      }
      
      await axios.patch(`items/${gameItemID}`, dataPackage, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      message.success('Game item data saved successfully.');

      setTimeout(() => {
        window.location.href = '/gamestore';
      }, 1000);
    } catch (error) {
      message.error(`Failed to save game store data. ${error}`);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.put(`items/${gameItemID}/archive`, null, {
        headers: {
          accept: '*/*',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      message.success('Game item deleted successfully.');

      setTimeout(() => {
        window.location.href = '/gamestore';
      }, 1000);
    } catch (error) {
      message.error(`Failed to delete game item. ${error}`);
    }
  };

  const [modalActive, setModalActive] = useState(false);
  const showModal = () => {
    setModalActive(true);
  };
  const handleCancel = () => {
    setModalActive(false);
  };

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/gamestore">
            <LeftOutlined />
            Game Store
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Game Item</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Row>
          <Col flex={4}>
            <Title
              level={3}
              style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '20px' }}>
              Edit Game Item
            </Title>
          </Col>
          <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              <Button danger onClick={showModal}>
                <DeleteOutlined />
                Delete
              </Button>
              <Modal
                title="Are you sure you want to delete?"
                open={modalActive}
                onOk={handleDelete}
                onCancel={handleCancel}
                centered
                closable={false}
                footer={[
                  <>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button type="primary" onClick={handleDelete} danger>
                      <DeleteOutlined />
                      Yes, Delete
                    </Button>
                  </>,
                ]}>
                If you confirm this action, you are unable to undo.
              </Modal>
              {gameItemData.status === 'published' ? (
                <Tooltip title="Save changes and unpublish item.">
                  <Button onClick={handleStatusUpdate}>
                    <CloseOutlined />
                    Unpublish
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title="Save changes and publish item." placement="topRight">
                  <Button onClick={handleStatusUpdate}>
                    <CheckOutlined />
                    Publish
                  </Button>
                </Tooltip>
              )}
              <Tooltip title="Save current changes.">
                <Button type="primary" onClick={handleSave}>
                  <CheckOutlined />
                  Save
                </Button>
              </Tooltip>
            </Space>
          </Col>
        </Row>
        {loading === false ? (
          <GameItemForm form={form} data={gameItemData} setPromoItems={setPromoItems} />
        ) : (
          <Skeleton />
        )}
      </Content>
    </>
  );
}
