import React, { useState, useEffect } from 'react';
import {
  Typography,
  Layout,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Breadcrumb,
  Form,
  Skeleton,
  message,
} from 'antd';
import { DeleteOutlined, CheckOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { CardpackDetailForm } from './component/cardpackDetailFormV2';

export function EditCardpackPage(props) {
  const history = useHistory();
  const curId = props.match.params.id;
 
  const [loading, setLoading] = useState();
  const [curData, setCurData] = useState();

  async function initData() {
    if (curId) {
      try {
        setLoading(true);
        const res = await axios.get(`/cardpack/getCardpackById/${curId}`);
  
        setCurData(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    initData();
  }, []);

  const handleSubmit = async (formData) => {
    try {
      await axios.post(`/cardpack/updateCardpack/${curId}`, formData);
      message.success('save successfully.');

      setTimeout(() => {
        history.push('/cardpackConfigListPage');
      }, 1000);
    } catch (error) {
      message.error(`Failed to save. ${error}`);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.delete(`/cardpack/deleteCardpackById/${curId}`);
      message.success('deleted successfully.');

      setTimeout(() => {
        history.push('/cardpackConfigListPage');
      }, 1000);
    } catch (error) {
      message.error(`Failed to delete. ${error}`);
    }
  };

  const onStatusUpdate = async (formData) => {
    try {
      await axios.post(`/cardpack/updateCardpack/${curId}`, {
        ...formData,
        status: curData.status === 'unpublished' ? 'published' : 'unpublished',
      });
      message.success('save successfully.');

      setTimeout(() => {
        history.push('/cardpackConfigListPage');
      }, 1000);
    } catch (error) {
      message.error(`Failed to save. ${error}`);
    }
  };

  return (
    <>
      <CardpackDetailForm
        isCreate={false}
        onSubmit={handleSubmit}
        onDelete={handleDelete}
        onStatusUpdate={onStatusUpdate}
        editData={curData}
        loading={loading} />
     
    </>
  );
}
