import React, { useEffect, useState } from 'react';
import {
  Form,
  Tooltip,
  Input,
  message,
  Row,
  Col,
  Modal,
  InputNumber,
  Breadcrumb,
  Skeleton,
  Button,
  Space,
  Tabs,
  DatePicker,
  Typography,
  Layout,
} from 'antd';
import { InfoCircleOutlined, DeleteOutlined, CheckOutlined, LeftOutlined } from '@ant-design/icons';
import moment from 'moment';
import { GamePrize } from './GamePrize';
import { RankPrizeList } from './RankPrizeList';

const { Title, Link } = Typography;
const { Content } = Layout;

export function MiniGameConfigForm({ isCreate, onSubmit, editData, loading, onDelete }) {
  const [form] = Form.useForm();

  const [bubbleCrushIslandForm] = Form.useForm();

  const [planetHitForm] = Form.useForm();
  const [monkeyJumpForm] = Form.useForm();

  const tabItems = [
    {
      key: 0,
      label: 'Bubble Crush Island',
      children: (
        <Form form={bubbleCrushIslandForm}>
          <Form.Item
            name="perScore"
            label={
              (
                <Row>
                  <Col>perScore</Col>
                  <Col style={{ paddingLeft: '10px' }}>
                    <Tooltip title="Game score by eliminating an item.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                </Row>
              )
            }
            rules={[
              {
                required: true,
                message: 'Please enter!',
              },
            ]}>
            <InputNumber
              min={0}
              placeholder="0"
              style={{
                width: '120px',
              }} />
          </Form.Item>

          <Form.Item
            name="stepTime"
            label={
              (
                <Row>
                  <Col>stepTime</Col>
                  <Col style={{ paddingLeft: '10px' }}>
                    <Tooltip title="The time for each step.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                </Row>
              )
            }
            rules={[
              {
                required: true,
                message: 'Please enter!',
              },
            ]}>
            <InputNumber
              min={0}
              placeholder="0"
              style={{
                width: '120px',
              }} />
          </Form.Item>

          <Form.Item
            name="decayTime"
            label={
              (
                <Row>
                  <Col>decayTime</Col>
                  <Col style={{ paddingLeft: '10px' }}>
                    <Tooltip title="decayTime.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                </Row>
              )
            }
            rules={[
              {
                required: true,
                message: 'Please enter!',
              },
            ]}>
            <InputNumber
              min={0}
              placeholder="0"
              style={{
                width: '120px',
              }} />
          </Form.Item>
   
        </Form>
      ),
    },

    {
      key: 1,
      label: 'MiniGame_PlanetHit',
      children: (
        <Form form={planetHitForm}>
          <Form.Item
            name="perScore"
            label={
              (
                <Row>
                  <Col>perScore</Col>
                  <Col style={{ paddingLeft: '10px' }}>
                    <Tooltip title="Game score by eliminating an item.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                </Row>
              )
            }
            rules={[
              {
                required: true,
                message: 'Please enter!',
              },
            ]}>
            <InputNumber
              min={0}
              placeholder="0"
              style={{
                width: '120px',
              }} />
          </Form.Item>

          <Form.Item
            name="stepTime"
            label={
              (
                <Row>
                  <Col>stepTime</Col>
                  <Col style={{ paddingLeft: '10px' }}>
                    <Tooltip title="The time for each step.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                </Row>
              )
            }
            rules={[
              {
                required: true,
                message: 'Please enter!',
              },
            ]}>
            <InputNumber
              min={0}
              placeholder="0"
              style={{
                width: '120px',
              }} />
          </Form.Item>

          <Form.Item
            name="decayTime"
            label={
              (
                <Row>
                  <Col>decayTime</Col>
                  <Col style={{ paddingLeft: '10px' }}>
                    <Tooltip title="decayTime.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                </Row>
              )
            }
            rules={[
              {
                required: true,
                message: 'Please enter!',
              },
            ]}>
            <InputNumber
              min={0}
              placeholder="0"
              style={{
                width: '120px',
              }} />
          </Form.Item>

          <Form.Item
            name="hopeScore"
            label={
              (
                <Row>
                  <Col>hopeScore</Col>
                  <Col style={{ paddingLeft: '10px' }}>
                    <Tooltip title="Score for eating diamonds.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                </Row>
              )
            }
            rules={[
              {
                required: true,
                message: 'Please enter!',
              },
            ]}>
            <InputNumber
              min={0}
              placeholder="0"
              style={{
                width: '120px',
              }} />
          </Form.Item>
          
          <Form.Item
            name="winScore"
            label={
              (
                <Row>
                  <Col>winScore</Col>
                  <Col style={{ paddingLeft: '10px' }}>
                    <Tooltip title="Victory bonus points.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                </Row>
              )
            }
            rules={[
              {
                required: true,
                message: 'Please enter!',
              },
            ]}>
            <InputNumber
              min={0}
              placeholder="0"
              style={{
                width: '120px',
              }} />
          </Form.Item>

          <Form.Item
            name="knifeAmount"
            label={
              (
                <Row>
                  <Col>knifeAmount</Col>
                  <Col style={{ paddingLeft: '10px' }}>
                    <Tooltip title="The number of flying knives ranges from 1 to 17.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                </Row>
              )
            }
            rules={[
              {
                required: true,
                message: 'Please enter!',
              },
            ]}>
            <InputNumber
              min={1}
              max={17}
              placeholder="0"
              style={{
                width: '120px',
              }} />
          </Form.Item>
          
          <Form.Item
            name="rotateSpeed"
            label={
              (
                <Row>
                  <Col>rotateSpeed</Col>
                  <Col style={{ paddingLeft: '10px' }}>
                    <Tooltip title="rotary speed.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                </Row>
              )
            }
            rules={[
              {
                required: true,
                message: 'Please enter!',
              },
            ]}>
            <InputNumber
              min={1}
              placeholder="0"
              style={{
                width: '120px',
              }} />
          </Form.Item>
        </Form>
      ),
    },

    {
      key: 2,
      label: ' MiniGame_MonkeyJump',
      children: (
        <Form form={monkeyJumpForm}>
          <Form.Item
            name="perScore"
            label={
              (
                <Row>
                  <Col>perScore</Col>
                  <Col style={{ paddingLeft: '10px' }}>
                    <Tooltip title="Game score by eliminating an item.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                </Row>
              )
            }
            rules={[
              {
                required: true,
                message: 'Please enter!',
              },
            ]}>
            <InputNumber
              min={0}
              placeholder="0"
              style={{
                width: '120px',
              }} />
          </Form.Item>

          <Form.Item
            name="stepTime"
            label={
              (
                <Row>
                  <Col>stepTime</Col>
                  <Col style={{ paddingLeft: '10px' }}>
                    <Tooltip title="The time for each step.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                </Row>
              )
            }
            rules={[
              {
                required: true,
                message: 'Please enter!',
              },
            ]}>
            <InputNumber
              min={0}
              placeholder="0"
              style={{
                width: '120px',
              }} />
          </Form.Item>

          <Form.Item
            name="decayTime"
            label={
              (
                <Row>
                  <Col>decayTime</Col>
                  <Col style={{ paddingLeft: '10px' }}>
                    <Tooltip title="decayTime.">
                      <InfoCircleOutlined />
                    </Tooltip>
                  </Col>
                </Row>
              )
            }
            rules={[
              {
                required: true,
                message: 'Please enter!',
              },
            ]}>
            <InputNumber
              min={0}
              placeholder="0"
              style={{
                width: '120px',
              }} />
          </Form.Item>
   
        </Form>
      ),
    },
   
  ];

  const handleSubmit = async () => {
    await form.validateFields();
    await bubbleCrushIslandForm.validateFields();
    const formData = form.getFieldsValue();
    const bubbleCrushIslandData = bubbleCrushIslandForm.getFieldsValue();
    const planetHitData = planetHitForm.getFieldsValue();
    const monkeyJumpData = monkeyJumpForm.getFieldsValue();

    const dataPackage = {
      ...formData,
      gameConfig: {
        MiniGame_BubbleCrushIsland: bubbleCrushIslandData,
        MiniGame_MonkeyJump: monkeyJumpData,
        MiniGame_PlanetHit: planetHitData,
      },
    };

    onSubmit(dataPackage);
  };

  const initData = () => {
    if (!isCreate && editData) {
      form.setFieldsValue(editData);

      bubbleCrushIslandForm.setFieldsValue(editData.gameConfig.MiniGame_BubbleCrushIsland);

      planetHitForm.setFieldsValue(editData.gameConfig.MiniGame_PlanetHit);
      monkeyJumpForm.setFieldsValue(editData.gameConfig.MiniGame_MonkeyJump);
    }
  };
  useEffect(() => { initData(); }, [editData]);

  return (

    <>

      <Content className="layout-background">
        <Row>
          <Col flex={4}>
            <Title
              level={3}
              style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '20px' }}>
              Game Config
            </Title>
          </Col>
          <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>

              <Button type="primary" onClick={handleSubmit}>
                <CheckOutlined />
                Submit
              </Button>
            </Space>
          </Col>
        </Row>
        {loading === false ? (
          <Row>
            <Col xs={24} lg={20}>
              <Form form={form} layout="vertical">
                <div className="content-area">

                  <Row>
                
                    <Col xs={24} lg={6}>
                      <Form.Item
                        name="heeCoinThreshold"
                        label="HeeCoin Threshold"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter!',
                          },
                        ]}>
                        <InputNumber
                          min={0}
                          placeholder="0"
                          style={{
                            width: '120px',
                          }} />
                      </Form.Item>
                    </Col>

                    <Col xs={24} lg={6}>
                      <Form.Item
                        name="hopeCoinConsumption"
                        label="HopeCoin Consumption"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter!',
                          },
                        ]}>
                        <InputNumber
                          min={0}
                          placeholder="0"
                          style={{
                            width: '120px',
                          }} />
                      </Form.Item>
                    </Col>
                  </Row>

                </div>
               
              </Form>

              <div className="content-area">
                <Tabs
                  defaultActiveKey="0"
                  type="card"
                  items={tabItems}
                />

              </div>

              <div className="content-area">

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <h3 style={{ marginBottom: '10px', color: 'rgba(0, 0, 0, 0.85)', textAlign: 'left' }}>Rank Prize List</h3>
                </div>
                <RankPrizeList />
              </div>
            </Col>
          </Row>
        ) : (
          <Skeleton />
        )}
      </Content>
    </>
  );
}
