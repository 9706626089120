import React, { useState, useEffect } from 'react'; // useCallback
import {
  Table,
  Layout,
  Button,
  Row,
  Col,
  Select,
  Space,
  DatePicker,
  Typography,
  //   Modal,
  Input,
  Breadcrumb,
  Skeleton,
  message,
  Form,
  Tag,
  Modal,
  Upload,
  Popover,
  Tooltip,
} from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  PlusOutlined,
  UploadOutlined,
  EditOutlined,
} from '@ant-design/icons';

import moment from 'moment';
import axios from 'axios';
import VoucherTemplate from '../../assets/file/voucher-bulk-upload-template.csv';
import { getToken } from '../../utils/AuthService';

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Text, Link } = Typography;

const adminVoucherCols = [
  {
    title: 'Merchant',
    dataIndex: 'merchantName',
    key: 'merchantName',
    width: 200,
  },
  {
    title: 'Voucher',
    dataIndex: 'voucher',
    key: 'voucher',
    width: 200,
  },
  {
    title: 'Discount Amount',
    dataIndex: 'discountAmount',
    key: 'discountAmount',
    render: (data) => `RM ${data}`,
    width: 150,
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    width: 100,
    align: 'center',
  },
  {
    title: 'Status',
    key: 'status',
    width: 100,
    render: (record) => {
      let res;

      switch (record.status) {
        case 'Approved':
          res = <Tag color="green">Approved</Tag>;
          break;
        case 'Pending':
          res = <Tag color="yellow">Pending Approval</Tag>;
          break;
        case 'Rejected':
          res = (
            <Tooltip title={record.remarks} placement="right">
              <Tag color="red">Rejected</Tag>
            </Tooltip>
          );
          break;
        default:
          res = '';
          break;
      }

      return res;
    },
  },
  {
    title: 'Start Date',
    dataIndex: 'startDate',
    key: 'startDate',
    render: (data) => (data ? moment(data).format('YYYY-MM-DD') : '-'),
    width: 150,
  },
  {
    title: 'End Date',
    dataIndex: 'endDate',
    key: 'endDate',
    render: (data) => (data ? moment(data).format('YYYY-MM-DD') : '-'),
    width: 150,
  },
  //   {
  //     title: 'Action',
  //     key: 'action',
  //     fixed: 'right',
  //     width: 90,
  //     render: (record) => (
  //       <Button type="text" href={`/vouchers/${record.key}/edit`}>
  //         <EditOutlined />
  //       </Button>
  //     ),
  //   },
];

export default function VoucherRequest(props) {
  const { userRole } = props;

  const token = getToken();
  const [loading, setLoading] = useState(true);

  const [voucherList, setVoucherList] = useState([]);

  const [voucherListData, setVoucherListData] = useState([]);

  const [adminFilterForm] = Form.useForm();
  const [merchantFilterForm] = Form.useForm();

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [filterData, setFilterData] = useState({});

  const [merchantList, setMerchantList] = useState([]);
  const [uploadedFileNames, setUploadedFileNames] = useState('');
  const [uploadData, setUploadData] = useState();

  const [selectedRow, setSelectedRow] = useState();
  const [paginationInfo, setPaginationInfo] = useState({ current: 1, pageSize: 4 });

  const [rejectRemark, setRejectRemark] = useState('');

  const handleTableChange = (pagination) => {
    setPaginationInfo(pagination);
  };

  useEffect(() => {
    const getData = async () => {
      await axios
        .get('merchants', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setMerchantList(res.data.results);
        })
        .catch((error) => {
          message.error(`Failed to retrieve merchant data. ${error}`);
        });
    };

    if (userRole === 'admin') {
      getData();
    }
  }, []);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  function getMerchantList(merchantData) {
    const list = [{ value: 'all', label: 'All' }];
    for (let i = 0; i < merchantData.length; i++) {
      list.push({ value: merchantData[i]._id, label: merchantData[i].name });
    }

    return list;
  }

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/voucher-request">Requests</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const onFilterUpdate = () => {
    setIsFilterUpdated(true);
  };

  const AdminFilterRow = () => (
    <Form form={adminFilterForm}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={12} lg={8}>
          <Row>
            <p>Voucher</p>
          </Row>
          <Row>
            <Form.Item name="voucherName" style={{ width: '100%' }}>
              <Input placeholder="Voucher Name" />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={8}>
          <Row>
            <p>Merchant</p>
          </Row>
          <Row>
            <Form.Item name="merchant" style={{ width: '100%' }}>
              <Select
                placeholder="Select Merchant"
                options={getMerchantList(merchantList)}
                onChange={onFilterUpdate}
              />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={8}>
          <Row>
            <p>Effective Date</p>
          </Row>
          <Row>
            <Form.Item name="voucherDate" style={{ width: '100%' }}>
              <RangePicker style={{ width: '100%' }} onChange={onFilterUpdate} />
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </Form>
  );

  const MerchantFilterRow = () => (
    <Form form={merchantFilterForm}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={12} lg={6}>
          <Row>
            <p>Voucher</p>
          </Row>
          <Row>
            <Form.Item name="voucherName" style={{ width: '100%' }}>
              <Input placeholder="Voucher Name" style={{ width: '100%' }} />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={6}>
          <Row>
            <p>Voucher Value</p>
          </Row>
          <Row>
            <Form.Item name="value" style={{ width: '100%' }}>
              <Select
                defaultValue="all"
                style={{ width: '100%' }}
                onChange={onFilterUpdate}
                options={[
                  {
                    value: 'all',
                    label: 'All',
                  },
                  {
                    value: '0to49',
                    label: 'RM 0 - RM 49',
                  },
                  {
                    value: '50to100',
                    label: 'RM 50 - RM 100',
                  },
                ]}
              />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={6}>
          <Row>
            <p>Expired Date</p>
          </Row>
          <Row>
            <Form.Item name="voucherDate" style={{ width: '100%' }}>
              <RangePicker style={{ width: '100%' }} onChange={onFilterUpdate} />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={6}>
          <Row>
            <p>Status</p>
          </Row>
          <Row>
            <Form.Item name="status" style={{ width: '100%' }}>
              <Select
                defaultValue="all"
                style={{ width: '100%' }}
                onChange={onFilterUpdate}
                options={[
                  {
                    value: 'all',
                    label: 'All',
                  },
                  {
                    value: 'Pending',
                    label: 'Pending Approval',
                  },
                  {
                    value: 'Approved',
                    label: 'Approved',
                  },
                  {
                    value: 'Rejected',
                    label: 'Rejected',
                  },
                ]}
              />
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </Form>
  );

  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      const minus3days = moment(data[i].endDate).subtract(3, 'days').toDate();

      list.push({
        key: data[i].id,
        merchantName: data[i].merchant.name,
        voucher: data[i].name,
        discountAmount: data[i].value.toFixed(2),
        quantity: data[i].quantity,
        // voucherCode: data[i].voucherCode,
        availableAmount: data[i].quantity,
        balance: data[i].balance,
        redeemedAmount: data[i].redeemed,
        distributedAmount: data[i].distributed,
        startDate: data[i].startDate,
        endDate: minus3days,
        expiryDate: data[i].endDate,
        status: data[i].status,
        createDate: data[i].createdAt,
        remarks: data[i].remarks,
      });
    }
    setVoucherListData(list);
  };

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      const params = {
        ...filterData,
      };

      await axios
        .get('voucherRequest', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params,
        })
        .then((res) => {
          setVoucherList(res.data.results);
          setTotalData(res.data.totalResults);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve voucher data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, [filterData]);

  useEffect(() => {
    if (loading === false) {
      rerenderList(voucherList);
    }
  }, [loading, voucherList]);

  const adminFilter = () => {
    adminFilterForm
      .validateFields()
      .then((res) => {
        const { voucherName, merchant, voucherDate, status } = res;

        const items = {};

        items.name = voucherName;
        if (merchant !== 'all') {
          items.merchantId = merchant;
        }
        if (status !== 'all') {
          items.status = status;
        }
        if (voucherDate) {
          items.filterStartDate = moment(voucherDate[0]).startOf('day').toDate();
          items.filterEndDate = moment(voucherDate[1]).endOf('day').toDate();
        }

        setFilterData(items);
        setIsFilterUpdated(true);
      })
      .catch(() => {});
  };

  const clearAdminFilter = () => {
    adminFilterForm.resetFields();
    adminFilter();
    setTimeout(() => {
      setIsFilterUpdated(false);
    }, 0);
  };

  const merchantFilter = () => {
    merchantFilterForm
      .validateFields()
      .then((res) => {
        const { voucherName, value, voucherDate, status } = res;

        const items = {};
        items.name = voucherName;
        if (value !== 'all') {
          items.filterValue = value;
        }
        if (status !== 'all') {
          items.status = status;
        }
        if (voucherDate) {
          items.filterStartDate = moment(voucherDate[0]).startOf('day').toDate();
          items.filterEndDate = moment(voucherDate[1]).endOf('day').toDate();
        }

        setFilterData(items);
        setIsFilterUpdated(true);
      })
      .catch(() => {});
  };

  const clearMerchantFilter = () => {
    merchantFilterForm.resetFields();
    merchantFilter();
    setTimeout(() => {
      setIsFilterUpdated(false);
    }, 0);
  };

  const onChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
  };  
  
  const beforeUploadCSV = (file) => {
    const isCSV = file.type === 'text/csv';

    if (!isCSV) {
      message.error('You can only upload CSV file!');
      return Promise.reject(new Error('Invalid file type.'));
    }

    return Promise.resolve(file);
  };

  const uploadProps = {
    name: 'voucherFile',
    action: 'voucherRequest/validate',
    beforeUpload: beforeUploadCSV,
    maxCount: 1,
    onChange(info) {
      const { status, name } = info.file;

      if (!status) {
        console.log('wrong type of file or dimension');
      } else {
        setUploadedFileNames(name);
      }
    },
    customRequest: (options) => {
      const fileData = new FormData();
      fileData.append('csvFile', options.file);
      const config = {
        headers: {
          'Content-type': 'multipart/form-data',
          Authorization: `Bearer ${token.access.token}`,
        },
      };
      axios
        .post(options.action, fileData, config)
        .then((res) => {
          const { data } = res;
          setUploadData(data);

          console.log(data);
        })
        .catch((err) => {
          message.error(`${options.file.name} file upload failed. ${err}`);
        });
    },
  };

  const [importModalActive, setImportModalActive] = useState(false);

  const showImportModal = () => {
    setImportModalActive(true);
  };

  const [approveModalActive, setApproveModalActive] = useState(false);
  const showApproveModal = () => {
    setApproveModalActive(true);
  };

  const [rejectModalActive, setRejectModalActive] = useState(false);
  const showRejectModal = () => {
    setRejectModalActive(true);
  };

  const handleRetry = () => {
    setUploadData(null);
  };

  const handleCancel = () => {
    setImportModalActive(false);
    setApproveModalActive(false);
    setRejectModalActive(false);
    setUploadData(null);
    setRejectRemark('');
  };

  const handleConfirm = async () => {
    await axios
      .post('voucherRequest/bulk-upload', uploadData.vouchersData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.success) {
          setTimeout(() => {
            message.success('Voucher Request uploaded successfully.', 2);
          }, 2);
          clearMerchantFilter();
        } else {
          message.error('Voucher Request upload failed.', 2);
        }

        handleCancel();
      })
      .catch((error) => {
        message.error(`Voucher Request upload failed. ${error}`);
      });
  };

  const handleApprove = async () => {
    let selectedRows = [];

    if (rowSelection.selectedRowKeys.length > 0) {
      selectedRows = voucherListData
        .filter((row) => {
          return rowSelection.selectedRowKeys.includes(row.key);
        })
        .map(({ key }) => key);

      await axios
        .post('voucherRequest/approve', selectedRows, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.success) {
            setTimeout(() => {
              message.success('Vouchers approved and created successfully.', 2);
            }, 2);

            setSelectedRowKeys([]);
            clearMerchantFilter();
          } else {
            message.error('Voucher approval and creation failed.', 2);
          }

          handleCancel();
        })
        .catch((error) => {
          message.error(`Voucher approval and creation failed. ${error}`);
        });
    }

    console.log(selectedRows);
  };
  const handleReject = async () => {
    let selectedRows = [];

    if (rowSelection.selectedRowKeys.length > 0) {
      selectedRows = voucherListData
        .filter((row) => {
          return rowSelection.selectedRowKeys.includes(row.key);
        })
        .map(({ key }) => key);

      const payload = {
        voucherIds: selectedRows,
        remarks: rejectRemark,
      };

      await axios
        .post('voucherRequest/reject', payload, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          const { data } = res;
          if (data.success) {
            setTimeout(() => {
              message.success('Vouchers rejected and deleted successfully.', 2);
            }, 2);

            setSelectedRowKeys([]);
            clearMerchantFilter();
          } else {
            message.error('Voucher rejection and deletion failed.', 2);
          }

          handleCancel();
        })
        .catch((error) => {
          message.error(`Voucher rejection and deletion failed. ${error}`);
        });
    }

    console.log(selectedRows);
  };

  const handleRejectRemarkChange = (e) => {
    setRejectRemark(e.target.value);
  };

  const beforeUpload = (file) => {
    const sizeInMB = file.size / 1024 / 1024;
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return Promise.reject(new Error('Invalid file type.'));
    }

    if (sizeInMB > 2) {
      message.error(`${file.name} exceeds 2MB`);
      return Promise.reject(new Error('File size exceeds 2MB.'));
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          if (img.width === 140 && img.height === 140) {
            resolve(file);
          } else {
            message.error('Image must be 140x140');
            reject(new Error('Invalid image dimensions.'));
          }
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const uploadImageProps = {
    name: 'voucherImage',
    beforeUpload,
    action: 'vouchers/upload-voucher-image',
    maxCount: 1,
    customRequest: (options) => {
      const imageData = new FormData();
      imageData.append('voucherImage', options.file);
      const config = {
        headers: {
          'Content-type': 'multipart/form-data',
          Authorization: `Bearer ${token.access.token}`,
        },
      };
      axios
        .post(options.action, imageData, config)
        .then((res) => {
          const url = res.data.Location;
          console.log(url);
          setUploadData((prevData) => {
            console.log(prevData);
            const newData = [...prevData.vouchersData];
            if (selectedRow >= 0 && selectedRow < newData.length) {
              newData[selectedRow].voucherUrl = url;
            }
            return { vouchersData: newData, success: prevData.success };
          });

          setTimeout(() => {
            message.success(`${options.file.name} file uploaded successfully.`, 2);
          }, 2);
        })
        .catch((err) => {
          message.error(`${options.file.name} file upload failed. ${err}`);
        });
    },
  };

  const uploadedSuccessCols = [
    {
      title: 'Voucher Image',
      key: 'voucherUrl',
      align: 'left',
      width: 100,
      render: (record, _, index) => {
        const defaultUrl = 'https://heewon.s3.ap-southeast-1.amazonaws.com/Vouchers/gift-voucher+(1).png';
        const dataIndex = (paginationInfo.current - 1) * paginationInfo.pageSize + index;

        const handleUploadChange = (info) => {
          const { status, name } = info.file;
          setSelectedRow(dataIndex);

          if (!status) {
            console.log('wrong type of file or dimension');
          } else {
            setUploadedFileNames(name);
          }
        };

        return (
          <>
            {record.voucherUrl && record.voucherUrl !== defaultUrl ? (
              <>
                <div style={{ flex: 'none' }}>
                  <Popover
                    placement="right"
                    content={(
                      <Upload
                        {...uploadImageProps}
                        accept=".jpg, .jpeg, .png"
                        showUploadList={false}
                        onChange={(info) => handleUploadChange(info)}>
                        <Button icon={<EditOutlined />}>Edit</Button>
                      </Upload>
                    )}
                    trigger="hover">
                    <img
                      src={record.voucherUrl}
                      alt="Uploaded logo"
                      style={{ width: 100, height: 'auto' }}
                    />
                  </Popover>
                </div>
                <Button
                  icon={<CloseOutlined />}
                  onClick={() => {
                    const newData = [...uploadData.vouchersData];
                    if (dataIndex >= 0 && dataIndex < newData.length) {
                      newData[dataIndex].voucherUrl = defaultUrl;
                    }
                    setUploadData({ vouchersData: newData, success: uploadData.success });
                  }}
                  style={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    marginTop: '10px',
                    marginRight: '10px',
                  }}
                  shape="circle"
                  size="small"
                  danger
                />
              </>
            ) : (
              <Upload
                {...uploadImageProps}
                accept=".jpg, .jpeg, .png"
                showUploadList={false}
                onChange={(info) => handleUploadChange(info)}>
                <Button icon={<UploadOutlined />}>Upload</Button>
              </Upload>
            )}
          </>
        );
      },
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      width: 150,
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
      width: 90,
    },
    {
      title: 'Value (MYR)',
      dataIndex: 'value',
      key: 'value',
      align: 'center',
      width: 90,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      width: 150,
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      align: 'center',
      render: (data) => (data ? moment(data).format('YYYY-MM-DD') : '-'),
      width: 90,
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      align: 'center',
      render: (data) => (data ? moment(data).format('YYYY-MM-DD') : '-'),
      width: 90,
    },
    {
      title: 'T&C Content',
      dataIndex: 'tncContent',
      key: 'tncContent',
      align: 'left',
      width: 270,
      render: (data) => (data !== '' ? data : '-'),
    },
    {
      title: 'T&C Url',
      dataIndex: 'tncUrl',
      key: 'tncUrl',
      align: 'left',
      width: 270,
      render: (data) => (data !== '' ? data : '-'),
    },
  ];

  const uploadedFailedCols = [
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
      align: 'center',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'left',
      render: (data) => {
        let res;

        if (data) {
          res = <Text type="danger">{data}</Text>;
        } else {
          res = <Text type="success">Valid</Text>;
        }

        return res;
      },
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
      render: (data) => {
        let res;

        if (data) {
          res = <Text type="danger">{data}</Text>;
        } else {
          res = <Text type="success">Valid</Text>;
        }

        return res;
      },
    },
    {
      title: 'Value (MYR)',
      dataIndex: 'value',
      key: 'value',
      align: 'center',
      render: (data) => {
        let res;

        if (data) {
          res = <Text type="danger">{data}</Text>;
        } else {
          res = <Text type="success">Valid</Text>;
        }

        return res;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'left',
      render: (data) => {
        let res;

        if (data) {
          res = <Text type="danger">{data}</Text>;
        } else {
          res = <Text type="success">Valid</Text>;
        }

        return res;
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'startDate',
      key: 'startDate',
      align: 'center',
      render: (data) => {
        let res;

        if (data) {
          res = <Text type="danger">{data}</Text>;
        } else {
          res = <Text type="success">Valid</Text>;
        }

        return res;
      },
    },
    {
      title: 'End Date',
      dataIndex: 'endDate',
      key: 'endDate',
      align: 'center',
      render: (data) => {
        let res;

        if (data) {
          res = <Text type="danger">{data}</Text>;
        } else {
          res = <Text type="success">Valid</Text>;
        }

        return res;
      },
    },
    {
      title: 'T&C Content',
      dataIndex: 'tncContent',
      key: 'tncContent',
      align: 'center',
      render: (data) => {
        let res;

        if (data) {
          res = <Text type="danger">{data}</Text>;
        } else {
          res = <Text type="success">Valid</Text>;
        }

        return res;
      },
    },
    {
      title: 'T&C Url',
      dataIndex: 'tncUrl',
      key: 'tncUrl',
      align: 'center',
      render: (data) => {
        let res;

        if (data) {
          res = <Text type="danger">{data}</Text>;
        } else {
          res = <Text type="success">Valid</Text>;
        }

        return res;
      },
    },
  ];

  if (userRole === 'merchant') {
    return (
      <>
        <DisplayBreadCrumb />
        <Content className="layout-background">
          <Space direction="vertical" style={{ display: 'flex' }}>
            <Row justify="end">
              <Col>
                <Space>
                  <Button
                    href={VoucherTemplate}
                    download="voucher-bulk-upload-template.csv"
                    target="_blank"
                    rel="noopener noreferrer">
                    <DownloadOutlined />
                    Download Template
                  </Button>
                  <Button type="primary" onClick={showImportModal}>
                    <PlusOutlined />
                    Bulk Upload Vouchers
                  </Button>
                  <Modal
                    title="Add Vouchers"
                    open={importModalActive}
                    onCancel={handleCancel}
                    closable={false}
                    centered
                    width={1000}
                    bodyStyle={{ padding: 0 }}
                    footer={[
                      <>
                        <Button onClick={handleCancel}>Cancel</Button>
                        {uploadData && !uploadData.success && (
                          <Button onClick={handleRetry} type="primary">
                            Retry
                          </Button>
                        )}
                        {uploadData && uploadData.success && (
                          <Button onClick={handleConfirm} type="primary">
                            Confirm
                          </Button>
                        )}
                      </>,
                    ]}>
                    <div
                      className="content-area"
                      style={{
                        textAlign: 'center',
                        backgroundColor: 'inherit',
                      }}>
                      <Space direction="vertical" size="middle" style={{ display: 'contents' }}>
                        {!uploadData && (
                          <div>
                            Please fill in downloaded{' '}
                            <Text color="black">voucher-bulk-upload-template.csv</Text> and upload
                            here.
                          </div>
                        )}
                        <div style={{ flex: 1 }}>
                          {!uploadData ? (
                            <Upload {...uploadProps} showUploadList={false}>
                              <Button icon={<UploadOutlined />}>Click to Upload</Button>
                            </Upload>
                          ) : (
                            <div>
                              {uploadData.success ? (
                                <div>
                                  Uploaded File: <Text type="warning">{uploadedFileNames}</Text>
                                </div>
                              ) : (
                                <div>
                                  Errors found while uploading{' '}
                                  <Text type="warning">{uploadedFileNames}</Text>
                                </div>
                              )}
                              {uploadData.success ? (
                                <div className="table-content">
                                  <Table
                                    columns={uploadedSuccessCols}
                                    dataSource={uploadData.vouchersData}
                                    scroll={{ x: '100%' }}
                                    pagination={{
                                      pageSize: 4,
                                      total: uploadData.vouchersData.length,
                                    }}
                                    onChange={handleTableChange}
                                  />
                                </div>
                              ) : (
                                <div className="table-content">
                                  <Table
                                    columns={uploadedFailedCols}
                                    dataSource={uploadData.errors}
                                    scroll={{ x: '100%' }}
                                    pagination={{
                                      pageSize: 4,
                                      total: uploadData.errors.length,
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </Space>
                    </div>
                  </Modal>
                  <Button type="primary" href="/voucher-request/create">
                    <PlusOutlined />
                    Add Voucher
                  </Button>
                </Space>
              </Col>
            </Row>
            <MerchantFilterRow />
            <Row justify="end">
              <Space>
                <Col>
                  <Button danger onClick={clearMerchantFilter} disabled={!isFilterUpdated}>
                    Reset Filters
                  </Button>
                </Col>
                <Col>
                  <Button onClick={merchantFilter}>Apply Filters</Button>
                </Col>
              </Space>
            </Row>
            <div className="table-content">
              {loading === false ? (
                <Table
                  columns={adminVoucherCols}
                  dataSource={voucherListData}
                  pagination={{
                    pageSize: limit,
                    pageSizeOptions: [20, 50, 100],
                    showSizeChanger: true,
                    total: totalData,
                    onChange,
                    current: page,
                  }}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                  }}
                  scroll={{ x: '100%' }}
                />
              ) : (
                <Skeleton />
              )}
            </div>
          </Space>
        </Content>
      </>
    );
  }
  if (userRole === 'admin') {
    return (
      <>
        <DisplayBreadCrumb />
        <Content className="layout-background">
          <Space direction="vertical" style={{ display: 'flex' }}>
            <Row justify="end">
              <Col>
                <Space>
                  {rowSelection.selectedRowKeys.length > 0
                    ? `${rowSelection.selectedRowKeys.length} rows selected`
                    : ''}
                  <Button
                    type="danger"
                    disabled={rowSelection.selectedRowKeys.length === 0}
                    onClick={showRejectModal}>
                    <CloseOutlined />
                    Reject
                  </Button>
                  <Modal
                    title="Confirm to reject selected voucher requests?"
                    open={rejectModalActive}
                    onOk={handleReject}
                    onCancel={handleCancel}
                    closable={false}
                    centered
                    bodyStyle={{ padding: 0 }}
                    footer={[
                      <>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button type="danger" onClick={handleReject}>
                          Reject
                        </Button>
                      </>,
                    ]}
                  >
                    <div
                      style={{
                        padding: '20px',
                        backgroundColor: 'inherit',
                      }}>
                      <div style={{ width: '100%', flex: 1 }}>
                        <div style={{ marginBottom: '.5rem' }}>Reject Remark</div>
                        <Input value={rejectRemark} onChange={handleRejectRemarkChange} />
                      </div>
                    </div>
                  </Modal>
                  <Button
                    type="primary"
                    disabled={rowSelection.selectedRowKeys.length === 0}
                    onClick={showApproveModal}>
                    <CheckOutlined />
                    Approve
                  </Button>
                  <Modal
                    title="Confirm to approve selected voucher requests?"
                    open={approveModalActive}
                    onOk={handleApprove}
                    onCancel={handleCancel}
                    closable={false}
                    centered
                    bodyStyle={{ padding: 0 }}
                    footer={[
                      <>
                        <Button onClick={handleCancel}>Cancel</Button>
                        <Button type="primary" onClick={handleApprove}>
                          Approve
                        </Button>
                      </>,
                    ]}
                  />
                </Space>
              </Col>
            </Row>
            <AdminFilterRow />
            <Row justify="end">
              <Space>
                <Col>
                  <Button danger onClick={clearAdminFilter} disabled={!isFilterUpdated}>
                    Reset Filters
                  </Button>
                </Col>
                <Col>
                  <Button onClick={adminFilter}>Apply Filters</Button>
                </Col>
              </Space>
            </Row>
            <div className="table-content">
              {loading === false ? (
                <Table
                  columns={adminVoucherCols}
                  dataSource={voucherListData}
                  pagination={{
                    pageSize: limit,
                    pageSizeOptions: [20, 50, 100],
                    showSizeChanger: true,
                    total: totalData,
                    onChange,
                    current: page,
                  }}
                  scroll={{ x: '100%' }}
                  rowSelection={{
                    type: 'checkbox',
                    ...rowSelection,
                  }}
                />
              ) : (
                <Skeleton />
              )}
            </div>
          </Space>
        </Content>
      </>
    );
  }
}
