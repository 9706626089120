import React, { useState, useEffect } from 'react';
import {
  Typography,
  Layout,
  Row,
  Col,
  Button,
  Space,
  Breadcrumb,
  Form,
  Skeleton,
  message,
} from 'antd';
import { DeleteOutlined, CheckOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { CardpackDetailForm } from './component/cardpackDetailFormV2';

export function CreateCardpackPage() {
  const history = useHistory();
  const handleSubmit = async (formData) => {
    try {
      await axios.post('cardpack/createCardpack', formData);
      message.success('created successfully.');

      setTimeout(() => {
        history.push('/cardpackConfigListPage');
      }, 1000);
    } catch (error) {
      message.error(`Failed to create. ${error}`);
    }
  };

  return (
    <>
      <CardpackDetailForm isCreate onSubmit={handleSubmit} loading={false} />
    </>
  );
}
