import React, { useEffect, useState } from 'react';
import { Typography, Layout, Row, Col, Button, Space, Form, Input, message, Skeleton } from 'antd';
import { CloseOutlined, CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

const { Title } = Typography;
const { Content } = Layout;

export default function EditBranchRequest() {
  const [form] = Form.useForm();
  const token = getToken();
  const [loading, setLoading] = useState(true);
  const [editReqData, setEditReqData] = useState({});
  const [branchId, setBranchId] = useState();
  const [branchData, setBranchData] = useState({});
  const editRequestId = window.location.href.split('/')[4];

  useEffect(() => {
    const getData = async () => {
      await axios
        .get(`branchEditRequest/${editRequestId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setBranchId(res.data.branchId);
          setEditReqData(res.data);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve edit request data. ${error}`);
          setLoading(false);
        });
    };

    getData();
  }, [editRequestId]);

  useEffect(() => {
    const getExistingData = async () => {
      await axios
        .get(`branch/${branchId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setBranchData(res.data);
        })
        .catch((error) => {
          message.error(`Failed to retrieve existing branch data. ${error}`);
        });
    };

    if (branchId !== undefined) {
      getExistingData();
    }
  }, [branchId]);

  const highlightField = (fieldName) => {
    return editReqData[fieldName] !== branchData[fieldName] ? 'warning' : '';
  };
  
  useEffect(() => {
    form.setFieldsValue({
      branchName: editReqData.branchName,
      branchAddress: editReqData.branchAddress,
      branchEmail: editReqData.branchEmail,
      branchContactNum: editReqData.branchContactNum,
      PIC: editReqData.PIC,
      PICnumber: editReqData.PICnumber,
      PICemail: editReqData.PICemail,
    });
  }, [editReqData]);

  const handleSave = async () => {
    await axios.put(`branchEditRequest/${editRequestId}`, null, {
      headers: {
        accept: '*/*',
        Authorization: `Bearer ${token.access.token}`,
      },
    });
    message.success('Edit request approved. Branch data saved.');

    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
  };

  const handleReject = async () => {
    await axios.put(`branchEditRequest/${editRequestId}/archive`, null, {
      headers: {
        accept: '*/*',
        Authorization: `Bearer ${token.access.token}`,
      },
    });
    message.success('Edit request rejected.');

    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
  };

  return (
    <Content className="layout-background" style={{ paddingTop: '15px' }}>
      {loading === false ? (
        <>
          <Row>
            <Col flex={4}>
              <Title
                level={3}
                style={{ display: 'flex', justifyContent: 'flex-start', textAlign: 'left' }}>
                {editReqData.branchName}
              </Title>
            </Col>
            <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Space>
                <Button type="primary" danger onClick={handleReject}>
                  <CloseOutlined />
                  Reject
                </Button>
                <Button type="primary" onClick={handleSave}>
                  <CheckOutlined />
                  Approve
                </Button>
              </Space>
            </Col>
          </Row>
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col xs={24} lg={16}>
                <div className="content-area">
                  <Form.Item
                    name="branchName"
                    label="Branch Name"
                    validateStatus={highlightField('branchName')}
                    >
                    <Input placeholder="Enter Branch Name (e.g. Mid Valley Megamall)" readOnly />
                  </Form.Item>
                  <Form.Item
                    name="branchAddress"
                    label="Location / Address"
                    validateStatus={highlightField('branchAddress')}
                    >
                    <Input placeholder="Enter Branch Address" readOnly />
                  </Form.Item>
                  <Form.Item
                    name="branchEmail"
                    label="E-mail Address"
                    validateStatus={highlightField('branchEmail')}
                    >
                    <Input placeholder="Enter Branch Email Address" readOnly />
                  </Form.Item>
                  <Form.Item
                    name="branchContactNum"
                    label="Contact Number"
                    validateStatus={highlightField('branchContactNum')}
                    >
                    <Input placeholder="Enter Branch Contact Number" readOnly />
                  </Form.Item>
                  <p style={{ color: 'blue' }}>
                    <InfoCircleOutlined /> Highlighted box is the informations 
                    that merchant request to edit.
                  </p>
                </div>
              </Col>
              <Col xs={24} lg={8}>
                <div className="content-area">
                  <Form.Item name="PIC" label="Person in Charge" validateStatus={highlightField('PIC')}>
                    <Input placeholder="Enter PIC's Name" readOnly />
                  </Form.Item>
                  <Form.Item name="PICnumber" label="Contact Number" validateStatus={highlightField('PICnumber')}>
                    <Input placeholder="Enter PIC Contact Number" readOnly />
                  </Form.Item>
                  <Form.Item name="PICemail" label="E-mail Address" validateStatus={highlightField('PICemail')}>
                    <Input placeholder="Enter PIC's Email Address" readOnly />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        <Skeleton />
      )}
    </Content>
  );
}
