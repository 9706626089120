import React, { useState, useEffect, useCallback, useRef } from 'react';
import { utils, writeFileXLSX } from 'xlsx';
import {
  Table,
  Layout,
  Button,
  Row,
  Col,
  Space,
  Modal,
  message,
  Tag,
  Breadcrumb,
  Typography,
  Select,
  Form,
  Input,
} from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

import moment from 'moment';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

const { Content } = Layout;
const { Link } = Typography;

export default function PlayerList() {
  const token = getToken();

  const history = useHistory();

  const [modalActive, setModalActive] = useState(false);
  const [loading, setLoading] = useState(true);

  const [playerList, setPlayerList] = useState([]);
  const [tierList, setTierList] = useState([]);

  const [playerListData, setPlayerListData] = useState([]);

  const [filterForm] = Form.useForm();
  // const [searchStatus, setSearchStatus] = useState('');
  // const [searchTier, setSearchTier] = useState('');
  // const [searchEKYCStatus, setSearchEKYCStatus] = useState();
  // const [searchPhone, setSearchPhone] = useState('');

  const columns = [
    {
      title: 'Player Name',
      dataIndex: 'playerName',
      key: 'playerName',
      width: 170,
    },
    {
      title: 'Player ID',
      dataIndex: 'playerID',
      key: 'playerID',
      width: 90,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      key: 'phoneNumber',
      width: 100,
    },
    {
      title: 'EKYC Status',
      dataIndex: 'ekycStatus',
      key: 'ekycStatus',
      render: (status, record) => {
        const handleApprove = async () => {
          await axios.patch(
            `players/${record.key}`,
            { isEKYCVerified: 'true' },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token.access.token}`,
              },
            },
          );
          message.success('Updated EKYC Status.');
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        };
  
        let res;
  
        switch (status) {
          case false:
            res = (
              <Button type="primary" onClick={handleApprove}>
                Verify
              </Button>
            );
            break;
          case true:
            res = <Tag color="green">Verified</Tag>;
            break;
          default:
            res = '';
            break;
        }
  
        return res;
      },
      width: 120,
      align: 'center',
    },
    {
      title: "Pet's Name",
      dataIndex: 'petName',
      key: 'petName',
      width: 100,
      align: 'center',
    },
    {
      title: 'Topup Amount',
      dataIndex: 'topupAmount',
      key: 'topupAmount',
      width: 130,
      align: 'center',
    },
    {
      title: 'Total HeeCoins',
      dataIndex: 'totalHeeCoins',
      key: 'totalHeeCoins',
      width: 130,
      align: 'center',
    },
    {
      title: 'Tier',
      dataIndex: 'tier',
      key: 'tier',
      width: 100,
      align: 'center',
    },
    {
      title: 'Total Egg',
      dataIndex: 'eggNum',
      key: 'eggNum',
      width: 100,
      align: 'center',
    },
    {
      title: 'Total Hatched',
      dataIndex: 'hatchedNum',
      key: 'hatchedNum',
      width: 100,
      align: 'center',
    },
    {
      title: 'Total Unhatched',
      dataIndex: 'unhatchedNum',
      key: 'unhatchedNum',
      width: 100,
      align: 'center',
    },
    {
      title: 'Total Incubator',
      dataIndex: 'incubatorNum',
      key: 'incubatorNum',
      width: 100,
      align: 'center',
    },
    {
      title: 'Created Date',
      dataIndex: 'createdDate',
      key: 'createdDate',
      render: (data) => (data ? moment(data).format('YYYY-MM-DD HH:mm:ss') : '-'),
      sorter: (a, b) => moment(a.createdDate).unix() - moment(b.createdDate).unix(),
      width: 150,
    },
  ];

  const showModal = () => {
    setModalActive(true);
  };
  // const handleOk = () => {
  //   setModalActive(false);
  // };
  const handleCancel = () => {
    setModalActive(false);
  };

  function getTierList(tierData) {
    const list = [{ value: 'All', label: 'All' }];
    for (let i = 0; i < tierData.length; i++) {
      list.push({ value: tierData[i]._id, label: tierData[i].name });
    }
    return list;
  }

  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      list.push({
        key: data[i]._id,
        playerName: data[i].name ? data[i].name : `Guest ${data[i].uid}`,
        playerID: data[i].uid,
        phoneNumber: data[i].phone,
        ekycStatus: data[i].isEKYCVerified,
        petName: data[i].petName,
        topupAmount: `RM ${data[i].totalAmountSpent.toFixed(2)}`,
        tier: data[i].tier,
        eggNum: data[i].totalEggCount,
        hatchedNum: data[i].totalHatchedCount,
        unhatchedNum: data[i].totalUnhatchedCount,
        incubatorNum: data[i].totalIncubator,
        createdDate: data[i].createdAt,
        totalHeeCoins: data[i].totalHeeCoins,
      });
    }
    setPlayerListData(list);
  };

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleOk = useCallback(() => {
    let selectedRows = [];
  
    if (rowSelection.selectedRowKeys.length === 0) {
      // Export all rows when no rows are selected
      selectedRows = playerListData.map(({ key, ...rest }) => rest);
    } else {
      // Export selected rows
      selectedRows = playerListData
        .filter((row) => rowSelection.selectedRowKeys.includes(row.key))
        .map(({ key, ...rest }) => rest);
    }

    // Rename column headers
    const renamedColumns = {
      playerName: 'Player Name',
      playerID: 'Player ID',
      phoneNumber: 'Phone Number',
      ekycStatus: 'EKYC Status',
      petName: 'Pet Name',
      topupAmount: 'Topup Amount',
      eggNum: 'Total Egg',
      hatchedNum: 'Total Hatched',
      unhatchedNum: 'Total Unhatched',
      incubatorNum: 'Total Incubator',
      createdDate: 'Created Date',
    };

    const transformedRows = selectedRows.map((row) => (
      Object.entries(row).reduce((acc, [key, value]) => {
        const renamedKey = renamedColumns[key] || key;
        if (key === 'createdDate') {
          const createdDate = new Date(value);
          const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Asia/Kuala_Lumpur',
          };
          return { ...acc, [renamedKey]: createdDate.toLocaleString('en-US', options) };
        }
        return { ...acc, [renamedKey]: value };
      }, {})));  
  
    const currentDate = new Date();
    const formattedDate = currentDate
      .toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(/[/:\s]/g, '_');
  
    const ws = utils.json_to_sheet(transformedRows);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    writeFileXLSX(wb, `Players_${formattedDate}.xlsx`);

    setModalActive(false);
  }, [playerListData, rowSelection.selectedRowKeys]);

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [filterData, setFilterData] = useState({});
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);

  // 'state' object passed from tier page
  const { state } = useHistory().location;

  // Set the filterData state if redirected from tier
  useEffect(() => {
    setLoading(true);
    if (state && state.filterData) {
      setFilterData(state.filterData);
      filterForm.setFieldValue('tier', state.filterData.tier);
      setIsFilterUpdated(true);
    } else if (state && state.playerList) {
      const filter = { playerId: state.playerList };
      setFilterData(filter);
      setIsFilterUpdated(true);
    } else {
      setFilterData({});
    }
  }, [state]);

  const source = useRef();
  useEffect(() => {
    const params = { ...filterData, limit, page };
    
    setLoading(true);
    const getData = async () => {
      try {
        if (source.current) {
          source.current.cancel('Previous request canceled');
        }
        const cancelTokenSource = axios.CancelToken.source();
        source.current = cancelTokenSource;

        const res = await axios.get('players', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params,
          cancelToken: cancelTokenSource.token,
        });

        setPlayerList(res.data.results);
        setTotalData(res.data.totalResults);
        setLoading(false);
      } catch (error) {
        if (axios.isCancel(error) || error.message === 'Previous request canceled') {
          // does nothing
        } else {
          message.error(`Failed to retrieve players data. ${error}`);
          setLoading(false);
        }
      } 
    };
    getData();
  }, [filterData, limit, page]);  

  useEffect(() => {
    const getTierData = async () => {
      await axios
        .get('tiers', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            limit: 50,
            page: 1,
          },
        })
        .then((res) => {
          setTierList(res.data.results);
        })
        .catch((error) => {
          message.error(`Failed to retrieve transactions data. ${error}`);
        });
    };

    getTierData();
  }, []);  

  useEffect(() => {
    if (loading === false) {
      rerenderList(playerList);
    }
  }, [loading, playerList]);

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/player-management">Players</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const onFilterUpdate = () => {
    setIsFilterUpdated(true);
  };

  const onFilter = () => {
    filterForm
      .validateFields()
      .then((res) => {
        const { petName, uid, status, tier, isEKYCVerified, phone } = res;

        const items = {};
        
        if (status !== 'All') {
          items.status = status;
        }
        if (tier !== 'All') {
          items.tier = tier;
        }
        if (isEKYCVerified === 'All' || isEKYCVerified === undefined) {
          items.isEKYCVerified = undefined;
        }
        if (isEKYCVerified === true) {
          items.isEKYCVerified = true;
        }
        if (isEKYCVerified === null) {
          items.isEKYCVerified = false;
        }

        if (phone !== '') {
          items.phone = phone;          
        }
        if (petName !== '') {
          items.petName = petName;          
        }
        if (uid !== '') {
          items.uid = uid;          
        }
        
        setFilterData(items);

        setIsFilterUpdated(true);
      })
      .catch(() => {});
  };

  const clearFilter = () => {
    filterForm.resetFields();
    onFilter();
    setTimeout(() => {
      setIsFilterUpdated(false);
    }, 0);
  };

  const newlyRegistered = () => {
    filterForm.resetFields();
    const startDate = moment().startOf('day').toDate();
    const endDate = moment().endOf('day').toDate();
    setFilterData({ startDate, endDate });
    setIsFilterUpdated(true);
  };

  const FilterRow = () => (
    <Form form={filterForm}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={12} lg={8}>
          <Row>
            <p>Pet Name</p>
          </Row>
          <Row>
            <Form.Item name="petName" style={{ width: '100%' }}>
              <Input placeholder="Pet Name" />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={8}>
          <Row>
            <p>Player ID</p>
          </Row>
          <Row>
            <Form.Item name="uid" style={{ width: '100%' }}>
              <Input placeholder="Player ID" />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={8}>
          <Row>
            <p>Player Status</p>
          </Row>
          <Row>
            <Form.Item name="status" style={{ width: '100%' }}>
              <Select placeholder="Select Category" defaultValue="All" onChange={onFilterUpdate}>
                <Select.Option value="All">All</Select.Option>
                <Select.Option value="Active">Active</Select.Option>
                <Select.Option value="Inactive">Inactive</Select.Option>
                <Select.Option value="Deactivated">Deactivated</Select.Option>
                <Select.Option value="Suspended">Suspended</Select.Option>
              </Select>
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={8}>
          <Row>
            <p>Tier</p>
          </Row>
          <Row>
            <Form.Item name="tier" style={{ width: '100%' }}>
              <Select 
                placeholder="Select Tier" 
                options={getTierList(tierList)} 
                onChange={onFilterUpdate}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={8}>
          <Row>
            <p>EKYC Status</p>
          </Row>
          <Row>
            <Form.Item name="isEKYCVerified" style={{ width: '100%' }}>
              <Select placeholder="Select EKYC Status" defaultValue="All" onChange={onFilterUpdate}>
                <Select.Option value="All">All</Select.Option>
                <Select.Option value>Verified</Select.Option>
                <Select.Option>Not Verified</Select.Option>
              </Select>
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={8}>
          <Row>
            <p>Phone</p>
          </Row>
          <Row>
            <Form.Item name="phone" style={{ width: '100%' }}>
              <Input placeholder="Phone Number" />
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </Form>
  );

  const onChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
  };

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Row justify="end">
            <Col>
              <Button onClick={showModal}>
                <ImportOutlined rotate={-90} />
                Export
              </Button>
              <Modal
                title="Are you sure you want to export player management data?"
                open={modalActive}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                closable={false}
                bodyStyle={{ padding: 0 }}
                footer={[
                  <>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button type="primary" onClick={handleOk}>
                      Export
                    </Button>
                  </>,
                ]}
              />
            </Col>
          </Row>
          <FilterRow />
          <Row justify="end">
            <Space>
              <Col>
                <Button danger onClick={clearFilter} disabled={!isFilterUpdated}>
                  Reset Filters
                </Button>
              </Col>
              <Col>
                <Button onClick={newlyRegistered}>
                  Newly Registered Today
                </Button>
              </Col>
              <Col>
                <Button onClick={onFilter}>Apply Filters</Button>
              </Col>
            </Space>
          </Row>
          <Row>
            <div className="table-content" style={{ width: '100%' }}>
              <Table
                columns={columns}
                pagination={{ pageSize: limit,
                  pageSizeOptions: [20, 50, 100],
                  showSizeChanger: true,
                  total: totalData,
                  onChange,
                  current: page,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                scroll={{ x: 'max-content' }}
                onRow={(rowIndex) => ({
                  onClick: (event) => {
                    if (!event.target.closest('.ant-btn')) {
                      history.push(`player-management/${rowIndex.key}`);
                    }
                  }, // click row
                })}
                dataSource={playerListData}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
                rowClassName="hover-cursorChange"
                loading={loading}
                style={{ tableLayout: 'fixed' }}
              />
            </div>
          </Row>
        </Space>
      </Content>
    </>
  );
}
