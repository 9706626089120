import React, { useState, useEffect } from 'react';
import {
  Typography,
  Layout,
  Row,
  Col,
  Button,
  Space,
  // Modal,
  Breadcrumb,
  Form,
  message,
  Skeleton,
} from 'antd';
import { CheckOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

import HeeCoinForm from '../../components/heeCoinSetting/HeeCoinForm';

const { Title, Link } = Typography;
const { Content } = Layout;

export default function EditVoucher() {
  const [form] = Form.useForm();
  const token = getToken();
  const [loading, setLoading] = useState(true);

  const heeCoinID = window.location.href.split('/')[4];
  const [heeCoinData, setHeeCoinData] = useState({});

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      await axios
        .get(`heeCoin/${heeCoinID}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setHeeCoinData(res.data);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve HeeCoin data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, []);

  const handleSave = async () => {
    try {
      const formData = form.getFieldsValue();

      const dataPackage = {
        name: formData.name,
        amount: formData.amount,
        quantity: formData.quantity,
      };

      await axios.patch(`heeCoin/${heeCoinID}`, dataPackage, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      message.success('HeeCoin data saved successfully.');

      setTimeout(() => {
        window.location.href = '/heecoin-setting';
      }, 1000);
    } catch (error) {
      message.error(`Failed to save HeeCoin data. ${error}`);
    }
  };

  // const handleDeactivate = async () => {
  //   try {
  //     const formData = form.getFieldsValue();
  //     if (heeCoinData.status === 'Active') {
  //       const dataPackage = {
  //         status: 'Inactive',
  //         merchantId: formData.voucherMerchant,
  //       };

  //       await axios.patch(`vouchers/${heeCoinID}`, dataPackage, {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${token.access.token}`,
  //         },
  //       });
  //       message.success('Voucher deactivated successfully.');
  //     } else {
  //       const dataPackage = {
  //         status: 'Active',
  //         merchantId: formData.voucherMerchant,
  //       };

  //       await axios.patch(`vouchers/${heeCoinID}`, dataPackage, {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${token.access.token}`,
  //         },
  //       });
  //       message.success('Voucher activated successfully.');
  //     }

  //     setTimeout(() => {
  //       window.location.href = '/vouchers';
  //     }, 1000);
  //   } catch (error) {
  //     message.error(`Failed to delete voucher. ${error}`);
  //   }
  // };

  // const [modalActive, setModalActive] = useState(false);
  // const showModal = () => {
  //   setModalActive(true);
  // };
  // const handleOk = () => {
  //   setModalActive(false);
  //   handleDeactivate();
  // };
  // const handleCancel = () => {
  //   setModalActive(false);
  // };

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/heecoin-setting">
            <LeftOutlined />
            HeeCoin
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit HeeCoin</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Row>
          <Col flex={4}>
            <Title
              level={3}
              style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '20px' }}>
              Edit HeeCoin
            </Title>
          </Col>
          <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              {/* <Button danger onClick={showModal} disabled={heeCoinData.status === 'Inactive'}>
                {heeCoinData.status !== 'Inactive' ? 'Deactivate Voucher' : 'Deactivated'}
              </Button>
              <Modal
                title="Are you sure you want to deactivate?"
                open={modalActive}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                closable={false}
                footer={[
                  <>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button type="primary" danger onClick={handleOk}>
                      <DeleteOutlined />
                      Yes, Deactivate
                    </Button>
                  </>,
                ]}>
                <p>If you confirm this action, you are unable to undo.</p>
              </Modal> */}
              <Button type="primary" onClick={handleSave}>
                <CheckOutlined />
                Save
              </Button>
            </Space>
          </Col>
        </Row>
        {loading === false ? (
          <HeeCoinForm form={form} mode="Edit" data={heeCoinData} />
        ) : (
          <Skeleton />
        )}
      </Content>
    </>
  );
}
