import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Select,
  Upload,
  Typography,
  message,
  Tooltip,
  Row,
  Col,
  Table,
  Tabs,
} from 'antd';
import { InfoCircleOutlined, PictureFilled } from '@ant-design/icons';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

const { TabPane } = Tabs;
const { TextArea } = Input;
const { Dragger } = Upload;
const { Paragraph, Text } = Typography;
const { Option } = Select;

export default function GameItemForm({ form, data, setPromoItems }) {
  const token = getToken();
  const [smallFileName, setSmallFileName] = useState('');
  const [largeFileName, setLargeFileName] = useState('');
  const [bannerFileName, setBannerFileName] = useState('');
  const [effectLabel, setEffectLabel] = useState();
  const [smallImageUrl, setSmallImageUrl] = useState();
  const [largeImageUrl, setLargeImageUrl] = useState();
  const [bannerImageUrl, setBannerImageUrl] = useState();

  const [itemPrizePool, setItemPrizePool] = useState([]);
  const [itemList, setItemList] = useState([]);

  const beforeUpload = (file, type) => {
    const sizeInMB = file.size / 1024 / 1024;
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return Promise.reject(new Error('Invalid file type.'));
    }

    if (sizeInMB > 2) {
      message.error(`${file.name} exceeds 2MB`);
      return Promise.reject(new Error('File size exceeds 2MB.'));
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          // if (type === 'large') {
          //   if (img.width === 508 && img.height === 560) {
          //     resolve(file);
          //   } else {
          //     message.error('Image must be 508x560');
          //     reject(new Error('Invalid image dimensions.'));
          //   }
          // } else if (type === 'small') {
          //   if (img.width === 127 && img.height === 140) {
          //     resolve(file);
          //   } else {
          //     message.error('Image must be 127x140');
          //     reject(new Error('Invalid image dimensions.'));
          //   }
          // } else if (type === 'banner') {
          //   if (img.width === 468 && img.height === 60) {
          //     resolve(file);
          //   } else {
          //     message.error('Image must be 468x60');
          //     reject(new Error('Invalid image dimensions.'));
          //   }
          // }

          resolve(file);
        };
   
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const bannerProps = {
    name: 'bannerImage',
    beforeUpload: (file) => beforeUpload(file, 'banner'),
    maxCount: 1,
    action: 'items/upload-item-image',
    onChange(info) {
      const { status, name } = info.file;

      if (!status) {
        console.log('wrong type of file or dimension');
      } else {
        setBannerFileName(name);
      }
    },
    customRequest: (options) => {
      const newName = `banner_${options.file.name}`;
      const imageData = new FormData();
      imageData.append('itemImage', options.file, newName);
      const config = {
        headers: {
          'Content-type': 'multipart/form-data',
          Authorization: `Bearer ${token.access.token}`,
        },
      };
      axios
        .post(options.action, imageData, config)
        .then((res) => {
          setBannerImageUrl(res.data.Location);
          form.setFieldsValue({
            bannerImage: res.data.Location,
          });
          message.success(`${options.file.name} file uploaded successfully.`);
        })
        .catch((err) => {
          message.error(`${options.file.name} file upload failed. ${err}`);
        });
    },
  };

  const largeIconProps = {
    name: 'gameItemLargeImage',
    beforeUpload: (file) => beforeUpload(file, 'large'),
    maxCount: 1,
    action: 'items/upload-item-image',
    onChange(info) {
      const { status, name } = info.file;

      if (!status) {
        console.log('wrong type of file or dimension');
      } else {
        setLargeFileName(name);
      }
    },
    customRequest: (options) => {
      const newName = `largeIcon_${options.file.name}`;
      const imageData = new FormData();
      imageData.append('itemImage', options.file, newName);
      const config = {
        headers: {
          'Content-type': 'multipart/form-data',
          Authorization: `Bearer ${token.access.token}`,
        },
      };
      axios
        .post(options.action, imageData, config)
        .then((res) => {
          setLargeImageUrl(res.data.Location);
          form.setFieldsValue({ gameItemLargeImage: res.data.Location });
          message.success(`${options.file.name} file uploaded successfully.`);
        })
        .catch((err) => {
          message.error(`${options.file.name} file upload failed. ${err}`);
        });
    },
  };

  const smallIconProps = {
    name: 'gameItemSmallImage',
    beforeUpload: (file) => beforeUpload(file, 'small'),
    maxCount: 1,
    action: 'items/upload-item-image',
    onChange(info) {
      const { status, name } = info.file;

      if (!status) {
        console.log('wrong type of file or dimension');
      } else {
        setSmallFileName(name);
      }
    },
    customRequest: (options) => {
      const newName = `smallIcon_${options.file.name}`;
      const imageData = new FormData();
      imageData.append('itemImage', options.file, newName);
      const config = {
        headers: {
          'Content-type': 'multipart/form-data',
          Authorization: `Bearer ${token.access.token}`,
        },
      };
      axios
        .post(options.action, imageData, config)
        .then((res) => {
          setSmallImageUrl(res.data.Location);
          form.setFieldsValue({ gameItemSmallImage: res.data.Location });
          message.success(`${options.file.name} file uploaded successfully.`);
        })
        .catch((err) => {
          message.error(`${options.file.name} file upload failed. ${err}`);
        });
    },
  };

  useEffect(() => {
    if (data) {
      let effectlabel;
      switch (data.type) {
        case 'Daily Deals':
          effectlabel = 'Amount of Coins';
          break;
        case 'Weekly Deals':
          effectlabel = 'Amount of Coins';
          break;
        case 'Game Coin':
          effectlabel = 'Amount of Coins';
          break;
        case 'Food':
          effectlabel = 'Amount of Satiety Restored';
          break;
        case 'Health':
          effectlabel = 'Amount of Health Restored';
          break;
        case 'Equipment':
          effectlabel = 'Hours of Equipment Effect';
          break;
        default:
          effectlabel = 'Effect';
          break;
      }
      setEffectLabel(effectlabel);
 
      form.setFieldsValue({
        gameItemName: data.name,
        gameItemNameCN: data.nameCN,
        gameItemPrice: data.price,
        gameItemCurrency: data.currency,
      
        gameItemGroup: data.group,
        gameItemCategory: data.type,
        effect: data.effect,
        descriptions: data.description,
      
      });

      if (data.type === 'Promotion') {
        setBannerImageUrl(data.largeIconUrl);
        const itemPrizePoolList = [];
        const items = [];
        data.promoItems.forEach((obj) => {
          items.push({
            key: obj.item.id,
            name: obj.item.name,
            largeIconUrl: obj.item.largeIconUrl,
            quantity: obj.quantity,
            description: obj.item.description,
            type: obj.item.type,
          });
          itemPrizePoolList.push({ item: obj.item.id, quantity: obj.quantity });
        });
  
        setItemPrizePool(items);
        setPromoItems(itemPrizePoolList);
        form.setFieldsValue({
          bannerImage: data.largeIconUrl,
          items,
        });
      } else {
        setLargeImageUrl(data.largeIconUrl);
        setSmallImageUrl(data.smallIconUrl);
        form.setFieldsValue({
          gameItemLargeImage: data.largeIconUrl,
          gameItemSmallImage: data.smallIconUrl,
        });
      }
    }
  }, [data]);

  const selectPrefix = (
    <Form.Item name="gameItemCurrency" initialValue="Game Coin" noStyle>
      <Select disabled={data && data.type === 'Game Coin'} style={{ width: 120 }}>
        <Option value="Game Coin">Game Coin</Option>
        <Option value="Hope Coin">Diamond</Option>
      </Select>
    </Form.Item>
  );

  const getItemData = async () => {
    await axios
      .get('/incubatorConfig/getPromotionItemPrizeOptions', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      })
      .then((res) => {
        setItemList(res.data);
      })
      .catch((error) => {
        message.error(`Failed to retrieve transactions data. ${error}`);
      });
  };

  useEffect(() => {
    getItemData();
  }, []);

  const handleChange = (value) => {
    const items = [];
    const promoItemsTemp = [];
    value.forEach((itemId) => {
      const selectedItem = itemList.find((item) => item.id === itemId);
      const existingItem = itemPrizePool.find((item) => item.key === selectedItem.id);

      if (itemId === selectedItem.id) {
        items.push({
          key: selectedItem.id,
          name: selectedItem.name,
          largeIconUrl: selectedItem.largeIconUrl,
          description: selectedItem.description,
          type: selectedItem.type,
          quantity: existingItem ? existingItem.quantity : 1,
        });

        promoItemsTemp.push({
          item: selectedItem.id,
          quantity: existingItem ? existingItem.quantity : 1,
        });
      }
    });

    setItemPrizePool(items);
    setPromoItems(promoItemsTemp);
  };

  const handleQuantityChange = (key, newQuantity) => {
    const updatedDataSource = itemPrizePool.map((item) => {
      if (item.key === key) {
        return { ...item, quantity: newQuantity };
      }
      return item;
    });
    const promoItemsTemp = itemPrizePool.map((item) => {
      if (item.key === key) {
        return { item: item.key, quantity: newQuantity };
      }
      return { item: item.key, quantity: item.quantity };
    });

    setItemPrizePool(updatedDataSource);
    setPromoItems(promoItemsTemp);
  };

  const handleBlur = (key, input) => {
    let newQuantity = parseInt(input, 10);

    if (Number.isNaN(newQuantity)) {
      newQuantity = 1;
    }

    handleQuantityChange(key, newQuantity);
  };

  const itemColumns = [
    {
      title: '',
      dataIndex: 'largeIconUrl',
      key: 'key',
      width: '100px',
      render: (text) => <img style={{ width: '50px' }} alt="Item Img" src={text} />,
    },
    {
      title: 'Item',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      width: '200px',
      render: (text, record) => (
        <InputNumber
          value={text}
          max={100}
          min={1}
          controls={false}
          onBlur={(e) => handleBlur(record.key, e.target.value)}
        />
      ),
    },
  ];

  function getItemList(itemData) {
    const list = [];
    for (let i = 0; i < itemData.length; i++) {
      list.push({ value: itemData[i].id, label: `${itemData[i].type} - ${itemData[i].name}` });
    }

    return list;
  }

  const ItemPrizePool = () => (
    <>
      <Form.Item
        name="items"
        rules={[
          {
            required: true,
            type: 'array',
            message: 'Please select at least 1 item!',
          },
        ]}>
        <Select
          mode="multiple"
          allowClear
          placeholder="Select Items"
          options={getItemList(itemList)}
          onChange={handleChange}
        />
      </Form.Item>
      <Table
        columns={itemColumns}
        dataSource={itemPrizePool}
        pagination={false}
        scroll={{ x: '100%' }}
      />
    </>
  );

  return (
    <div className="content-area">
      <Form form={form} layout="vertical">
        {form.getFieldValue('gameItemCategory') === 'Promotion' ? (
          <Form.Item name="bannerImage" rules={[{ required: true }]}>
            <div style={{ display: 'flex' }}>
              <div style={{ flex: 1 }}>
                <Dragger {...bannerProps} accept=".jpg, .jpeg, .png" showUploadList={false}>
                  <p className="ant-upload-drag-icon">
                    <PictureFilled />
                  </p>
                  <p className="ant-upload-text">Upload Banner (*required)</p>
                  <Paragraph className="ant-upload-hint">
                    <Text type="secondary">Upload item image. Max 1 image.</Text>
                    <br />
                    <Text type="secondary">File Format</Text>
                    <Text> jpeg, png</Text>
                    <br />
                    {/* <Text type="secondary">Required Size</Text>
                    <Text> 468&times;60</Text>
                    <br /> */}
                    <Text type="secondary">Maximum file size: 2MB</Text>
                  </Paragraph>
                </Dragger>
              </div>
              {bannerImageUrl && (
                <div style={{ flex: 1, marginLeft: 10 }}>
                  <img
                    src={bannerImageUrl}
                    alt="Uploaded large game item"
                    style={{ width: 150, height: 'auto' }}
                  />{' '}
                </div>
              )}
            </div>
            <div>{bannerFileName ? `Uploaded File: ${bannerFileName}` : ''}</div>
          </Form.Item>
        ) : (
          <>
            <Form.Item name="gameItemLargeImage" rules={[{ required: true }]}>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <Dragger {...largeIconProps} accept=".jpg, .jpeg, .png" showUploadList={false}>
                    <p className="ant-upload-drag-icon">
                      <PictureFilled />
                    </p>
                    <p className="ant-upload-text">Upload Large Icon (*required)</p>
                    <Paragraph className="ant-upload-hint">
                      <Text type="secondary">Upload item image. Max 1 image.</Text>
                      <br />
                      <Text type="secondary">File Format</Text>
                      <Text> jpeg, png</Text>
                      <br />
                      {/* <Text type="secondary">Required Size</Text>
                      <Text> 508&times;560</Text>
                      <br /> */}
                      <Text type="secondary">Maximum file size: 2MB</Text>
                    </Paragraph>
                  </Dragger>
                </div>
                {largeImageUrl && (
                  <div style={{ flex: 1, marginLeft: 10 }}>
                    <img
                      src={largeImageUrl}
                      alt="Uploaded large game item"
                      style={{ width: 150, height: 'auto' }}
                    />{' '}
                  </div>
                )}
              </div>
              <div>{largeFileName ? `Uploaded File: ${largeFileName}` : ''}</div>
            </Form.Item>
            <Form.Item name="gameItemSmallImage" rules={[{ required: true }]}>
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1 }}>
                  <Dragger {...smallIconProps} accept=".jpg, .jpeg, .png" showUploadList={false}>
                    <p className="ant-upload-drag-icon">
                      <PictureFilled />
                    </p>
                    <p className="ant-upload-text">Upload Small Icon (*required)</p>
                    <Paragraph className="ant-upload-hint">
                      <Text type="secondary">Upload item image. Max 1 image.</Text>
                      <br />
                      <Text type="secondary">File Format</Text>
                      <Text> jpeg, png</Text>
                      <br />
                      {/* <Text type="secondary">Required Size</Text>
                      <Text> 127&times;140</Text>
                      <br /> */}
                      <Text type="secondary">Maximum file size: 2MB</Text>
                    </Paragraph>
                  </Dragger>
                </div>
                {smallImageUrl && (
                  <div style={{ flex: 1, marginLeft: 10 }}>
                    <img
                      src={smallImageUrl}
                      alt="Uploaded small game item"
                      style={{ width: 150, height: 'auto' }}
                    />{' '}
                  </div>
                )}
              </div>
              <div>{smallFileName ? `Uploaded File: ${smallFileName}` : ''}</div>
            </Form.Item>
          </>
        )}
        <Typography.Title level={3} style={{ textAlign: 'start' }}>
          Required Size
        </Typography.Title>
        <Form.Item name="gameItemName" label="Item Name" rules={[{ required: true }]}>
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item name="gameItemNameCN" label="Item Name (CN)" rules={[{ required: true }]}>
          <Input placeholder="Name" />
        </Form.Item>
        <Form.Item name="gameItemPrice" label="Price" rules={[{ required: true }]}>
          <InputNumber
            disabled={data && data.type === 'Game Coin'}
            min={0}
            addonBefore={selectPrefix}
            placeholder="0"
          />
        </Form.Item>
        <Form.Item
          name="gameItemGroup"
          label="Page to be appeared in game store."
          rules={[{ required: true }]}>
          <Select placeholder="Select page" style={{ width: 180 }}>
            <Option value="Coins">Coins</Option>
            <Option value="Survival">Survival</Option>
            <Option value="Special Item">Special Items</Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="gameItemCategory"
          rules={[{ required: true }]}
          label={(
            <Row
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}>
              <Col>Category</Col>
              <Col style={{ paddingLeft: '10px' }}>
                <Tooltip title="Section to be appeared in game store." placement="right">
                  <InfoCircleOutlined />
                </Tooltip>
              </Col>
            </Row>
          )}>
          <Select
            placeholder="Select category"
            style={{ width: 180 }}
            onChange={(value) => {
              let effectlabel;
              switch (value) {
                case 'Spin':
                  effectlabel = 'Amount of Spin Restored';
                  break;
                case 'Game Ticket':
                  effectlabel = 'Amount of Game Ticket Effect';
                  break;
                case 'Colored Egg':
                  effectlabel = 'Amount of Colored Egg Restored';
                  break;
                case 'Game Coin':
                  effectlabel = 'Amount of Coins';
                  break;
                case 'Food':
                  effectlabel = 'Amount of Satiety Restored';
                  break;
                case 'Health':
                  effectlabel = 'Amount of Health Restored';
                  break;
                case 'Equipment':
                  effectlabel = 'Hours of Shield Effect';
                  break;
                
                default:
                  effectlabel = 'Effect';
                  break;
              }
              setEffectLabel(effectlabel);
            }}>
            <Option value="Spin">Spin</Option>
            <Option value="Game Ticket">Game Ticket</Option>
            <Option value="Colored Egg">Colored Egg</Option>
            <Option value="Game Coin">Game Coin</Option>
            <Option value="Promotion">Promotion</Option>
            <Option value="Food">Food</Option>
            <Option value="Health">Health</Option>
            <Option value="Equipment">Equipment</Option>
            <Option value="Consumable">Consumable</Option>
          </Select>
        </Form.Item>
        <Form.Item name="effect" label={effectLabel || 'Effect'} rules={[{ required: true }]}>
          <InputNumber min={0} placeholder="0" />
        </Form.Item>
        <Form.Item name="descriptions" label="Descriptions">
          <TextArea placeholder="Write your description here..." />
        </Form.Item>
        {form.getFieldValue('gameItemCategory') === 'Promotion' && (
          <Tabs defaultActiveKey="1">
            <TabPane tab="Items" key="1">
              <ItemPrizePool />
            </TabPane>
          </Tabs>
        )}
      </Form>
    </div>
  );
}
