import React, { useState, useEffect } from 'react';
import { DatePicker, List, Row, Col, message, Form, Button, Space, Select, Skeleton } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { getToken } from '../../utils/AuthService';

const { RangePicker } = DatePicker;

const ACTION_TYPE = {
  /// //////////////////////////////////////////////
  TierHistory: 'tierHistory',
  TutorialRewards: 'tutorialRewards', // 新手教学奖励
  DistributeVoucher: 'distributeVoucher', //
  ClaimElixir: 'claimElixir',
  Redeem: 'redeem',
  ReloadPinRedeem: 'reloadPinRedeem',
  /// //////////////////////////////////////////////
  Attack: 'Attack', // 攻击（主动）
  Attacked: 'Attacked', // 被攻击
  Steal: 'Steal', // 偷蛋（主动）
  Stolen: 'Stolen', // 被偷

  Overwhelmed: 'Overwhelmed', // 破防
  EggReward: 'EggReward', // 领取孵蛋奖励
  CardpackReward: 'CardpackReward', // 卡包集齐奖励
  MiniGameReward: 'MiniGameReward', // 小游戏排行奖励
  SpinReward: 'SpinReward', // 转盘奖励
  ExchangeCard: 'ExchangeCard', // 交换卡片
  MiniGameScoreConvert: 'MiniGameScoreConvert', // 小游戏分数转换heecoin
};

export default function PlayerActivity({ playerId }) {
  const [loading, setLoading] = useState(true);
  const [activityList, setActivityList] = useState([]);
  const [activityData, setActivityData] = useState([]);

  const token = getToken();

  const [filterForm] = Form.useForm();

  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      list.push({
        key: data[i].id,
        description: data[i].description,
        date: data[i].createdAt,
      });
    }
    setActivityData(list);
  };

  const [limit, setLimit] = useState(10); 
  const [page, setPage] = useState(1); 
  const [totalData, setTotalData] = useState();
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setLoading(true);

    const params = {
      playerId,
      sortBy: '-createdAt',
      limit,
      page,
      ...filterData,
    };

    const getData = async () => {
      await axios
        .get('actionlogs', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params,
        })
        .then((res) => {
          setActivityList(res.data.results);
          setTotalData(res.data.totalResults);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve actionlogs data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, [limit, page, filterData]);

  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const onFilterUpdate = () => {
    setIsFilterUpdated(true);
  };

  const onFilter = () => {
    filterForm
      .validateFields()
      .then((res) => {
        const { date, actionType } = res;

        const items = {};

        if (date) {
          items.startDate = moment(date[0]).startOf('day').toDate();
          items.endDate = moment(date[1]).endOf('day').toDate();
        }
        if (actionType === 'All' || actionType === undefined) {
          items.actionType = undefined;
        } else {
          items.actionType = actionType;
        }

        setFilterData(items);

        setIsFilterUpdated(true);
      })
      .catch(() => {});
  };

  const clearFilter = () => {
    filterForm.resetFields();
    onFilter();
    setTimeout(() => {
      setIsFilterUpdated(false);
    }, 0);
  };

  useEffect(() => {
    if (loading === false) {
      rerenderList(activityList);
    }
  }, [loading, activityList]);

  const onChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
  };

  return (
    <>
      <Form form={filterForm}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col sm={24} lg={12}>
            <Row>
              <p>Date & Time</p>
            </Row>
            <Row>
              <Form.Item name="date" style={{ width: '100%' }}>
                <RangePicker style={{ width: '100%' }} onChange={onFilterUpdate} />
              </Form.Item>
            </Row>
          </Col>
          <Col sm={24} lg={12}>
            <Row>
              <p>Action Type</p>
            </Row>
            <Row>
              <Form.Item name="actionType" style={{ width: '100%' }}>
                <Select placeholder="Select Action Type" defaultValue="All" style={{ width: '100%' }} onChange={onFilterUpdate}>
                  <Select.Option value="All">All</Select.Option>

                  <Select.Option value={ACTION_TYPE.Attack}>Attack History</Select.Option>
                  <Select.Option value={ACTION_TYPE.Attacked}>Attacked History</Select.Option>
                  <Select.Option value={ACTION_TYPE.Steal}>Steal History</Select.Option>
                  <Select.Option value={ACTION_TYPE.Stolen}>Stolen History</Select.Option>

                  <Select.Option value={ACTION_TYPE.Redeem}>Voucher Redemption</Select.Option>
                  <Select.Option value={ACTION_TYPE.ReloadPinRedeem}>Reload PIN Redemption</Select.Option>
                  <Select.Option value={ACTION_TYPE.TierHistory}>Tier History</Select.Option>
                  <Select.Option value={ACTION_TYPE.ClaimElixir}>Elixir Claim</Select.Option>
                  <Select.Option value={ACTION_TYPE.EggReward}>Egg Reward </Select.Option>
                  <Select.Option value={ACTION_TYPE.SpinReward}>Spin Reward </Select.Option>
                  <Select.Option value={ACTION_TYPE.CardpackReward}>Cardpack Reward </Select.Option>
                  <Select.Option value={ACTION_TYPE.MiniGameReward}>MiniGame Reward </Select.Option>
                  <Select.Option value={ACTION_TYPE.ExchangeCard}>Card Exchange </Select.Option>
                  <Select.Option value={ACTION_TYPE.MiniGameScoreConvert}>Game Score Convert </Select.Option>

                </Select>
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
      <Row justify="end">
        <Space>
          <Col>
            <Button danger onClick={clearFilter} disabled={!isFilterUpdated}>Reset Filters</Button>
          </Col>
          <Col>
            <Button onClick={onFilter}>Apply Filters</Button>
          </Col>
        </Space>
      </Row>
      {loading === false ? (
        <List
          pagination={{ pageSize: limit,
            pageSizeOptions: [10, 20],
            showSizeChanger: true,
            total: totalData,
            onChange,
            current: page,
            position: 'bottom',
            align: 'end',
          }}
          itemLayout="horizontal"
          dataSource={activityData}
          renderItem={(item) => (
            <>
              <List.Item>
                <List.Item.Meta description={item.description} />
                <div>{moment(item.date).format('YYYY-MM-DD HH:mm:ss')}</div>
              </List.Item>
            </>
          )}
          style={{ marginTop: '20px' }}
        />
      ) : (
        <Skeleton />
      )}
    </>
  );
}
