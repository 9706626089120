import React from 'react';
import { Typography, Layout, Row, Col, Button, Space, Breadcrumb, Form, message } from 'antd';
import { CheckOutlined, LeftOutlined } from '@ant-design/icons';
// import moment from 'moment';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

import HeeCoinForm from '../../components/heeCoinSetting/HeeCoinForm';

const { Title, Link } = Typography;
const { Content } = Layout;

export default function CreateHeeCoin() {
  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/heecoin-setting">
            <LeftOutlined />
            HeeCoin
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Create HeeCoin</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const [form] = Form.useForm();
  const token = getToken();

  const handleSave = async () => {
    try {
      const formData = form.getFieldsValue();

      // TODO: change status and balance
      const dataPackage = {
        name: formData.name,
        amount: formData.amount,
        quantity: formData.quantity,
      };

      await axios.post('heeCoin', dataPackage, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      message.success('HeeCoin data saved successfully.');

      setTimeout(() => {
        window.location.href = '/heecoin-setting';
      }, 1000);
    } catch (error) {
      message.error(`Failed to save HeeCoin data. ${error}`);
    }
  };

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Row>
          <Col flex={4}>
            <Title
              level={3}
              style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '20px' }}>
              Create HeeCoin
            </Title>
          </Col>
          <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              {/* <Button type="primary" disabled>
                <DeleteOutlined />
                Deactivate Voucher
              </Button> */}
              <Button type="primary" onClick={handleSave}>
                <CheckOutlined />
                Save
              </Button>
            </Space>
          </Col>
        </Row>
        <HeeCoinForm form={form} mode="Create" data={null} />
      </Content>
    </>
  );
}
