import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Col,
  Layout,
  Row,
  Space,
  Button,
  Typography,
  Breadcrumb,
  message,
  Tooltip,
  Skeleton,
} from 'antd';
import { PlusOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';

import axios from 'axios';
import { getToken } from '../../utils/AuthService';

const { Content } = Layout;
const { Link } = Typography;

export default function Tier() {
  const [loading, setLoading] = useState(true);
  const [tierList, setTierList] = useState([]);
  const [tierListData, setTierListData] = useState([]);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();

  const history = useHistory();

  const redirectToDestinationPage = (key) => {
    // Your custom logic here, e.g., setting state and redirecting
    const dataToPass = { tier: key };

    // Use history.push to navigate to the destination page
    history.push('/player-management', { filterData: dataToPass });
  };

  const columns = [
    {
      title: 'Tier',
      dataIndex: 'tier',
      key: 'key',
      width: 70,
    },
    {
      title: 'Tier Name',
      dataIndex: 'name',
      key: 'name',
      width: 90,
    },
    {
      title: 'Players in Tier',
      dataIndex: 'playersInTier',
      key: 'playersInTier',
      width: 100,
      render: (text, record) => (
        <Button
          className="ant-typography"
          type="text"
          onClick={() => redirectToDestinationPage(record.key)}>
          {text}
        </Button>
      ),
    },
    {
      title: (
        <Row>
          <Col>Egg Rate (Minutes)</Col>
          <Col style={{ paddingLeft: '10px' }}>
            <Tooltip title="Time based egg spawn time for relevant tier players.">
              <InfoCircleOutlined />
            </Tooltip>
          </Col>
        </Row>
      ),
      dataIndex: 'eggRateSeconds',
      key: 'eggRateSeconds',
      render: (data) => data / 60,
      width: 200,
    },
    {
      title: (
        <Row>
          <Col>Turnover (RM)</Col>
          <Col style={{ paddingLeft: '10px' }}>
            <Tooltip title="The monetary value of distributed rewards allocated to a player before tier regression.">
              <InfoCircleOutlined />
            </Tooltip>
          </Col>
        </Row>
      ),
      dataIndex: 'turnover',
      key: 'turnover',
      width: 150,
    },
    {
      title: (
        <Row>
          <Col>Threshold</Col>
          <Col style={{ paddingLeft: '10px' }}>
            <Tooltip title="The minimum spending threshold required for a player to advance to current tier.">
              <InfoCircleOutlined />
            </Tooltip>
          </Col>
        </Row>
      ),
      dataIndex: 'threshold',
      key: 'threshold',
      width: 170,
      render: (threshold) => <span> RM {threshold} </span>,
    },
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 90,
      render: (record) => (
        <Button type="text" href={`/tier/${record.key}/edit`}>
          <EditOutlined />
        </Button>
      ),
    },
  ];

  const token = getToken();

  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      list.push({
        key: data[i]._id,
        tier: data[i].tier,
        playersInTier: data[i].playersInTier,
        name: data[i].name,
        eggRateSeconds: data[i].eggRateSeconds,
        prizeRate: data[i].prizeRate,
        prizePool: data[i].prizePool,
        turnover: data[i].turnover,
        threshold: data[i].threshold,
      });
    }
    setTierListData(list);
  };

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      await axios
        .get('tiers', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            limit,
            page,
          },
        })
        .then((res) => {
          setTierList(res.data.results);
          setTotalData(res.data.totalResults);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve transactions data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, [limit, page]);

  useEffect(() => {
    if (loading === false) {
      rerenderList(tierList);
    }
  }, [loading, tierList]);

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/tier">Tier</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const onChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
  };

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Row justify="end">
            <Col>
              <Space>
                <Button type="primary" href="/tier/create">
                  <PlusOutlined />
                  Add New Tier
                </Button>
              </Space>
            </Col>
          </Row>
          <div className="table-content">
            {loading === false ? (
              <Table
                columns={columns}
                dataSource={tierListData}
                pagination={{
                  pageSize: limit,
                  pageSizeOptions: [10, 20],
                  showSizeChanger: true,
                  total: totalData,
                  onChange,
                  current: page,
                }}
                scroll={{ x: '100%' }}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </Space>
      </Content>
    </>
  );
}
