import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, NavLink, useHistory } from 'react-router-dom';

// Ant Design
import { Layout, Menu, Row, Col, Button, message, Tooltip, Image, Drawer, Badge } from 'antd';
import {
  UserOutlined,
  LogoutOutlined,
  LineChartOutlined,
  ProfileOutlined,
  BellOutlined,
  SettingOutlined,
  SmileOutlined,
  ShoppingOutlined,
  TagOutlined,
  WalletOutlined,
  AppstoreOutlined,
  // NotificationOutlined,
  ShopOutlined,
  CrownOutlined,
  OrderedListOutlined,
  SecurityScanOutlined,
  DollarOutlined,
  GiftOutlined,
  SketchOutlined,
  CreditCardOutlined,
  FormatPainterOutlined,
  BorderOuterOutlined,
} from '@ant-design/icons';

import axios from 'axios';
import Cookie from 'js-cookie';

// File
import './layout.css';
import '../App.less';
import '../styles/general.css';
import logo from '../assets/images/heewon_logo.png';
import { getToken, removeUserSession, getUser } from '../utils/AuthService';
import RouteList from '../routes/RouteLists';
import NotificationsDrawerContent from '../components/NotificationsDrawerContent';

const { Header, Content, Sider } = Layout;

export default function LayoutPage() {
  const history = useHistory();
  const token = getToken();
  const user = getUser();

  let activeHref;
  const { location } = window;
  const hasQueryParam = window.location.href.includes('?');

  if (hasQueryParam === true) {
    activeHref = location.pathname.split('/', 2)[1];
  } else {
    activeHref = location.href.split('/')[3];
    // console.log('false: ', activeHref);
  }

  const [collapsed, setCollapsed] = useState(false);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
  };

  // handles notification drawer + sider menu interaction
  const [notifPanelOpen, setNotifPanelOpen] = useState(false);
  const [menuActiveItem, setMenuActiveItem] = useState(`/${activeHref}`);
  const [loading, setLoading] = useState(true);
  const [isNotifExist, setIsNotifExist] = useState(false);
  const [allNotif, setAllNotif] = useState([]);
  const [requestNotif, setRequestNotif] = useState([]);
  // changes when notif data refetch required 
  const [flag, setFlag] = useState(true);

  useEffect(() => {
    setLoading(true);

    if (user.role === 'admin') {
      const getAllNotif = async () => {
        await axios
          .get('dashboardNotif?status=Unread', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
            params: {
              limit: 100,
              page: 1,
            },
          })
          .then((res) => {
            if (res.data.results.length === 0) {
              setAllNotif([]);
              setIsNotifExist(false);
            } else {
              setIsNotifExist(true);
              setAllNotif(res.data.results);
            }
          })
          .catch((error) => {
            message.error(`Failed to retrieve notifs. ${error}`);
            setLoading(false);
          });
      };
      const getRequests = async () => {
        await axios
          .get('dashboardNotif?type=Request', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
            params: {
              limit: 100,
              page: 1,
            },
          })
          .then((res) => {
            if (res.data.results.length === 0) {
              setRequestNotif([]);
            } else {
              setRequestNotif(res.data.results);
            }
          })
          .catch((error) => {
            message.error(`Failed to retrieve notifs. ${error}`);
            setLoading(false);
          });
      };

      getAllNotif();
      getRequests();
      setLoading(false);
    }

    if (user.role === 'operation') {
      const getOpNotif = async () => {
        const adsReminder = axios.get('dashboardNotif?status=Unread&type=adsReminder', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            limit: 100,
            page: 1,
          },
        });
        const playersReg = axios.get('dashboardNotif?status=Unread&type=playerReg', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            limit: 100,
            page: 1,
          },
        });

        const response = await axios.all([adsReminder, playersReg]);
        const adsReminderNotif = response[0].data.results;
        const playersRegNotif = response[1].data.results;

        const combinedResults = [...adsReminderNotif, ...playersRegNotif];
        if (combinedResults.length > 0) {
          setIsNotifExist(true);
        }

        setAllNotif(combinedResults);
        setLoading(false);
      };

      getOpNotif();
    }
  }, [flag]);

  const showNotifPanel = () => {
    setNotifPanelOpen(true);
  };
  const closeNotifPanel = () => {
    setNotifPanelOpen(false);
    setMenuActiveItem(`/${activeHref}`);
  };

  useEffect(() => {
    if (hasQueryParam === true) {
      activeHref = location.pathname.split('/', 2)[1];
      setMenuActiveItem(`/${activeHref}`);
    } else {
      activeHref = location.href.split('/')[3];
      setMenuActiveItem(`/${activeHref}`);
    }
  }, [window.location.href]);

  const handleMenuClick = ({ key }) => {
    if (key === '/notification') {
      if (notifPanelOpen) {
        closeNotifPanel();
      } else {
        showNotifPanel();
        setMenuActiveItem(key);
      }
    } else {
      setNotifPanelOpen(false);
      setMenuActiveItem(key);
    }
  };

  const handleLogout = () => {
    axios
      .post('/auth/logout', {
        refreshToken: token.refresh.token,
      })
      .then(() => {
        removeUserSession();
        Cookie.remove('selectedTab');
        history.push('/login');
        window.location.reload();
      })
      .catch((e) => {
        message.error(`${e}. Please try again.`);
      });
  };

  // merchant menu items
  const menuItems = [
    {
      key: '/',
      label: (
        <NavLink to="/" activeclassname="active">
          Dashboard
        </NavLink>
      ),
      icon: <LineChartOutlined />,
    },
    {
      key: 'vouchers',
      label: 'Vouchers',
      icon: <TagOutlined />,
      children: [
        {
          label: (
            <NavLink to="/vouchers" activeclassname="active">
              Vouchers
            </NavLink>
          ),
          key: '/vouchers',
        },
        {
          label: (
            <NavLink to="/voucher-request" activeclassname="active">
              Requests
            </NavLink>
          ),
          key: '/voucher-request',
        },
        {
          label: (
            <NavLink to="/voucher-history" activeClassName="active">
              Voucher History
            </NavLink>
          ),
          key: '/voucher-history',
        },
      ],
    },
    {
      key: '/branches',
      label: (
        <NavLink to="/branches" activeclassname="active">
          Branches
        </NavLink>
      ),
      icon: <ShopOutlined />,
    },
    {
      key: '/settings',
      label: (
        <NavLink to="/settings" activeclassname="active">
          Settings
        </NavLink>
      ),
      icon: <SettingOutlined />,
    },
  ];

  // merchantBranch menu items
  const branchMenuItems = [
    {
      key: 'vouchers',
      label: 'Vouchers',
      icon: <TagOutlined />,
      children: [
        {
          label: (
            <NavLink to="/vouchers" activeclassname="active">
              Vouchers
            </NavLink>
          ),
          key: '/vouchers',
        },
        {
          label: (
            <NavLink to="/voucher-history" activeClassName="active">
              Voucher History
            </NavLink>
          ),
          key: '/voucher-history',
        },
      ],
    },
    {
      key: '/settings',
      label: (
        <NavLink to="/settings" activeclassname="active">
          Settings
        </NavLink>
      ),
      icon: <SettingOutlined />,
    },
  ];

  // administrator menu items
  const administratorMenuItems = [
    {
      key: '/',
      label: (
        <NavLink to="/" activeclassname="active">
          Dashboard
        </NavLink>
      ),
      icon: <LineChartOutlined />,
    },

    {
      key: '/cardpackConfigListPage',
      label: (
        <NavLink to="/cardpackConfigListPage" activeclassname="active">
          Cardpack 
        </NavLink>
      ),
      icon: <CreditCardOutlined />,
    },

    {
      key: '/incubatorConfigListPage',
      label: (
        <NavLink to="/incubatorConfigListPage" activeclassname="active">
          Incubator Config
        </NavLink>
      ),
      icon: <FormatPainterOutlined />,
    },

    {
      key: '/miniGameConfigPage',
      label: (
        <NavLink to="/miniGameConfigPage" activeclassname="active">
          Mini Game
        </NavLink>
      ),
      icon: <BorderOuterOutlined />,
    },
    {
      key: '/tier',
      label: (
        <NavLink to="/tier" activeclassname="active">
          Tier
        </NavLink>
      ),
      icon: <OrderedListOutlined />,
    },
    {
      key: '/player-management',
      label: (
        <NavLink to="/player-management" activeclassname="active">
          Players
        </NavLink>
      ),
      icon: <SmileOutlined />,
    },
    {
      key: '/merchants',
      label: (
        <NavLink to="/merchants" activeclassname="active">
          Merchants
        </NavLink>
      ),
      icon: <ShoppingOutlined />,
    },
    {
      key: 'vouchers',
      label: 'Vouchers',
      icon: <TagOutlined />,
      children: [
        {
          label: (
            <NavLink to="/vouchers" activeclassname="active">
              Vouchers
            </NavLink>
          ),
          key: '/vouchers',
        },
        {
          label: (
            <NavLink to="/voucher-request" activeclassname="active">
              Requests
            </NavLink>
          ),
          key: '/voucher-request',
        },
        {
          label: (
            <NavLink to="/voucher-history" activeClassName="active">
              Voucher History
            </NavLink>
          ),
          key: '/voucher-history',
        },
      ],
    },

    {
      key: 'HeeCoin',
      label: 'HeeCoin',
      icon: <SketchOutlined />,
      children: [
        {
          label: (
            <NavLink to="/heecoin-setting" activeclassname="active">
              Heecoin Setting
            </NavLink>
          ),
          key: '/heecoin-setting',
        },

        {
          label: (
            <NavLink to="/heecoin-history" activeclassname="active">
              Heecoin History
            </NavLink>
          ),
          key: '/heecoin-history',
        },
      
      ],
    },
    {
      key: '/transaction',
      label: (
        <NavLink to="/transaction" activeclassname="active">
          Transaction
        </NavLink>
      ),
      icon: <WalletOutlined />,
    },
    {
      key: '/gamestore',
      label: (
        <NavLink to="/gamestore" activeclassname="active">
          Game Stores
        </NavLink>
      ),
      icon: <AppstoreOutlined />,
    },
    {
      key: '/treasurehunt',
      label: (
        <NavLink to="/treasurehunt" activeclassname="active">
          Treasure Hunt
        </NavLink>
      ),
      icon: <CrownOutlined />,
    },
    {
      key: '/goblinStats',
      label: (
        <NavLink to="/goblinStats" activeclassname="active">
          Goblin Config
        </NavLink>
      ),
      icon: <SecurityScanOutlined />,
    },
    {
      key: '/welcome-gift-setting',
      label: (
        <NavLink to="/welcome-gift-setting" activeclassname="active">
          Welcome Gift
        </NavLink>
      ),
      icon: <GiftOutlined />,
    },

    {
      key: '/reload-pin',
      label: (
        <NavLink to="/reload-pin" activeclassname="active">
          Reload Pin
        </NavLink>
      ),
      icon: <DollarOutlined />,
    },

    {
      key: '/user-management',
      label: (
        <NavLink to="/user-management" activeclassname="active">
          User Management
        </NavLink>
      ),
      icon: <UserOutlined />,
    },
    {
      key: '/audit-log',
      label: (
        <NavLink to="/audit-log" activeclassname="active">
          Audit Log
        </NavLink>
      ),
      icon: <ProfileOutlined />,
    },
    {
      key: '/notification',
      label: (
        <span>
          Notification {isNotifExist ? (<Badge color="red" />) : ''}
        </span>
      ),
      icon: <BellOutlined />,
    },
    {
      key: '/settings',
      label: (
        <NavLink to="/settings" activeclassname="active">
          Settings
        </NavLink>
      ),
      icon: <SettingOutlined />,
    },
  ];

  const operationMenuItems = [
    {
      key: '/tier',
      label: (
        <NavLink to="/tier" activeclassname="active">
          Tier
        </NavLink>
      ),
      icon: <OrderedListOutlined />,
    },
    {
      key: '/player-management',
      label: (
        <NavLink to="/player-management" activeclassname="active">
          Players
        </NavLink>
      ),
      icon: <SmileOutlined />,
    },
    {
      key: '/merchants',
      label: (
        <NavLink to="/merchants" activeclassname="active">
          Merchants
        </NavLink>
      ),
      icon: <ShoppingOutlined />,
    },
    {
      key: '/vouchers',
      label: (
        <NavLink to="/vouchers" activeclassname="active">
          Vouchers
        </NavLink>
      ),
      icon: <TagOutlined />,
    },
    {
      key: '/gamestore',
      label: (
        <NavLink to="/gamestore" activeclassname="active">
          Game Stores
        </NavLink>
      ),
      icon: <AppstoreOutlined />,
    },
    {
      key: '/treasurehunt',
      label: (
        <NavLink to="/treasurehunt" activeclassname="active">
          Treasure Hunt
        </NavLink>
      ),
      icon: <CrownOutlined />,
    },
    // {
    //   key: '/ad-management',
    //   label: (
    //     <NavLink to="/ad-management" activeclassname="active">
    //       Ads Management
    //     </NavLink>
    //   ),
    //   icon: <NotificationOutlined />,
    // },
    {
      key: '/user-management',
      label: (
        <NavLink to="/user-management" activeclassname="active">
          User Management
        </NavLink>
      ),
      icon: <UserOutlined />,
    },
    {
      key: '/notification',
      label: (
        <span>
          Notification {isNotifExist ? (<Badge color="red" />) : ''}
        </span>
      ),
      icon: <BellOutlined />,
    },
    {
      key: '/settings',
      label: (
        <NavLink to="/settings" activeclassname="active">
          Settings
        </NavLink>
      ),
      icon: <SettingOutlined />,
    },
  ];

  const financeMenuItems = [
    {
      key: '/',
      label: (
        <NavLink to="/" activeclassname="active">
          Dashboard
        </NavLink>
      ),
      icon: <LineChartOutlined />,
    },
    {
      key: '/transaction',
      label: (
        <NavLink to="/transaction" activeclassname="active">
          Transaction
        </NavLink>
      ),
      icon: <WalletOutlined />,
    },
    {
      key: '/audit-log',
      label: (
        <NavLink to="/audit-log" activeclassname="active">
          Audit Log
        </NavLink>
      ),
      icon: <ProfileOutlined />,
    },
    {
      key: '/settings',
      label: (
        <NavLink to="/settings" activeclassname="active">
          Settings
        </NavLink>
      ),
      icon: <SettingOutlined />,
    },
  ];

  let sidebarItems = [];
  let openKeys = [];

  switch (user.role) {
    case 'admin':
      sidebarItems = administratorMenuItems;
      break;
    case 'operation':
      sidebarItems = operationMenuItems;
      break;
    case 'finance':
      sidebarItems = financeMenuItems;
      break;
    case 'merchantHQ':
      sidebarItems = menuItems;
      break;
    case 'merchantBranch':
      sidebarItems = branchMenuItems;
      openKeys = ['vouchers'];
      break;
    default:
      break;
  }
  return (
    <div>
      <Router>
        <Layout style={{ minHeight: '100vh' }}>
          <Sider
            style={{
              overflow: 'auto',
            }}
            breakpoint="md"
            className="menu-layout"
            collapsible
            collapsed={collapsed}
            onCollapse={handleCollapse}>
            <div style={{ height: collapsed ? 15 : 100, margin: 16 }}>
              <Image src={logo} height={collapsed ? 30 : 100} preview={false} />
            </div>
            <Menu
              defaultSelectedKeys={[`/${activeHref}`]}
              selectedKeys={[menuActiveItem]}
              mode="inline"
              onClick={handleMenuClick}
              items={sidebarItems}
              defaultOpenKeys={openKeys}
            />
          </Sider>
          <Layout>
            <Header className="header-layout">
              <Row>
                <Col span={12} style={{ fontSize: 16, fontWeight: 500 }}>
                  HeeWon
                </Col>
                <Col span={12} className="header-text">
                  <p style={{ marginRight: '0px' }}>{user.name}</p>
                  <p>
                    <Tooltip placement="bottom" title="Log Out">
                      <Button
                        type="link"
                        block
                        key="/logout"
                        icon={<LogoutOutlined />}
                        onClick={handleLogout}
                      />
                    </Tooltip>
                  </p>
                </Col>
              </Row>
            </Header>
            <Content className="content-layout drawer-wrap">
              {loading === false ? (
                <Drawer
                  placement="left"
                  closable={false}
                  onClose={closeNotifPanel}
                  open={notifPanelOpen}
                  getContainer={false}
                  style={{
                    position: 'absolute',
                  }}>
                  <NotificationsDrawerContent
                    role={user.role}
                    allNotif={allNotif}
                    requestNotif={requestNotif}
                    closeNotifPanel={closeNotifPanel}
                    onDataUpdate={() => setFlag(!flag)}
                  />
                </Drawer> 
              ) : '' }
              {RouteList(user.role)}
            </Content>
          </Layout>
        </Layout>
      </Router>
    </div>
  );
}
