import React, { useState, useEffect } from 'react';
import { DatePicker, List, Row, Col, Select, Space, Typography, message, Form, Button, Skeleton } from 'antd';
import axios from 'axios';
import moment from 'moment';
import { getToken } from '../../utils/AuthService';

const { RangePicker } = DatePicker;
const { Text } = Typography;

export default function SpendingDetails({ playerId }) {
  const [loading, setLoading] = useState(true);
  const [buyList, setBuyList] = useState([]);
  const [buyData, setBuyData] = useState([]);

  const token = getToken();

  const [filterForm] = Form.useForm();

  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      list.push({
        key: data[i].id,
        item: data[i].description,
        spent: `- ${data[i].amount} ${data[i].currency}`,
        type: data[i].type,
        date: data[i].createdAt,
      });
    }
    setBuyData(list);
  };

  const [limit, setLimit] = useState(7); 
  const [page, setPage] = useState(1); 
  const [totalData, setTotalData] = useState();
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    setLoading(true);

    const params = {
      playerId,
      category: 'in-game',
      ...filterData,
    };

    const getData = async () => {
      await axios
        .get('transactions', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params,
        })
        .then((res) => {
          setBuyList(res.data.results);
          setTotalData(res.data.totalResults);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve transactions data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, [filterData]);

  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const onFilterUpdate = () => {
    setIsFilterUpdated(true);
  };

  const onFilter = () => {
    filterForm
      .validateFields()
      .then((res) => {
        const { type, date } = res;

        const items = {};

        if (type !== 'all') {
          items.type = type;
        }
        if (date) {
          items.startDate = moment(date[0]).startOf('day').toDate();
          items.endDate = moment(date[1]).endOf('day').toDate();
        }

        setFilterData(items);
        setIsFilterUpdated(true);
      })
      .catch(() => {});
  };

  const clearFilter = () => {
    filterForm.resetFields();
    onFilter();
    setTimeout(() => {
      setIsFilterUpdated(false);
    }, 0);
  };

  useEffect(() => {
    if (loading === false) {
      rerenderList(buyList);
    }
  }, [loading, buyList]);

  const onChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
  };

  return (
    <>
      <Form form={filterForm}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col sm={24} lg={12}>
            <Row>
              <p>Date & Time</p>
            </Row>
            <Row>
              <Form.Item name="date" style={{ width: '100%' }}>
                <RangePicker style={{ width: '100%' }} onChange={onFilterUpdate} />
              </Form.Item>
            </Row>
          </Col>
          <Col sm={24} lg={12}>
            <Row>
              <p>Category</p>
            </Row>
            <Row>
              <Form.Item name="type" style={{ width: '100%' }}>
                <Select
                  defaultValue="all"
                  onChange={onFilterUpdate}
                  options={[
                    {
                      value: 'all',
                      label: 'All',
                    },
                    {
                      value: 'Food',
                      label: 'Food',
                    },
                    {
                      value: 'Health',
                      label: 'Health',
                    },
                    {
                      value: 'Equipment',
                      label: 'Equipment',
                    },
                    {
                      value: 'Incubator',
                      label: 'Incubator',
                    },
                    {
                      value: 'Game Coin',
                      label: 'Game Coin',
                    },
                  ]}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>
      <Row justify="end">
        <Space>
          <Col>
            <Button danger onClick={clearFilter} disabled={!isFilterUpdated}>Reset Filters</Button>
          </Col>
          <Col>
            <Button onClick={onFilter}>Apply Filters</Button>
          </Col>
        </Space>
      </Row>
      {loading === false ? (      
        <List
          pagination={{ pageSize: limit,
            pageSizeOptions: [7, 15],
            showSizeChanger: true,
            total: totalData,
            onChange,
            current: page,
            position: 'bottom',
            align: 'end',
          }}
          itemLayout="horizontal"
          dataSource={buyData}
          renderItem={(item) => (
            <>
              <List.Item
                extra={
                  (
                    <Space direction="vertical" size="small" style={{ textAlign: 'right' }}>
                      <Text level={5}>{item.spent}</Text>
                      <Text type="secondary">{moment(item.date).format('YYYY-MM-DD HH:mm:ss')}</Text>
                    </Space>
                  )
                }>
                <List.Item.Meta title={item.item} description={item.type} />
              </List.Item>
            </>
          )}
          style={{ marginTop: '20px' }}
        />
      ) : (
        <Skeleton />
      )}
    </>
  );
}
