import React, { useEffect } from 'react';
import {
  Form,
  Input,
  // Select,
  Row,
  Col,
  InputNumber,
} from 'antd';
// import { InfoCircleOutlined } from '@ant-design/icons';
// import axios from 'axios';
// import { getToken } from '../../utils/AuthService';

export default function VoucherForm({ form, mode, data }) {
  // const token = getToken();

  useEffect(() => {
    if (mode === 'Edit') {
      form.setFieldsValue({
        name: data.name,
        amount: data.amount,
        quantity: data.quantity,
      });
    }
  }, [form, mode, data]);

  // const [tierList, setTierList] = useState([]);

  // useEffect(() => {
  //   const getTierData = async () => {
  //     await axios
  //       .get('tiers', {
  //         headers: {
  //           'Content-Type': 'application/json',
  //           Authorization: `Bearer ${token.access.token}`,
  //         },
  //         params: {
  //           limit: 50,
  //           page: 1,
  //         },
  //       })
  //       .then((res) => {
  //         setTierList(res.data.results);
  //       })
  //       .catch((error) => {
  //         message.error(`Failed to retrieve transactions data. ${error}`);
  //       });
  //   };

  //   getData();
  //   getTierData();
  // }, []);

  // function getTierList(tierData) {
  //   const list = [];
  //   for (let i = 0; i < tierData.length; i++) {
  //     list.push({ value: tierData[i].id, label: tierData[i].name });
  //   }

  //   return list;
  // }

  return (
    <Form form={form} layout="vertical">
      <Row gutter={16}>
        <Col xs={24} lg={16}>
          <div className="content-area">
            <Form.Item
              name="name"
              label="HeeCoin Name"
              rules={[
                {
                  required: true,
                  message: 'HeeCoin name cannot be empty!',
                },
              ]}>
              <Input placeholder="Enter HeeCoin Name" />
            </Form.Item>
            <Row gutter={10}>
              <Col>
                <Form.Item
                  name="amount"
                  label="Amount per Distribution"
                  rules={[
                    {
                      required: true,
                      message: 'HeeCoin amount cannot be empty!',
                    },
                  ]}>
                  <InputNumber style={{ width: '100%' }} min={0} placeholder="0" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={10}>
              <Col>
                <Form.Item
                  name="quantity"
                  label="Quantity"
                  rules={[
                    {
                      required: true,
                      message: 'Quantity cannot be empty!',
                    },
                  ]}>
                  <InputNumber style={{ width: '100%' }} min={0} placeholder="0" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
