import React, { useEffect, useState } from 'react';
import {
  Form,
  Tooltip,
  Select,
  Input,
  message,
  Row,
  Col,
  Modal,
  InputNumber,
  Breadcrumb,
  Skeleton,
  Button,
  Space,
  Typography,
  Layout,
} from 'antd';
import { InfoCircleOutlined, DeleteOutlined, CheckOutlined, LeftOutlined } from '@ant-design/icons';
import { PrizePool } from '../../../../components/componentV2/PrizePoolV2/PrizePool';

const { Title, Link } = Typography;
const { Content } = Layout;

export function IncubatorDetailForm({ isCreate, onSubmit, editData, loading, onDelete }) {
  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/incubatorConfigListPage">
            <LeftOutlined />
            Incubator
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{isCreate ? 'Create Config' : 'Edit Config'}</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const [form] = Form.useForm();

  const [prizePoolConfig, setPrizePoolConfig] = useState({
    mode: 'probability',
    poolTypes: ['Items', 'Cardpack'],
    totalProbability: 100,
    tierVouchersProbability: 0,
    itemPool: [],
    cardpackPool: [],
    heeCoinPool: [],
    voucherPool: [],
  });

  const handleSubmit = async () => {
    await form.validateFields();
    if (prizePoolConfig.itemPool.length === 0) {
      message.warning('Please select at least 1 item!');
      return;
    }

    if (prizePoolConfig.totalProbability !== 100) {
      message.warning('Weightage percentage must add up to 100!');
      return;
    }

    const formData = form.getFieldsValue();
    const dataPackage = {
      ...formData,
      roulettePrizePoolConfig: prizePoolConfig,
    };

    onSubmit(dataPackage);
  };

  const initEditData = () => {
    if (!isCreate && editData) {
      form.setFieldsValue(editData);
      
      if (editData.roulettePrizePoolConfig) {
        const { roulettePrizePoolConfig } = editData;
        setPrizePoolConfig(roulettePrizePoolConfig);
      }
    }
  };
  useEffect(() => { initEditData(); }, [editData]);

  const [modalActive, setModalActive] = useState(false);
  const showModal = () => {
    setModalActive(true);
  };

  const handleOk = () => {
    setModalActive(false);
    onDelete(editData);
  };
  const handleCancel = () => {
    setModalActive(false);
  };
  return (

    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Row>
          <Col flex={4}>
            <Title
              level={3}
              style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '20px' }}>
              {isCreate ? 'Create New Config' : 'Edit Config '}
            </Title>
          </Col>
          <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>

              <Button danger onClick={showModal} disabled={isCreate}>
                <DeleteOutlined />
                Delete
              </Button>
              <Modal
                title="Are you sure you want to delete?"
                open={modalActive}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                closable={false}
                footer={(
                  <>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button type="primary" danger onClick={handleOk}>
                      <DeleteOutlined />
                      Yes, Delete
                    </Button>
                  </>
                )}>
                <p>If you confirm this action, you are unable to undo.</p>
              </Modal>
              <Button type="primary" onClick={handleSubmit}>
                <CheckOutlined />
                Submit
              </Button>
            </Space>
          </Col>
        </Row>
        {loading === false ? (
          <Row>
            <Col xs={24} lg={20}>
              <Form form={form} layout="vertical">
                <div className="content-area">

                  <Form.Item
                    name="level"
                    label="Level"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter!',
                      },
                    ]}>
                    <InputNumber
                      placeholder="level"
                      disabled={!isCreate}
                      style={{
                        width: '120px',
                      }} />
                  </Form.Item>

                  <Row>
                 
                    <Col xs={24} lg={6}>
                      <Form.Item
                        name="upgradationCost"
                        initialValue={0}
                        label={(
                          <Row>
                            <Col>Upgradation Cost</Col>
                            <Col style={{ paddingLeft: '10px' }}>
                              <Tooltip title="Currency required for upgrading.">
                                <InfoCircleOutlined />
                              </Tooltip>
                            </Col>
                          </Row>
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter!',
                          },
                        ]}>
                        <InputNumber
                          min={0}
                          placeholder="0"
                          style={{
                            width: '120px',
                          }} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={6}>
                      <Form.Item
                        name="incubatorNum"
                        label="Incubator Number"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter!',
                          },
                        ]}>
                        <InputNumber
                          min={1}
                          max={99}
                          placeholder="0"
                          style={{
                            width: '120px',
                          }} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={6}>
                      <Form.Item
                        name="rouletteCell"
                        label="Roulette Cell"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter threshold!',
                          },
                        ]}>

                        <Select
                          style={{
                            width: '120px',
                          }}
                          options={[
                            {
                              value: 8,
                              label: 8,
                            },
                            {
                              value: 12,
                              label: 12,
                            },
                          ]}
                       />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={24} lg={6}>
                      <Form.Item
                        name="shortenedTime"
                        label={(
                          <Row>
                            <Col>Shortened Time (second)</Col>
                            <Col style={{ paddingLeft: '10px' }}>
                              <Tooltip title="Shortened incubation time.">
                                <InfoCircleOutlined />
                              </Tooltip>
                            </Col>
                          </Row>
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter!',
                          },
                        ]}>
                        <InputNumber
                          min={0}
                          placeholder="0"
                          style={{
                            width: '120px',
                          }} />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={6}>
                      <Form.Item
                        name="spinNum"
                        initialValue={0}
                        label={(
                          <Row>
                            <Col>Spin Number</Col>
                            <Col style={{ paddingLeft: '10px' }}>
                              <Tooltip title="Number of spins obtained per day.">
                                <InfoCircleOutlined />
                              </Tooltip>
                            </Col>
                          </Row>
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter!',
                          },
                        ]}>
                        <InputNumber
                          min={0}
                          placeholder="0"
                          style={{
                            width: '120px',
                          }} />
                      </Form.Item>
                    </Col>
                   
                    <Col xs={24} lg={6}>
                      <Form.Item
                        name="attackBonus"
                        label={(
                          <Row>
                            <Col>Attack Bonus</Col>
                            <Col style={{ paddingLeft: '10px' }}>
                              <Tooltip title="Gold coins obtained from successful attacks.">
                                <InfoCircleOutlined />
                              </Tooltip>
                            </Col>
                          </Row>
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter threshold!',
                          },
                        ]}>
                        <InputNumber
                          min={0}
                          placeholder="0"
                          style={{
                            width: '120px',
                          }} />
                      </Form.Item>
                    </Col>
                  </Row>

                </div>

                <PrizePool
                  headerTitle="Roulette  Prize Pool"
                  tabOption={prizePoolConfig.poolTypes}
                  mode={prizePoolConfig.mode}
                  itemSelectOptionUrl="/incubatorConfig/getSpinItemPrizeOptions"
                  option={prizePoolConfig}
                  onOptionChange={setPrizePoolConfig} /> 
              </Form>
            </Col>
          </Row>
        ) : (
          <Skeleton />
        )}
      </Content>
    </>
  );
}
