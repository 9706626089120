import React, { useState, useEffect, useCallback } from 'react';
import { utils, writeFileXLSX } from 'xlsx';
import {
  Table,
  Layout,
  Button,
  Row,
  Col,
  Space,
  Tag,
  Modal,
  Typography,
  Breadcrumb,
  Skeleton,
  message,
  Upload,
  Form,
  Select,
  Input,
  Card,
  Statistic,
  DatePicker,
} from 'antd';
import {
  EditOutlined,
  PlusOutlined,
  DownloadOutlined,
  SettingOutlined,
  UploadOutlined,
  PictureFilled,
  ImportOutlined,
} from '@ant-design/icons';

import moment from 'moment';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';
import ReloadPinTemplate from '../../assets/file/reloadPIN-template.csv';
// import sevenElevenLogo from '../../assets/images/7_eleven_logo.png';
// import touchNGoLogo from '../../assets/images/Touch-n-Go-Logo.png';

const { Content } = Layout;
const { Link, Text } = Typography;
const { Option } = Select;
const { Dragger } = Upload;
const { RangePicker } = DatePicker;

const columns = [
  {
    title: 'Platform',
    dataIndex: 'platform',
    key: 'platform',
    render: (data) => data.name,
  },
  {
    title: 'Amount (RM)',
    dataIndex: 'amount',
    key: 'amount',
  },
  {
    title: 'Serial Number',
    dataIndex: 'serialNumber',
    key: 'serialNumber',
    render: (data) => (data || '-'),
  },
  {
    title: 'Created By',
    dataIndex: 'createdBy',
    key: 'createdBy',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 130,
    render: (status) => {
      let res;

      switch (status) {
        case 'Published':
          res = <Tag color="green">Live</Tag>;
          break;
        case 'Unpublished':
          res = <Tag color="purple">Not Live</Tag>;
          break;
        case 'Redeemed':
          res = <Tag color="red">Redeemed</Tag>;
          break;
        default:
          res = '';
          break;
      }

      return res;
    },
  },
  {
    title: 'Player Redeem',
    dataIndex: 'redeemedBy',
    key: 'redeemedBy',
    render: (data) => {
      let name = data ? data.name ? data.name : '-' : '-';

      if (name !== '-') {
        name = <Link href={`/player-management/${data.id}`}>{name}</Link>;
      }

      return name;
    },
  },
  {
    title: 'Player ID',
    dataIndex: 'redeemedBy',
    key: 'redeemedBy',
    render: (data) => {
      let uid = data ? data.uid ? data.uid : '-' : '-';

      if (uid !== '-') {
        uid = <Link href={`/player-management/${data.id}`}>{uid}</Link>;
      }

      return uid;
    },
  },
  {
    title: 'Redeem Date',
    dataIndex: 'redeemedTime',
    key: 'redeemedTime',
    render: (data) => (data ? moment(data).format('YYYY-MM-DD') : '-'),
  },
  {
    title: 'Expired Date',
    dataIndex: 'expirationDate',
    key: 'expirationDate',
    render: (data) => (data ? moment(data).format('YYYY-MM-DD') : '-'),
  },
  {
    title: 'Created Date',
    dataIndex: 'createdDate',
    key: 'createdDate',
    render: (data) => (data ? moment(data).format('YYYY-MM-DD') : '-'),
  },
  {
    title: 'Action',
    key: 'action',
    fixed: 'right',
    width: 90,
    render: (record) => (
      <Button type="text" href={`/reload-pin/${record.key}/edit`}>
        <EditOutlined />
      </Button>
    ),
  },
];

// const platformList = [
//   {
//     label: '7-Eleven',
//     value: '7-Eleven',
//   },
//   {
//     label: 'Touch n Go',
//     value: 'Touch n Go',
//   },
// ];

export default function ReloadPin() {
  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/reload-pin">Reload Pin</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const token = getToken();
  const [loading, setLoading] = useState(true);

  const [reloadPinList, setReloadPinList] = useState([]);
  const [reloadPinListData, setReloadPinListData] = useState([]);

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [totalPages, setTotalPages] = useState();
  const [totalPublished, setTotalPublished] = useState(0);
  const [totalUnpublished, setTotalUnpublished] = useState(0);
  const [totalRedeemed, setTotalRedeemed] = useState(0);
  const [totalUnredeemed, setTotalUnredeemed] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [uploadData, setUploadData] = useState();
  // const [uploadedLogoNames, setUploadedLogoNames] = useState('');
  const [uploadedFileNames, setUploadedFileNames] = useState('');
  const [fileUploaded, setFileUploaded] = useState(false);
  const [ratio, setRatio] = useState(0);
  const [ratioUpdated, setRatioUpdated] = useState(false);

  const [form] = Form.useForm();
  const [isCreateNew, setIsCreateNew] = useState(false);

  const [imageUrl, setImageUrl] = useState();
  const [platformList, setPlatformList] = useState([]);

  const [filterForm] = Form.useForm();
  const [filterData, setFilterData] = useState({});
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  
  // export modal use
  const [modalActive, setModalActive] = useState(false);
  const showModal = () => {
    setModalActive(true);
  };

  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      list.push({
        key: data[i].id,
        platform: data[i].platform,
        amount: data[i].amount,
        // code: data[i].code,
        serialNumber: data[i].serialNumber,
        createdBy: data[i].createdBy.name,
        status: data[i].status,
        redeemedBy: data[i].redeemedBy,
        redeemedTime: data[i].redeemedTime,
        expirationDate: data[i].expirationDate,
        createdDate: data[i].createdAt,
      });
    }
    setReloadPinListData(list);
  };

  useEffect(() => {
    setLoading(true);

    const params = { ...filterData, limit, page };

    const getData = async () => {
      await axios
        .get('reloadPIN', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params,
        })
        .then((res) => {
          setReloadPinList(res.data.results);
          setTotalData(res.data.totalResults);
          setTotalPages(res.data.totalPages);
          let published = 0;
          let unpublished = 0;
          let redeemed = 0;
          let total = 0;

          res.data.statistics.forEach((data) => {
            switch (data.status) {
              case 'Published':
                published = data.count;
                break;
              case 'Unpublished':
                unpublished = data.count;
                break;
              case 'Redeemed':
                redeemed = data.count;
                total = data.totalAmount;
                break;
              default:
                setTotalPublished(0);
                setTotalUnpublished(0);
                setTotalRedeemed(0);
                setTotalUnredeemed(0);
                setTotalAmount(0);
                break;
            }
          });

          // Update the state only once after the loop
          setTotalPublished(published);
          setTotalUnpublished(unpublished);
          setTotalRedeemed(redeemed);
          setTotalUnredeemed(published + unpublished);
          setTotalAmount(total);
          
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve reload PIN data. ${error}`);
          setLoading(false);
        });
    };

    const getRatio = async () => {
      await axios
        .get('configs/heecoinConfig', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setRatio(res.data.value);
        })
        .catch((error) => {
          message.error(`Failed to retrieve reload PIN data. ${error}`);
          setLoading(false);
        });
    };

    const getPlatforms = async () => {
      await axios
        .get('reloadPINPlatform', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setPlatformList(res.data);
        })
        .catch((error) => {
          message.error(`Failed to retrieve ad data. ${error}`);
          setLoading(false);
        });
    };

    getPlatforms();
    getRatio();
    getData();
  }, [filterData, limit, page]);

  useEffect(() => {
    if (loading === false) {
      rerenderList(reloadPinList);
    }
  }, [loading, reloadPinList]);

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      await axios
        .get('reloadPIN', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: { limit },
        })
        .then((res) => {
          setReloadPinList(res.data.results);
          setTotalData(res.data.totalResults);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve ad data. ${error}`);
          setLoading(false);
        });
    };

    const getPlatforms = async () => {
      await axios
        .get('reloadPINPlatform', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setPlatformList(res.data);
        })
        .catch((error) => {
          message.error(`Failed to retrieve reload PIN platform data. ${error}`);
          setLoading(false);
        });
    };

    getPlatforms();
    getData();
    setFileUploaded(false);
  }, [fileUploaded]);

  useEffect(() => {
    setLoading(true);

    const getRatio = async () => {
      await axios
        .get('configs/heecoinConfig', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setRatio(res.data.value);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to ratio config data. ${error}`);
          setLoading(false);
        });
    };

    getRatio();
    setRatioUpdated(false);
  }, [ratioUpdated]);

  const onChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
  };

  const [ratioModalActive, setRatioModalActive] = useState(false);
  const showRatioModal = () => {
    setRatioModalActive(true);
  };

  const [importModalActive, setImportModalActive] = useState(false);
  const showImportModal = () => {
    setImportModalActive(true);
  };

  const handleCancel = () => {
    setModalActive(false);
    setImportModalActive(false);
    setRatioModalActive(false);
    setUploadData(null);
    form.setFieldsValue({
      platform: null,
      name: null,
      logo: null,
    });
    setImageUrl(null);
    setIsCreateNew(null);
  };

  const handleRetry = () => {
    setUploadData(null);
  };

  const handleConfirm = async () => {
    const payload = [];
    const newPlatform = form.getFieldValue('name');
    let selectedPlatform = form.getFieldValue('platform');
    const isNewPlatform = selectedPlatform === 'create';
    if (isNewPlatform) {
      if (platformList.some((platform) => platform.name === newPlatform)) {
        message.warning('Platform already exists. Please select the platform from the dropdown.');
        return;
      }
      const newPlatformData = {
        name: newPlatform,
        logoUrl: imageUrl,
      };
      await axios
        .post('reloadPINPlatform', newPlatformData, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          const { data } = res;
          selectedPlatform = data.id;
        })
        .catch((error) => {
          message.error(`Platform creation failed. ${error}`);
        });
    }
    uploadData.reloadPINData.forEach((data) => {
      const { expiryDate, platform, ...rest } = data;
      payload.push({
        ...rest,
        platform: selectedPlatform,
        expirationDate: expiryDate,
      });
    });
    await axios
      .post('reloadPIN/bulk-upload', payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      })
      .then((res) => {
        const { data } = res;
        if (data.success) {
          setTimeout(() => {
            message.success('Reload Pin uploaded successfully.', 2);
          }, 2);
          setFileUploaded(true);
        } else {
          message.error('Reload Pin upload failed.', 2);
        }

        handleCancel();
      })
      .catch((error) => {
        message.error(`Reload Pin upload failed. ${error}`);
      });
  };

  const beforeUploadCSV = (file) => {
    const isCSV = file.type === 'text/csv';

    if (!isCSV) {
      message.error('You can only upload CSV file!');
      return Promise.reject(new Error('Invalid file type.'));
    }

    return Promise.resolve(file);
  };

  const uploadProps = {
    name: 'reloadPinFile',
    action: 'reloadPIN/validate',
    maxCount: 1,
    beforeUpload: beforeUploadCSV,
    onChange(info) {
      const { status, name } = info.file;

      if (status) {
        setUploadedFileNames(name);
      }
    },
    customRequest: (options) => {
      const fileData = new FormData();
      fileData.append('csvFile', options.file);
      const config = {
        headers: {
          'Content-type': 'multipart/form-data',
          Authorization: `Bearer ${token.access.token}`,
        },
      };
      axios
        .post(options.action, fileData, config)
        .then((res) => {
          const { data } = res;
          setUploadData(data);
        })
        .catch((err) => {
          message.error(`${options.file.name} file upload failed. ${err.response.data.error}`);
        });
    },
  };

  const uploadedSuccessCols = [
    //   {
    //     title: 'Platform',
    //     dataIndex: 'platform',
    //     key: 'platform',
    //     align: 'left',
    //   },
    {
      title: 'Serial Number',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      align: 'center',
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
    },
    {
      title: 'Amount (MYR)',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      align: 'center',
      render: (data) => (data ? moment(data).format('YYYY-MM-DD') : '-'),
    },
  ];

  const uploadedFailedCols = [
    {
      title: 'Row',
      dataIndex: 'row',
      key: 'row',
      align: 'center',
    },
    // {
    //   title: 'Platform',
    //   dataIndex: 'platform',
    //   key: 'platform',
    //   align: 'left',
    //   render: (data) => {
    //     let res;

    //     if (data) {
    //       res = <Text type="danger">{data}</Text>;
    //     } else {
    //       res = <Text type="success">Valid</Text>;
    //     }

    //     return res;
    //   },
    // },
    {
      title: 'Serial Number',
      dataIndex: 'serialNumber',
      key: 'serialNumber',
      align: 'center',
      render: (data) => {
        let res;

        if (data) {
          res = <Text type="danger">{data}</Text>;
        } else {
          res = <Text type="success">Valid</Text>;
        }

        return res;
      },
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      align: 'center',
      render: (data) => {
        let res;

        if (data) {
          res = <Text type="danger">{data}</Text>;
        } else {
          res = <Text type="success">Valid</Text>;
        }

        return res;
      },
    },
    {
      title: 'Amount (MYR)',
      dataIndex: 'amount',
      key: 'amount',
      align: 'center',
      render: (data) => {
        let res;

        if (data) {
          res = <Text type="danger">{data}</Text>;
        } else {
          res = <Text type="success">Valid</Text>;
        }

        return res;
      },
    },
    {
      title: 'Expiry Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      align: 'left',
      render: (data) => {
        let res;

        if (data) {
          res = <Text type="danger">{data}</Text>;
        } else {
          res = <Text type="success">Valid</Text>;
        }

        return res;
      },
    },
  ];

  const handlePlatformChange = () => {
    if (form.getFieldValue('platform') === 'create') {
      setIsCreateNew(true);
    } else {
      setIsCreateNew(false);
    }
  };

  const handleRatioChange = (e) => {
    if (!Number.isNaN(parseInt(e.target.value, 10))) {
      setRatio(parseInt(e.target.value, 10));
    }
  };

  const handleSaveRatio = async () => {
    await axios
      .patch(
        'configs/heecoinConfig',
        { value: ratio },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        },
      )
      .then((res) => {
        if (res) {
          setTimeout(() => {
            message.success('Ratio setting updated successfully.', 2);
          }, 2);
        }

        setRatioUpdated(true);
        handleCancel();
      })
      .catch((error) => {
        message.error(`Ratio setting update failed. ${error}`);
      });
  };

  const beforeUpload = (file, type) => {
    const sizeInMB = file.size / 1024 / 1024;
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
      return Promise.reject(new Error('Invalid file type.'));
    }

    if (sizeInMB > 2) {
      message.error(`${file.name} exceeds 2MB`);
      return Promise.reject(new Error('File size exceeds 2MB.'));
    }

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const img = new Image();
        img.onload = () => {
          if (type === 'image') {
            if (img.width === 100 && img.height === 100) {
              resolve(file);
            } else {
              message.error('Image must be 100x100');
              reject(new Error('Invalid image dimensions.'));
            }
          } else if (type === 'icon') {
            if (img.width === 100 && img.height === 100) {
              resolve(file);
            } else {
              message.error('Image must be 100x100');
              reject(new Error('Invalid image dimensions.'));
            }
          }
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const props = {
    name: 'logo',
    beforeUpload: (file) => beforeUpload(file, 'image'),
    action: 'reloadPINPlatform/upload-platform-logo',
    maxCount: 1,
    onChange(info) {
      const { status } = info.file;

      if (!status) {
        message.error('Wrong type of file or dimension');
      } else {
        // setUploadedLogoNames(name);
      }
    },
    customRequest: (options) => {
      const imageData = new FormData();
      imageData.append('logo', options.file);
      const config = {
        headers: {
          'Content-type': 'multipart/form-data',
          Authorization: `Bearer ${token.access.token}`,
        },
      };
      axios
        .post(options.action, imageData, config)
        .then((res) => {
          setImageUrl(res.data.Location);
          form.setFieldsValue({ logo: res.data.Location });
          setTimeout(() => {
            message.success(`${options.file.name} file uploaded successfully.`, 2);
          }, 2);
        })
        .catch((err) => {
          message.error(`${options.file.name} file upload failed. ${err}`);
        });
    },
  };

  const onFilter = () => {
    filterForm
      .validateFields()
      .then((res) => {
        const { serialNumber, playerUid, status, redeemedTime, expirationDate, date } = res;
        const items = {};

        if (serialNumber) {
          items.serialNumber = serialNumber;
        }
        if (playerUid) {
          items.playerUid = playerUid;
        }
        if (status !== 'all') {
          items.status = status;
        }
        if (redeemedTime) {
          items.redeemedTimeStartDate = moment(redeemedTime[0]).startOf('day').toDate();
          items.redeemedTimeEndDate = moment(redeemedTime[1]).endOf('day').toDate();
        }
        if (expirationDate) {
          items.expirationStartDate = moment(expirationDate[0]).startOf('day').toDate();
          items.expirationEndDate = moment(expirationDate[1]).endOf('day').toDate();
        }
        if (date) {
          items.startDate = moment(date[0]).startOf('day').toDate();
          items.endDate = moment(date[1]).endOf('day').toDate();
        }
        
        setFilterData(items);
        setIsFilterUpdated(true);
      })
      .catch(() => {});
  };

  const onFilterUpdate = () => {
    setIsFilterUpdated(true);
  };

  const clearFilter = () => {
    filterForm.resetFields();
    onFilter();
    setTimeout(() => {
      setIsFilterUpdated(false);
    }, 0);
  };

  const StatisticRow = () => (
    <Row gutter={[16, 16]} style={{ padding: '20px 0' }}>
      <Col style={{ width: '20%' }}>
        <Card bordered={false}>
          <Statistic
            title="Total Live"
            value={totalPublished}
            valueStyle={{
              color: '#1890FF',
            }}
          />
        </Card>
      </Col>
      <Col style={{ width: '20%' }}>
        <Card bordered={false}>
          <Statistic
            title="Total Not Live"
            value={totalUnpublished}
            valueStyle={{
              color: '#1890FF',
            }}
          />
        </Card>
      </Col>
      <Col style={{ width: '20%' }}>
        <Card bordered={false}>
          <Statistic
            title="Total Unredeemed"
            value={totalUnredeemed}
            valueStyle={{
              color: '#1890FF',
            }}
          />
        </Card>
      </Col>
      <Col style={{ width: '20%' }}>
        <Card bordered={false}>
          <Statistic
            title="Total Redeemed"
            value={totalRedeemed}
            valueStyle={{
              color: '#1890FF',
            }}
          />
        </Card>
      </Col>
      <Col style={{ width: '20%' }}>
        <Card bordered={false}>
          <Statistic
            title="Total Redeemed (RM)"
            value={`RM ${totalAmount}`}
            valueStyle={{
              color: '#1890FF',
            }}
          />
        </Card>
      </Col>
    </Row>
  );

  const FilterRow = () => (
    <Form form={filterForm}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col xs={12} lg={8}>
          <Row>
            <p>Serial Number</p>
          </Row>
          <Row>
            <Form.Item name="serialNumber" style={{ width: '100%' }}>
              <Input placeholder="Serial Number" />
            </Form.Item>
          </Row>
        </Col>
        <Col xs={12} lg={8}>
          <Row>
            <p>Player ID</p>
          </Row>
          <Row>
            <Form.Item name="playerUid" style={{ width: '100%' }}>
              <Input placeholder="Player ID" />
            </Form.Item>
          </Row>
        </Col>
        <Col xs={12} lg={8}>
          <Row>
            <p>Status</p>
          </Row>
          <Row>
            <Form.Item name="status" style={{ width: '100%' }}>
              <Select 
                defaultValue="all"
                onChange={onFilterUpdate}
                options={[
                  {
                    value: 'all',
                    label: 'All',
                  },
                  {
                    value: 'Published',
                    label: 'Live',
                  },
                  {
                    value: 'Unpublished',
                    label: 'Not Live',
                  },
                  {
                    value: 'Redeemed',
                    label: 'Redeemed',
                  },
                ]}
              />
            </Form.Item>
          </Row>
        </Col>
        <Col xs={12} lg={8}>
          <Row>
            <p>Redeem Date</p>
          </Row>
          <Row>
            <Form.Item name="redeemedTime" style={{ width: '100%' }}>
              <RangePicker style={{ width: '100%' }} onChange={onFilterUpdate} />
            </Form.Item>
          </Row>
        </Col>
        <Col xs={12} lg={8}>
          <Row>
            <p>Expired Date</p>
          </Row>
          <Row>
            <Form.Item name="expirationDate" style={{ width: '100%' }}>
              <RangePicker style={{ width: '100%' }} onChange={onFilterUpdate} />
            </Form.Item>
          </Row>
        </Col>
        <Col xs={12} lg={8}>
          <Row>
            <p>Date</p>
          </Row>
          <Row>
            <Form.Item name="date" style={{ width: '100%' }}>
              <RangePicker style={{ width: '100%' }} onChange={onFilterUpdate} />
            </Form.Item>
          </Row>
        </Col>
      </Row>
      <Row justify="end">
        <Space>
          <Col>
            <Button danger onClick={clearFilter} disabled={!isFilterUpdated}>
              Reset Filters
            </Button>
          </Col>
          <Col>
            <Button onClick={onFilter}>Apply Filters</Button>
          </Col>
        </Space>
      </Row>
    </Form>
  );
  
  // export use
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };
  
  const handleOk = useCallback(async () => {
    let selectedRows = [];
  
    if (rowSelection.selectedRowKeys.length === 0) {
      // Export all rows when no rows are selected
      const params = {
        limit,
        ...filterData,
      };

      const getData = async (currentPage) => {
        const response = await axios
          .get('reloadPIN', {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
            params: { ...params, page: currentPage },
          });

        return response.data.results;
      };

      // generate promises to get all data
      const promises = Array.from({ length: totalPages }, (_, i) => getData(i + 1));

      // wait for promises to resolve
      const results = await Promise.all(promises);

      // array of arrays -> single array
      const flattenResults = results.flat();

      for (let i = 0; i < flattenResults.length; i++) {     
        selectedRows.push({
          platform: flattenResults[i].platform.name,
          amount: flattenResults[i].amount,
          serialNumber: flattenResults[i].serialNumber,
          createdBy: flattenResults[i].createdBy.name,
          status: flattenResults[i].status,
          playerRedeem: flattenResults[i].redeemedBy ? flattenResults[i].redeemedBy.name : '-',
          playerID: flattenResults[i].redeemedBy ? flattenResults[i].redeemedBy.uid : '-',
          redeemedTime: flattenResults[i].redeemedTime,
          expirationDate: flattenResults[i].expirationDate,
          createdDate: flattenResults[i].createdAt,
        });
      }
    } else {
      // Export selected rows
      selectedRows = reloadPinListData
        .filter((row) => rowSelection.selectedRowKeys.includes(row.key))
        .map(({ key, platform, amount, redeemedBy, ...rest }) => ({
          platform: rest.platform.name,
          amount: rest.amount,
          serialNumber: rest.serialNumber,
          createdBy: rest.createdBy,
          status: rest.status,
          playerRedeem: redeemedBy ? redeemedBy.name : '-',
          playerID: redeemedBy ? redeemedBy.uid : '-',
          redeemedTime: rest.redeemedTime,
          expirationDate: rest.expirationDate,
          createdDate: rest.createdDate,
        }));
    }

    // Rename column headers
    const renamedColumns = {
      platform: 'Platform',
      amount: 'Amount (RM)',
      serialNumber: 'Serial Number',
      createdBy: 'Created By',
      status: 'Status',
      playerRedeem: 'Player Redeem',
      playerID: 'Player ID',
      redeemedTime: 'Redeem Date',
      expirationDate: 'Expired Date',
      createdDate: 'Created Date',
    };

    const transformedRows = selectedRows.map((row) => (
      Object.entries(row).reduce((acc, [key, value]) => {
        const renamedKey = renamedColumns[key] || key;
        if (value !== null && (key === 'redeemedTime' || key === 'expirationDate' || key === 'createdDate')) {
          const date = new Date(value);
          const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZone: 'Asia/Kuala_Lumpur',
          };
          return { ...acc, [renamedKey]: date.toLocaleString('en-US', options) };
        }
        return { ...acc, [renamedKey]: value };
      }, {})));  
  
    const currentDate = new Date();
    const formattedDate = currentDate
      .toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(/[/:\s]/g, '_');
  
    const ws = utils.json_to_sheet(transformedRows);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    writeFileXLSX(wb, `ReloadPIN_${formattedDate}.xlsx`);

    setModalActive(false);
  }, [reloadPinListData, rowSelection.selectedRowKeys]);

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Row justify="end">
            <Col>
              <Space>
                <Button onClick={showModal}>
                  <ImportOutlined rotate={-90} />
                  Export
                </Button>
                <Modal
                  title="Are you sure you want to export reload PIN data?"
                  open={modalActive}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  centered
                  closable={false}
                  bodyStyle={{ padding: 0 }}
                  footer={[
                    <>
                      <Button onClick={handleCancel}>Cancel</Button>
                      <Button type="primary" onClick={handleOk}>
                        Export
                      </Button>
                    </>,
                  ]}
                />
              </Space>
            </Col>
          </Row>
          <FilterRow />
          <StatisticRow />
          <Row justify="end">
            <Col>
              <Space>
                <Button onClick={showRatioModal}>
                  <SettingOutlined />
                  Ratio Setting
                </Button>
                <Modal
                  title="Ratio Setting"
                  open={ratioModalActive}
                  onCancel={handleCancel}
                  closable={false}
                  centered
                  bodyStyle={{ padding: 0 }}
                  footer={[
                    <>
                      <Button onClick={handleCancel}>Cancel</Button>
                      <Button onClick={handleSaveRatio} type="primary">
                        Save Changes
                      </Button>
                    </>,
                  ]}>
                  <div
                    style={{
                      padding: '20px',
                      backgroundColor: 'inherit',
                    }}>
                    <div style={{ display: 'flex', width: '100%', gap: '0.75em' }}>
                      <div style={{ width: '100%', flex: 1 }}>
                        RM
                        <Input value={1} disabled />
                      </div>
                      <div style={{ height: '28px', marginTop: 'auto', flex: 0 }}>=</div>
                      <div style={{ width: '100%', flex: 1 }}>
                        HeeCoin
                        <Input value={ratio} onChange={handleRatioChange} />
                      </div>
                    </div>
                  </div>
                </Modal>
                <Button
                  href={ReloadPinTemplate}
                  download="reload-pin-template.csv"
                  target="_blank"
                  rel="noopener noreferrer">
                  <DownloadOutlined />
                  Download Template
                </Button>
                <Button type="primary" onClick={showImportModal}>
                  <PlusOutlined />
                  Add New Reload Pin
                </Button>
                <Modal
                  title="Add New Reload Pin"
                  open={importModalActive}
                  onCancel={handleCancel}
                  closable={false}
                  centered
                  bodyStyle={{ padding: 0 }}
                  footer={[
                    <>
                      <Button onClick={handleCancel}>Cancel</Button>
                      {uploadData && !uploadData.success && (
                        <Button onClick={handleRetry} type="primary">
                          Retry
                        </Button>
                      )}
                      {uploadData && uploadData.success && (
                        <Button
                          onClick={handleConfirm}
                          type="primary"
                          disabled={
                            (isCreateNew && (!form.getFieldValue('name') || !imageUrl)) 
                            || !form.getFieldValue('platform')
                          }>
                          Confirm
                        </Button>
                      )}
                    </>,
                  ]}>
                  <div
                    style={{
                      padding: '20px',
                      backgroundColor: 'inherit',
                    }}>
                    <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                      {!uploadData && (
                        <div>Please fill in downloaded template and upload here.</div>
                      )}
                      <div style={{ flex: 1 }}>
                        <Form form={form} layout="vertical">
                          <Form.Item
                            name="platform"
                            required
                            label="Redeem Platform"
                            initialValue={null}>
                            <Select
                              placeholder="Select platform"
                              optionLabelProp="label"
                              onChange={handlePlatformChange}>
                              {platformList.map((platform) => (
                                <Option key={platform.id} value={platform.id} label={platform.name}>
                                  <Space>
                                    <span role="img" aria-label={platform.name}>
                                      <img alt={platform.name} src={platform.logoUrl} height={25} />
                                    </span>
                                    {platform.name}
                                  </Space>
                                </Option>
                              ))}
                              {/* <Option value="Touch n Go" label="Touch n Go">
                                <Space>
                                  <span role="img" aria-label="Touch n Go">
                                    <img alt="Touch n Go" src={touchNGoLogo} height={25} />
                                  </span>
                                  Touch n Go
                                </Space>
                              </Option> */}
                              <Option
                                key="create"
                                value="create"
                                label="+ Add Platform (Eg: Watson)">
                                <Space>
                                  <span role="img" aria-label="China">
                                    <PlusOutlined />
                                  </span>
                                  Add Platform (Eg: Watson)
                                </Space>
                              </Option>
                            </Select>
                          </Form.Item>

                          {isCreateNew && (
                            <>
                              <Form.Item
                                name="name"
                                label="Platform Name"
                                required
                                rules={[
                                  {
                                    type: 'string',
                                    message: 'Please enter title!',
                                  },
                                ]}>
                                <Input placeholder="Enter Platform Name (Eg: Watson)" />
                              </Form.Item>
                              <Form.Item name="logo" label="Platform Logo" required>
                                <div style={{ display: 'flex' }}>
                                  <div style={{ flex: 1 }}>
                                    <Dragger
                                      {...props}
                                      accept=".jpg, .jpeg, .png"
                                      showUploadList={false}>
                                      {/* <PlusOutlined style={{ fontSize: '16px' }} /> */}
                                      <p className="ant-upload-drag-icon" style={{ marginBottom: '.5rem' }}>
                                        <PictureFilled />
                                      </p>
                                      <p style={{ fontSize: '14px' }}>
                                        Click/drag PNG or JPEG file to this area to upload logo
                                        image
                                      </p>
                                      <small>
                                        Required Size: <Text>100&times;100</Text>, Maximum file size
                                        : <Text>2MB</Text>
                                      </small>
                                    </Dragger>
                                  </div>
                                  {imageUrl && (
                                    <div style={{ flex: 'none', marginLeft: 10 }}>
                                      <img
                                        src={imageUrl}
                                        alt="Uploaded logo"
                                        style={{ width: 100, height: 'auto' }}
                                      />
                                    </div>
                                  )}
                                </div>
                              </Form.Item>
                            </>
                          )}
                          {!uploadData ? (
                            <div>
                              <Form.Item label="Upload File" initialValue={null}>
                                <Upload {...uploadProps} showUploadList={false}>
                                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                              </Form.Item>
                            </div>
                          ) : (
                            <div>
                              {uploadData.success ? (
                                <div>
                                  Uploaded File: <Text type="warning">{uploadedFileNames}</Text>
                                </div>
                              ) : (
                                <div>
                                  Errors found while uploading{' '}
                                  <Text type="warning">{uploadedFileNames}</Text>
                                </div>
                              )}
                              {uploadData.success ? (
                                <div className="table-content">
                                  <Table
                                    columns={uploadedSuccessCols}
                                    dataSource={uploadData.reloadPINData}
                                    scroll={{ x: '100%' }}
                                    pagination={{
                                      pageSize: 4,
                                      total: uploadData.reloadPINData.length,
                                    }}
                                  />
                                </div>
                              ) : (
                                <div className="table-content">
                                  <Table
                                    columns={uploadedFailedCols}
                                    dataSource={uploadData.errors}
                                    scroll={{ x: '100%' }}
                                    pagination={{
                                      pageSize: 4,
                                      total: uploadData.errors.length,
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        </Form>
                      </div>
                    </Space>
                  </div>
                </Modal>
              </Space>
            </Col>
          </Row>
          <div className="table-content">
            {loading === false ? (
              <Table
                columns={columns}
                dataSource={reloadPinListData}
                pagination={{
                  pageSize: limit,
                  pageSizeOptions: [20, 50, 100],
                  showSizeChanger: true,
                  total: totalData,
                  onChange,
                  current: page,
                }}
                scroll={{ x: 1300 }}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </Space>
      </Content>
    </>
  );
}
