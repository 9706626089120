import React, { useEffect, useState, useMemo } from 'react';
import {
  Row,
  Col,
  InputNumber,
  Table,
  Image,
  Tooltip,
  Select,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import axios from 'axios';

/**
 * 游戏道具奖池
 * 
 * value:{
 *   id:string,//道具id
 *   probability?:number, //概率（%）
 *   obtainAmount?:number,//数量 
 *   
 * }
 * 
 * 
 * mode :'obtainAmount'|'probability'
 * 
 */
export function PoolItem({
  value,
  onChange,
  type = 'Items', // Items|HeeCoin|CardpackVoucher|Cardpack
  columns = [],
  selectOptionUrl,
  mode = 'probability',
}) {
  const [selectOption, setSelectOption] = useState([]);
  const [selectItemKeys, setSelectItemKeys] = useState([]);
  const [curPrizePool, setCurPrizePool] = useState([]);

  /**
   * 获取所有道具列表
   */
  const initData = async () => {
    const labelMap = (data) => {
      if (type === 'Items') {
        return `${data.type} - ${data.name}`;
      }

      if (type === 'Cardpack') {
        return data.seriesName;
      }

      return data.name;
    };
    const res = await axios
      .get(selectOptionUrl);
    
    setSelectOption(res.data.map((item) => {
      return {
        ...item,
        value: item.id,
        label: labelMap(item),
      };
    }));
    setSelectItemKeys(value.map((valueItem) => valueItem.data));

    const tableData = value.map((valueItem) => {
      const matchItem = res.data.find((item) => item.id === valueItem.data);
      if (matchItem) {
        return {
          key: Math.random(),
          ...matchItem,
          probability: valueItem.probability || 1,
          obtainAmount: valueItem.obtainAmount || 1,
        };
      }
      return null;
    });
    setCurPrizePool(tableData.filter((item) => item != null));
    return res;
  };
    
  useEffect(() => {
    initData();
  }, []);
    
  useEffect(() => {
    const emitData = curPrizePool.map((item) => {
      return {
        data: item.id,
        probability: item.probability,
        obtainAmount: item.obtainAmount,
      };
    });
    onChange(emitData);
  }, [curPrizePool]);

  const onSelectItemsChange = (valueList) => {
    const newPoolsList = [];
    valueList.forEach((itemId) => {
      const selectedItem = selectOption.find((item) => item.id === itemId);
      const existingItem = curPrizePool.find((item) => item.id === itemId);
      newPoolsList.push({
        key: Math.random(),
        ...selectedItem,
        probability: existingItem ? existingItem.probability : 1,
        obtainAmount: existingItem ? existingItem.obtainAmount : 1,
      });
    });
    setSelectItemKeys(valueList);
    setCurPrizePool(newPoolsList);
  };
    
  const setRowFiledValue = (filedName, newValue, record) => {
    const updatedDataSource = curPrizePool.map((item) => {
      if (item.id === record.id) {
        return { ...item, [filedName]: newValue };
      }
      return item;
    });
    setCurPrizePool(updatedDataSource);
  };
  const curColumns = [
    ...columns,
  ];
  if (mode === 'probability') {
    curColumns.push(
      {
        title: (
          <Row>
            <Col>
              Probability  (%)
            </Col>
            <Col style={{ padding: '0 5px' }}>
              <Tooltip title="Probability of player getting items out of 100%">
                <InfoCircleOutlined />
              </Tooltip>
            </Col>
          </Row>
        ),
        dataIndex: 'probability',
        key: 'probability',
        width: '200px',
        render: (text, record) => (
          <InputNumber
            value={text}
            max={100}
            min={0.0001}
            controls
            onChange={(v) => setRowFiledValue('probability', v, record)}
                />
        ),
      },
      
    );
  }
          
  if (mode === 'obtainAmount') {
    curColumns.push(
      {
        title: (
          <Row>
            <Col>
              Amount
            </Col>
            <Col style={{ padding: '0 5px' }}>
              <Tooltip title="Amount of player getting items">
                <InfoCircleOutlined />
              </Tooltip>
            </Col>
          </Row>
        ),
        dataIndex: 'obtainAmount',
        key: 'obtainAmount',
        width: '200px',
        render: (text, record) => (
          <InputNumber
            value={text}
            max={999999}
            min={1}
            controls
            onChange={(v) => setRowFiledValue('obtainAmount', v, record)}
                />
        ),
      },
      
    );
  }
  return (
    <div
      className="content-area"
      style={{ padding: 0, margin: 0 }}
    >
      
      <Select
        mode="multiple"
        allowClear
        placeholder="Select Items"
        defaultValue={selectItemKeys}
        value={selectItemKeys}
        options={selectOption}
        onChange={onSelectItemsChange}
        style={{ width: '100%', marginBottom: '8px' }}
        />
      <Table
        columns={curColumns}
        dataSource={curPrizePool}
        pagination={false}
        scroll={{ x: '100%' }}
      />
    </div>
  );
}
