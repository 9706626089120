import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  InputNumber,
  Button,
  Table,
  Image,
  Tag,
  Tooltip,
  Tabs,
  Select,
  Space,
  Modal,
  Skeleton,
  message,
  Typography,
  Layout,
  Breadcrumb,
} from 'antd';
import { InfoCircleOutlined, LeftOutlined, DeleteOutlined, CheckOutlined } from '@ant-design/icons';
import axios from 'axios';

import { PrizePool } from '../../../components/componentV2/PrizePoolV2/PrizePool';

const { Title, Link } = Typography;

const { Content } = Layout;

export default function TierDetailForm({
  isCreate,
  editData,
  onSubmit,

  loading,
  onDelete,

}) {
  const [form] = Form.useForm();
  const [voucherPrizePool, setVoucherPrizePool] = useState([]);

  const [colorEggPrizePoolConfig, setColorEggPrizePoolConfig] = useState({
    mode: 'probability',
    poolTypes: ['TierVouchers', 'Items', 'HeeCoin', 'Cardpack'],
    totalProbability: 100,
    tierVouchersProbability: 0,
    itemPool: [],
    cardpackPool: [],
    heeCoinPool: [],
    voucherPool: [],
  });// 彩蛋奖池(新增)

  const [goldenEggPrizePoolConfig, setGoldenEggPrizePoolConfig] = useState({
    mode: 'probability',
    poolTypes: ['TierVouchers', 'Items', 'HeeCoin', 'Cardpack'],
    totalProbability: 100,
    tierVouchersProbability: 0,
    itemPool: [],
    cardpackPool: [],
    heeCoinPool: [],
    voucherPool: [],
  });// 金蛋奖池(新增)

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      // 金蛋校验
      if (goldenEggPrizePoolConfig.totalProbability !== 100) {
        message.error('Golden Egg Prize Pool Weightage percentage must add up to 100!');
        return;
      }

      if (goldenEggPrizePoolConfig.itemPool.length === 0) {
        message.warning('Please select Golden Egg Prize Pool at least 1 item!');
        return;
      }

      // 彩蛋校验

      if (colorEggPrizePoolConfig.totalProbability !== 100) {
        message.warning('Colored Egg Prize Pool Weightage percentage must add up to 100!');
        return;
      }

      if (colorEggPrizePoolConfig.itemPool.length === 0) {
        message.warning('Please select Colored Egg Prize Pool at least 1 item!');
        return;
      }

      const formData = form.getFieldsValue();

      const dataPackage = {
        tier: formData.tier,
        name: formData.name,
        eggRateSeconds: formData.eggRateMinutes * 60,

        turnover: formData.turnover,
        threshold: formData.threshold,

        colorEggPrizePoolConfig,
        goldenEggPrizePoolConfig,
      };

      onSubmit(dataPackage);
    } catch (error) {
      message.error(`Failed to create tier. ${error}`);
    }
  };

  const initData = () => {
    if (!isCreate && editData) {
      form.setFieldsValue({
        ...editData,
        eggRateMinutes: editData.eggRateSeconds / 60,

      });
      setVoucherPrizePool(editData.voucherPrizePool);
      if (editData.colorEggPrizePoolConfig) {
        setColorEggPrizePoolConfig(editData.colorEggPrizePoolConfig);
      }

      if (editData.goldenEggPrizePoolConfig) {
        setGoldenEggPrizePoolConfig(editData.goldenEggPrizePoolConfig);
      }
    }
  };
  useEffect(() => { initData(); }, [editData]);

  const [modalActive, setModalActive] = useState(false);
  const showModal = () => {
    setModalActive(true);
  };

  const handleOk = () => {
    setModalActive(false);
    onDelete(editData);
  };
  const handleCancel = () => {
    setModalActive(false);
  };

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/tier">
            <LeftOutlined />
            Tier
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{isCreate ? 'Create Tier' : 'Edit Tier'}</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">

        <Row>
          <Col flex={4}>
            <Title
              level={3}
              style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '20px' }}>
              Edit Tier
            </Title>
          </Col>
          <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              <Button danger onClick={showModal}>
                <DeleteOutlined />
                Delete
              </Button>
              <Modal
                title="Are you sure you want to delete?"
                open={modalActive}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                closable={false}
                footer={[
                  <>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button type="primary" danger onClick={handleOk}>
                      <DeleteOutlined />
                      Yes, Delete
                    </Button>
                  </>,
                ]}>
                <p>If you confirm this action, you are unable to undo.</p>
              </Modal>
              <Button type="primary" onClick={handleSubmit}>
                <CheckOutlined />
                Submit
              </Button>
            </Space>
          </Col>
        </Row>

        {loading === false ? (
          <Row>
            <Col xs={24} lg={20}>
              <Form form={form} layout="vertical">
                <div className="content-area">
                  <Form.Item
                    name="tier"
                    label="Tier"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter yarn!',
                      },
                    ]}>
                    <InputNumber placeholder="Tier" readOnly={!isCreate} />
                  </Form.Item>
                  <Form.Item
                    name="name"
                    label="Tier Name"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter tier name!',
                      },
                    ]}>
                    <Input placeholder="Tier X" />
                  </Form.Item>
                  <Row>
                    <Col xs={24} lg={6}>
                      <Form.Item
                        name="eggRateMinutes"
                        label={(
                          <Row>
                            <Col>Egg Rate (Minutes)</Col>
                            <Col style={{ paddingLeft: '10px' }}>
                              <Tooltip title="Time based egg spawn time for relevant tier players.">
                                <InfoCircleOutlined />
                              </Tooltip>
                            </Col>
                          </Row>
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter egg rate!',
                          },
                        ]}>
                        <InputNumber min={0} placeholder="0" />
                      </Form.Item>
                    </Col>

                    <Col xs={24} lg={6}>
                      <Form.Item
                        name="turnover"
                        label={(
                          <Row>
                            <Col>Turnover (RM)</Col>
                            <Col style={{ paddingLeft: '10px' }}>
                              <Tooltip title="The monetary value of distributed rewards allocated to a player before tier regression.">
                                <InfoCircleOutlined />
                              </Tooltip>
                            </Col>
                          </Row>
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter turnover rate!',
                          },
                        ]}>
                        <InputNumber min={0} placeholder="0" />
                      </Form.Item>
                    </Col>
                    <Col xs={24} lg={6}>
                      <Form.Item
                        name="threshold"
                        label={(
                          <Row>
                            <Col>Threshold</Col>
                            <Col style={{ paddingLeft: '10px' }}>
                              <Tooltip title="The minimum spending threshold required for a player to advance to current tier.">
                                <InfoCircleOutlined />
                              </Tooltip>
                            </Col>
                          </Row>
                        )}
                        rules={[
                          {
                            required: true,
                            message: 'Please enter threshold!',
                          },
                        ]}>
                        <InputNumber min={0} placeholder="0" />
                      </Form.Item>
                    </Col>
                  </Row>
                </div>

                <PrizePool
                  headerTitle="Golden Egg Prize Pool"
                  option={goldenEggPrizePoolConfig}
                  itemSelectOptionUrl="/incubatorConfig/getCommonItemPrizeOptions"
                  onOptionChange={setGoldenEggPrizePoolConfig}
                  tierVoucherOptions={voucherPrizePool}
                />

                <PrizePool
                  headerTitle="Colored Egg Prize Pool"
                  itemSelectOptionUrl="/incubatorConfig/getCommonItemPrizeOptions"
                  option={colorEggPrizePoolConfig}
                  onOptionChange={setColorEggPrizePoolConfig}
                  tierVoucherOptions={voucherPrizePool}
                />
              </Form>
            </Col>
          </Row>
        ) : (
          <Skeleton />
        )}

      </Content>
    </>

  );
}
