import {
  Layout,
  Row,
  Col,
  Button,
  Space,
  Typography,
  Card,
  Divider,
  Descriptions,
  DatePicker,
  List,
  Breadcrumb,
  Skeleton,
  message,
  Form,
} from 'antd';
import {
  EditOutlined,
  PhoneOutlined,
  MailOutlined,
  CompassOutlined,
  UserOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import Cookie from 'js-cookie';
import axios from 'axios';
import moment from 'moment';
import { getToken } from '../../utils/AuthService';

const { RangePicker } = DatePicker;
const { Content } = Layout;
const { Title, Link } = Typography;

export default function MerchantBranchDetail() {
  const branchID = window.location.href.split('/')[4];
  const [branchData, setBranchData] = useState();
  const token = getToken();
  const [loading, setLoading] = useState(true);

  const [activityList, setActivityList] = useState([]);
  const [activityData, setActivityData] = useState([]);

  const [filterForm] = Form.useForm();
  const [searchDate, setSearchDate] = useState();

  const userCookieStr = Cookie.get('user');
  const userData = JSON.parse(userCookieStr);

  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      list.push({
        key: data[i].id,
        title: data[i].description,
        description: moment(data[i].createdAt).format('YYYY-MM-DD HH:mm:ss'),
      });
    }
    setActivityData(list);
  };

  useEffect(() => {
    setLoading(true);

    const params = {
      branchId: branchID,
      limit: 100,
      page: 1,
    };

    if (searchDate) {
      params.startDate = moment(searchDate[0]).startOf('day').toDate();
      params.endDate = moment(searchDate[1]).endOf('day').toDate();
    }

    const getData = async () => {
      await axios
        .get(`branch/${branchID}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setBranchData(res.data);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve branch data. ${error}`);
          setLoading(false);
        });
    };

    const getRedeemData = async () => {
      await axios
        .get('actionlogs/getVoucherHistory', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params,
        })
        .then((res) => {
          setActivityList(res.data.results);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve actionlogs data. ${error}`);
          setLoading(false);
        });
    };

    getData();
    getRedeemData();
  }, [searchDate]);

  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const onFilterUpdate = () => {
    setIsFilterUpdated(true);
  };

  const onFilter = () => {
    filterForm
      .validateFields()
      .then((res) => {
        const { date } = res;

        setSearchDate(date);
        setIsFilterUpdated(true);
      })
      .catch(() => {});
  };

  const clearFilter = () => {
    filterForm.resetFields();
    onFilter();
    setTimeout(() => {
      setIsFilterUpdated(false);
    }, 0);
  };

  useEffect(() => {
    if (loading === false) {
      rerenderList(activityList);
    }
  }, [loading, activityList]);

  // const data = [
  //   {
  //     title: 'James Bell redeemed 1 Floral Body Scrub',
  //     description: (
  //       <Space size={70}>
  //         <p>Voucher Balance: 328</p>
  //         <p>Discount Amount: RM 20</p>
  //       </Space>
  //     ),
  //   },
  //   {
  //     title: 'James Bell redeemed 1 Floral Body Scrub',
  //     description: (
  //       <Space size={70}>
  //         <p>Voucher Balance: 328</p>
  //         <p>Discount Amount: RM 20</p>
  //       </Space>
  //     ),
  //   },
  //   {
  //     title: 'James Bell redeemed 1 Floral Body Scrub',
  //     description: (
  //       <Space size={70}>
  //         <p>Voucher Balance: 328</p>
  //         <p>Discount Amount: RM 20</p>
  //       </Space>
  //     ),
  //   },
  // ];

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/branches">
            <LeftOutlined />
            Branches
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Branch Detail</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        {loading === false ? (
          <Row>
            <Col xs={24} lg={8}>
              <Row>
                <Col span={24}>
                  <Card>
                    <Space direction="vertical">
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>
                          <Title level={3}>{branchData.branchName}</Title>
                        </div>
                        <div>
                          <Button type="primary" href="edit">
                            <EditOutlined />
                          </Button>
                        </div>
                      </div>
                      <p>{userData.name}</p>
                      <Divider />
                      <Descriptions
                        title="Contact Details"
                        layout="horizontal"
                        colon={false}
                        column={1}>
                        <Descriptions.Item
                          label={<MailOutlined style={{ alignSelf: 'center' }} />}
                          span={1}>
                          {branchData.branchEmail}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={<PhoneOutlined style={{ alignSelf: 'center' }} />}
                          span={1}>
                          {branchData.branchContactNum}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={<CompassOutlined style={{ alignSelf: 'center' }} />}
                          span={1}>
                          {branchData.branchAddress}
                        </Descriptions.Item>
                      </Descriptions>
                      <Divider />
                      <Descriptions title="Person In Charge" layout="horizontal" column={1}>
                        <Descriptions.Item
                          label={<UserOutlined style={{ alignSelf: 'center' }} />}
                          span={1}>
                          {branchData.PIC}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={<PhoneOutlined style={{ alignSelf: 'center' }} />}
                          span={1}>
                          {branchData.PICnumber}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={<MailOutlined style={{ alignSelf: 'center' }} />}
                          span={1}>
                          {branchData.PICemail}
                        </Descriptions.Item>
                      </Descriptions>
                    </Space>
                  </Card>
                </Col>
              </Row>
            </Col>
            <Col xs={24} lg={16}>
              <Row>
                <Col xs={24} lg={24}>
                  <div className="content-area merchantBranchDetail" style={{ marginTop: 0 }}>
                    <Title level={5}>Branch Activity</Title>
                    <Form form={filterForm}>
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col sm={24} lg={12}>
                          <Row>
                            <p>Date</p>
                          </Row>
                          <Row>
                            <Form.Item name="date">
                              <RangePicker onChange={onFilterUpdate} />
                            </Form.Item>
                          </Row>
                        </Col>
                      </Row>
                    </Form>
                    <Row justify="end">
                      <Space>
                        <Col>
                          <Button danger onClick={clearFilter} disabled={!isFilterUpdated}>
                            Reset Filters
                          </Button>
                        </Col>
                        <Col>
                          <Button onClick={onFilter}>Apply Filters</Button>
                        </Col>
                      </Space>
                    </Row>
                    <List
                      itemLayout="horizontal"
                      dataSource={activityData}
                      renderItem={(item) => (
                        <>
                          <List.Item
                            // extra={(
                            //   <Space
                            //     direction="vertical"
                            //     size="small"
                            //     style={{ textAlign: 'right' }}>
                            //     <Text type="secondary">2023-01-12 13:05:20</Text>
                            //   </Space>
                            // )}
                            >
                            <List.Item.Meta title={item.title} description={item.description} />
                          </List.Item>
                        </>
                      )}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <Skeleton />
        )}
      </Content>
    </>
  );
}
