import React, { useState, useEffect } from 'react';
import {
  Typography,
  Layout,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Breadcrumb,
  Skeleton,
  message,
  Form,
  Tooltip,
} from 'antd';
import { DeleteOutlined, CheckOutlined, CloseOutlined, LeftOutlined } from '@ant-design/icons';
import axios from 'axios';
// import moment from 'moment';
import { getToken } from '../../utils/AuthService';

import ReloadPinForm from '../../components/reloadPin/ReloadPinForm';

const { Title, Link } = Typography;
const { Content } = Layout;

export default function EditReloadPin() {
  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/reload-pin">
            <LeftOutlined />
            Reload Pin
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Reload Pin</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const [form] = Form.useForm();
  const token = getToken();
  const [loading, setLoading] = useState(true);
  const reloadPinID = window.location.href.split('/')[4];
  const [reloadPinData, setReloadData] = useState({});

  useEffect(() => {
    setLoading(true);

    const getData = async () => {
      await axios
        .get(`reloadPIN/${reloadPinID}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setReloadData(res.data);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve reload pin data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, []);

  const handleSave = async () => {
    try {
      const formData = form.getFieldsValue();

      const dataPackage = {
        status: reloadPinData.status,
        code: formData.code,
        amount: formData.value,
        description: formData.descriptions,
      };
      await axios.patch(`reloadPIN/${reloadPinID}`, dataPackage, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      message.success('Reload Pin data saved successfully.');

      setTimeout(() => {
        window.location.href = '/reload-pin';
      }, 1000);
    } catch (error) {
      message.error(`Failed to save reload pin data. ${error}`);
    }
  };

  const handleStatusUpdate = async () => {
    try {
      const formData = form.getFieldsValue();

      const dataPackage = {
        PIN: formData.code,
        value: formData.value,
        description: formData.descriptions,
      };
      dataPackage.status = (reloadPinData.status === 'Published' ? 'Unpublished' : 'Published');
      await axios.patch(`reloadPIN/${reloadPinID}`, dataPackage, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      message.success('Reload Pin data and status updated successfully.');

      setTimeout(() => {
        window.location.href = '/reload-pin';
      }, 1000);
    } catch (error) {
      message.error(`Failed to save reload pin data. ${error.response.data.message}`);
    }
  };

  const handleDelete = async () => {
    try {
      await axios.put(`reloadPIN/${reloadPinID}/archive`, null, {
        headers: {
          accept: '*/*',
          Authorization: `Bearer ${token.access.token}`,
        },
      });
      message.success('Reload Pin deleted successfully.');

      setTimeout(() => {
        window.location.href = '/reload-pin';
      }, 1000);
    } catch (error) {
      message.error(`Failed to delete reload pin. ${error}`);
    }
  };

  const [modalActive, setModalActive] = useState(false);

  const showModal = () => {
    setModalActive(true);
  };
  const handleOk = () => {
    setModalActive(false);
    handleDelete();
  };
  const handleCancel = () => {
    setModalActive(false);
  };

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Row>
          <Col flex={4}>
            <Title
              level={3}
              style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '20px' }}>
              Edit Reload Pin
            </Title>
          </Col>
          <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Space>
              <Button danger onClick={showModal}>
                <DeleteOutlined />
                Delete
              </Button>
              <Modal
                title="Are you sure you want to delete?"
                open={modalActive}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                closable={false}
                footer={[
                  <>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button type="primary" onClick={handleOk} danger>
                      <DeleteOutlined />
                      Yes, Delete
                    </Button>
                  </>,
                ]}>
                If you confirm this action, you are unable to undo.
              </Modal>
              {/* shows different button based on ad listing status */}
              {reloadPinData.status === 'Published' ? (
                <Tooltip title="Save changes and unpublish ad.">
                  <Button onClick={handleStatusUpdate}>
                    <CloseOutlined />
                    Unpublish
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title="Save changes and publish ad.">
                  <Button onClick={handleStatusUpdate}>
                    <CheckOutlined />
                    Publish
                  </Button>
                </Tooltip>
              )}
              <Tooltip title="Save current changes.">
                <Button type="primary" onClick={handleSave}>
                  <CheckOutlined />
                  Save
                </Button>
              </Tooltip>
            </Space>
          </Col>
        </Row>
        {loading === false ? <ReloadPinForm form={form} data={reloadPinData} /> : <Skeleton />}
      </Content>
    </>
  );
}
