import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import moment from 'moment';

import {
  Form,
  Table,
  Col,
  Layout,
  Row,
  Space,
  Button,
  Typography,
  DatePicker,
  Breadcrumb,
  message,
  Tooltip,
  Skeleton,
  Input,
} from 'antd';
import { PlusOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';

import axios from 'axios';

const { Title, Link } = Typography;

const { Content } = Layout;

export function MinGameRankPage() {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [filterForm] = Form.useForm();

  const [totalData, setTotalData] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);

  const getTableData = async (pageNum, pageSize) => {
    try {
      const { week, playerUid } = filterForm.getFieldValue();

      setLoading(true);
      const res = await axios
        .get('miniGame/queryMiniGameRankList', {
          params: {
            week: week ? `${moment(week).year()}#${moment(week).week()}` : '',

            playerUid,
            limit: pageSize,
            page: pageNum,
          },
        });
    
      setTableData(res.data.results);
      setTotalData(res.data.totalResults);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    setPage(1);

    getTableData(1, limit);
  };

  const onTablePageChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
    getTableData(current, pageSize);
  };

  const clearFilter = () => {
    filterForm.resetFields();
    filterForm.setFieldsValue({
      week: moment(),
    });
  
    handleSearch();
  };
  
  useEffect(() => {
    handleSearch();
  }, []);

  const columns = [
    {
      title: 'Rank Index',
      key: 'rankIndex',
      width: 100,
      render: (_key, _row, index) => {
        return index + 1 + (page - 1) * limit;
      },
    },

    {
      title: 'Pet Name',
      key: 'Pet Name',
      width: 150,
      render: (_data, row) => {
        return row.pet?.name || '-';
      },
    },
    {
      title: 'Pet Type',
      key: 'Pet Type',
      width: 150,
      render: (_data, row) => {
        return row.pet?.type || '-';
      },
    },
    {
      title: 'Player UID',
      key: 'Player UID',
      width: 150,
      render: (data, row) => {
        return row.player?.uid || '-';
      },
    },

    {
      title: 'Game Score',
      dataIndex: 'gameScore',
      key: 'gameScore',
      width: 150,
    },

    {
      title: 'Week',
      dataIndex: 'week',
      key: 'week',
      width: 150,
    },
    
    {
      title: 'Update Date',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      width: 150,
      render: (data) => {
        return moment(data).format('YYYY-MM-DD HH:mm:ss');
      },
    },
 
  ];

  return (
    <>

      <Content className="layout-background" style={{ padding: '0 20px' }}>
        <Title
          level={3}
          style={{ display: 'flex', justifyContent: 'flex-start' }}>
          Rank History
        </Title>
        <Space direction="vertical" style={{ display: 'flex', marginTop: '20px' }}>
                  
          <Form form={filterForm} layout="inline">
            <Form.Item name="week" label="Week" initialValue={moment()}>
              <DatePicker picker="week" allowClear={false} />
            </Form.Item>

            {/* <Form.Item name="playerUid" label="Player Uid">
              <Input placeholder="please input" />
            </Form.Item> */}

            <Form.Item>
              <Space>
                <Col>
                  <Button danger onClick={() => clearFilter()}>
                    Reset Filters
                  </Button>
                </Col>
                <Col>
                  <Button onClick={() => handleSearch()}>Apply Filters</Button>
                </Col>
              </Space>
            </Form.Item>
          </Form>
    
          <div className="table-content">
            {loading === false ? (
              <Table
                columns={columns}
                dataSource={tableData}
                pagination={{ pageSize: limit,
                  pageSizeOptions: [10, 20, 50, 100],
                  showSizeChanger: true,
                  total: totalData,
                  onChange: onTablePageChange,
                  current: page,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
                rowKey="_id"
                scroll={{ x: '100%' }}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </Space>
      </Content>
    </>
  );
}
