import React, { useEffect, useState, useCallback } from 'react';
import { utils, writeFileXLSX } from 'xlsx';
import {
  Table,
  Layout,
  Space,
  Tag,
  Row,
  Col,
  Form,
  DatePicker,
  Input,
  Select,
  Modal,
  Button,
  Breadcrumb,
  Typography,
  message,
  Skeleton,
} from 'antd';
import { ImportOutlined } from '@ant-design/icons';
import moment from 'moment';
import Cookie from 'js-cookie';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

const { Content } = Layout;
const { Link } = Typography;
const { RangePicker } = DatePicker;

const voucherHistoryCols = [
  {
    title: 'Player Voucher ID',
    key: 'Voucher ID',
    render: (_data, row) => {
      return row.playerVoucherId || '-';
    },
    width: 200,
  },
 
  {
    title: 'Voucher',
    dataIndex: 'voucher',
    key: 'voucher',
    width: 200,
  },

  {
    title: 'Player Uid',
    dataIndex: 'playerUid',
    key: 'playerUid',
    width: 200,
  },
  {
    title: 'Action Type',
    dataIndex: 'actionType',
    key: 'actionType',
    width: 200,
    render: (actionType) => {
      let res;

      switch (actionType) {
        case 'redeem':
          res = <Tag color="purple">Redeem</Tag>;
          break;
        case 'distributeVoucher':
          res = <Tag color="gold">Distributed</Tag>;
          break;
        default:
          res = '';
          break;
      }

      return res;
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: 200,
    render: (status) => {
      let res;

      switch (status) {
        case 'Success':
          res = <Tag color="blue">Successful</Tag>;
          break;
        case 'Failed':
          res = <Tag color="red">Failed</Tag>;
          break;
        default:
          res = '';
          break;
      }

      return res;
    },
  },
  {
    title: 'Date',
    dataIndex: 'date',
    key: 'date',
    width: 200,
    render: (data) => (data ? moment(data).format('YYYY-MM-DD') : '-'),
  },
];

export default function VoucherHistory(props) {
  const { userRole } = props;

  const userCookieStr = Cookie.get('user');
  const userData = JSON.parse(userCookieStr);
  const token = getToken();

  const [modalActive, setModalActive] = useState(false);
  const showModal = () => {
    setModalActive(true);
  };
  // const handleOk = () => {
  //   setModalActive(false);
  // };
  const handleCancel = () => {
    setModalActive(false);
  };

  const [filterForm] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [actionLogList, setActionLogList] = useState();
  const [actionLogListData, setActionLogListData] = useState();

  const [voucherList, setVoucherList] = useState([]);

  const rerenderList = (data) => {
    const list = [];
    for (let i = 0; i < data.length; i++) {
      list.push({
        playerVoucherId: data[i].data.playerVoucherId,
        key: data[i]._id,
        playerUid: data[i].player.uid,
        name: data[i].player.name ? data[i].player.name : `Player ${data[i].player.uid}`,
        voucher: data[i].voucher.name,
        status: data[i].status,
        actionType: data[i].actionType,
        date: data[i].createdAt,
      });
    }
    setActionLogListData(list);
  };

  function getVoucherList(voucherData) {
    const list = [{ value: 'all', label: 'All' }];
    for (let i = 0; i < voucherData.length; i++) {
      list.push({ value: voucherData[i].id, label: voucherData[i].name });
    }

    return list;
  }

  const [limit, setLimit] = useState(20);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const [filterData, setFilterData] = useState({});

  useEffect(() => {
    const getData = async () => {
      let merchantID;
      let branchID;

      if (userRole === 'merchantHQ') {
        const merchantIdResponse = await axios
          .get(`merchants/getMerchantId/${userData.id}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
          })
          .catch((error) => {
            message.error(`Failed to retrieve merchant data. ${error}`);
          });

        merchantID = merchantIdResponse.data;
      }

      if (userRole === 'merchantBranch') {
        const branchIdResponse = await axios
          .get(`branch/getBranchId/${userData.id}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token.access.token}`,
            },
          })
          .catch((error) => {
            message.error(`Failed to retrieve branch data. ${error}`);
          });

        branchID = branchIdResponse.data;
      }

      const params = {
        merchantId: merchantID,
        branchId: branchID,
        ...filterData,
      };

      await axios
        .get('actionlogs/getVoucherHistory', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params,
        })
        .then((res) => {
          setActionLogList(res.data.results);
          setTotalData(res.data.totalResults);
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve voucher history. ${error}`);
          setLoading(false);
        });
    };

    getData();
  }, [filterData]);

  useEffect(() => {
    const getVoucherData = async () => {
      await axios
        .get('vouchers', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            limit: 100,
            page: 1,
            sortBy: 'createdAt:desc',
          },
        })
        .then((res) => {
          setVoucherList(res.data.results);
        })
        .catch((error) => {
          message.error(`Failed to retrieve voucher data. ${error}`);
        });
    };
  
    getVoucherData();
  }, []);
  
  useEffect(() => {
    if (loading === false) {
      rerenderList(actionLogList);
    }
  }, [loading, actionLogList]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => {
      setSelectedRowKeys(selectedKeys);
    },
  };

  const handleOk = useCallback(() => {
    let selectedRows = [];
  
    if (rowSelection.selectedRowKeys.length === 0) {
      // Export all rows when no rows are selected
      selectedRows = actionLogListData.map(({ key, ...rest }) => rest);
    } else {
      // Export selected rows
      selectedRows = actionLogListData
        .filter((row) => rowSelection.selectedRowKeys.includes(row.key))
        .map(({ key, ...rest }) => rest);
    }

    // Rename column headers
    const renamedColumns = {
      name: 'Player Name',
      voucher: 'Voucher Name',
      status: 'Status',
      date: 'Date',
    };

    const transformedRows = selectedRows.map((row) => (
      Object.entries(row).reduce((acc, [key, value]) => {
        const renamedKey = renamedColumns[key] || key;
        if (key === 'date') {
          const createdDate = new Date(value);
          const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'Asia/Kuala_Lumpur',
          };
          return { ...acc, [renamedKey]: createdDate.toLocaleString('en-US', options) };
        }
        return { ...acc, [renamedKey]: value };
      }, {})));  
  
    const currentDate = new Date();
    const formattedDate = currentDate
      .toLocaleString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
      })
      .replace(/[/:\s]/g, '_');
  
    const ws = utils.json_to_sheet(transformedRows);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');
    writeFileXLSX(wb, `VoucherHistory_${formattedDate}.xlsx`);

    setModalActive(false);
  }, [actionLogListData, rowSelection.selectedRowKeys]);

  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const onFilterUpdate = () => {
    setIsFilterUpdated(true);
  };

  const FilterRow = () => (
    <Form form={filterForm}>
      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
        <Col sm={12} lg={5}>
          <Row>
            <p>Player Name</p>
          </Row>
          <Row>
            <Form.Item name="playerName" style={{ width: '100%' }}>
              <Input placeholder="Player Name" style={{ width: '100%' }} />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={5}>
          <Row>
            <p>Voucher</p>
          </Row>
          <Row>
            <Form.Item name="voucher" style={{ width: '100%' }}>
              <Select
                style={{ width: '100%' }}
                placeholder="Select Voucher"
                options={getVoucherList(voucherList)}
                onChange={onFilterUpdate}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={5}>
          <Row>
            <p>Action Type</p>
          </Row>
          <Row>
            <Form.Item name="actionType" style={{ width: '100%' }}>
              <Select
                defaultValue="all"
                style={{ width: '100%' }}
                onChange={onFilterUpdate}
                options={[
                  {
                    value: 'all',
                    label: 'All',
                  },
                  {
                    value: 'redeem',
                    label: 'Redeem',
                  },
                  {
                    value: 'distributeVoucher',
                    label: 'Distributed',
                  },
                ]}
              />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={4}>
          <Row>
            <p>Status</p>
          </Row>
          <Row>
            <Form.Item name="status" style={{ width: '100%' }}>
              <Select
                defaultValue="all"
                style={{ width: '100%' }}
                onChange={onFilterUpdate}
                options={[
                  {
                    value: 'all',
                    label: 'All',
                  },
                  {
                    value: 'Success',
                    label: 'Successful',
                  },
                  {
                    value: 'Failed',
                    label: 'Failed',
                  },
                ]}
              />
            </Form.Item>
          </Row>
        </Col>
        <Col sm={12} lg={5}>
          <Row>
            <p>Date</p>
          </Row>
          <Row>
            <Form.Item name="date" style={{ width: '100%' }}>
              <RangePicker style={{ width: '100%' }} onChange={onFilterUpdate} />
            </Form.Item>
          </Row>
        </Col>
      </Row>
    </Form>
  );

  const onFilter = () => {
    filterForm
      .validateFields()
      .then((res) => {
        const { playerName, voucher, actionType, status, date } = res;
        const items = {};
        if (playerName !== '') {
          items.playerName = playerName;
        }
        if (voucher !== 'all') {
          items.voucherId = voucher;
        }
        if (actionType !== 'all') {
          items.actionType = actionType;
        }
        if (status !== 'all') {
          items.status = status;
        }
        if (date) {
          items.filterStartDate = moment(date[0]).startOf('day').toDate();
          items.filterEndDate = moment(date[1]).endOf('day').toDate();
        }

        setFilterData(items);
        setIsFilterUpdated(true);
      })
      .catch(() => {});
  };

  const clearFilter = () => {
    filterForm.resetFields();
    onFilter();
    setTimeout(() => {
      setIsFilterUpdated(false);
    }, 0);
  };

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/voucher-history">Voucher history</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  const onChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
  };

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Row justify="end">
            <Col>
              <Space>
                <Button onClick={showModal}>
                  <ImportOutlined rotate={-90} />
                  Export
                </Button>
                <Modal
                  title="Are you sure you want to export voucher history data?"
                  open={modalActive}
                  onOk={handleOk}
                  onCancel={handleCancel}
                  centered
                  closable={false}
                  bodyStyle={{ padding: 0 }}
                  footer={[
                    <>
                      <Button onClick={handleCancel}>Cancel</Button>
                      <Button type="primary" onClick={handleOk}>
                        Export
                      </Button>
                    </>,
                  ]}
                />
              </Space>
            </Col>
          </Row>
          <FilterRow />
          <Row justify="end">
            <Space>
              <Col>
                <Button danger onClick={clearFilter} disabled={!isFilterUpdated}>
                  Reset Filters
                </Button>
              </Col>
              <Col>
                <Button onClick={onFilter}>Apply Filters</Button>
              </Col>
            </Space>
          </Row>
          <div className="table-content">
            {loading === false ? (
              <Table
                columns={voucherHistoryCols}
                dataSource={actionLogListData}
                pagination={{ pageSize: limit,
                  pageSizeOptions: [20, 50, 100],
                  showSizeChanger: true,
                  total: totalData,
                  onChange,
                  current: page,
                }}
                scroll={{ x: 1300 }}
                rowSelection={{
                  type: 'checkbox',
                  ...rowSelection,
                }}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </Space>
      </Content>
    </>
  );
}
