import React, { useState, useEffect } from 'react';
import {
  Typography,
  Layout,
  Row,
  Col,
  Button,
  Form,
  Input,
  Modal,
  Space,
  Breadcrumb,
  message,
  Skeleton,
} from 'antd';
import { CheckCircleOutlined, CheckOutlined, LeftOutlined } from '@ant-design/icons';
import Cookie from 'js-cookie';
import axios from 'axios';
import { getToken } from '../../utils/AuthService';

const { Title, Text, Link } = Typography;
const { Content } = Layout;

export default function MerchantEditBranch() {
  const [form] = Form.useForm();

  const branchID = window.location.href.split('/')[4];
  const [merchantID, setMerchantID] = useState();
  const token = getToken();
  const [loading, setLoading] = useState(true);

  const userCookieStr = Cookie.get('user');
  const userData = JSON.parse(userCookieStr);
  const merchantUserID = userData.id;

  useEffect(() => {
    setLoading(true);
    const getData = async () => {
      await axios
        .get(`/merchants/getMerchantId/${merchantUserID}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          setMerchantID(res.data);
        })
        .catch((error) => {
          message.error(`Failed to retrieve merchant data. ${error}`);
          setLoading(false);
        });

      await axios
        .get(`/branch/${branchID}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
        })
        .then((res) => {
          form.setFieldsValue({
            branchName: res.data.branchName,
            branchAddress: res.data.branchAddress,
            branchEmail: res.data.branchEmail,
            branchContactNum: res.data.branchContactNum,
            PIC: res.data.PIC,
            PICnumber: res.data.PICnumber,
            PICemail: res.data.PICemail,
          });
          setLoading(false);
        })
        .catch((error) => {
          message.error(`Failed to retrieve branch data. ${error}`);
          setLoading(false);
        });
    };
    getData();
  }, []);
  
  const [modalActive, setModalActive] = useState(false);
  const showModal = () => {
    setModalActive(true);
  };
  const handleOk = () => {
    setModalActive(false);
  };
  
  const handleSubmit = async () => {
    const formData = form.getFieldsValue();

    const dataPackage = {
      merchantId: merchantID,
      branchId: branchID,
      branchName: formData.branchName,
      branchAddress: formData.branchAddress,
      branchEmail: formData.branchEmail,
      branchContactNum: formData.branchContactNum,
      PIC: formData.PIC,
      PICnumber: formData.PICnumber,
      PICemail: formData.PICemail,
    };

    await axios.post('/branchEditRequest', dataPackage, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token.access.token}`,
      },
    })
      .then(() => {
        showModal();
      })
      .catch((error) => {
        message.error(`Failed to submit edit request. ${error}`);
      });
  };

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/branches">
            <LeftOutlined />
            Branches
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Branch Detail</Breadcrumb.Item>
        <Breadcrumb.Item>Edit Branch</Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Row>
          <Col flex={4}>
            <Title
              level={3}
              style={{ display: 'flex', justifyContent: 'flex-start', marginLeft: '10px' }}>
              Edit Branch
            </Title>
          </Col>
          <Col flex={1} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button type="primary" onClick={handleSubmit}>
              <CheckOutlined />
              Submit Request
            </Button>
            <Modal
              title={null}
              open={modalActive}
              centered
              closable={false}
              bodyStyle={{ padding: 0 }}
              footer={null}>
              <div className="content-area" style={{ textAlign: 'center' }}>
                <Space direction="vertical" size="middle">
                  <CheckCircleOutlined style={{ fontSize: 50, fontWeight: 'bold' }} />
                  <Title level={3}>Requested Successfully!</Title>
                  <Text type="secondary" style={{ fontSize: 15 }}>
                    Your edit request is sent successfully, admin will review and approve your
                    request soon.
                  </Text>
                  <Button block type="primary" onClick={handleOk}>
                    Done
                  </Button>
                </Space>
              </div>
            </Modal>
          </Col>
        </Row>
        {loading === false ? (
          <Form form={form} layout="vertical">
            <Row gutter={16}>
              <Col xs={24} lg={16}>
                <div className="content-area" style={{ margin: '20px 0px' }}>
                  <Form.Item
                    name="branchName"
                    label="Branch Name"
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <Input placeholder="Enter Branch Name (e.g. Mid Valley Megamall)" />
                  </Form.Item>
                  <Form.Item
                    name="branchAddress"
                    label="Location / Address"
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <Input placeholder="Enter Branch Address" />
                  </Form.Item>
                  <Form.Item
                    name="branchEmail"
                    label="E-mail Address"
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <Input placeholder="Enter Branch Email Address" />
                  </Form.Item>
                  <Form.Item
                    name="branchContactNum"
                    label="Contact Number"
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <Input placeholder="Enter Branch Contact Number" />
                  </Form.Item>
                </div>
              </Col>
              <Col xs={24} lg={8}>
                <div className="content-area" style={{ margin: '20px 0px' }}>
                  <Form.Item
                    name="PIC"
                    label="Person in Charge"
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <Input placeholder="Enter PIC's Name" />
                  </Form.Item>
                  <Form.Item
                    name="PICnumber"
                    label="Contact Number"
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <Input placeholder="Enter PIC Contact Number" />
                  </Form.Item>
                  <Form.Item
                    name="PICemail"
                    label="E-mail Address"
                    rules={[
                      {
                        required: true,
                      },
                    ]}>
                    <Input placeholder="Enter PIC's Email Address" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        ) : (
          <Skeleton />
        )}
      </Content>
    </>
  );
}
