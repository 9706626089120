import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Table,
  Col,
  Layout,
  Row,
  Space,
  Button,
  Typography,
  Breadcrumb,
  message,
  Tooltip,
  Skeleton,
} from 'antd';
import { PlusOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';

import axios from 'axios';
import { getToken } from '../../../utils/AuthService';

const { Content } = Layout;
const { Link } = Typography;

export function IncubatorConfigListPage() {
  const token = getToken();
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [totalData, setTotalData] = useState();
  const history = useHistory();
  const toCreate = () => {
    history.push('/createIncubatorPage');
  };
  const toEdit = (editData) => {
    history.push(`/editIncubatorPage/${editData.level}`, { data: editData });
  };
    
  const getTableData = async (pageNum, pageSize) => {
    try {
      setLoading(true);
      const res = await axios
        .get('incubatorConfig/queryIncubatorConfigs', {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token.access.token}`,
          },
          params: {
            sortBy: 'level:asc',
            limit: pageSize,
            page: pageNum,
          },
        });
    
      setTableData(res.data.results);
      setTotalData(res.data.totalResults);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    setPage(1);

    getTableData(1, limit);
  };
  
  const onTablePageChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
    getTableData(current, pageSize);
  };
    
  useEffect(() => { handleSearch(); }, []);

  const columns = [
    {
      title: 'Level',
      dataIndex: 'level',
      key: 'level',
      width: 80,
    },

    {
      title: 'Star rating',
      dataIndex: 'starRating',
      key: 'starRating',
      width: 150,
    },

    {
      title: 'Upgradation Cost',
      dataIndex: 'upgradationCost',
      key: 'upgradationCost',
      width: 180,
    },

    {
      title: 'Incubator Number',
      dataIndex: 'incubatorNum',
      key: 'incubatorNum',
      width: 180,
    },
    {
      title: 'Shortened Time',
      dataIndex: 'shortenedTime',
      key: 'shortenedTime',
      width: 180,
    },

    {
      title: 'Spin Number',
      dataIndex: 'spinNum',
      key: 'spinNum',
      width: 130,
    },

    {
      title: 'Attack Bonus',
      dataIndex: 'attackBonus',
      key: 'attackBonus',
      width: 130,
    },
    
    {
      title: 'Roulette Cell',
      dataIndex: 'rouletteCell',
      key: 'rouletteCell',
      width: 130,
    },
    
    {
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 90,
      render: (record) => (
        <Button type="text" onClick={() => toEdit(record)}>
          <EditOutlined />
        </Button>
      ),
    },
  ];

  const DisplayBreadCrumb = () => (
    <div className="breadcrumb-layout">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link href="/incubatorConfigListPage">Incubator Config</Link>
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );

  return (
    <>
      <DisplayBreadCrumb />
      <Content className="layout-background">
        <Space direction="vertical" style={{ display: 'flex' }}>
          <Row justify="end">
            <Col>
              <Space>
                <Button type="primary" onClick={() => toCreate()}>
                  <PlusOutlined />
                  Add New Incubator
                </Button>
              </Space>
            </Col>
          </Row>
          <div className="table-content">
            {loading === false ? (
              <Table
                columns={columns}
                dataSource={tableData}
                rowKey="id"
                pagination={{
                  pageSize: limit,
                  pageSizeOptions: [10, 20],
                  showSizeChanger: true,
                  total: totalData,
                  onChange: onTablePageChange,
                  current: page,
                }}
                scroll={{ x: '100%' }}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </Space>
      </Content>
    </>
  );
}
