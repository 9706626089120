import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Form,
  Table,
  Col,
  Layout,
  Row,
  Space,
  Button,
  Typography,
  Breadcrumb,
  Input,
  message,
  Tooltip,
  Select,
  Skeleton,
} from 'antd';
import { PlusOutlined, EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import moment from 'moment';

import axios from 'axios';
import { getToken } from '../../../utils/AuthService';

const { Content } = Layout;
const { Title, Link } = Typography;

export function MinGameHeecoinPage() {
  const [loading, setLoading] = useState(true);
  const [tableData, setTableData] = useState([]);

  const [totalData, setTotalData] = useState();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [filterForm] = Form.useForm();

  const getTableData = async (pageNum, pageSize) => {
    try {
      setLoading(true);
      const res = await axios
        .get('/heecoinHistory/getConversionHeeCoinHistory', {
          params: {
            ...filterForm.getFieldsValue(),

            limit: pageSize,
            page: pageNum,
          },
        });
    
      setTableData(res.data.results);
      setTotalData(res.data.totalResults);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSearch = () => {
    setPage(1);

    getTableData(1, limit);
  };
    
  const onTablePageChange = (current, pageSize) => {
    setPage(current);
    setLimit(pageSize);
    getTableData(current, pageSize);
  };
  const clearFilter = () => {
    filterForm.resetFields();
  
    handleSearch();
  };
  
  useEffect(() => { handleSearch(); }, []);

  const columns = [

    {
      title: 'Pet Name',
      key: 'Pet Name',
      width: 150,
      render: (_data, row) => {
        return row.pet?.name || '-';
      },
    },
    {
      title: 'Pet Type',
      key: 'Pet Type',
      width: 150,
      render: (_data, row) => {
        return row.pet?.type || '-';
      },
    },
    {
      title: 'Player UID',
      key: 'playerId',
      width: 150,
      render: (data, row) => {
        return row.player?.uid || '-';
      },
    },

    {
      title: 'Game Score',
      dataIndex: 'gameScoreConversionMeta.gameScore',
      key: 'gameScore',
      width: 150,

      render: (data, row) => {
        return row.gameScoreConversionMeta?.gameScore || '-';
      },
    },
    
    {
      title: 'HeeCoin',
      dataIndex: 'heeCoin',
      key: 'heeCoin',
      width: 150,
    },
    
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 150,
      render: (data) => {
        return moment(data).format('YYYY-MM-DD HH:mm:ss');
      },
    },
 
  ];
 
  return (
    <>

      <Content className="layout-background" style={{ padding: '0 20px' }}>

        <Title
          level={3}
          style={{ display: 'flex', justifyContent: 'flex-start' }}>
          Heecoin History
        </Title>
        <Form form={filterForm} layout="inline">
          <Form.Item name="playerUid" label="Player Uid">
            <Input placeholder="please input" />
          </Form.Item>
         
          <Form.Item>
            <Space>
              <Col>
                <Button danger onClick={() => clearFilter()}>
                  Reset Filters
                </Button>
              </Col>
              <Col>
                <Button onClick={() => handleSearch()}>Apply Filters</Button>
              </Col>
            </Space>
          </Form.Item>
        </Form>
        <Space direction="vertical" style={{ display: 'flex', marginTop: '20px' }}>
    
          <div className="table-content">
            {loading === false ? (
              <Table
                columns={columns}
                dataSource={tableData}
                rowKey="_id"
                scroll={{ x: '100%' }}
                pagination={{ pageSize: limit,
                  pageSizeOptions: [10, 20, 50],
                  showSizeChanger: true,
                  total: totalData,
                  onChange: onTablePageChange,
                  current: page,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
              />
            ) : (
              <Skeleton />
            )}
          </div>
        </Space>
      </Content>
    </>
  );
}
